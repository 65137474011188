import React from "react";
import { WORKFLOW_SETUP, constants } from "../../../services/constants/constants";
import { isValidParam, getStringParam } from "../../../services/helper/parameterVerifier";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import AuditTrailAndLeadScore from "../components/auditTrailAndLeadScore";
import TemplateDialog from "../components/templateDialog";
import ScheduleDialog from "../components/scheduleDialog";
import ConfirmScheduleDialog from "../components/confirmScheduleDialog";
import ExecuteDialog from "../components/executeDialog";
import AddGroupDialog from "../components/addGroupDialog";
import RepeatDialog from "../components/repeatDialog";
import ListViewLogoUpload from "../components/listViewLogoUpload";
import AlertDialog from "../components/alertDialog";
import SFDialogContainer from "../components/sfDialogContainer";
import AttachmentUpload from "../components/attachmentUpload";
import Lookup from "./Lookup";
import TestMail from "../components/testMail";
import AutomationTestMail from "../components/automationTestMail";
import RecurringEditConfirmDailog from "../components/recurringEditConfirmDailog";
import RecurringDeleteDailog from "../components/recurringDeleteDialog";
import InvoiceRecipientsDailog from "../components/InvoiceRecipientsDailog";
import MarkPaidAllActionDailog from "../components/MarkPaidAllActionDailog";
import DocumentLibraryDialog from "../components/documentLibraryDialog";
import ConvertToAccount from "../components/convertToAccount";
import AdvanceSearchDate from "../components/advanceSearchDate";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import ProfileImageUpload from "../components/profileImageUpload";
import VideoInsertDialog from "../components/videoInsertDialog";
import VerifyEmailDialog from "../components/verifyEmailDialog";
import AddCampaignTypeDialog from "../components/addCampaignTypeDialog";
import AddCategoryDialog from "../components/addCategoryDialog";
import PickAndAddContactDialogForSms from "../components/pickAndAddContactDialogForSms";
import ListViewDatePicker from "../components/listViewDatePicker";
import EditorSelectionDialog from "../components/editorSelectionDialog";
import EditorTemplateDialog from "../components/editorTemplateDialog";
import DocumentPush from "../components/documentPush";
import FieldValueDialog from "../components/FieldValueDialog";
import ExternalMenu from '../components/ExternalMenu';
import UploadDocument from "../components/uploadDocument";
import ChangeLogo from "../components/changeLogo";
import Restore from "../components/restore";
import AssetsMenuDialog from "../components/assetsMenuDialog";
import AssetsCreateFolder from "../components/assetsCreateFolder";
import AutomationScheduleDialog from "../components/automationScheduleDialog";
import SFScheduleDialog from "../components/SFScheduleDialog";
import WorkflowDialog from "../components/workflowDialog";
import SFCalendarDialog from "../components/SFCalendarDialog";
import LandingPageFormBuilder from "../components/landingPageFormBuilder";
import DocumentShare from "../components/documentShare";
import { getAppDialog, setAppDialogActions, } from "../../../services/actions/appContainerActions";
import SelectUnitDialog from "../components/SelectUnitDialog";
import SelectAccountForUnitDilog from '../components/SelectAccountForUnitDilog';
import TestSms from "../components/TestSms";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import AddWorkFlowSetupStages from "../components/AddWorkFlowSetupStages";
import RoyaltyRejectionReasonDialog from "../components/RoyaltyRejectionReasonDialog";
import AttachmentDialog from "../components/AttachmentDialog";
import AuditCategoriesDialog from "../components/AuditCategoriesDialog";
import AddAuditCategoryQuestion from "../components/AddAuditCategoryQuestion";
import AuditSelectChecklistDialog from "../components/AuditSelectChecklistDialog";
import DashboardNewWidgetdDialog from "../components/dashboardNewWidgetdDialog";
import DashboardAddWigetDialog from "../components/dashboardAddWigetDialog";
import MobileAuditAttachments from "../components/MobileAuditAttachments";
import AdvancePartnerSearch from "../components/AdvancePartnerSearch.js";

const SFCustomDialog = ({ params }) => {
	let _params = useParams();
	const dispatch = useDispatch();
	if (params == undefined) {
		params = _params;
	}

	const app = useSelector((state) => state.app);
	const appContainer = useSelector((state) => state.appContainer);
	const { dialogProps: { data
		, dialogType
	} } = useSelector((state) => state.appContainer);
	const location = useLocation();
	const path = location.pathname.split("/")[1];





	const handleDialogClose = () => {
		if (appContainer.dialogProps?.callBackActions?.onClose) {
			appContainer.dialogProps.callBackActions.onClose();
		}
		dispatch(getAppDialog(false, null, null, null, null, null));
	};
	const getDialogComponent = () => {
		try {
			let dialogType = appContainer.dialogProps.dialogType;
			let object = appContainer.dialogProps.data !== undefined && appContainer.dialogProps.data !== null &&
				appContainer.dialogProps.data.hasOwnProperty("object")
				? appContainer.dialogProps.data.object
				: params?.object;
			if (object === "appointments") {
				if (isValidParam(appContainer.customDrawerProps)) {
					if (
						appContainer.customDrawerProps.data.object ===
						constants.BROADCAST_CAMPAIGNS_OBJECT
					) {
						object = constants.BROADCAST_CAMPAIGNS_OBJECT;
					}
				}
			}
			if (appContainer.dialogProps?.data?.object == undefined && params?.object == "units") {
				object = "units";
			}
			if (appContainer.dialogProps?.data?.object == undefined && params?.object == "royaltyrun" && appContainer.dialogProps?.dialogType !== "sendMailAttachmentUploadDialog" && appContainer.dialogProps?.dialogType !== "LibraryDialog") {
				object = "royaltyrun";
				dialogType = "RejectionReasonDialog"
			}
			if (dialogType === constants.ADVANCE_PARTNE_RSEARCH) {
				return <AdvancePartnerSearch object={constants.PARTNERS_OBJECT} />;
			}
			else if (isValidParam(object)) {
				switch (object) {
					case constants.USEFUL_LINK_OBJECT:
						if (dialogType === constants.USEFUL_LINK_OBJECT_CATEGORY) {
							return <div>{appContainer.dialogProps.data}</div>;
						}
						break;

					case constants.SMS_TEMPLATE_OBJECT:
						if (dialogType === constants.SMS_MESSAGE_DIALOG) {
							return (
								<TestSms data={appContainer.dialogProps.data} />
							)
						} else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
									data={appContainer.dialogProps.data}
								/>
							);
						}

					case constants.SALES_TEMPLATE_OBJECT:
						if (dialogType === constants.TEST_MAIL_DIALOG) {
							return (
								<TestMail data={appContainer.dialogProps.data} />
							);
						} else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
								/>
							);
						} else if (dialogType === constants.VIDEO_INSERT_DIALOG) {
							return <VideoInsertDialog />;
						}
						break;
					case constants.AGREEMENT_TEMPLATE_OBJECT:
						if (dialogType === constants.TEST_MAIL_DIALOG) {
							return (
								<TestMail data={appContainer.dialogProps.data} />
							);
						} else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
								/>
							);
						} else if (dialogType === constants.VIDEO_INSERT_DIALOG) {
							return <VideoInsertDialog />;
						}
						break;
					case constants.MARKETING_TEMPLATE_OBJECT:
						if (dialogType === constants.TEST_MAIL_DIALOG) {
							return (
								<TestMail data={appContainer.dialogProps.data} />
							);
						} else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
								/>
							);
						} else if (dialogType === constants.VIDEO_INSERT_DIALOG) {
							return <VideoInsertDialog />;
						} else if (dialogType === constants.EDITOR_SELECTION_DIALOG) {
							return <EditorSelectionDialog />;
						} else if (dialogType === constants.EDITOR_TEMPLATE_DIALOG && params.object === constants.MARKETING_TEMPLATE_OBJECT && params.suffix === "queries") {
							return <EditorTemplateDialog object={object} />;
						}
						break;
					case constants.NEWS_OBJECT:
						if (dialogType === constants.VIDEO_INSERT_DIALOG) {
							return <VideoInsertDialog />;
						} else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
								/>
							);
						}
						break;

					case constants.BROADCAST_CAMPAIGNS_OBJECT:
						if (dialogType === constants.TEMPLATE_DIALOG) {
							return <TemplateDialog />;
						} else if (dialogType === constants.EDITOR_TEMPLATE_DIALOG) {
							return <EditorTemplateDialog object={object} />;
						} else if (dialogType === constants.SCHEDULE_DIALOG) {
							return <ScheduleDialog data={{ type: "schedule" }} />;
						} else if (dialogType === constants.RESCHEDULE_DIALOG) {
							return <ScheduleDialog data={{ type: "reschedule" }} />;
						} else if (dialogType === constants.CONFIRM_SCHEDULE_DIALOG) {
							return (
								<ConfirmScheduleDialog
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.EXECUTE_DIALOG) {
							return <ExecuteDialog />;
						} else if (dialogType === constants.ADD_GROUP_DIALOG) {
							return (
								<AddGroupDialog
									app={app}
									appContainer={appContainer}
									setAppDialogActions={setAppDialogActions}
									getAppDialog={getAppDialog}
								/>
							);
						} else if (dialogType === constants.TEST_MAIL_DIALOG) {
							return (
								<TestMail data={appContainer.dialogProps.data} />
							);
						} else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
								/>
							);
						} else if (dialogType === constants.VIDEO_INSERT_DIALOG) {
							return <VideoInsertDialog />;
						} else if (dialogType === constants.VERIFY_EMAIL_DIALOG) {
							return <VerifyEmailDialog />;
						} else if (dialogType === constants.ADD_CAMPAIGN_TYPE_DIALOG) {
							return <AddCampaignTypeDialog />;
						}
						break;
					case constants.AB_CAMPAIGNS_OBJECT:
						if (dialogType === constants.TEMPLATE_DIALOG) {
							return <TemplateDialog />;
						} else if (dialogType === constants.SCHEDULE_DIALOG) {
							return <ScheduleDialog data={{ type: "schedule" }} />;
						} else if (dialogType === constants.RESCHEDULE_DIALOG) {
							return <ScheduleDialog data={{ type: "reschedule" }} />;
						} else if (dialogType === constants.CONFIRM_SCHEDULE_DIALOG) {
							return (
								<ConfirmScheduleDialog
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.EXECUTE_DIALOG) {
							return <ExecuteDialog />;
						} else if (dialogType === constants.ADD_GROUP_DIALOG) {
							return (
								<AddGroupDialog
									app={app}
									appContainer={appContainer}
									setAppDialogActions={setAppDialogActions}
									getAppDialog={getAppDialog}
								/>
							);
						} else if (dialogType === constants.TEST_MAIL_DIALOG) {
							return (
								<TestMail data={appContainer.dialogProps.data} />
							);
						} else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
								/>
							);
						} else if (dialogType === constants.VIDEO_INSERT_DIALOG) {
							return <VideoInsertDialog />;
						} else if (dialogType === constants.VERIFY_EMAIL_DIALOG) {
							return <VerifyEmailDialog />;
						}
						break;
					case constants.ACCOUNTS_OBJECT:
						if (dialogType === constants.ADD_GROUP_DIALOG) {
							return (
								<AddGroupDialog
									app={app}
									appContainer={appContainer}
									setAppDialogActions={setAppDialogActions}
									getAppDialog={getAppDialog}
								/>
							);
						} else if (dialogType === constants.SMS_SCHEDULE_DIALOG) {
							return <SFScheduleDialog data={{ type: "schedule" }} />;
						} else if (dialogType === constants.AUDIT_SELECT_CHECKLIST) {
							return <AuditSelectChecklistDialog
								data={appContainer.dialogProps.data}
								callFrom={appContainer.dialogProps.callFrom}
							/>
						}
						else if (dialogType === "XmsPushDialog") {
							return <FieldValueDialog />;
						}
						else if (dialogType === constants.SMS_CONFIRM_SCHEDULE_DIALOG) {
							return (
								<ConfirmScheduleDialog
									data={appContainer.dialogProps.data}
									callFrom={appContainer.dialogProps.callFrom}
								/>
							);
						} else if (dialogType === constants.VIDEO_INSERT_DIALOG) {
							return <VideoInsertDialog />;
						} else if (dialogType === constants.AUDIT) {
							return (
								<AuditTrailAndLeadScore
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.LISTVIEW_ATTACHMENT_UPLOAD_DIALOG || dialogType === constants.SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG) {
							return (
								<AttachmentUpload data={appContainer.dialogProps.data} />
							)
						} else if (
							dialogType === constants.LIST_VIEW_LOGO_UPLOAD_DIALOG ||
							dialogType === constants.DETAIL_VIEW_LOGO_UPLOAD_DIALOG
						) {
							return (
								<ListViewLogoUpload
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.LIST_VIEW_DATE_PICKER) {
							return (
								<ListViewDatePicker
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.ASSETMENU_DIALOG) {
							return (
								<AssetsMenuDialog
									callFrom={appContainer.dialogProps.callFrom}
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.LOOKUP_DIALOG) {
							return (
								<Lookup object={constants.ACCOUNTS_OBJECT} isLookup={true} />
							);
						} else if (dialogType === constants.SELECT_UNIT) {
							return <SelectUnitDialog />;
						} else if (dialogType === constants.ALERT_DIALOG) {
							return (
								<AlertDialog
									data={appContainer.dialogProps.data}
									getAppDialog={getAppDialog}
								/>
							);
						} else if (dialogType === constants.TASKS) {
							return (
								<AttachmentDialog
									data={appContainer.dialogProps.data}
									getAppDialog={getAppDialog}
								/>
							);
						}
						break;
					case constants.AUDIT_QUESTIONS_OBJECT:
						if (dialogType === constants.AUDIT_CATEGORY_TYPE_ADD_DIALOG) {
							return <AddCategoryDialog />;
						}
						break;

					case constants.AUDIT_CHECKLIST_CATEGORY_OBJECT:
						if (dialogType === constants.AUDIT_CATEGORY_QUESTION_TYPE_DIALOG) {
							return <AddAuditCategoryQuestion data={appContainer.dialogProps.data} />
						}
						break;
					case constants.AUDITS_OBJECT:
						if (dialogType === constants.TASKS) {
							return <AttachmentDialog
								data={appContainer.dialogProps.data}
								getAppDialog={getAppDialog}
							/>
						} else if (dialogType === constants.VIDEO_INSERT_DIALOG) {
							return <VideoInsertDialog />;
						} else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG) {
							{
								return (
									<AttachmentUpload
										data={appContainer.dialogProps.data}
									/>
								);
							}
						} else if (dialogType === constants.MOBILE_AUDITS_OBJECT) {
							return (
								<MobileAuditAttachments 	
									callFrom={appContainer.dialogProps.callFrom}
									data={appContainer.dialogProps.data} 
								/>
							);
						}
						break;
					case constants.INBOX_OBJECT:
						if (dialogType === constants.SMS_CONTACT_PICKING_DIALOG) {
							return <PickAndAddContactDialogForSms object={object} />;
						}
						else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.SMS_SCHEDULE_DIALOG) {
							return <SFScheduleDialog data={{ type: "schedule" }} />;
						} else if (dialogType === constants.SMS_CONFIRM_SCHEDULE_DIALOG) {
							return (
								<ConfirmScheduleDialog
									data={appContainer.dialogProps.data}
									callFrom={appContainer.dialogProps.callFrom}
								/>
							);
						}

						break;
					case constants.APPOINTMENTS:
						if (dialogType === constants.REPEAT_DIALOG) {
							return <RepeatDialog />;
						} else if (dialogType === constants.RECURRING_EDIT_CONFIRM_DIALOG) {
							return <RecurringEditConfirmDailog />;
						} else if (dialogType === constants.RECURRING_DELETE_DIALOG) {
							return <RecurringDeleteDailog object={constants.APPOINTMENTS} />;
						} else if (dialogType === constants.LOOKUP_DIALOG) {
							return (
								<Lookup object={constants.ACCOUNTS_OBJECT} isLookup={true} />
							);
						} else if (dialogType === constants.LIST_VIEW_DATE_PICKER) {
							return (
								<ListViewDatePicker
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.VIDEO_INSERT_DIALOG) {
							return <VideoInsertDialog />;
						}

						break;
					case constants.OPPORTUNITIES_OBJECT:
						if (
							dialogType === constants.LIST_VIEW_LOGO_UPLOAD_DIALOG ||
							dialogType === constants.DETAIL_VIEW_LOGO_UPLOAD_DIALOG
						) {
							return (
								<ListViewLogoUpload
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.VIDEO_INSERT_DIALOG) {
							return <VideoInsertDialog />;
						} else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (
							dialogType === constants.LISTVIEW_ATTACHMENT_UPLOAD_DIALOG ||
							dialogType === constants.SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG
						) {
							return (
								<AttachmentUpload
									data={appContainer.dialogProps.data}
								/>
							);
						}
						else if (dialogType === constants.SF_CALENDAR_DIALOG) {
							return (
								<SFCalendarDialog />
							);
						}

						else if (dialogType === constants.LOOKUP_DIALOG) {
							let lookupObject = getStringParam(
								appContainer.dialogProps.data.lookupObject
							);
							return (
								<Lookup
									object={
										lookupObject === constants.ACCOUNTS_OBJECT
											? constants.ACCOUNTS_OBJECT
											: constants.CONTACTS_OBJECT
									}
									isLookup={true}
								/>
							);
						} else if (dialogType === constants.LIST_VIEW_DATE_PICKER) {
							return (
								<ListViewDatePicker
									data={appContainer.dialogProps.data}
								/>
							);

						} else if (dialogType === constants.TASKS) {
							return (
								<AttachmentDialog
									data={appContainer.dialogProps.data}
									getAppDialog={getAppDialog}
								/>
							);
						}
						break;
					case constants.TASKS:
						if (dialogType === constants.REPEAT_DIALOG) {
							return <RepeatDialog />;
						} else if (dialogType === constants.RECURRING_EDIT_CONFIRM_DIALOG) {
							return <RecurringEditConfirmDailog />;
						} else if (dialogType === constants.RECURRING_DELETE_DIALOG) {
							return <RecurringDeleteDailog object={constants.TASKS} />;
						} else if (dialogType === constants.LOOKUP_DIALOG) {
							return (
								<Lookup object={constants.ACCOUNTS_OBJECT} isLookup={true} />
							);
						} else if (dialogType === constants.LIST_VIEW_DATE_PICKER) {
							return (
								<ListViewDatePicker
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.SF_CALENDAR_DIALOG) {
							return (
								<SFCalendarDialog />
							);
						} else if (dialogType === constants.LISTVIEW_ATTACHMENT_UPLOAD_DIALOG || dialogType === constants.SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG) {
							return (
								<AttachmentUpload data={appContainer.dialogProps.data} />
							)
						} else if (dialogType === constants.TASKS) {
							return (
								<AttachmentDialog
									data={appContainer.dialogProps.data}
									getAppDialog={getAppDialog}
								/>
							);
						}
						break;
					case constants.CONTACTS_OBJECT:
						if (
							dialogType === constants.LIST_VIEW_LOGO_UPLOAD_DIALOG ||
							dialogType === constants.DETAIL_VIEW_LOGO_UPLOAD_DIALOG
						) {
							return (
								<ListViewLogoUpload
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (
							dialogType === constants.LISTVIEW_ATTACHMENT_UPLOAD_DIALOG ||
							dialogType === constants.SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG
						) {
							return (
								<AttachmentUpload
									data={appContainer.dialogProps.data}
								/>
							);
						}
						else if (dialogType === constants.SMS_SCHEDULE_DIALOG) {
							return <SFScheduleDialog data={{ type: "schedule" }} />;
						} else if (dialogType === constants.SMS_CONFIRM_SCHEDULE_DIALOG) {
							return (
								<ConfirmScheduleDialog
									data={appContainer.dialogProps.data}
									callFrom={appContainer.dialogProps.callFrom}
								/>
							);
						} else if (dialogType === constants.VIDEO_INSERT_DIALOG) {
							return <VideoInsertDialog />;
						} else if (dialogType === constants.LOOKUP_DIALOG) {
							return (
								<Lookup object={constants.ACCOUNTS_OBJECT} isLookup={true} />
							);
						} else if (dialogType === constants.ADD_GROUP_DIALOG) {
							return (
								<AddGroupDialog
									app={app}
									appContainer={appContainer}
									setAppDialogActions={setAppDialogActions}
									getAppDialog={getAppDialog}
								/>
							);
						} else if (dialogType === constants.LIST_VIEW_DATE_PICKER) {
							return (
								<ListViewDatePicker
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.ASSETMENU_DIALOG) {
							return (
								<AssetsMenuDialog
									callFrom={appContainer.dialogProps.callFrom}
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.ALERT_DIALOG) {
							return (
								<AlertDialog
									data={appContainer.dialogProps.data}
									getAppDialog={getAppDialog}
								/>
							);
						} else if (dialogType === constants.TASKS) {
							return (
								<AttachmentDialog
									data={appContainer.dialogProps.data}
									getAppDialog={getAppDialog}
								/>
							);
						}
						break;
					case constants.SETTING:
						if (dialogType === constants.AUDIT_QUESTIONS_OBJECT) {
							return (
								<AuditCategoriesDialog object={object} />
							)
						}
					case constants.AUTOMATION_DESIGNER_OBJECT:
						if (dialogType === constants.TEMPLATE_DIALOG) {
							return (
								<TemplateDialog
									setTemplateData={
										appContainer.dialogProps.eventName
									}
								/>
							);
						} else if (dialogType === constants.ALERT_DIALOG) {
							return (
								<AlertDialog
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.TEST_MAIL_DIALOG) {
							return (
								<TestMail data={appContainer.dialogProps.data} />
							);
						} else if (dialogType === constants.VIDEO_INSERT_DIALOG) {
							return <VideoInsertDialog />;
						} else if (dialogType === constants.LIST_VIEW_DATE_PICKER) {
							return (
								<ListViewDatePicker
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.VERIFY_EMAIL_DIALOG) {
							return <VerifyEmailDialog />;
						} else if (dialogType === constants.ADD_CAMPAIGN_TYPE_DIALOG) {
							return <AddCampaignTypeDialog />;
						} else if (dialogType === constants.AUTOMATION_TEST_MAIL_DIALOG) {
							return (
								<AutomationTestMail
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.SMS_MESSAGE_DIALOG) {
							return (
								<TestSms
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.EDITOR_SELECTION_DIALOG) {
							return <EditorSelectionDialog />;
						} else if (dialogType === constants.AUTOMATION_SCHEDULE_DIALOG) {
							return <AutomationScheduleDialog />;
						} else if (dialogType === constants.EDITOR_TEMPLATE_DIALOG) {
							return (
								<EditorTemplateDialog
									object={constants.AUTOMATION_DESIGNER_OBJECT}
									setTemplateData={
										appContainer.dialogProps.eventName
									}
								/>
							);
						} else if (dialogType === constants.EXTERNAL_LISTS_DIALOG) {
							return <ExternalMenu />
						}
						break;
					case constants.CASES_OBJECT:
						if (dialogType === constants.LOOKUP_DIALOG) {
							return (
								<Lookup object={constants.ACCOUNTS_OBJECT} isLookup={true} />
							);
						} else if (dialogType === constants.LIST_VIEW_DATE_PICKER) {
							return (
								<ListViewDatePicker
									data={appContainer.dialogProps.data}
								/>
							);
						}
						break;
					case constants.PROJECTS_OBJECT:
						if (dialogType === constants.LOOKUP_DIALOG) {
							return (
								<Lookup object={constants.ACCOUNTS_OBJECT} isLookup={true} />
							);
						} else if (dialogType === constants.LIST_VIEW_DATE_PICKER) {
							return (
								<ListViewDatePicker
									data={appContainer.dialogProps.data}
								/>
							);
						}
						break;
					case constants.ISSUES_OBJECT:
						if (dialogType === constants.LOOKUP_DIALOG) {
							return (
								<Lookup object={constants.PROJECTS_OBJECT} isLookup={true} />
							);
						} else if (dialogType === constants.LIST_VIEW_DATE_PICKER) {
							return (
								<ListViewDatePicker
									data={appContainer.dialogProps.data}
								/>
							);
						}
						break;
					case constants.ATTACHMENT_OBJECT:
						if (
							dialogType === constants.LISTVIEW_ATTACHMENT_UPLOAD_DIALOG ||
							dialogType === constants.SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG
						) {
							return (
								<AttachmentUpload
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.LIST_VIEW_DATE_PICKER) {
							return (
								<ListViewDatePicker
									data={appContainer.dialogProps.data}
								/>
							);
						}
						break;
					case constants.CUSTOM_TABLE1_OBJECT:
						if (dialogType === constants.LOOKUP_DIALOG) {
							return (
								<Lookup object={constants.ACCOUNTS_OBJECT} isLookup={true} />
							);
						} else if (dialogType === constants.LIST_VIEW_DATE_PICKER) {
							return (
								<ListViewDatePicker
									data={appContainer.dialogProps.data}
								/>
							);
						}
						break;
					case constants.CUSTOM_TABLE2_OBJECT:
						if (dialogType === constants.LOOKUP_DIALOG) {
							return (
								<Lookup object={constants.ACCOUNTS_OBJECT} isLookup={true} />
							);
						} else if (dialogType === constants.LIST_VIEW_DATE_PICKER) {
							return (
								<ListViewDatePicker
									data={appContainer.dialogProps.data}
								/>
							);
						}
						break;
					case constants.CUSTOM_TABLE3_OBJECT:
						if (dialogType === constants.LOOKUP_DIALOG) {
							return (
								<Lookup object={constants.ACCOUNTS_OBJECT} isLookup={true} />
							);
						} else if (dialogType === constants.LIST_VIEW_DATE_PICKER) {
							return (
								<ListViewDatePicker
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (
							dialogType === constants.LISTVIEW_ATTACHMENT_UPLOAD_DIALOG ||
							dialogType === constants.SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG
						) {
							return (
								<AttachmentUpload
									data={appContainer.dialogProps.data}
								/>
							);
						}
						break;
					case constants.CUSTOM_TABLE4_OBJECT:
						if (dialogType === constants.LOOKUP_DIALOG) {
							return (
								<Lookup object={constants.ACCOUNTS_OBJECT} isLookup={true} />
							);
						} else if (dialogType === constants.LIST_VIEW_DATE_PICKER) {
							return (
								<ListViewDatePicker
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (
							dialogType === constants.LISTVIEW_ATTACHMENT_UPLOAD_DIALOG ||
							dialogType === constants.SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG
						) {
							return (
								<AttachmentUpload
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
									data={appContainer.dialogProps.data}
								/>
							);
						}
						break;
					case constants.GROUP_OBJECT:
						if (dialogType === constants.ALERT_DIALOG) {
							return (
								<AlertDialog
									data={appContainer.dialogProps.data}
									getAppDialog={getAppDialog}
								/>
							);
						}
						break;
					case constants.SOLUTIONS_OBJECT:
						if (dialogType === constants.LOOKUP_DIALOG) {
							return (
								<Lookup object={constants.ACCOUNTS_OBJECT} isLookup={true} />
							);
						}
						break;
					case constants.ASSETS_OBJECT:
						if (dialogType === constants.ASSETS_FOLDER_ADD_DIALOG) {
							return <AssetsCreateFolder data={appContainer.dialogProps.data} />;
						} else if (dialogType === constants.DOCUMENT_SHARE_DIALOG) {
							return <DocumentShare />;
						} else if (dialogType === constants.DOCUMENT_UPLOAD_DIALOG) {
							return <UploadDocument />;
						} else if (dialogType === constants.DOCUMENT_PUSH_DIALOG) {
							return <DocumentPush />;
						}
						break;
					case constants.PETS_OBJECT:
						if (
							dialogType === constants.LIST_VIEW_LOGO_UPLOAD_DIALOG ||
							dialogType === constants.DETAIL_VIEW_LOGO_UPLOAD_DIALOG
						) {
							return (
								<ListViewLogoUpload
									data={appContainer.dialogProps.data}
								/>
							);
						}
						break;
					case constants.UNITS_OBJECT:
						if (
							dialogType === constants.SELECT_ACCOUNT
						) {
							return (
								<SelectAccountForUnitDilog
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.SMS_SCHEDULE_DIALOG) {
							return <SFScheduleDialog data={{ type: "schedule" }} />;
						} else if (dialogType === constants.SMS_CONFIRM_SCHEDULE_DIALOG) {
							return (
								<ConfirmScheduleDialog
									data={appContainer.dialogProps.data}
									callFrom={appContainer.dialogProps.callFrom}
								/>
							);
						} else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.LOOKUP_DIALOG && appContainer.dialogProps?.data?.object == constants.OPPORTUNITIES_OBJECT) {
							return (
								<Lookup object={constants.OPPORTUNITIES_OBJECT} isLookup={true} />
							);
						}
						else if (dialogType === constants.LOOKUP_DIALOG) {
							return (
								<Lookup object={constants.ACCOUNTS_OBJECT} isLookup={true} />
							);
						}
						else if (dialogType === constants.SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG) {
							return (
								<AttachmentUpload data={appContainer.dialogProps.data} />
							)
						}
						break;
					default:
						if (dialogType === constants.SF_ALERT_DIALOG) {
							return <SFDialogContainer callFrom={appContainer.dialogProps.callFrom} data={appContainer.dialogProps.data} />;
						} else if (dialogType === constants.CONVERT_TO_ACCOUNTS) {
							return <ConvertToAccount />;
						} else if (dialogType === "RejectionReasonDialog") {
							return <RoyaltyRejectionReasonDialog />;
						}
						else if (dialogType === constants.ADVANCE_SEARCH_DATE_DIALOG) {
							return <AdvanceSearchDate />;
						} else if (
							dialogType === constants.SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG
						) {
							return (
								<AttachmentUpload
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (
							dialogType === constants.PROFILE_DETAILS_LOGO_UPLOAD_DIALOG
						) {
							return <ProfileImageUpload />;
						} else if (dialogType === constants.LIBRARY_DIALOG) {
							return (
								<DocumentLibraryDialog
									callFrom={appContainer.dialogProps.callFrom}
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.DASHBOARD_NEW_WIDGET) {
							return (
								<DashboardNewWidgetdDialog
									callFrom={appContainer.dialogProps.callFrom}
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.DASHBOARD_ADD_WIDGET) {
							return (
								<DashboardAddWigetDialog
									callFrom={appContainer.dialogProps.callFrom}
									data={appContainer.dialogProps.data}
								/>
							);
						} else if (dialogType === constants.LIST_VIEW_DATE_PICKER) {
							return (
								<ListViewDatePicker
									data={appContainer.dialogProps.data}
								/>
							);
						}
						else if (dialogType === constants.EXTERNAL_LISTS_DIALOG) {
							return <ExternalMenu />
						}
						else if (dialogType === constants.CHANGE_LOGO_UPLOAD_DIALOG) {
							return <ChangeLogo />;
						} else if (dialogType === constants.RESTORE) {
							return (
								<Restore
									data={appContainer.dialogProps.data}
									callFrom={appContainer.dialogProps.callFrom}
								/>
							);
						} else if (dialogType === constants.VIDEO_INSERT_DIALOG) {
							return <VideoInsertDialog />;
						} else if (dialogType === constants.SMS_SCHEDULE_DIALOG) {
							return <SFScheduleDialog data={{ type: "schedule" }} />;
						} else if (dialogType === constants.SMS_CONFIRM_SCHEDULE_DIALOG) {
							return (
								<ConfirmScheduleDialog
									data={appContainer.dialogProps.data}
									callFrom={appContainer.dialogProps.callFrom}
								/>
							);
						} else if (dialogType === constants.SF_SCHEDULE_DIALOG) {
							return <SFScheduleDialog />;
						} else if (dialogType === constants.LOOKUP_DIALOG) {
							return (
								<Lookup object={constants.ACCOUNTS_OBJECT} isLookup={true} />
							);
						} else if (dialogType === constants.WORKFLOW_DIALOG) {
							return <WorkflowDialog />;
						} else if (dialogType === constants.SF_CALENDAR_DIALOG) {
							return <SFCalendarDialog />;
						}
						else if (dialogType === WORKFLOW_SETUP.CONFIGURE_WORKFLOW_STAGES) {
							return <AddWorkFlowSetupStages />;
						}
						else if (
							dialogType === constants.LANDING_PAGE_FORM_BUILDER_DIALOG
						) {
							return (
								<LandingPageFormBuilder
									data={appContainer.dialogProps.data}
								/>
							);
						}
						else if (dialogType === constants.INVOICE_RECIPIENTS) {
							return <InvoiceRecipientsDailog object={constants.INVOICE_RECIPIENTS} />;
						}
						else if (dialogType === constants.MARK_PAID_ALL) {
							return <MarkPaidAllActionDailog object={constants.MARK_PAID_ALL} />;
						}
						if (
							dialogType === constants.ADD_BRANCH
						) {
							return (
								<SelectAccountForUnitDilog
									data={appContainer.dialogProps.data}
								/>
							);
						}
						if (
							dialogType === constants.ADD_BASE
						) {
							return (
								<SelectAccountForUnitDilog
									data={appContainer.dialogProps.data}
								/>
							);
						}
						else {
							return <div />;
						}
				}
			}
		} catch (error) {
			console.error(
				"Error in 'SFCustomDialog.js -> getDialogComponent()':" + error
			);
		}
	}
	let _dialogType = appContainer.hasOwnProperty('dialogProps') && appContainer.dialogProps.hasOwnProperty("dialogType") ? appContainer.dialogProps.dialogType : "";
	const dialogComponent = appContainer.dialogProps.isOpenDialog ? getDialogComponent() : null;
	let object = appContainer.dialogProps.data !== undefined && appContainer.dialogProps.data !== null &&
		appContainer.dialogProps.data.hasOwnProperty("object")
		? appContainer.dialogProps.data.object
		: params?.object;
	return (
		<div>
			<Dialog key={"dialog_" + _dialogType}
				open={appContainer.dialogProps.isOpenDialog}
				onClose={handleDialogClose}
				scroll="paper"
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
				fullWidth={
					appContainer.dialogProps !== null &&
						appContainer.dialogProps !== undefined &&
						appContainer.dialogProps.data !== null &&
						appContainer.dialogProps.data !== undefined &&
						appContainer.dialogProps.hasOwnProperty("data") &&
						appContainer.dialogProps.data.hasOwnProperty(
							"fullWidth"
						)
						? appContainer.dialogProps.data.fullWidth
						: true
				}

				maxWidth={
					appContainer.dialogProps !== null &&
						appContainer.dialogProps !== undefined &&
						appContainer.dialogProps.data !== null &&
						appContainer.dialogProps.data !== undefined &&
						appContainer.dialogProps.hasOwnProperty("data") &&
						appContainer.dialogProps.data.hasOwnProperty(
							"maxWidth"
						)

						? appContainer.dialogProps.data.maxWidth
						: _dialogType === constants.REPEAT_DIALOG ||
							_dialogType ===
							constants.LISTVIEW_ATTACHMENT_UPLOAD_DIALOG ||
							_dialogType ===
							constants.SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG ||
							_dialogType === constants.DOCUMENT_UPLOAD_DIALOG
							? "md"
							: "sm"
				}
			>

				{_dialogType !== constants.XMS_PUSH_DIALOG && _dialogType !== constants.EXTERNAL_LISTS_DIALOG && (<DialogTitle id="scroll-dialog-title">
					<div className="sf-dialog-title">
						<div
							style={{
								width: (_dialogType === "Select Account" && object === 'units') || _dialogType === constants.MOBILE_AUDITS_OBJECT ? '' : "90%",
								display: "inline-block",
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
								fontWeight: '400',
								fontSize: _dialogType === constants.SCHEDULE_DIALOG && object === constants.BROADCAST_CAMPAIGNS_OBJECT ? '27px' : '25px',
								color: 'rgb(0 0 0 / 87%)',
								marginLeft: _dialogType === "Select Account" && object === 'units' ? '20px' : _dialogType === "AlertDialog" ? '2px' : '10px',
							}}
							title={appContainer.dialogProps.dialogTitle}
						>
							{appContainer.dialogProps.dialogTitle}
						</div>
						<span className="pull-right">
							<IconButton
								key={"close"}
								onClick={handleDialogClose}
								title={getLocalizedStrings().label.COMMON.CLOSE}>
								<Icon>close</Icon>
							</IconButton>
						</span>
					</div>
				</DialogTitle>)}
				<DialogContent
					style={
						appContainer.dialogProps !== null &&
							appContainer.dialogProps !== undefined &&
							appContainer.dialogProps.data !== null &&
							appContainer.dialogProps.data !== undefined &&
							appContainer.dialogProps.hasOwnProperty("data") &&
							appContainer.dialogProps.data.hasOwnProperty(
								"dialogContentHeight"
							)
							? {
								height:
									appContainer.dialogProps.data
										.dialogContentHeight,
							}
							: { height: '100%' }
					}
				>
					{dialogComponent}
				</DialogContent>
				<DialogActions>
					{appContainer.dialogProps.dialogActions}
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default SFCustomDialog;
