const message = {
    COMMON: {
        NO_ACTIONS_AVAILABLE: 'No object available for Quick Add action',
        NOTES_REMOVE_MESSAGE:'Selected Note(s) will be deleted. Are you sure?',
        SELECTED_NOTE_MESSAGE:'To perform this action, you must select at least one note.',
        ARCHIVE_CONFIRMATION_MESSAGE:'Selected Notes will be archived. Pinned notes will not be archived. Are you sure?',
        WEBHOOK_OPEN_DIALOG:'You have a webhook dialog open. Please save or cancel the webhook before performing this operation.',
        BR_WINDOW_OPEN_CONFORMATION: 'We do not support drag and drop feature for Campaigns. Would you like to open the Campaign?',
        CONFIRM_DIALOG_TITLE: 'Confirmation Dialog',
        ALERT_DIALOG_TITLE: 'Alert Dialog',
        XMS_SUCCESS_ALERT_DIALOG_TITLE: 'Success',
        XMS_ERROR_ALERT_DIALOG_TITLE: 'Error',
        SUCCESS_ALERT_DIALOG_TITLE: 'Success',
        WARNING_DIALOG_TITLE: 'Warning!',
        FIELD_EMPLTY_VALIDATION:'Please fill all required field.',
        COPY_EMAIL:'Email id has been copied to your clipboard',
        COPY_MOBILE:'Mobile Number has been copied to your clipboard',
        COPY_PHONE:'Phone Number has been copied to your clipboard',
        EMPTY_EMAIL:'No email is present to copy',
        FILED_TO_COPY:'No value is present to copy.',
        COPIED_SUCCESS : 'Copied Successfully.',
        EMPTY_PHONE:'No phone is present to copy',
        SAVE: 'Saved successfully.',
        MERGE: 'Merged successfully.',
        UPDATE: 'Updated successfully.',
        DELETE: 'Deleted successfully.',
        REMOVE: 'Removed successfully.',
        UNLINK: 'Unlinked successfully.',
        ARCHIVE: 'Archived successfully',
        UNARCHIVE: 'Unarchived sucessfully',
        ASSIGN: 'Assigned sucessfully',
        ERROR: 'System not responding.',
        ACCESS_DENIED: 'Sorry, you do not have the permission to perform this operation. Please contact your administrator.',
        NOTIFICATION_INFO_EMPTY: 'No notification available',
        /** START:  Validation message */
        BLANK: 'blank',
        EMAIL: 'is an invalid e-mail address.',
        EMAIL_VALIDATION:'Please Enter a valid email.',
        AGE_VALIDATION:'Please enter valid age',
        VALID_EMAIL: 'The email address you entered doesn\'t appear to be a valid address. Please try again.  Hint: Please ensure that the email address has the email\\@domain.com format.',
        URL: 'is an invalid url.',
        VALID_URL: 'Please enter a valid url.',
        INTEGER: 'is not an integer.',
        VALID_INTEGER: 'Please enter a valid integer.',
        FLOAT: 'is not a float.',
        VALID_FLOAT: 'Please enter a valid float.',
        VALID_PHONE: 'Phone numbers must be numeric!!',
        VALID_LIST: 'Please select a value.',
        TOO_BIG_SIZE: 'The value you have entered is too big for this field. Enter a lower value.',
        NO_RECORD: 'No record found.',
        NO_NOTES:'No Notes found',
        VALIDATE_BLANK: 'cannot be blank.',
        VALIDATE_INTEGER: 'has to be an integer.',
        VALIDATE_DECIMAL: 'has to be in decimal.',
        VALIDATE_POSITIVE_DECIMAL: 'has to be greater than 0 and in decimal.',
        VALIDATE_URL: 'Invalid URL format. Example - www.soffront.com',
        VALIDATE_EMAIL: 'Invalid Email format.',
        VALUE: 'value',
        LIBRARY_DIALOG_TITLE: 'Soffront Online Library',
        BLANK_EMAIL: 'Email is blank',
        BLANK_NUMBER : 'Number is blank',
        INVALID_NUMBER :'Please provide a valid Number ',
        INVALID_EMAIL: 'Please provide a valid email!',
        EMPTY_SEARCH_CRITERIA: 'Please Enter Search Criteria',
        VALIDATE_MORE_CHECKBOX_SELECTION: 'You can not perform search with <*> when more than one checkbox is selected!',
        VALIDATE_NO_CHECKBOX_SELECTION: 'You can not perform search with <*> when more than no checkbox is selected!',
        NO_DATA: 'No data Found',
        DISCONNECT_ALERT_MSG: 'Are you sure to disconnect this client?',
        DISCONNECT_SUCCESS: 'You have successfully disconnected your client.',
        CONFIRM_DELETE_RECORD: 'Record will be deleted. Are you sure?',
        DELETE_SUCCESS: ' has been deleted successfully.',
        COULDNOT_LOCATE: "We couldn't locate ",
        DELETED_OR_ACCESS_DENIED: " It may have been deleted or you may not have access to it or you have already opened it.",
        CLICK: 'Click',
        HERE: 'here',
        MERGE_ALERT_1: 'Are you sure to merge',
        WITH: 'with',
        MERGE_ALERT_2: 'and delete',
        DATA_ACCESS_REQUEST_SEND_SUCCESS: 'Data access Request sent to',
        DATA_ACCESS_REQUEST_SEND_FAILURE: 'Data access Request cannot send to"',
        REQUEST_DATA_ACCESS: 'Request data access from',
        WE_WILL_EMAIL: 'We will email',
        AT: 'at',
        TO_APPROVE_REQUEST: 'to approve your request',
        HAVE_BEEN: 'have been',
        REMINDER_VALUE: 'Reminder value cannot be blank',
        DUPLICATE_EMAIL: 'You already have record(s) with same email id. Click Ok to continue or click Cancel to change the email id.',
        DUPLICATE_CONTACT_EMAIL: 'You already have record(s) with same email id. Please enter different email id.',
        CLIENT_CONNECT_MESSAGE: 'This customer does not have an account with this email in Soffront Online. First ask the customer to create an account with the same email.',
        BLANK_CAMPAIGN_TYPE: 'Campaign type cannot be blank.',
        DUPLICATE_CAMPAIGN_TYPE: 'Campaign type already exists.',
        CAMPAIGN_TYPE_SAVE_SUCCESS: 'Campaign type saved successfully.',
        MAX_ATTACHMENT_VALIDATION:'Maximum three photo can be attached.',
        /** END:  Validation message */
        STEPPER_BACK_MESSAGE: 'Do you like to go to the Template info page? Any unsaved changes would be lost.',
        CENCEL_MOSAICO_MESSAGE: 'Are you sure you want to cancel without saving?',
        NO_RESULTS_FOUND: 'No results found',
        FOR: 'for',
        ENABLE_MAILING_SUCCESS: 'Successfully enabled email sending',
        DISABLE_MAILING_SUCCESS: 'Successfully disabled email sending',
        MAILING_FAILURE: 'This account is not a CRM tenant',
        TEST: 'Test',
        SELECT_RECORD_FOR_DELETE: 'Please select a record to delete.',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: 'Please select one record to edit.',
        SELECT_RECORD_FOR_DELETE_ONE_RECORD: 'Please select one record to delete.',
        ASSETMENU_DIALOG_TITLE: 'Select an Asset',
        NEW_EMAIL:'New Email',
        EMPTY_RECYCLE_BIN_MSG: 'Please unselect the records to perform empty recyle bin.',
        UNIT_SELECT_ALERT_MESSAGE: 'You can select one Unit at a time.',
        LOOKUP_MULTI_SELECT: 'Please select only one record.',
        LOOKUP_SELECT_A_RECORD: 'Please select one record.',
        TITLE_FIELD_BLANK: 'Title field cannot be blank',
        BOTH_COMPANY_AND_WORKFLOW_SELECTED: 'You have selected both company and workflow.',
        VALIDATE_ZERO: 'cannot be zero.',
        GREENLIGHT_EXPORT_NO_RECORD: 'There are no accounts in the Greenlight workflow for this date range.',
        WITHOUT_SAVE_MESSAGE: 'Do you want to continue without saving the changes?',
        DELETE_API_KEY: 'This will delete your API Key in CRM. Would you like to continue ?',
        API_KEY_MSG_1: 'The api key is a unique encrypted string assigned by',
        API_KEY_MSG_2: 'which identifies the user making an API call. This replaces basic username/password authentication.',
        API_KEY_MSG_3: 'Generate API Key!',
        API_KEY_MSG_4: 'Generated API Key',
        SOFFRONT: 'Soffront',
        UNIT_ALERT: 'Please select a record to login.',
        UNIT_PLAN_NAME_ALERT: 'Please select a record to add plan name.',
        THIS_FIELD_CANNOT_BLANK: 'This field cannot be blank.',
        CANNOT_DELETE_ALL_ITEM: 'You must have atleast one item in each module under Desktop Menu.',
        CANNOT_DELETE_ALL_MOBILE_ITEM: 'You must have atleast one item in each module under Mobile Menu.',
        DUPLICATE_MSG_1: 'You already have record(s) with same ',
        DUPLICATE_MSG_2: '. Click Ok to continue or click Cancel to change the ',
        DUPLICATE_MSG_3: ' and ',
        DUPLICATE_CONTACT_EMAIL_1: 'You already have record(s) with same ',
        DUPLICATE_CONTACT_EMAIL_2: '. Please enter different ',
        INVALID_TIME_ERROR_MSG: 'Please provide a valid time interval.',
        DOESNT_HAVE_ASSOCIATED_1:'This ',
        DOESNT_HAVE_ASSOCIATED_2: " doesn't have an associated contact.", 
        PHONE_CALL_NOT_ENABLED: 'Phone call is not enabled.',
        NOT_CONFG_CALL_SETUP : 'You have not configured call setup.',
        NOT_CONFG_CALL_SMS_SETUP:'You have not configured call/sms setup.',
        SEARCH_NO_CHECKBOX_SELECTION: 'You can not perform search when no checkbox is selected!',
        VALID_SOURCE_LIST: 'Please select source field.',
        LMS_USER_NOT_AVAILABLE: 'LMS is not activated yet. Please contact your administrator.',   
        ADD_BRANCH_BILLINGMASSAGE:'You currently have no licenses. You will be purchasing a new license for this unit at $40.00/month. In addition, a setup fee of $100.00 will be charged to setup and train the new tenant.'  ,
        NO_RECORDS_FOR_PRINT:'No record available for print.'
    },
    WORKFLOW_SETUP:{
        EMPTY_SUBJECT:'Please enter a subject',
        EMPTY_WORKFLOW_NAME:'Please enter a name to identify this workflow.',
        NO_OF_URL_ALLOWED:'Maximum 10 attachment Url allowed.',
        NOT_ALLOWED_TO_COPY_APPOINTMENT_MSG:'You do not have permission to copy this Appointment. There is already one Appointment available for this stage. You can add only one Appointment for a stage.',
        COPY_NOT_ALLOWED : 'You do not have permission to copy',
        EDIT_NOT_ALLOWED_MSG:'You do not have permission to edit this',
        DELETE_NOT_ALLOWED_MSG:'You do not have permission to delete this',
        DELETE_MSG_FOR_TASK:'will be deleted. Are you sure',
        DELETE_MSG_FOR_EMAIL:'task will be deleted. Are you sure? It will also delete the already scheduled email tasks for the Accounts records which are associated with this workflow and are in this stage',
        MULTIPLE_APPOINTMENT_AVAILABILITY_ERROR:'There is already one Appointment available for this stage. You can add only one Appointment for a stage',
        BODY_ERROR_EMAIL:'Please enter the body of this email',
        INTERVEL_ERROR:'Please enter the interval (when this email will go out)',
        WEBHOOK_OPEN:'You have a webhook dialog open. Please save or cancel the webhook before performing this operation.',
        EDIT_ERROR:'You have a task dialog open. Please save or cancel the task before performing this operation.',
        TEXT_MESSAGE_ERROR:'You have a Text message dialog open. Please save or cancel the message before performing this operation.',
        APPOINMENT_ERROR:'You have a appointment dialog open. Please save or cancel the appointment before performing this operation.',
        CHECK_EMAIL_TASK_OPEN:'You have a eamil dialog open. Please save or cancel the email before performing this operation.',
        PERSONALIZE:'Personalize',
        WEBHOOK_NAME_BLANK:'Please provide a webhook name!',
        WEBHOOK_URL_BLANK : 'Please provide an Endpoint URL !',
        WEBHOOK_DATA_BLANK : 'Data field cannot be blank!',
        INVALID_URL:'Please provide a valid url name',
        EMAPTY_MESSAGE:'Please provide message text',
        CONFIRM_DELETE_MESSAGE:'This WebHook will be deleted. Are you sure?',
        TASK_DELETE:'This task will be deleted. Are you sure?',
        APPOINTMENT_ERROR:'Please provide an appointment name',
        DUE_BY_EMPTY:'Please provide a value in the Due by field!',
        DELETE_STAGE:'Records are available in Lead stage. Are you sure want to delete?',
        TASK_NAME_EMPTY:'Please Provide a task name',
        WORKFLOW_NAME_BLANK_ERROR_MESSAGE:'Please enter a name to identify this workflow.',
        CONVERT_NEXT_STAGE_INFO_MESSAGE:'There will be two radio buttons – “Manually” and “Automatically”.“Manually” will be selected by default and it will follow current convert functionality. If the “automatically” option is selected for a stage, after all the tasks of the stage are marked as done then record will be converted to next stage automatically.',
    },
    SALES_TEMPLATE: {
        NAME_BLANK: 'Please enter a name to identify this template.',
        SUBJECT_BLANK: 'Please enter a subject for this template.',
        DUPLICATE_NAME: 'You already have a template named %% . We do not support duplicate templates. Please type in a different name and try again.',
        DELETE: 'Template will be deleted. Are you sure?',
        TEMPLATE_DELETED: 'Template deleted successfully',
        TEMPLATE_NAME_ERROR: 'You already have an existing template with this name. Please enter a different name and try again.',
        CATEGORY_BLANK:'Please select the sub category for this template.'
    },
    AGREEMENT_TEMPLATE: {
        NAME_BLANK: 'Please enter a name to identify this template.',
        // SUBJECT_BLANK: 'Please enter a subject for this template.',
        DUPLICATE_NAME: 'You already have a template named %% . We do not support duplicate templates. Please type in a different name and try again.',
        DELETE: 'Template will be deleted. Are you sure?',
        TEMPLATE_DELETED: 'Template deleted successfully',
        TEMPLATE_NAME_ERROR: 'You already have an existing template with this name. Please enter a different name and try again.',
        DUPLICATE_TEMPLAE_EXIST: 'This template name is already exist.',
        RECORD_NOT_EXIST: 'This record is not exist.',
        SAVE_TO_DOWNLOAD: 'You have not saved the Agreement Template yet. Please save the Agreement template first to download it.',
        TEMPLATE_CAN_NOT_EMPTY: 'Agreement template name can not be empty.',
        SELECT_TEMPLATE: 'Please select a template.',
        SENT_OR_SIGNED: 'You have already sent this agreement once. You cannot send it again.',
        SIGNATURE_TAGS_NOT_SEQUENCED_1: 'Please add the signature tags in sequential order.',
        SIGNATURE_TAGS_NOT_SEQUENCED_2: 'It seems you have skipped the sequential order while adding the signature tag.',
        SIGNATURE_TAGS_MISSING: 'There is no signature tag available in the Agreement body. Agreement body should have at least one signature tag.',
        SIGNATURE_TAGS_CONTACT_FIELDS_COMBO_MISSING: 'It seems you have used merge fields for a signature without using the signature tag. Please add the signature tag to proceed.',
        AGREEMENT_EDIT: 'You can perform edit only on Agreement records not on the other attachment records or agreement records already sent.',
        AGREEMENT_EXPIRED_1: 'This agreement has expired on ',
        AGREEMENT_EXPIRED_2: 'Please extend the agreement expiration date before sending it.',

    },
    SMS_TEMPLATE: {
        BLANK_MESSAGE: 'Please enter a message to save this template.',
        SAVE_MESSAGE: 'SMS saved successfully.',
        SUCCESS : 'Your sms has been sent.'
    },
    MARKETING_TEMPLATE: {
        NAME_BLANK: 'Please enter a name to identify this template.',
        SUBJECT_BLANK: 'Please enter a subject for this template.',
        DUPLICATE_NAME: 'You already have a template named %% . We do not support duplicate templates. Please type in a different name and try again.',
        DELETE: 'Template will be deleted. Are you sure?',
        BACK: 'If you go back, you will lose your unsaved changes. Do you wish to proceed?',
        SELECT_DESIGN: 'Select from a design',
        TEMPLATE_UPDATE: 'Marketing template(s) updated',
        CONFIRM1: 'of',
        CONFIRM2: 'records will be updated to',
        CONFIRM3: 'Are you sure?',
        ALERT1: 'out of',
        FROM_NAME_BLANK: 'Please enter a from name.',
        FROM_EMAIL_BLANK: 'Please choose a from email.',
        DUPLICATE_TEMPLATE_NAME: 'The template name is already exists.',
        CATEGORY_BLANK:'Please select the sub category for this template.'
    },
    BROADCAST_CAMPAIGNS: {
        NAME_BLANK: 'Please enter a Name to identify this Broadcast Campaign.',
        GROUP_FILTER_BLANK_COMMON_MSG: 'Please select',
        FILTER_TYPE_GROUP: 'a Group',
        FILTER_TYPE_CONTACT: 'a Contact Filter',
        FILTER_TYPE_ACCOUNT: 'an Account Filter',
        SUBJECT_BLANK: 'Please enter a Subject',
        FROM_NAME_BLANK: 'Please enter a From Name',
        FROM_EMAIL_BLANK: 'Please select a From Email',
        GROUP_NAME_BLANK: 'Group name can not be blank!',
        SAVE: 'You have successfully created this campaign.',
        UPDATE: 'You have successfully updated this campaign.',
        GROUP_NAME_SIZE_LIMIT: 'Group name can not be more than 128 characters.',
        EXECUTION_CANNOT_CANCEL_1: 'Execution can not be cancelled now.',
        EXECUTION_CANCEL_MSG: 'Application will try to cancel the execution immediately.',
        CONFIRM: 'Please confirm.',
        EXECUTION_CANNOT_CANCEL_2: 'A campaign which is already under processing can not be cancelled.',
        PURCHASE_MORE: 'to purchase more.',
        CREDITS_REMAINING: 'email credits remaining.',
        VERIFY_BLANK_EMAIL: 'You must enter an email address to verify.',
        VERIFY_INVALID_EMAIL: 'is an invalid e-mail address.',
        SEND_VERIFICATION_EMAIL_SUCCESS: 'We have sent the verification email.Please check your inbox and click Verify this email address. Then come back and click the refresh icon to see your new email address.',
        EMAIL_INTEGRATION_NEW: 'This email address has been already submitted for verification! Please check your inbox and click Verify this email address. Then come back and click the refresh icon to see your new email address.',
        EMAIL_INTEGRATION_VERIFIED: 'This email address has been already verified!',
        BD_NAME_COPY_ERROR: 'You have an existing campaign with this name. Please enter a different name for this campaign',
        STEPPER_BACK_MESSAGE: 'Do you like to go to the Broadcast info page? Any unsaved changes would be lost.'
    },
    SPACE: ' ',
    LIST_VIEW: {
        SELECT_RECORD: 'Please select a record to perform more action.',
        SELECT_RECORD_FOR_CONVERT: 'Please select a record to convert.',
        SELECT_RECORD_FOR_EDIT: 'Please select a record to edit.',
        SELECT_RECORD_FOR_COPY: 'Please select a record to copy.',
        SELECT_RECORD_FOR_SEND_EMAIL: 'Please select a record to Send Email.',
        MULTIPLE_RECORD_FOR_EDIT: 'Please select one record for edit.',
        MULTIPLE_RECORD_FOR_COPY: 'Please select one record for copy.',
        MULTIPLE_RECORD_FOR_MORE: 'Please select a record and try again.',
        SELECT_RECORD_FOR_COPY_ONE_RECORD: 'Please select one record to copy.',
        CANNOT_COPY_APPOINTMENT_WORKFLOW: 'You cannot copy this workflow.',
        SELECT_RECORD_FOR_ATTACH_ONE_RECORD: 'Please select a single attachment.',
        SELECT_MAXIMUM_FIELD_FOR_SETUP: 'You reached the maximum limit of total 15 selected fields.',
        SELECT_MAXIMUM_FIELD_FOR_LISTVIEW_SETUP: 'You reached the maximum limit of total 30 selected fields.',
        SELECT_MAXIMUM_FIELD_FOR_PARTNERS_COMPARE: 'You reached the maximum limit of total 20 selected fields.',
        CONVERT_CONTACT_TO_ACCOUNT_CONFIRM: 'This contact is already associated with an account. Click Ok to see the details of the account.',
        SHARE_PERMISSION: 'You don\'t have the permission to share this record.',
        NOT_ADDED: 'You have not added',
        FIELDS_TO_VIEW: 'fields to view.',
        SETUP_MINIMUM_FIELD_ALERT: 'Please add another field. We require a minimum of two fields for the view.',
        SELECT_ANOTHER_TABLE: 'Please select another table and try again.',
        WEB_FORM_ALERT1: 'out of',
        WEB_FORM_ALERT2: 'selected webform(s) is not deleted as it is used in automation designer.',
        SETUP_WORKFLOWSTAGE_MINIMUM_FIELD_ALERT: 'Please add a field for column. We require a minimum of 1 field for the view.',
        SELECT_RECORD_FOR_SEND_SMS: 'Please select a record to Send Text Message.',
        SELECT_RECORD_FOR_CALL: 'Please select a record to Make Call.',
        SELECT_MORE_RECORD_ACTION_ALERT: 'Please select one record to perform More action.',
        SELECT_RECORD_FOR_DOWNLOAD: 'Please select a record to Download accounts.',
        CHECKBOX_COLOR_GREEN_TITLE: 'Last update within 30 days',
        CHECKBOX_COLOR_YELLOW_TITLE: 'Last update within 30-60 days',
        CHECKBOX_COLOR_RED_TITLE: 'Last update before 60 days',
        STATUS_FIELD_NOT_ADDED_FOR_VIEW: 'You have not added any status field to view.',
        SELECT_MAXIMUM_FIELD_FOR_COLUMN_SETUP: 'You reached the maximum limit of total 5 selected fields.',
        NO_EMAIL_TO_SEND_MAIL: 'There is no email address available to send an email.',
        REQUIRED_FIELD_CONVERT_FAILED: 'Convert failed. One or more required fields are missing.',
        SELECT_RECORD_FOR_To_EXCEL: 'Please select a record To Excel.',
        SELECT_RECORD_FOR_ADD_PLAN_NAMES_ONE_RECORD: 'Please select one record to add plan names.',
        SELECT_RECORD_FOR_ADD_TASK: 'Please select one record to add task',
        UNIT_TASK_MESSAGE : 'You can add task only to the units that are directly connected to you.',
        UNIT_SEND_EMAIL : 'You can open send email only to the units that are directly connected to you',
        UNIT_PHONE_NUMBER : 'You can open Phone Call  only to the units that are directly connected to you',
        FDD_AND_FA_AGREEMENT_CANNOT_BE_DELETED: 'You are not allowed to delete the FDD or FA type attachment record.',
        MULTIPLE_SELECT_FDD_FA_MSG_1: 'Out of the ',
        MULTIPLE_SELECT_FDD_FA_MSG_2: ' selected attachment record(s) ',
        MULTIPLE_SELECT_FDD_FA_MSG_3: ' attachment record(s) will not be deleted as those are FDD or FA type attachment records. Are you sure?',
        RECORD_ALREADY_LINKED : "Selected record(s) is already linked with this record.",
        DUPLICATE_WITH_UNIQUE_RECORDS : "Some of the record(s) you have checked is already linked with this record! Please uncheck those record(s) and try again.",
        RECORDS_LINKED : "Records linked successfully.",
        CANNOT_OPEN_EMAIL_AND_SMS_TASK: "You cannot open email or text type task from this list.",
    },
    AB_CAMPAIGNS: {
        NAME_BLANK: 'Enter a name for the campaign.',
        GROUP_FILTER_BLANK_COMMON_MSG: 'Please select',
        FILTER_TYPE_GROUP: 'a Group',
        FILTER_TYPE_CONTACT: 'a Contact Filter',
        FILTER_TYPE_ACCOUNT: 'an Account Filter',
        GROUP_FILTER_BLANK: 'Select a group.',
        CONTACT_FILTER_BLANK: 'Please select a Contact Filter.',
        ACCOUNT_FILTER_BLANK: 'Please select an Account Filter.',
        SUBJECT_BLANK: 'Enter a subject for this message.',
        FROM_NAME_BLANK: 'Enter the sender name.',
        FROM_EMAIL_BLANK: 'Enter the sender\'s email.',
        GROUP_NAME_BLANK: 'Group name can not be blank!',
        AB_DISTRIBUTION: 'Enter the a/b distribution.',
        INVALID_AB_DISTRIBUTION: 'Invalid a/b distribution.',
        INVALID_WINNING_MARGIN: 'Margin for Winner is invalid.',
        ZERO_WINNING_MARGIN: 'Enter margin for winner.',
        MESSAGE_B_NOT_FILLED: 'Please enter the Message B details.',
        AB_DISTRIBUTION_OUT_OF_RANGE: 'Enter a value between 1 to 99 for test ',
        DAYS_BLANK: 'Please enter how many days you would like to wait before sending the winning message to the remaining list.',
        INVALID_DAYS: 'Invalid entry. Please enter how many days you would like to wait before sending the winning message to the remaining list.',
        SAVE: 'You have successfully created this campaign.',
        UPDATE: 'You have successfully updated this campaign.',
        EXECUTION_CANNOT_CANCEL_1: 'Execution can not be cancelled now.',
        EXECUTION_CANCEL_MSG: 'Application will try to cancel the execution immediately.',
        CONFIRM: 'Please confirm.',
        EXECUTION_CANNOT_CANCEL_2: 'A campaign which is already under processing can not be cancelled.',
        CAMPAIGN_EXECUTE_IN_60_MIN: 'This campaign will execute in 60 minutes.',
        OK_TO_CONFIRM: 'Click Ok to confirm',
        ERROR_MSG_1: 'Please select the template.',
    },
    REPEAT_DIALOG: {
        END_DATE_BLANK: 'End date cannot be blank.',
        INVALID_END_DATE: 'Start date cannot be greater than or same as end date.',
        DAY_CHECKBOX_BLANK: 'Atleast check one Day.',
        DELETE_RECUR_TITLE: 'Delete Recurring',
        EDIT_RECUR_TITLE: 'Edit Recurring',
        REPEAT: 'Repeat'
    },
    BATCH_ACTION: {
        CONVERT_SUCCESSFULL: ' have been converted successfully.',
        SINGLE_CONVERT_SUCCESSFULL: ' has been converted successfully.',
        CONVERT_UNSUCCESSFULL: 'Action unable to perform',
        CONVERT_SAME_STAGE: ' record is already in ',
        MULTI_SHARE_SUCCESSFULL: ' have been shared successfully.',
        SINGLE_SHARE_SUCCESSFULL: ' has been shared successfully.',
        SHARE_UNSUCCESSFULL: 'Action unable to perform',
        MULTI_ASSIGN_SUCCESSFULLY: ' have been assigned successfully.',
        SINGLE_ASSIGN_SUCCESSFULLY: ' has/have been assigned successfully.',
        ASSIGN_UNSUCCESSFULL: 'Action unable to perform',
        COMPLETE_SUCCESSFULL: ' has been completed successfully.',
        BATCH_COMPLETE_SUCCESSFULL: ' have been completed successfully.',
        COMPLETE_UNSUCCESSFULL: 'Action unable to perform',
        GROUP_ADDED_SUCCESSFULLY: 'Successfully added to Group(s)',
        GROUP_REMOVED_SUCCESSFULLY: 'Successfully removed from all the group(s).',
        GROUP_CLICK_TO_CONFIRM: ' will be added to the selected group(s). Click "OK" to confirm.',
        GROUP_BLANK_ADDED_BATCH: 'Please select at least one group.',
        NOTE_TXT_CANNOT_BE_BLANK: 'Text cannot be blank.',
        WORKFLOW_CONFIRMATION: 'Workflow will be changed for this record. Click Ok to confirm.',
        BATCH_WORKFLOW_CONFIRMATION: 'Workflow will be changed for these records. Click Ok to confirm.',
        WORKFLOW_APPLIED: 'Workflow applied successfully.',
        RECORDS_WITH_DIFFERENT_WORKFLOW: 'You have selected records with different workflows. Click Ok to continue and perform the apply workflow operation or click Cancel to select the records again.',
        RECORDS_WITH_DIFFERENT_WORKFLOW_STATUS: 'You have selected records with different status. Click Ok to continue and perform the apply workflow operation or click Cancel to select the records again.',
        ACTIVE_SUCCESSFULL: 'Status has been changed to Active.',
        INACTIVE_SUCCESSFULL: 'Status has been changed to Inactive.',
        BATCH_EDIT_BLANK: 'Value field can not be blank!',
        INVALID_WORKFLOW_STAGE: 'You can not convert the record. Status of the selected record is not available in the current workflow. Press Ok to change status or workflow',
        CONFIRMATION_DELETE_MSG: ' will be deleted. Are you sure?',
        CONFIRMATION_REMOVE_MSG: ' will be removed. Are you sure?',
        CONFIRMATION_UNLINK_MSG: ' will be unlinked. Are you sure?',
        CONVERT_ALERT_MSG: ' will be converted to ',
        CLICK_TO_CONFIRM: ' Click Ok to confirm.',
        ASSIGN_ALERT_MSG: ' record(s) will be assigned to ',
        ROUND_ROBIN_BASIS: ' on a round robin basis. Are you sure?',
        CONFIRM_MSG: 'Are you sure?',
        RECORDS: 'records',
        RECORD: 'record',
        ALL_RECORDS: 'All records',
        RECORD_WILL_BE_UPDATED: 'records will be updated to',
        CONFIRM_UNSUBSCRIBE_RECORD: ' will be unsubscribed. Are you sure?',
        UNSUBSCRIBE: 'You have successfully unsubscribed the selected contact(s).',
        USE_ASSIGN_RULE_ALERT_MSG: ' record(s) will be assigned to the Sales Rep(s) as per ',
        EMPTY_RECYCLE_BIN: 'All records will be permanently deleted from the Recycle Bin. Are you sure?',
        PERMANENT_DELETE_MSG: ' will be permanently deleted. Are you sure?',
        INVALID_STATUS_FOR_WORKFLOW_STAGE: 'Status of the selected record is not available in the current workflow. Press Ok to change the status of the record.',
        CONTACT_STATUS_UPDATE_SUCCESS: ' status has been  updated successfully.',
        PUBLISH_SUCCESSFULL: 'Status has been changed to Published.',
        UNPUBLISH_SUCCESSFULL: 'Status has been changed to Unpublished.',
        RECORDS_NOT_IN_WORKFLOW: 'All the selected records are not in the contacts workflow.',
        RECORD_NOT_IN_WORKFLOW: 'You cannot convert as the selected record is not in the contacts workflow.',
    },
    BATCH_ACTION_VALIDATION: {
        ADDTOGROUP_BLANK: 'Selected record will be removed from all group(s). Click "OK" to confirm.',
        ADDTOGROUP_NAMEBLANK: 'Group name cannot be blank.',
        SHARE_WITH_BLANKUSER: 'Please select atleast one user.',
        ASSIGN_BLANKUSER: 'Please select a user.',
        CONVERT_SELECT_STATUS: 'Please select a status for ',
        MAP_INSUFFICIENT_INFO: 'Insufficient information to show map',
        GROUP_ALREADY_EXIST: 'Group name already exists!',
        ALREADY_ACTIVE: 'Record already in Active status.',
        ALREADY_INACTIVE: 'Record already in Inactive status.',
        ALREADY_PUBLISH: 'Record already in Published status.',
        ALREADY_UNPUBLISH: 'Record already in Unpublished status.',
        ALREADY_DISABLE: 'Record already in Disabled status.',
    },
    ATTACHMENT: {
        ATTACHMENT_UPLOAD: ' Attachment uploaded successfully',
        ATTACHMENT_LINK_ADDED: 'Document Link has been added successfully!',
        ATTACHMENT_UPLOAD_IMAGE: 'Image uploaded successfully',
        ATTACHMENTS_UPLOAD: ' Attachments Uploaded successfully',
        VALIDATE_ATTACHMENT: 'Please select a file to attach  ',
        ATTACHMENT_UPLOAD_FAILURE: ' This file already exists.',
        VALIDATE_EMPTY_FILE: ' The upload File size can not be 0 ',
        VALIDATE_FILE_SIZE: 'The upload File size can not be more than 5 mb',
        VALIDATE_LOGO_SIZE: 'The photo you have selected is too large to use. The maximum file size allowed is 500 KB',
        VALIDATE_BLANK_FILE: 'You can not upload a blank file',
        VALIDATE_LOGO_EXTENSION: 'Please select a jpg, gif or png file!',
        VALIDATE_FAVICON_EXTENSION: 'Please select a ico, gif or png file!',
        ATTACHMENT_LOGO_TEXT: 'You can upload a jpg, gif, or png file.',
        ATTACHMENT_LOGO_SIZE_TEXT: ' The maximum file size allowed is 500 KB.',
        UPLOAD_IMAGE: 'Upload an image',
        UPLOAD_FILE: 'Select files to upload (up to five files at a time)',
        ADD_LINK: 'Add an external link',
        VALIDATE_MAX_ATTACHMENTS: 'You can not upload more than five files',
        SELECT_IMAGE_TO_UPLOAD: 'Select an image to upload',
        MAX_SIZE_2_MB_ALERT: 'The upload File size  can not be more than 2 mb',
        VALID_FILE_UPLOAD: 'You can upload a jpg, gif, or png file from computer.',
        MAX_SIZE_2_MB: 'The maximum file size allowed is 2 MB.',
        CANNOT_UPLOAD_EXECUTABLE_FILE: 'You can not upload executable file',
        THE_ATTACHMENT: 'The attachment',
        DOCUMENT_LINK: 'Document Link', 
        FOLDER_NAME: 'Folder name',
        FILE_NAME:'File name',
        ALREADY_EXIST: 'already exists.',
        CANNOT_BE_DELETED: 'cannot be deleted.',
        CLICK_TO_DELETE: 'Click OK to delete ',
        EMPTY_FOLDER_ERROR_DOWNLOAD: 'Not able to download as the folder is empty.',
        EMPTY_FOLDER_ERROR_PUSH: 'Not able to push as the folder is empty.',
        RENAME_SUCCESSFULLY: 'Folder renamed successfully.',
        DELETE_SUCCESSFULLY: 'Folder deleted successfully.',
        ADDED_SUCCESSFULLY: 'added successfully.',
        DOCUMENT_LINK_ADDED_SUCCESSFULLY: 'Document Link  added successfully.',
        DOCUMENT_LINK_UPDATE_SUCCESSFULLY: 'Document Link updated successfully.',
        DOCUMENT_LINK_DELETE_SUCCESSFULLY: 'Document Link deleted successfully.',
        FILE_ADDED_SUCCESSFULLY: 'File added successfully.',
        FILE_RENAME_SUCCESSFULLY: 'File renamed successfully.',
        FILE_DELETE_SUCCESSFULLY: 'File deleted successfully.',
        IMAGE_DELETE_SUCCESSFULLY: 'Image deleted successfully.',
        IMAGE_RENAME_SUCCESSFULLY: 'Image renamed successfully.',
        IMAGE_NAME: 'Image name',
        MAX_SIZE_50_MB: 'The maximum file size allowed is 50 MB.',
        MAX_LIMIT: 'The maximum file length allowed is 500, select other folder',
    },
    AUTOMATION_DESIGNER: {
        STEPPER_BACK_MESSAGE: 'Do you like to go to the Template info page? Any unsaved changes would be lost. ',
        MAX_CONNECTION: "Maximum no. of connections has been reached.",
        TOUCH_CAMPAIGN_NAME: "Please select a Name for this touch campaign",
        SELECT_FILTERS: "Please select an",
        SELECT_FILTERS_A: "Please select a",
        START_DATE: "Please select a Start Date for",
        EMPTY_WEBSITE_LINK: "You can not add empty Website Link.",
        CANNOT_START_WITH_DECISSION: 'You cannot start with a decision. Please start with any of the options under the "Start with" menu at the left.',
        CANNOT_START_WITH_ACTION: 'You cannot start with an action. Please start with any of the options under the "Start with" menu at the left.',
        CONNECT_WITH_PROPER_NODE: 'Connect with proper node',
        FROM_NAME: 'Please enter a From Name for',
        FROM_EMAIL: 'Please select a From Email',
        RESEND_AFTER: 'Please enter Resend After in day(s)',
        SELECT_WEB_FORM: 'Please select a web form',
        SELECT_GROUP: 'Please select a group.',
        SELECT_GROUP_OPERATION: 'Please choose a group operation.',
        ENTER_NOTE: 'Please enter your note.',
        SELECT_ONE_RECIPIENT: 'Please select at least one recipient',
        SELECT_USER: 'Please select a user.',
        ENTER_TASK_NAME: 'Please enter a task name',
        PROVIDE_DUEBY_FIELD: 'Please provide a value in the Due by field!',
        ENTER_LINK: 'Please enter a link.',
        PROVIDE_VALID_URL: 'Please provide a valid website url!',
        CATCH_ALL_LINK_CONNECTION: 'You already have a "catch all" link click condition',
        ENTER_SCORE: 'Please enter a score',
        INVALID_SCORE: 'You have entered an invalid score',
        ENTER_SUBJECT: 'Please enter a subject',
        ENTER_BODY: 'Please enter body content',
        ENTER_INTERVAL_VALUE: 'Please enter a value for the interval',
        SELECT_ONE_HOUR: 'Please select 1 hour or more for the hourly interval.',
        BLANK_CAMPAIGN_NAME: 'Campaign name cannot be blank.',
        CAMPAIGN_SAVED_SUCCESSFULLY: 'This campaign has been saved successfully',
        BLANK_CANVAS: 'You cannot save a blank canvas.',
        NOT_ROOT_NODE: 'cannot be a root node.',
        START_DATE_MUST_BE_GREATER: 'Start date must be greater that the previous touch campaign.',
        EDIT_AND_COMPLETE_DETAILS: 'Please edit and complete the details of the',
        PROVIDE_WAIT_INTERVAL: 'Please provide an wait interval more then zero.',
        CHECK_INTERVAL_WITH_PREVIOUS_TOUCH_CAMPAIGN: 'Please check the interval with previous touch campaign resend day(s).',
        CHECK_START_DATE_OR_WAIT_BEFORE_SAVE_TC: 'Please check the start date or wait before save the touch campaign.',
        PROVIDE_START_DATE: 'Please provide a start date.',
        INTERVAL_EXPEXT_ZERO: 'Please provide an interval except zero.',
        EMPTY_LINK_NODE: 'You can not add empty link node.',
        DELETE_NODE_CONFIRMATION: 'Node will be deleted. Do you want to continue?',
        MSG_CANT_BE_DELETED: 'This message has been sent and cannot be deleted.',
        FIRST_MSG_CANT_BE_DELETED: 'You cannot delete the first message of a touch campaign',
        FIRST_WAIT_CANT_BE_DELETED: 'You cannot delete the first wait of a touch campaign',
        BLANK_INTERVAL: 'Interval cannot be blank',
        PROVIDE_POSITIVE_INTERVAL: 'Please provide an interval greater than 0',
        WONT_BE_ABLE_TO_DELETE: 'You won’t be able to delete it.',
        URL_NOT_EXIST: 'The URL you are provided is not available in email body.',
        GROUP_NAME_BLANK: 'Group name can not be blank!',
        SELECT_LANDING_PAGE: 'Please select a landing page',
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: 'You already have one open Automation Designer, please close or save it to open a new Automation Designer.',
        SAVE_DIAGRAM_BEFORE_COPY: 'Please save the diagram before perform the copy operation.',
        NO_TOUCH_FOUND: 'No Touch Campaign found.',
        NO_SMS_CAMPAIGN_FOUND: 'No SMS Campaign report found.',
        ADD_ONE_FIELD: 'Please add atleast one field',
        SELECT_FIELD_VALUE: 'Please select a field value',
        INVALID_URL: 'is an invalid url.',
        INVALID_INTEGER: 'is not an integer.',
        INVALID_EMAIL: 'is an invalid e-mail address.',
        ALLOWED_UPTO_FOUR_FIELDS: 'Update is allowed up to 4 fields. You cannot add another row of field value pair.',
        WILL_BE_UPDATED: 'will be updated. Are you sure?',
        DUPLICATE_TOUCH_NAME: 'You already have a Send Touch campaign named',
        TOOLTIP_MESSAGE_SEND_MAIL: 'Start with a touch campaign that sends a series of emails. To use this, drag and drop it on the canvas to the right.',
        TOOLTIP_MESSAGE_WEB_FORM: 'Start by defining what happens after a web form is submitted. To use this, drag and drop it on the canvas to the right.',
        TOOLTIP_MESSAGE_LANDING_PAGE: 'Start by defining what happens after a landing page is submitted. To use this, drag and drop it on the canvas to the right.',
        TOOLTIP_MESSAGE_WEBSITE_VISIT: 'Start by defining what happens after a contact visits a specific page on your website. To use this, drag and drop it on the canvas to the right.',
        TOOLTIP_MESSAGE_LINK_CLICK: 'Define what happens after a link in your email is clicked by a contact. To use this, drag and drop it on the canvas to the right and connect it with a touch message.',
        TOOLTIP_MESSAGE_EMAIL_OPEN: 'Define what happens after a touch message is opened by a contact. To use this, drag and drop it on the canvas to the right and connect it with a touch message.',
        TOOLTIP_MESSAGE_UNSUBSCRIBE: 'Define what happens after a contact unsubscribes from a touch message. To use this, drag and drop it on the canvas to the right and connect it with a touch message.',
        TOOLTIP_MESSAGE_SCORE_REACHED: 'Define what happens after a contact reaches a score threshold. To use this, drag and drop it on the canvas to the right.',
        TOOLTIP_MESSAGE_SMS_REPLY: 'Define what happens after a contact replies a sms. To use this, drag and drop it on the canvas to the right.',
        TOOLTIP_MESSAGE_AUTO_MAIL: 'The send email will be used to send both one-off and touch emails. This action can be added after a Touch Campaign or any decision or any action.',
        TOOLTIP_MESSAGE_CHANGE_GROUP: 'Add or remove accounts and contacts from groups. To use this, drag and drop it on the canvas to the right.',
        TOOLTIP_MESSAGE_ADD_NOTE: 'Add a note to the account. To use this, drag and drop it on the canvas to the right.',
        TOOLTIP_MESSAGE_NOTIFY: 'Notify a CRM user. To use this, drag and drop it on the canvas to the right.',
        TOOLTIP_MESSAGE_ADD_TASK: 'Assign a task to another user. To use this, drag and drop it on the canvas to the right.',
        TOOLTIP_MESSAGE_UPDATE_FIELD: 'Update the value of a field for account or contact. To use this, drag and drop it on the canvas to the right.',
        TOOLTIP_MESSAGE_ASSIGN: 'Reassign the account to another user. To use this, drag and drop it on the canvas to the right.',
        TOOLTIP_MESSAGE_CONVERT: 'Convert the account to a new status. To use this, drag and drop it on the canvas to the right.',
        TOOLTIP_MESSAGE_SEND_SMS: 'Send SMS to a group, account filter or contact filter. To use this, drag and drop it on the canvas to the right.',
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_1: 'You already have a touch campaign named',
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_2: 'Please enter a different name. ',
        INTERVAL_MODE_ADD_MSG_1: 'Please provide a',
        INTERVAL_MODE_ADD_MSG_2: 'after previous value except zero',
        AUTOMATED_CAMPAIGN_TITLE: 'Automated Campaigns',
        CANNOT_CONNECT_MULTIPLE: 'Cannot connect multiple ',
        CONNECTION_EXIST: 'You already have an active connection from ',
        REMOVE_CONNECTION_ALERT: 'If you remove the connection, you will lose all the steps following',
        CONTINUE_ALERT: 'Do you want to continue?',
        CANNOT_ADD: 'You cannot add',
        DIRECT_UNDER: 'directly under a',
        DIRECT_UNDER_AN: 'directly under an',
        MULTIPLE_TIME_SELECTED_MSG_1: 'You have selected the',
        MULTIPLE_TIME_SELECTED_MSG_2: 'field(s) multiple times for update. Please remove the duplicate rows.',
        CANNOT_BE_LEAF_NODE: 'cannot be a leaf node.',
        CLICK: 'Click',
        HERE: 'here',
        PURCHASE_MORE: 'to purchase more.',
        CREDITS_REMAINING: 'email credits remaining.',
        STOP_CAMPAIGN: 'Stop the campaign to make changes.',
        SELECT_EMAIL_TEMPLATE: 'Select an Email Template',
        NO_INFO_EXISTS: 'No information exists',
        SELECT_RECORD_FOR_COPY_ONE_RECORD: 'Please select one record to copy.',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: 'Please select one record to edit.',
        CANNOT_SAVE_CAMPAIGN: "Can not save this campaign. See the red blocks in the campaign for errors.",
        ADD_GROUP_CLICK: 'Click',
        ADD_GROUP_CLICK_HERE: 'here',
        CLICK_TO_ADD_NEW_GROUP: 'to add a new group',
        MESSAGE_SAVED_SUCCESSFULLY: 'You have successfully saved this message',
        MESSAGE_NOT_SAVED_SUCCESSFULLY: 'Message has not been saved successfully',
        CONTACT_FILTER_BLANK: 'Please select a Contact Filter.',
        ACCOUNT_FILTER_BLANK: 'Please select an Account Filter.',
        QUERY_FILTER_BLANK: 'Please select a Query',
        SAVE_AUTOMATION_FOR_TEST: 'Please save automation campaign.',
        BIRTHDAY_CAMPAIGN_NAME: 'Please select a Name for this birthday campaign',
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_1: 'You already have a birthday campaign named',
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_2: 'Please enter a different name. ',
        BIRTHDAY_FIRST_MSG_CANT_BE_DELETED: 'You cannot delete the first message of a birthday campaign',
        MIGRATION: 'This automated campaign is being upgraded to the new campaign builder. Thank you for your patience.',
        MIGRATION_DIALOG_TITLE: 'Upgrade in progress ...',
        MIGRATION_NOTE: 'Note: After update, automation campaign will start running the day you perform the Start operation.',
        BIRTHDAY_CAMPAIGN_NO_REPORT: 'Birthday campaign yet to be sent to the recipient(s).',
        RUNNING_CAMPAIGN_CANNOT_CANCEL: 'A running campaign can not be cancelled.',
        CONFIRM_CANCEL_SCHEDULE: 'Would you like to cancel the scheduled campaign?',
        CAMPAIGN_SCHEDULE_SUCCESSFULLY: 'This campaign has been scheduled successfully.',
        SCHEDULED_CAMPAIGN_CANCEL_SUCCESSFULLY: 'This campaign has been cancelled successfully.',
        CAMPAIGN_START_IMMEDIATELY: 'This campaign has been successfully set to start immediately.',
        ENTER_FROM_NAME: 'Please enter from name',
        ENTER_FROM_EMAIL: 'Please enter from email.',
        SMS_CREDITS_REMAINING:'SMS credits remaining.',
        ENTER_MOBILE_NO:'Please enter mobile number.'
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME_BLANK: 'Please provide a domain name to generating tracking code.',
        DOMAIN_NAME_INVALID: 'Please provide a valid domain name.',
        TRACKING_CODE_BLANK: 'There is no tracking code to copy.',
        TRACKING_CODE_ALREADY_GENERATED: 'Tracking code is already generated.',
        DONE: 'Your domain name has been registered.',
        SET_UP_INFO: 'Copy and paste this code into every page of your site, just before the end body tag.After you are done, come back to this page and click the Done button.'

    },
    MAIL: {
        SUCCESS: 'Your email has been sent.',
        BLANK_FROM_EMAIL: 'From email is blank.',
        INVALID_FROM_EMAIL: 'You have an invalid from email address in the From field.',
        INVALID_CC_EMAIL: 'You have an invalid CC email address in the CC field.',
        INVALID_BCC_EMAIL: 'You have an invalid BCC email address in the BCC field.',
        BLANK_SUBJECT: 'Subject is blank.',
        BLANK_EMAIL_BODY: 'Email body is blank.',
        BATCH_SEND_MAIL_MSG_1: 'Out of the',
        BATCH_SEND_MAIL_MSG_2: 'you have selected, only',
        BATCH_SEND_MAIL_MSG_3: 'have opted in to receive your emails. We would send the email only to these',
        BATCH_SEND_MAIL_MSG_4: 'None of the',
        BATCH_SEND_MAIL_MSG_5: 'have opted in to receive your emails. We will not send this email. Please close this tab',
        NO_RECIPIENT: 'You have not entered any recipient for this email',
        NO_RECIPIENT_FOUND: 'No recipient found for this email.',
        BLANK_TO_EMAIL: 'No email address in the To field.',
        INVALID_TO_EMAIL: 'Invalid email address in the To field.',
        SCHEDULE_SUCCESS: 'Your email has been scheduled successfully.',
        RESCHEDULE_SUCCESS: 'Email has been rescheduled successfully',
        TEMPLATE_NOT_SELECTED: 'Please select a template',
        NO_ACTIVE_AGREEMENT_TEMPLATE_MSG: "You don't have any active agreement template.",
        SELECT_DIFFERENT_SIGNER: "Please select a different signer.",
        SELECT_ALL_SIGNERS: 'Please select all the signer(s) to send the agreement email.',
    },
    APPOINTMENTS_TASKS: {
        DELETE: 'This record will be deleted. Are you sure?',
        INVALID_START_END_DATE: 'Start date cannot be greater than or same as end date.',
        RECURR_DELETE_DIALOG_ONLY_BTN_INFO: 'All other records in the series will remain.',
        RECURR_DELETE_DIALOG_FOLLOWING_BTN_INFO: 'This and all the following records will be deleted.',
        RECURR_DELETE_DIALOG_ALL_BTN_INFO: 'All records in the series will be deleted.',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_1: 'All other',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_2: 'in the series will remain the same.',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_1: 'This and all the following',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_2: 'will be changed.',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_3: ' Any changes to future',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_4: 'will be lost.',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_1: 'All',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_2: 'in the series will be changed.',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_3: ' Any changes made to other',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_4: 'will be kept.',
        RESCHEDULE_TASK_SUCCESS: 'Task has been rescheduled successfully',
        RESCHEDULE_APPOINTMENT_SUCCESS: 'Appointment has been rescheduled successfully',
        NO_TASK_FOUND: 'No tasks found',
        NO_APPOINTMENT_FOR_TODAY: 'There is no Scheduled Appointments available today',
        NO_PENDING_TASK_FOR_TODAY: 'There is no Pending Task available today',
        NO_TASK_APPOINTMENT_FOUND: 'Nothing pending',
        NO_TASK_APPOINTMENT_FOUND_FOR_COMPLETED: 'Nothing completed.',
        BLANK_START_END_DATE: 'Start date and end date cannot be blank',
        BLANK_START_DATE: 'Start date cannot be blank',
        BLANK_END_DATE: 'End date cannot be blank',
        BLANK_NO_OF_DAYS: "No. of days can not be blank.",
        ZERO_NO_OF_DAYS: "No. of days can not be 0.",
        BLANK_SPECIFIC_DATE: "Date can not be blank.",
        INVALID_DUEBY_END_DATE: 'Due by date cannot be greater than or same as Repeat end date.',
        SEND_EMAIL_PROGRESS_MSG: 'Email sending is in progress, it will take a moment.',
        INVALID_DUEBY_START_DATE: 'Due by date cannot be greater than or same as Start date.',
        SYNC_STARTED_MSG: 'Sync started. Please refresh the calendar to see new appointments.',
        SYNC_STARTED_MSG_FOR_SETUP: 'Sync started. Your calendar will be updated soon.',
        ENABLE_SYNC_CAL: 'Please enable sync calendar option under integration set up before doing sync.',
        PRIVATE_APPOINTMENT_ALERT: "This is a Private appointment; you cannot open this.",
        PRIVATE_APPOINTMENT_DRAGE_ALERT:"This is a Private appointment; you cannot drag this.",
        CAMPAIGN_DRAGE_ALERT:"You can not drag a campaign",
        START_END_DATE_VALIDATE:'cannot be greater than or same as',
        CONVERT_CONFIRMATION_MESSAGE: 'One or more tasks are not checked. Would you like to continue with Convert operation without checking those tasks?'
    },
    TASKS:{
        DELETE_CANCELLED_STATAUS_RESTRICT_MSG:'You are not allowed to cancel any corrective actions task.',
    },
    HOME: {
        FILTER_ADD: 'Filter added successfully',
        FILTER_NOT_FOUND: 'You have not configured any filter',
        FILTER_DELETE_RECORD: 'Filter will be deleted. Are you sure?',
        FILTER_DELETE: 'Filter removed successfully',
        FILTER_ON_MAX_FIELD: 'You have reached the maximum field condition. We allow up to 10 fields.',
        ASSIGNMENT_RULES_ON_MAX_FIELD: 'You have reached the maximum field condition. We allow up to 20 fields.',
        // not found in doc
        FILTER_NAME_BLANK: 'Please provide a Filter Name.',
        FILTER_NAME_MAX_LENGTH: 'Filter Name cannot be more than 64 characters.',
        FILTER_CRITERIA_EMPTY: 'Please provide at least one criteria.',
        FILTER_SQL_ERROR_NO_VALUE: ' can not be blank.',
        FILTER_SQL_ERROR_INTEGER: ' is not an integer.',
        FILTER_SQL_ERROR_FLOAT: ' is not a float.  ',
        FILTER_SQL_ERROE_ALREADY_EXISTS: 'Filter Name already exists!',
        FILTER_DELETE_MSG_TC: 'the following Touch Campaign(s) ',
        FILTER_DELETE_MSG_BC: ' the following Broadcast Campaign(s) ',
        FILTER_DELETE_MSG_AB: ' the following A/B Campaign(s) ',
        FILTER_DELETE_MSG_AND: ' and ',
        FILTER_DELETE_MSG_BEING_USED: ' cannot be deleted as it is being used in ',
        ADD_GROUP_SUCCESS: 'Group added successfully.'
    },
    DOCUMENT_LIBRARY: {
        INVALID_UPLOAD_ERROR: 'Select an image file!',
        CONFIRM_DELETE_MSG: 'Are you sure you want to delete this image?',
        CONFIRM_DELETE_FOLDER_MSG: 'Are you sure to delete this folder? All the articles in this folder would be deleted.',
        OVER_MAX_SIZE: 'The file you have selected is too large to use. The maximum file size allowed is 50 MB',
        OVER_MAX_SIZE_IMAGE: 'The file you have selected is too large to use. The maximum file size allowed is 500 KB',
        INVALID_DOCUMENT_UPLOAD_ERROR: 'exe, bat, sh documents are not allowed to be uploaded!',
        DOCUMENT_FILE_EMPTY_ERROR: 'Please select a document!',
        ASSETS_ALREADY_OPEN_ALERT: 'You already have Library tab open under Operations module.',
        DUPLICATE_IMAGE_NAME: 'Image already exists with this name. Please select a different image!',
        DUPLICATE_DOCUMENT_NAME: 'Document already exists with this name. Please select a different document!',
        UPLOAD_IMAGE: 'Select images to upload (up to five files at a time).',
        ASSETS_ALREADY_OPEN_ALERT_HUB: 'You already have Library tab open under Hub module.',
        BLANK_TITLE_ERROR : 'Title cannot be blank',
        BLANK_URL_ERROR : 'Link cannot be blank',
        INVALID_URL_ERROR : 'Please provide valid URL',
        CONFIRM_FILE_TYPE_CHANGE: {
            LN1: 'Do you like to exit the current section? Any unsaved changes would be lost.',
            LN2: 'Click Ok to confirm and exit.',
            LN3: 'Click Cancel to remain in the current section.'
        },
        CANT_PERFORM_ACTION: 'Please click on a folder to perform action',

    },
    MERGE: {
        NOT_URL: "is an invalid url.",
        NOT_FLOAT: "is not a float.",
        NOT_INTEGER: "is not an integer.",
        NOT_EMAIL: "is an invalid e-mail address.",
        MERGE_TENANT_EMAIL_ALERT_MESSAGE: 'You cannot merge these records. As you have chosen a paid account to delete!',
    },
    SCHEDULE: {
        EMPTY: 'Please select a schedule date',
        CAMPAIGN_SCHEDULE: 'This campaign will run on',
        CONFIRM: 'Click Ok to confirm.',
        SMS_SCHEDULE: 'This Text message will send on'
    },
    RELATIONSHIP: {
        BLANK_RECORD: "Please select the relationship for",
        BLANK_PARENT_RELATION: "Please select atleast one parent relation.",
        BLANK_CHILD_RELATION: "Please select atleast one child relation",
        NO_PARENT_RELATION: "You dont have any relation. Please create relation under customization from All Settings",
        NO_PARENT_RELATION_FOR_USER: "Sorry, there is no relation available. Please contact your administrator",
        NO_RELATIONSHIP_SETUP: "You have not setup any relationship types yet",
        PLACEHOLDERTEXT_COMPANY: "Type a Company name and press enter",
        PLACEHOLDERTEXT_NAME: "Type a Full name and press enter",
        PLACEHOLDERTEXT_EMAIL: "Type an Email and press enter",
    },
    GROUP: {
        NO_TOUCH_CAMPAIGN: "Not used in any Touch Campaign.",
        NO_BROADCAST_CAMPAIGN: "Not used in any Broadcast Campaign.",
        NO_AB_CAMPAIGN: "Not used in any A/B Campaign.",
        NO_WEBFORM: "Not used in any Web Form.",
        NO_LANDINGPAGE: "Not used in any Landing Page.",
        DELETE_MSG: 'The following group(s) cannot be deleted',
        USED_IN_TOUCH_CAMPAIGN: 'is used in the following Touch Campaign(s)',
        USED_IN_BROADCAST_CAMPAIGN: 'is used in the following Broadcast Campaign(s)',
        USED_IN_AB_CAMPAIGN: 'is used in the following A/B Campaign(s)',
        USED_IN_WEBFORM: ' is used in the following Web Form(s)',
        USED_IN_LANDINGPAGE: 'is used in the following Landing Page(s)',
        SELECT_GROUP: 'Select one or more groups',
        NO_GROUP_AVAILABLE: 'No group available. Click',
        SELECT_RECORDS: 'Selected records',
        ALL_RECORDS: 'All records',
        SELECT_OPTIONS: 'Select options',
        ADD_NEW_GROUP: 'to add a new group',
        GROUP_DELETE_RECORD: 'Group will be deleted. Are you sure?',
        COMMON_DELETE_MSG:" cannot be deleted as it is being used in the following campaign(s) :",
    },
    LOGIN: {
        VALIDATE_EMPTY_USER_NAME: 'Please enter your email and password',
        VALIDATE_EMPTY_PASSWORD: 'Please enter your password',
        VALIDATE_USERNAME_PASSWORD: 'You have entered an incorrect email or password',
        INVALID_RESPONSE: 'Invalid Response',
        LOGIN_COPYRIGHTS_TEXT_1: 'Copyright',
        LOGIN_COPYRIGHTS_TEXT_2: 'Soffront Software, Inc. All rights reserved.',
        BEST_VIEWED: 'Best viewed in Google Chrome, Mozilla Firefox and Safari.',
        EXPIRED_PASSWORD:'Your password is expired. Click OK to continue.',
        FE_EXPIRED_PASSWORD:'Your password is more than 60 days old. Create a new password to login.',
        RESEND_CODE_SUCCESS:"We have emailed you a new code. If you haven't received your new code, please check your spam folder.",
        RESEND_CODE_FAILURE:"The email couldn't be sent. Click 'Resend Code' to try again."
    },
    AUDIT_LEADSCORE: {
        NO_AUDIT_DATA: 'No audit trail record found',
        NO_SCORE_HISTORY: 'No history to display.'
    },
    DETAIL_VIEW: {
        CHANGE_PRIMARY_CONTACT_ALERT_1: 'You selected',
        CHANGE_PRIMARY_CONTACT_ALERT_2: 'as the main contact for',
        CLICK_TO_CONFIRM: 'Click OK to confirm.',
        MAX_SELECTED_FIELD_LIMIT: 'You reached the maximum limit of 50 selected fields',
        SELECT_ATLEAST_ONE_FIELD: 'Please select at least one field ',
        SELECT_ATLEAST_ONE_CHILD_OBJECT: 'Please select at least one child object.',
        MAX_SELECTED_CHILD_OBJECT_LIMIT: 'You reached the maximum limit of 10 selected objects.',
        MAX_SELECTED_CHILD_OBJECT_LIMIT_FOR_15_OBJECTS:'You reached the maximum limit of 15 selected objects.',
        NO_ASSOCIATED_GROUPS_FOUND: 'No associated groups are available',
        CONFIRM_UNSUBSCRIBE_RECORD: 'Record will be unsubscribed. Are you sure?',
        BLANK_SPECIFIC_DATE: 'Date can not be blank.',
        CONFIGURE_CHILD_SETUP: 'Please configure child setup.',
        CHILD_SETUP_SELECT_ATLEAST_ONE_FIELD: 'Please select at least one  field for child setup.',
        EMAIL_FORMAT_MSG: "- Invalid Email format.",
        URL_FORMAT_MSG: "- Invalid Url format.",
        FLOAT_FORMAT_MSG: "- Invalid Float format.",
        INTEGER_FORMAT_MSG: "- Invalid Integer format.",
        ERROR_MSG: "Do you want to leave without saving the changes?",
    },
    CHARTS: {
        NOTHING_TO_SHOW: 'Nothing to show for this time frame.'
    },
    ADVANCE_SEARCH: {
        SEARCH_FIELDS_NOT_FOUND: 'Nothing to search. Please enter a value.',
        MAX_ADD_FIELD_ALERT:'You cannot search with more than 10 fields',
        MIN_ADD_FIELD_ALERT:'Atleast one field is required to search.',
        SELECT_FIELD_ALERT:'Please select field before enter value.',
        ADVANCE_SEARCH: 'Advanced Search',
        EMPTY_DATE: 'Please enter date.',
        INVALID_DATE_RANGE: 'From date cannot be greater than To date',
        EMPTY_FROM_TO_DATE: 'Please enter From date and To date.',
        EMPTY_FROM_DATE: 'Please enter From date.',
        EMPTY_TO_DATE: 'Please enter To date.'
    },
    PUSH: {
        //PUSH_SUCCESS_MSG_1: 'This document will be pushed',
        PUSH_SUCCESS_MSG_1: 'document will be pushed',
        //PUSH_SUCCESS_MSG_1: 'You have successfully pushed',
        PUSH_SUCCESS_MSG_2: 'to the selected Units.',
        PUSH_SUCCESS_MSG_2_1: 'will be pushed',
        PUSH_SUCCESS_MSG_3: 'An email notification has been sent to the selected Units.',
        PUSH_SUCCESS_MSG_4: 'You have selected more than 20 records. You can push maximum 20',
        PUSH_SUCCESS_MSG_4_1: 'at a time.',
        PUSH_SUCCESS_MSG_5: 'Please select a Unit to Push',
        PUSH_SUCCESS_MSG_6: 'There are no Connected Clients available to push ',
        PUSH_SUCCESS_MSG_7: 'Pushed successfully.',
        PUSH_ALERT_MSG_8: 'Please select at least one Unit to push Email Templates',
        PUSH_ALERT_MSG_9: 'Please select at least one Unit to push Automation Campaigns',
        PUSH_ALERT_MSG_10: 'Please select at least one Unit to push Broadcast Campaigns',
        PUSH_SUCCESS_MSG_11: 'to the selected Unit.',
        //Added by Anindya
        PUSH_SUCCESS_MSG_11_2: 'Are you sure?',
        //End
        PUSH_SUCCESS_MSG_12: 'An email notification has been sent to the selected Unit.',
        PUSH_SUCCESS_MSG_13: 'Please select at least one Unit to push Sales Leads',
        PUSH_SUCCESS_MSG_14: 'Please select at least one Unit to push Workflows',
        PUSH_FOLDER: 'Folder',
        FAIL_USE_ASSIGNMENT_RULE_FOR_SALES_LEADS: 'No Assignment Rule is satisfied to push Sales Leads',
        PUSH_ALERT_MSG_12: 'Please select at least one Unit to push Agreement Templates',
        PUSH_ALERT_MSG_13: 'Please select at least one Unit to push Landing Pages',
        LANDING_PAGES: 'landing pages',
        PUSH_COMPLIANCE_MSG: 'will be pushed to all the connected franchises of the configured Franchisors.',
        ASSIGN_SUCESS:'will be assigned'
    },
    PROFILE_DETAILS: {
        UNMATCH_NEW_CONFIRM_PASSWORD: 'Confirmed password does not match with the new password.',
        NO_NEW_PASSWORD: 'Enter a valid password and confirm it.',
        NO_CONFIRM_PASSWORD: 'Confirm the new password.',
        LENGTH_MISMATCH: 'Atleast 12 characters long',
        ONE_UPPERCASE: 'one uppercase letter',
        ONE_LOWERCASE: 'one lowercase letter',
        ONE_NUMBER: 'one number',
        ONE_SYMBOL: 'one symbol',
        VALIDATE_PROFILE_IMAGE: 'Select an image file.',
        PROFILE_IMAGE_UPLOAD: 'Profile image saved successfully.',
        PROFILE_IMAGE_UPLOAD_FAILURE: 'Profile image could not be saved.',
        PROFILE_UPDATE: 'Profile updated successfully.',
        VALIDATE_PROFILE_IMAGE_SIZE: 'The photo you have selected is too large to use. The maximum file size allowed is 500 KB.',
        PROFILE_IMAGE_ATTACHMENT_LOGO_TEXT: 'You can upload a jpg, gif, or png file.',
        PROFILE_IMAGE_SIZE_TEXT: ' The maximum file size allowed is 500 KB.',
        PROFILE_SIGNATURE_AUTOSAVED: 'Signature saved successfully.'
    },
    CUSTOMIZATION: {
        LOCATION_VAL_NOT_MATCH: 'Location value does not match.',
        LOCATION_VAL_BLANK: 'There is no value available in the location field.'
    },
    IMPORT: {
        SELECT_FILE: 'Please select a file',
        SELECT_GROUP: 'Please select a group',
        SELECT_WORKFLOW: 'Please select a workflow',
        SELECT_STATUS: 'Please select a status',
        SELECT_SOURCE: 'Please select a primary source',
        SELECT_SECONDARY_SOURCE:'Please select a secondary source',
        SOURCR_NAME_EXISTS: 'Value already exists in List',
        SOURCR_NAME_BLANK: 'Source name can not be blank!',
        IMPORT_FILE_EXT: 'Please select file with .csv or .xls/xlsx extension',
        NO_RECORD_TO_IMPORT: 'No record for importing!',
        NO_MAPPING: 'There is no mapping for ',
        NOT_ALL_MAPPED: 'One or more columns are not mapped to CRM. Would you like to continue without importing those columns?',
        IMPORT_CONFIRM_MSG: 'records will be imported into',
        IMPORT_CONFIRM_MSG_1: 'record will be imported into',
        CONFIRM: 'Please confirm.',
        SELECT_LINK_USING: 'Please select an option for Link Using',
        SELECT_LINK_WITH: 'Please select an option for Link With',
        SELECT_DUPLICATE_FIELD: 'Please select atleast one option for duplicate field',
        SOURCE_ALERT_MSG_1: 'Please map the account “Source” field to a column that contains your marketing sources.',
        SOURCE_ALERT_MSG_2: 'You have chosen Source from the drop down list as well as from the mapping section. You can choose Source field value in one place not in two places.',
        NO_MAPPING_AVAILABLE: 'There is no mapping available.',
        BLANK_MAP_NAME: 'Please provide a map name!',
        VALID_MAP_NAME_LENGTH: 'Map name should not be greater than 32 characters!',
        DUPLICATE_MAP_NAME: 'Map name already exists!!',
        MAP_SAVE_SUCCESS: 'Your field mapping has been saved successfully!',
        NO_PREVIOUS_MAPPING: 'No Previous Mapping present!',
        MAP_NAME: 'Map Name',
        DELETE_MAP_ALERT: 'Are you sure you want to delete',
        DELETE_MAP_SUCCESS: 'Mapping deleted successfully!',
        IMPORT_SUCCESS: 'Successfully imported.',
        WARNING: 'Warning!',
        WARNING_TEXT: 'Please do not close this page until the import completes.',
        IMPORTING_RECORDS: 'Importing your record(s)',
        SUCCESS_IMPORT_COUNT: 'record(s) were imported',
        FAIL_IMPORT_COUNT: 'record(s) failed to import',
        FOUND: 'We found',
        DUPLICATES: 'duplicate(s)',
        APPLY_WORKFLOW_TEXT: 'to the imported records',
        APPLYING_WORKFLOW: 'Applying workflow',
        APPLIED_WORKFLOW: 'Completed applying workflow',
        APPLIED_WORKFLOW_TO: 'Applied Workflow to',
        MAX_IMPORT_RECORDS_MSG_1: 'There are',
        MAX_IMPORT_RECORDS_MSG_2: 'records in the file.Presently you can import 10000 records at a time!',
        REQUIRED_FIELDS:(invalidFieldLabels)=>`Please select all the required fields to import. <br><b>Invalid fields: ${invalidFieldLabels}</b>`,

        // not found in doc
        VERIFY_CONTACTS_MSG_1: 'Please verify that these contacts have given you their',
        VERIFY_CONTACTS_MSG_2: 'permission to email them. We do not allow rented or ',
        VERIFY_CONTACTS_MSG_3: 'purchased lists. These contacts must have either asked',
        VERIFY_CONTACTS_MSG_4: 'to receive emails from you or have close relationship with ',
        VERIFY_CONTACTS_MSG_5: 'your organization.',

        FILE_TYPE_MSG: 'The first row of the CSV or XLS/XLSX file must contain the field names.',
        DATE_FORMAT_MSG: 'Date must be in MM/DD/YYYY format ',
        ACCOUNTS_AND_CONTACTS: 'Accounts and Contacts',
        PARTNERS_AND_CONTACTS: 'Partners and Contacts',
        IMPORT_CSV_FILE: 'Please select file with .csv extension.',
        RECORDS_IMPORT_SUCCESS: 'Records have been imported successfully.',
        GREEN_LIGHT_STATUS_ERROR: 'Already one import is in progress, hence you can start a fresh import only after the completion of the current import process.',
        GREEN_LIGHT_STATUS_SUCCESS: 'Your current import is in progress. We will send you a confirmation email to the registered email of the concerned franchisees once it is done.',
        ASSIGN_SAVED_SUCCESS_MSG:'We have started assigning sales leads records.'
    },
    YOUTUBE_VIDEO: {
        EMPTY_VIDEO_LINK: 'Please paste a video URL for YouTube',
        INVALID_VIDEO_LINK: 'The URL you have entered is not valid',
        INSERT_TITLE: 'Insert video from YouTube',
        VIDEO_LINK_PLACEHOLDER: 'Paste a video URL from YouTube'
    },
    WISTIA_VIDEO: {
        EMPTY_VIDEO_LINK: 'Please paste a video URL for Wistia',
        INVALID_VIDEO_LINK: 'The URL you have entered is not valid',
        INSERT_TITLE: 'Insert video from Wistia',
        VIDEO_LINK_PLACEHOLDER: 'Paste a video URL from Wistia'
    },
    INXPRESS_REPORT: {
        VALIDATE_EMPTY_FROM_DATE: 'Please select from date.',
        VALIDATE_EMPTY_TO_DATE: 'Please select to date.',
        VALIDATE_EMPTY_COUNTRY: 'Please select country.',
        VALIDATE_EMPTY_FRANCHISE: 'Please select Franchise.',
        VALIDATE_EMPTY_WORKFLOW: 'Please select workflow.',
        VALIDATE_EMPTY_SALES_REP: 'Please select Sales Rep.',
        VALIDATE_EMPTY_OBJECT_NAME: 'Please select Report On.',
        NO_RECORD_EXPORT: 'No record available for export.',
        REPORT_NOT_READY_FOR_EXPORT: 'Please wait while the report is loading, you cannot export now.',
        BLANK_FROM_TO_DATE: 'Date cannot be blank.',
        VALIDATE_EMPTY_COUNTRY_INXPRESS_REPORT16: 'Please select a country to continue.',
        VALIDATE_EMPTY_WORKFLOW_INXPRESS_REPORT: 'Please select a workflow to continue.',
        VALIDATE_EMPTY_REGION_INXPRESS_REPORT: 'Please select a region to continue.',
        VALIDATE_EMPTY_DATE_RANGE: 'Please select a date range to continue.',
        VALIDATE_EMPTY_WORKFLOW_STAGES: 'Please select a workflow stage to continue.',
        VALIDATE_EMPTY_CHART_TYPE: 'Please select a chart type to continue.',
        VALIDATE_EMPTY_SHOW_TYPE: 'Please select a show type to continue.',
        VALIDATE_EMPTY_ACTIVITY_TYPE: 'Please select a activity type to continue.',
    },

    OTHER_VIEW: {
        TENANT_MESSAGE: 'Your subscription does not include $$@@##PRODUCT_NAME##@@$$',
        USER_MESSAGE: 'Please contact your administrator to add $$@@##PRODUCT_NAME##@@$$ to your subscription',
        AFTER_SUBSCRIPTION_VENDASTA_NOT_CONFIGURED: 'We haven\'t set up your account for $$@@##PRODUCT_NAME##@@$$ yet. We will notify you as soon as it is available.'
    },

    MY_INTEGRATION: {
        SAVE_SUCCESS: 'Your setting saved successfully.',
        APPEND_INFO: 'Append company and contact information to appointments added from Soffront',
        DISCONNECT_CONNECTION: 'Connection will be removed. Are you sure?',
        SAVE_FAIL: 'Your setting is not saved.',
        NO_CALENDAR: 'Calendar is not selected.',
        NO_SYNC_CHECKED: 'You have not selected any of the synchronization options. You can choose to synchronize your emails, calendar, or both.',
        SYNC_EMAIL_INFO_1: 'Select this option to automatically save the emails from your G Suite/Office 365 mailbox into CRM.',
        SYNC_EMAIL_INFO_2: 'The emails will be saved against a matching account or contact record.',
        SYNC_EMAIL_INFO_3: 'The emails will be synchronized every 5 minutes.',
        SYNC_CONTACT_INFO_1: 'Select this option to automatically add your CRM contacts to G Suite/Office 365.',
        SYNC_CONTACT_INFO_2: 'Any changes you make to the contacts in CRM will be automatically updated in G Suite/Office 365.',
        SYNC_CONTACT_INFO_3: 'The contacts will be synchronized every 5 minutes.',
        SYNC_CALENDAR_INFO_1: 'Select this option to automatically synchronize the appointments between your Soffront calendar with a calendar from your G Suite/Office 365 account.',
        SYNC_CALENDAR_INFO_2: 'If you have multiple G Suite/Office 365 calendars, you will need to choose one that you would like to synchronize.',
        SYNC_CALENDAR_INFO_3: 'The calendars will be synchronized every 5 minutes.',
        NO_CALENDAR_MESSAGE: 'We are waiting to get the details of your calendar. Please close this screen and come back in a few minutes. If you continue to see this message, please disconnect and reconnect your account.',
        NO_PERMISSION: 'Integration is not available in your current plan. Please subscribe to an appropriate plan or contact support at smbsupport@soffront.com.',
        SELECTED_CALENDAR_NOT_FOUND: 'We cannot find your prevoiusly selected calendar.Please select another one and save.',
        INVALID_ACCOUNT_SYNC_STATE1:'Your',
        INVALID_ACCOUNT_SYNC_STATE2:'connection is no longer valid. Please disconnect and connect once again.',
        SEND_SALES_EMAILS_VALIDATION1: 'To be able to send emails using',
        SEND_SALES_EMAILS_VALIDATION2: 'you will need to connect using',
        SEND_SALES_EMAILS_VALIDATION3: 'Please disconnect and connect again using',
        RE_AUTHENTICATE_AURINKO:'Please re-authorize your email and calendar integration setup.',
        
    },

    DOCUSIGN: {
        PROVIDE_USERNAME: 'Please provide a valid user name.',
        PROVIDE_PASSWORD: 'Please provide a valid password.',
        PROVIDE_INTEGRATOR_KEY: 'Please provide a valid integrator key.',
        SUCCESSFULLY_SAVED: 'You have successfully configured your DocuSign account.',
        FAILED_TO_SAVED: 'Unable to configure your DocuSign account.',
        DOCUSIGN_SETUP_TITLE_1: 'Select a document and send it ',
        DOCUSIGN_SETUP_TITLE_2: 'to ',
        DOCUSIGN_SETUP_TITLE_3: ' for signature',
        PROVIDE_RECIPIENT_NAME: 'Please provide recipient full name',
        PROVIDE_RECIPIENT_EMAIL: 'Please provide recipient email',
        PLEASE_SELECT_TEMPLATE: 'Please select a template.',
        NO_TEMPLATES_FOUND: 'No templates found.',
        UNABLE_TO_SEND: "Unable to send template since your DocuSign account has been expired.",
        TEMPLATE_NAME_LENTH: 'Template name must not exceed 65 characters.',
        PLEASE_SELECT_CONTACT: 'Please select at least one contact.',
        DOCUSIGN_CONTACT_MESSAGE_1: 'There is no email available for following Contact(s): ',
        DOCUSIGN_CONTACT_MESSAGE_2: 'Email will not be sent to these Contact(s).',
        DOCUSIGN_CONNECTED_MESSAGE_1: 'Sorry, you do not have the permission to perform this operation. Please contact Soffront.',
    },

    TWILIO: {
        CALL_OVER_SECURED_NETWORK: 'You can make a call only over a secured network',
        SAME_TO_AND_FROM_NUMBER: 'To Number and From number is same. Call cannot be initiated.',
        //CALLING_SUPPORT: 'We support only national calls. You can make calls within your Country',
        CALLING_SUPPORT: 'Calling is not supported in your country at present.',
        OUTBOUND_PHONE_NOT_VERIFIED: 'Your outbound phone number is not verified. Please verify it before making a call.',
        CALL_RECORDING_SAVE: 'Saved successfully. Please log out and log back in to see the change.',
        CALL_RECORDING_LAWS_AND_REGULATION_ALERT:'Please make sure your call recording practices comply with applicable laws and regulations.',
        NOTE_SAVE: 'Note saved successfully',
        PHONE_LOW_BALANCE1: 'Your phone balance is now ',
        PHONE_LOW_BALANCE2: ' minute(s).',
        PHONE_LOW_BALANCE3: 'You need more than 5 minutes balance to make a call.',
        PHONE_BLANK_MESSAGE: 'Please enter a phone number.',
        PHONE_ALREADY_CONFIGURE: 'This phone number is already configured.',
        DELETE_PHONE_NUMBER: 'Are you sure to delete this number?',
        VERIFY_PHONE_NUMBER: 'Please verify before adding another number.',
        PHONE_LIMIT: 'You cannot add more than 3 phone numbers.',
        VERIFICATION_CODE: 'At the prompt, please enter the verification code.',
        VERIFICATION_MESSAGE_1: 'We will call',
        VERIFICATION_MESSAGE_2: 'to verify the number.',
        FIELDS_ADD_LIMIT:'You cannot add more than 3 fields.',
        SETUP_UPDATED_SUCCESSFULLY:'Updated successfully.',
        SETUP_UPDATED_FIELDS:'Update failed.',
        SETUP_MINIMUM_SELECTFIELDS:'Please select atleast one field'
    },
    CALL_SCRIPT: {
        BLANK_NAME: 'Please enter a name for this script.',
        BLANK_DETAILS: 'Please enter the script details.',
        SAVE_SUCCESS: 'You have successfully saved this script.',
        UPDATE: 'You have successfully updated this script.',
        DELETE: 'You have successfully deleted this script.',
        NO_RECORD: 'No record available',
        'DUPLICATE_SCRIPT_NAME': 'This script name already exists. Duplicate script name is not allowed.',
        'EMPTY_SCRIPT_NAME_DETAILS': 'Script name and script details cannot be empty.',
        'RECORD_NOT_FOUND_UPDATE': 'Record not found to update.',
        DELETE_CONFIRMATION: 'Are you sure to delete this script?'
    },
    SMS_INTEGRATION: {
        PURCHASED_NUMBER: 'You have successfully purchased the number',
        SELECT_USER: 'Please select at least one sales rep to proceed further.',
        ENTER_ZIP_OR_AREA: 'Please enter a zip code or area code.',
        ENTER_AREA: 'Please enter an area code.',
        ENTER_VALID_ZIP: 'Please enter a valid zip code.',
        ENTER_VALID_AREA_CODE: 'Please enter a valid area code.',
        NO_AVAILABLE_PHONE_NUMBERS: 'There is no phone number available for the entered Zip/Area Code.',
        NO_AVAILABLE_PHONE_NUMBERS_FOR_ZIP: 'There is no phone number available for the entered Area Code.',
        NO_AVAILABLE_TOLL_FREE_NUMBERS: 'There is no toll-free number available for the selected country.',
        REMOVE_NUMBER_CONFIRMATION: 'Are you sure to remove this number?',
        //SMS_SUPPORT: 'You cannot send text messages to international phone numbers.',
        SMS_SUPPORT: 'Sending text messages is not supported in your country.',
        SELECT_NUMBER: 'Please select a number.',
        SELECT_AT_LEAST_ONE_NUMBER:'Please select at least one number to import',
        TWILIO_NUMBER_IMPORTED_SUCCESS:'Your twilio number(s) imported successfully',
        TWILIO_NUMBER_IMPORTED_ERROR:'Your twilio number(s) could not be imported.',
        PHONE_NUMBER_CANNOT_BE_PURCHASED:'Phone number cannot be purchased. Please contact Soffront support for more details.',
        ENTER_SMS_TEXT: 'Please enter SMS text.',
        SMS_LOW_BALANCE_1: 'Your SMS balance is now ',
        SMS_LOW_BALANCE_2: 'You need to buy SMS credits to send SMS.',
        TWILIO_NUMBER_PURCHASE_MSG_1: 'Your account will be billed',
        TWILIO_NUMBER_PURCHASE_MSG_2: 'month',
        TWILIO_NUMBER_PURCHASE_MSG_3: 'for this phone number. You can remove the number from your account any time to stop the billing.',
        SMS_TOOLTIP_MESSAGE: 'Find by zip or area code',
        SMS_TOOLTIP_MESSAGE_AREA_CODE: 'Find by area code',
        TOLL_FREE_TOOLTIP_MESSAGE: 'Find toll-free number',
        INSERT_IMAGE_FILE: 'Please select only jpg, jpeg, gif or png file',
        BATCH_SEND_SMS_MESSAGE: 'Message sent successfully',
        'INSUFFICIENT_BALANCE': 'Insufficient SMS Balance',
        'BATCH_SMS_SENT': 'Message sent successfully',
        'BATCH_SMS_FAILS': 'Failed to send Text Message',
        NO_FROM_NUMBER_MESSAGE: 'You will need to purchase a phone number to send text messages. Please purchase the phone number from the Integrations menu.',
        MAX_ATTACHMENT_FILE_FOR_SMS: 'Maximum 10 attachment files allowed.',
        'NO_INPUT_STREAM_FOUND': 'No input stream  found ',
        'NO_ATTACHMENT_FOUND': 'No attachment  found ',
        'FILE_SIZE_ERROR': 'File size should be less than 5MB.',
        IMAGE_SELECT: 'Select an image',
        MERGE_FIELD: 'Select merge fields',
        DUPLICATE_FILE_NAME: 'This file already exists. Please select a different file.',
        RECORD_UNSUBSCRIBE_ALERT: 'Selected record is unsubscribed from Texts. You cannot send Text Message.',
        UNSUBSCRIBE_LIST_CONFIRM_MSG: 'out of selected records has unsubscribed from Texts. Text Message cannot be sent to the unsubscribed record(s). Press OK to proceed.',
        UNSUBSCRIBE_LIST_CONFIRM_MSG_1: 'out of selected records have unsubscribed from Texts. Text Message cannot be sent to the unsubscribed record(s). Press OK to proceed.',
        'BATCH_SMS_SCHEDULE': 'Text message has been scheduled successfully',
        'BATCH_SMS_SCHEDULE_FAIL': 'Failed to schedule Text Message',
        BATCH_SEND_SMS_MSG_1: 'Out of the',
        BATCH_SEND_SMS_MSG_2: 'you have selected, only',
        BATCH_SEND_SMS_MSG_3: 'have opted in to receive your text message and has the mobile no. ',
        BATCH_SEND_SMS_MSG_4: 'We would send the text message only to these',
        BATCH_SEND_SMS_MSG_5: 'Press OK to proceed.',
        SINGLE_SEND_SMS_MSG_1: 'Please check if selected',
        SINGLE_SEND_SMS_MSG_2: 'has opted in to receive your text messages or has the mobile no.',
        RESCHEDULE_SUCCESS: 'SMS has been rescheduled successfully',
        AUTOMATION_SMS_ADD_MSG: 'You have successfully saved this text message',
        WORKFLOW_STATUS_MSG: 'You have one or more active workflows that are using this number to send text messages. Please update those workflows to use a different number before removing this number. ',
        SCHEDULED_STATUS_MSG: 'You have one or more staged sms that are using this number. Click OK to cancel all your staged sms and remove the number.',
        REMOVE_SUCCESS_MSG: 'The number has been removed successfully.',
        NUMBER_SAVED_SUCCESS_MSG: 'Number Saved Successfully.',
        NO_LINKED_MOBILE_NUMBER_MSG:'Associated record has no mobile number. You cannot send Text Message.',
        NUMBER_INVALID_MSG:"The contact's mobile number is incorrect!",
        CANNOT_DELETE_UNITS_NUMBER: 'You are not allowed to remove this number as this number has been assigned to an Unit.',

    },
    EVENTBRITE: {
        NO_ORGANISATION_SELECTED: 'Please select atleast one organisation',
        NO_MAPPING_FOUND: 'There is no mapping for CRM Contact and Eventbrite Fields.'
    },
    QUICKBOOKS: {
        NO_MAPPING_FOUND: 'There is no mapping for CRM Accounts and Quick Books Customer field.',
        COONECT_QB_MSG: 'You have not connected your Quickbooks account. Click Ok to connect your account.'
    },
    XERO: {
        SELECT_ONE_INTEGRATION_ITEM_AND_TYPE: 'Please select an item and an integration type to integrate.',
        SELECT_ONE_INTEGRATION_ITEM: 'Please select an item to integrate.',
        SELECT_INTEGRATION_TYPE: 'Please select the integration type.',
    },
    REPORT_SCHEDULE_DIALOG: {
        START_DATE_BLANK: 'Start date cannot be blank.',
        INVALID_END_DATE: 'Start date cannot be greater than or same as end date.',
        DAY_CHECKBOX_BLANK: 'Atleast check one Day.',
        SEND_TO_BLANK: ' Atleast select one user.',
        SAVE_SUCCESS: "Saved Successfully."
    },
    DOCUMENT_PUSH: {
        ALERT_MSG_1: 'Please select at least one Company to push Documents',
        DOCUMENTS: 'Document(s)',
        FAIL: 'Failed to push'
    },
    OPERATIONS_REPORT: {
        NO_CUSTOM_REPORT_MESSAGE: 'You don\'t have any custom reports.'
    },
    PINNED_NOTES: {
        CONFIRM_MESSAGE: 'This will replace the existing pinned note. Click Ok to proceed or Cancel to discard.'
    },
    SETTINGS: {
        EMPTY_NAME: 'Please enter',
        DUPLICATES_SOURCE_NAME: 'Source name already exists!',
        DUPLICATE_SOURCE_NAME:'The selected secondary source(s) already exists. Please add new secondary source and save it.',
        DUPLICATE_TASKS_NAME:'Please enter different name',
        CORPORATE_CURRENCY: 'The selected currency has been updated successfully.',
        INVALID_CURRENCY_NAME: 'Please select a valid Corporate Currency name',
        SYSTEM_NOTE_TYPE_EDIT_ALERT: 'You cannot edit this Note Type.',
        SYSTEM_APPOINTMENT_TYPE_EDIT_ALERT: 'You cannot edit this Appointment Type',
        SYSTEM_NOTE_TYPE_DELETE_ALERT: 'You cannot delete this Note Type.',
        SYSTEM_APPOINTMENT_TYPE_DELETE_ALERT: 'You cannot delete this Appointment Type.',
        SYSTEM_CAMPAIGN_TYPE_DELETE_ALERT: 'You cannot delete this Campaign Type.',
        CORPORATE_CURRENCY_ALERT: 'Please select a corporate currency.',
        CORPORATE_CURRENCY_CONFIRM: 'The selected currency will be saved. Are you sure?',
        'CORPORATECURRENCY': 'Select a Corporate Currency',
        'DUPLICATE_NOTE_TYPES': 'This Note Type already exists.Duplicate Note Types creation is not allowed.',
        'DUPLICATE_APPOINTMENT_TYPES': 'This Appointment Type already exists.Duplicate Appointment Types creation is not allowed.',
        'INCORRECT_RECORD_ID': 'Incorrect record Id.Update failed.',
        'RECORD_DOES_NOT_EXISTS': 'Record does not exist.Delete Operation Failed.',
        'RECORD_CANNOT_DELETED': 'System or Default NoteTypes cannot be deleted.',
        'DUPLICATE_CAMPAIGN_TYPES': 'This Campaign Type already exists.Duplicate Campaign Types creation is not allowed.',
        'DUPLICATE_RELATIONS': 'Relation name already exists!',
        RELATION_LINK_INFO_ALERT_MSG: 'There are records which follow this relation. Unlink the respective records first which follow this relation and then you will be able to delete this relation.'
    },
    SOURCE:{
        PRIMARY_SOURCE_EMPTY:'Please select a primary source before adding secondary source.',
        PRIMARY_SOURCE_EMPTY_VALIDATION:'Please enter Primary Source Name',
        SECONDARY_SOURCE_EMPTY_VALIDATION:'Please enter Secondary Source Name',
        PRIMARY_SOURCE_CHANGE_VALIDATION:'Do you like to discard adding this source? Any unsaved changes would be lost.',
    },
    RECYCLE_BIN: {
        SELECT_WORKFLOW: 'Please select a workflow',
        SELECT_STAGE: 'Please select a stage',
        NOTE: 'Note: Linked records of the original record will not be restored.',
        RESTORE: 'Record restore successfully'
    },
    UNSUBSCRIBE: {
        SELECTED_RECORD: 'This record will be unsubscribed from ',
        ARE_YOU_SURE: '. Are you sure?',
        SELECTED_BOTH_RECORD: 'This record will be unsubscribed from Emails and Texts. Are you sure?',
        'EMAILS_UNSUBSCRIBE': ' will be unsubscribed from Emails. Are you sure?',
        'TEXTS_UNSUBSCRIBE': ' will be unsubscribed from Texts. Are you sure?',
        'BOTHS_UNSUBSCRIBE': ' will be unsubscribed from Emails and Texts. Are you sure?',
    },
    WORKFLOWS: {
        STATUS_CHANGED: 'Status has been changed to ',
        'SAME_STATUS': 'Record already in',
        'FAILED_TO_CHANGE_WORKFLOW_STATUS': 'failed to change Workflow status.',
        ONLY_ONE_ACTIVE_WORKFLOW: 'You cannot make this workflow inactive. This is the only active workflow available. A table must have at least one active workflow.',
        ONLY_ONE_ACTIVE_WORKFLOW_CANNOT_DELETE: 'You cannot delete this workflow. This is the only active workflow available. A table must have at least one active workflow.',
        DELETED_WORKFLOW: 'The workflow does not exist. Select Ok to choose a new workflow.',
        WORKFLOW_BLANK_ALERT : 'Workflow can not be Blank.',
        STATUS_BLANK_ALERT:'status can not be Blank',
    },
    WORKFLOW_DESIGNER: {
        AUTOMATED_WORKFLOW_TITLE: 'Workflow Designer',
    },
    CHANGE_LOGO: {
        SELECT_IMAGE_TO_UPLOAD: 'Select an image to Change Logo',
        LOGO_IMAGE_UPLOAD_FAILURE: 'Company Logo could not be saved.',
        LOGO_IMAGE_UPLOAD: 'Company Logo saved successfully.',
        VALIDATE_LOGO_IMAGE: 'Select an image file.',
        VALIDATE_LOGO_IMAGE_SIZE: 'The photo you have selected is too large to use. The maximum file size allowed is 500 KB.',
        VALIDATE_FAVICON_IMAGE_SIZE: 'The icon you have selected is too large to use. The maximum file size allowed is 16x16.',
        LOGO_IMAGE_ATTACHMENT_LOGO_TEXT: 'You can upload a jpg, gif, or png file.',
        LOGO_IMAGE_SIZE_TEXT: ' The maximum file size allowed is 500 KB.',
        WEBSITE_URL: 'Website',
        VALIDATE_LOGO_WEBSITE_URL: 'Would you like to upload logo without any valid URL?'
    },
    ACCOUNTS: {
        DUPLICATE_MAIL_DOMAIN: 'There is an existing account for this company - would you like to add a contact instead?',
        DUPLICATE_FRANCHISE: 'This is duplicate Franchisee id, Please change',
        ALERT_MULTI_DRAWER_OPEN : 'A convert dialog is already opened in minimised mode.'

    },
    ASSIGNMENT_RULES: {
        NO_ASSIGNMENT_RULES: 'No rules available.',
        NO_NAME: 'Please provide assignment rule name.',
        NAME_MAX_LENGTH: 'Assignment rule name cannot be more than 64 characters.',
        NO_CRITERIA: 'Please provide at least one criteria.',
        NO_USER: 'Please provide at least one user.',
        RULE_SAVE_SUCCESS: 'Assignment Rule saved successfully.',
        RULE_NAME_EXISTS: 'Name already exists!',
        ACTIVE_RULE_EXCEEDS: 'You already have 60 active assignment rules.',
        SELECT_UNIT_MAXIMUM_FIELD: 'You can select only one unit',
        SELECT_A_UNIT_WORKFLOW: 'Please select a unit workflow.',
        NO_UNIT_USER: 'Please provide at least one unit.'
    },
    COUPONS: {
        CREATE_COUPON_NAME: 'Create an internal name for your coupon',
        CREATE_DISCOUNT_TYPE: 'What type of discount would you like to create?',
        FIXED_AMOUNT: 'Fixed Amount',
        PERCENTAGE: 'Percentage',
        PREFIX_COUPON_CODE: 'Specify a prefix for the coupon code',
        CAN_CLAIM_MORE_THAN_ONCE: 'Can be used more than once by the same account?',
        CALIM_LIMIT_ONE: 'Only once',
        CLAIM_LIMIT_UNLIMITED: 'Unlimited',
        WHYEN_CLAIM_CODE: 'Coupon expiration date',
        CLAIM_ANYTIME: 'Never expires',
        CLAIM_BEFORE_DATE: 'Expiration date',
        SAVE_SUCCESSFULLY: 'Coupon saved successfully',
        ACTIVE_SUCCESSFULL: 'Status has been changed to Active.',
        INACTIVE_SUCCESSFULL: 'Status has been changed to Inactive.',
        DELETE_CONFIRMATION: 'Coupons will be deleted. Are you sure?',
        INVALID: 'is an invalid',
        DUPLICATE_COUPONS: 'Coupon name already exists.',
        NEGATIVE_COUPONS: 'can not be negative.',
        SPECIFY_DATE: 'Please specify a date.',
        PODUCT_SERVICE: 'Product/Service',
    },
    NOTIFICATION_REMINDERS: {
        SAVE_SUCCESS: 'Reminder added successfully',
        INVALID_DAY: 'Enter a Valid Day(s)',
        DUPLICATE_REMINDER: 'This is duplicate Reminder, Please change',
        INVALID_DATE_FIELD: 'Please select a Date field', // changed
        DELETE_SUCCESS: 'Reminder deleted successfully',
        UPDATE_SUCCESS: 'Reminder updated successfully',
    },
    EMAIL_INTEGRATION: {
        DUPLICATE_EMAIL: 'This Email Address already exists!',
        EMAIL_SAVE_SUCCESS: 'Email Address saved successfully',
        SEND_VERIFICATION_EMAIL_SUCCESS_1: 'We have sent the verification email. Please check your inbox and click ',
        SEND_VERIFICATION_EMAIL_SUCCESS_2: 'Verify this email address. ',
        SEND_VERIFICATION_EMAIL_SUCCESS_3: 'Then come back and click the refresh icon to see the status of your email address.',
        EMAIL_INTEGRATION_VERIFIED: 'This Email Address has been already verified!',
        CONFIRM_DELETE_EMAIL: 'The selected email address(s) will be deleted. Are you sure?',
        INVALID_EMAIL: 'Invalid Email Address',
        EMAIL_CANT_BLANK: 'Email Address cannot be blank!',
        EDIT_EMAIL_SAVE_SUCCESS: 'Email saved successfully',
    },
    NEWS: {
        SAVE_ERROR: 'Unable to save.',
        VALIDATE_CATEGORY: 'Category cannot be blank.',
        VALIDATE_STATUS: 'Status cannot be blank.',
        VALIDATE_TITLE: 'Title cannot be blank.',
        VALIDATE_SUMMARY: 'Summary cannot be blank.',
        VALIDATE_EXTERNAL_LINK: 'External Link cannot be blank.',
        VALIDATE_DETAILS: 'Details cannot be blank.',
        CATEGORY_NAME_EXISTS: 'Value already exists in List.',
        DUPLICATE_TITLE: "This Title already exists!",
    },
    USEFUL_LINKS: {
        CATEGORY: 'Category',
        LINK: 'Link',
        TITLE: 'Title',
        VALID_CATEGORY: 'Please select a valid Category',
        DUPLICATE_CATEGORY: 'This is duplicate Category, Please change',
        DUPLICATE_TITLE: 'Title already exists with the same texts. Please change the title.',
        DUPLICATE_CATEGORY_MSG:'Category already exists with the same title. Please change the Category title.',
        CONFIRM_DELETE_CATEGORY: 'Category will be deleted. Are you sure?',
        CONFIRM_DELETE_LINK: 'Link will be deleted. Are you sure?',
        VALID_URL: 'Please enter a valid URL.',
        EXISTING_LINKS_CATGORY: 'You have existing links under this category. ',
        PLEASE_MOVE_LINKS: 'Please move the links to',
        CLICK_TO_CONFIRM: ' Click Ok to confirm.',

    },
    HUB_SETUP: {
        VALID_URL: 'Please enter a valid',
        URL: 'URL.',
        COMMUNITY: 'Community URL.',
        MANUAL: 'Manual URL.',
        TRAINING: 'Training URL.',
        INVALID_HUB_NAME: 'Plaese enter a valid Hub name',
    },
    CASE: {
        CASE_CLOSED_SUCCESS: 'Case closed successfully'
    },
    ORTHOTIC_REPORT: {
        VALIDATE_EMPTY_PRODUCT: 'Please select a product.'
    },
    USER_REPORT: {
        REPORT_DELETE_RECORD: 'Report will be deleted. Are you sure?',
        REPORT_DELETE: 'Report deleted successfully',
        INVALID_START_END_DATE: 'Start date cannot be greater than end date.'
    },
    AUTO_FILL_VALUES: {
        CHNAGE_PARENT_OBJECT: 'Do you really want to change the Parent Object?',
        CHANGE_CHILD_OBJECT: 'Do you really want to change the Child Object?',
        SELECT_PARENT_OBJECT: 'Select a Parent Object.',
        SELECT_CHILD_OBJECT: 'Select a Child Object.',
        NO_MAPPING_FIELDS: 'No mapping fields available between ',
        NO_MAPPING_FIELDS_AND: 'and',
        CHILD_FIELD_SIZESHOULD_GREATER: 'Child field size should be greater than Parent field size.',
        MAPPING_FROM: 'Mapping from ',
        MAPPING_TO: ' to ',
        NO_AVAILBLE_FIELDS_FOR_MAPPING: '. There is no available common field for default mapping.',
        NO_AVAILABLE_PARENT_CHILD_MAPPING: 'There is no available parent-child field mapping.',
        FIELD_MAPPING_DONE_SUCCESS: 'Field Mapping Done Successfully.',

        MAP_FIELDS_TO_AUTOFILL: 'Map the fields to auto-fill ',
        MAP_FIELDS_TO_AUTOFILL_FROM: ' from ',
        SELECT_PARENT_FIELD: 'Select a Parent field.',
        SELECT_CHILD_FIELD: 'Select a Child field.',
        MAPPING_NOT_POSSIBLE: ' is not possible.'
    },
    LANDING_PAGE: {
        INVALID_NAME: 'Please enter landing page name.',
        INVALID_URL_WARNING: 'Please enter URL name.',
        SAVE: 'Landing page saved successfully.',
        UPDATE: 'Landing page updated successfully.',
        INVALID_FORM_BLOCK_EDIT_MSG: 'Current active block has no form fields to edit, please select form fields block to edit.',
        DUPLICATE_NAME: 'Landing page already exists with this name. Please enter a different name and try again.',
        DUPLICATE_URL: 'Landing page already exists with this URL. Please enter a different URL and try again.',
        INPUT_DATA_LOSS_MSG: 'All the entered data will be erased. Are you sure?',
        EMAIL_REQUIRED_MSG: 'Email is required.',
        ATLEAST_ONE_BLOCK_ADD_MSG: 'Please add at least one Design Block to save this page.',
        PREVIEW_ALERT_MSG: 'Please save before previewing the landing page.',
        OPEN_URL_ALERT_MSG: 'Please save the changes before performing the action.',
        COPY_TO_CLIPBOARD: 'Landing page template link copied to clipboard.',
        FORM_ADDED_MSG: 'Form added successfully.',
        FORM_UPDATED_MSG: 'Form updated successfully.',
        INVALID_URL: 'Invalid url.',
        LABEL_FIELD_CANNOT_BLANK: 'label field cannot be blank.',
        FIELD_IS_ALREADY_ADDED: 'Field is already added.',
        MINIMUM_ONE_FIELD: 'Minimum one field is required.',
        NO_MORE_FIELD_AVAILABEL: 'No more fields are available.',
    },
    DOWNLOAD_LEADS: {
        SELECT_CRITERIA: 'Please select atleast one criteria.',
        SELECT_WORKFLOW: 'Please select a workflow.',
        SELECT_GROUP: 'Please select a group.',
        SELECT_STATUS: 'Please select status.',
        SELECT_SALESREP: 'Please select a salesrep.',
        LEAD_EXIST_MSG_1: 'You already have these leads in your account - ',
        LEAD_EXIST_MSG_2: '.Click Ok to stop the download and remove these leads from your selection.'
    },
    SCHEDULER: {
        BLANK_APPOINTMENT_NAME: 'Please enter Appointment Name.',
        BLANK_LOCATION: 'Please enter Location.',
        SAVE_SUCCESS: 'Scheduler setup saved successfully.',
        SAVE_FAIL: 'Scheduler setup couldnot be saved.',
        NO_SCHEDULER: 'Please create an appointment scheduler before using scheduler link.',
        VALIDATE_TIME: 'Start time cannot be greater than or same as End time.',
        NO_START_TIME: 'Please select start time.',
        NO_END_TIME: 'Please select end time.',
        SCHEDULER_LINK_COPIED_SUCCESS: 'Scheduler link copied to clipboard.',
        BLANK_SUBMIT_BUTTON: 'Button label cannot be blank.',
        CALENDAR_SYNC_MSG: 'Your CRM calendar is not connected to your GSuite or Office 365 calendar.',
        CALENDAR_SYNC_LINK: 'Click here to set it up.',
        SCHEDULER_UNSAVE_msg: 'Do you want to leave this form? Your changes will not be saved.',
    },
    RELATION_REPORT: {
        RANGE_FIELD_BLANK: 'Please select a date field.',
        ROW_FIELD_BLANK: 'Please select a row field.',
        COL_FIELD_BLANK: 'Please select a column field.',
        REPORT_NAME_BLANK: 'Please enter report name.',
        REPORT_SAVE_SUCCESS: 'Report saved successfully.',
        DUE_BY_BLANK: 'Please select a date range.',
        NO_ROW_FIELD_VALUE: 'Please select a value for row label.',
        NO_COLUMN_VALUE: 'Please select a value for column header.',
        SAME_ROW_COLUMN_FIELD: 'Row field and column fields cannot be same.',
        INVALID_END_DATE: 'Start date cannot be greater than end date.',
        'Report Name already exists': 'Report Name already exists.',
        VALID_REPORT_NAME: 'Please enter valid Report Name',

    },
    PAYMENTS: {
        NOT_ADDED_QB: 'The Quote/Invoice is not added to the Quickbook yet!',
        NO_LINKED_AVAILABLE: 'There is no linked Quote/Invoice available!',
        PAYMENT_VALUE_ALERT_MSG: 'Payment Value cannot be Zero.',
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: 'You have an open quote/invoice tab. Please close that tab before opening or adding a new quotation/invoice.',
        AMOUNT_EXCEED_BALANCE_DUE: 'The payment you are entering exceeds the balance due for this invoice. Click OK to continue the payment. Click Cancel to change the payment amount.',
        PAID_AMOUNT_EXCEED: 'The payment you are entering for refund exceeds the paid amount for this invoice. Click OK to change the payment amount.',
        REFUND_AMOUNT_EXCEED: 'The payment you are entering for refund exceeds the left refund amount for this invoice. Click OK to change the payment amount.',
        REFUND_COMPLETED:'Refund already completed.',
        QUOTE_NO: (quoteNo) => `${quoteNo} number cannot be blanked.`,
        QUOTE_NO_START_WITH_ZERO: (quoteNo) => `${quoteNo} no. cannot start with 0.`,
        INVALID_MONERIS_CREDENTIALS: 'Invalid Moneris Credentials. Kindly provide valid credentials and try again.',
        CONNECTED_TO_MONERIS: 'Connected to Moneris successfully.',
        DISCONNECTED_FROM_MONERIS: 'Disconnected from Moneris successfully.',
        PAYMENT_INTEGRATION_REQUIRED: 'Please select a payment integration option and try again.',
        PAYMENT_ALREADY_PAID: "This payment has already been paid.",
        PAYMENT_INVALID: "The payment you are making is invalid.",
        PAYMENT_INVALID_MSG: "Please enter and link a valid invoice number and try again."

    },
    PRODUCTS: {
        DUPLICATE_PRODUCT: 'Product Name already exists.',
        ADDED_TO_QUICKBOOK: 'Successfully added to QuickBooks.',
        PULLED_FROM_QUICKBOOKS: 'Successfully pulled from Quickbooks.',
        PRODUCT_PRICE_VALUE_ALERT_MSG: 'Price value cannot be Zero.',
        PRODUCT_NAME_COULDNOT_CONTAIN_COLON_MSG: 'cannot contain colon (:).',
        NEGATIVE_PRICE: 'Price cannot be negative for Inventory type.'
    },
    COMPANY_PROFILE: {
        BLANK_BUSINESS_NAME: 'Please enter Business Name.',
        BLANK_BUSINESS_ADDRESS: 'Please enter Business Address.',
        BLANK_BUSINESS_PHONE: 'Please enter Business Phone.'
    },
    EXPORT_SETTINGS: {
        CONSIDERATION: 'Consideration:',
        CONSIDERATION_MSG1: 'We support EXCEL & CSV formatted files.',
        CONSIDERATION_MSG2: 'A cell in Excel can contain maximum 32,767 characters, hence in the exported excel file we show maximum 32,767 characters for a cell and exclude rest of the characters.',
        NO_RECORDS_FOUND: 'No records found!',
        FILE_NAME_IS_EMPTY: 'File name is empty.',
        PLEASE_SELECT_THE_FILE_TYPE: 'Please select the file type.',
        PLEASE_SELECT_THE_PARENT_OBJECT: 'Please select the parent object.',
        PLEASE_SELECT_THE_QUERY_NAME: 'Please select the query name.',
        PLEASE_SELECT_THE_PARENT_FIELDS: 'Please select the parent fields.',
    },
    DATA_BASE: {
        FAILURE_MSG: 'Failed to Save.',
        SUCCESS_MSG: 'Your setting has been saved successfully. Please log out and log back in to see the changes.',
        VALIDATION_MSG: 'Please select at least one child object for ',
        VALIDATION_MSG1: "You reached the maximum limit of 15 selected objects.",
        SUCCESS_MSG1: 'Your setting has been saved successfully.',
    },
    UNITS: {
        BLANK_FIRST_NAME: 'Please enter First Name.',
        BLANK_LAST_NAME: 'Please enter Last Name.',
        BLANK_PASSWORD: 'Please enter Password.',
        UNMATCH_RETYPE_PASSWORD: 'Re-type Password does not match with the password.',
        BLANK_RETYPE_PASSWORD: 'Please enter Re-type Password.',
        BLANK_EMAIL: 'Please enter Email.',
        BLANK_PHONE: 'Please enter Phone.',
        BLANK_COMPANY: 'Please enter Company.',
        BLANK_FA_EXPIRATION_DATE:"The 'FA Expiration Date' is not valid. Please update it with a valid date.",
        UNIT_SAVED: 'Saved successfully.',
        UNIT_NOT_SAVED: 'Unit could not be saved.',
        DUPLICATE_EMAIL: 'You already have record(s) with same email id.',
        DUPLICATE_COMPANY: 'Company name already exists.',
        SUBSCRIPTION_NOT_VALID: 'Subscription is not valid for the selected unit. Please contact Soffront support for more details.',
        BLANK_ROYALTY_PERCENT: 'Please enter Royalty Percent.',
        NO_UNIT_TASKS: 'There is no task available for the Unit Owner.',
        NO_ACCOUNT_TASKS: 'There is no task available for the Account.',
        BLANK_FAMILY_INDUSTRY: 'Please select a family industry',
        NO_FAMILY_INDUSTRY: 'You would need a base template before you can add new units. Please contact BrandWide support to set up a base template.',
        BLANK_PLAN_NAME: 'Please select a Plan Name.',
        NO_AVAILABLE_PLANS: 'There are no available plans that can be enrolled for this unit.',
        NO_BRANCH_WORKFLOW: 'There is no available branch workflow.',
        PLEASE_SELECT_TYPE: 'Please select a Type to continue.',
        NO_BASE_LOGIN: 'Base does not exist or not configured! Fix the problem and try again.',
        BLANK_NAME: 'Please enter Name.',
        BLANK_TYPE: 'Please select type',
        NO_ONBOARDING_WORKFLOW: 'There is no onboarding workflow available.',
        TENANT_PASSWORD_NOT_SET_YET: 'Account password is not set. Please set the password before trying to login.',
    },
    SHARE: {
        SHARE_SUCCESS_MSG_1: 'Share information is updated successfully.',
        SHARE_SUCCESS_MSG_SL: 'Share information is updated successfully for the selected security levels if there is any.',
        SHARE_SUCCESS_MSG_2: 'Email notification will be sent to the newly selected Units if there is any.',
        SHARE_SUCCESS_MSG_3: 'Email notification will be sent to the newly selected Unit if there is any.',
        SHARE_SUCCESS_MSG_4: 'Are you sure?',
        SHARE_SUCCESS_MSG_5: 'will be shared to the selected Unit.',
        SHARE_SUCCESS_MSG_6: 'will be shared to the selected Units.',
        SHARE_FAILED_MSG: 'Failed to share.',
        SHARE_SELECT_CORPORATE_DOCUMENT: 'Please select at least one Corporate Users to share Documents.',
        SHARE_SELECT_CORPORATE_IMAGE: 'Please select at least one Corporate Users to share Image.',
        SHARE_SELECT_UNIT_DOCUMENT: 'Please select at least one Unit to share Documents.',
        SHARE_SELECT_UNIT_IMAGE: 'Please select at least one Unit to share Image.',
        NO_CONNECTED_CLIENT: 'There are no Connected Clients available to share Documents.',
    },
    SECURITY_LEVEL: {
        ERROR_MSG_1: 'Oops! Something went wrong. Please try later.',
        ERROR_MSG_2: 'Description cannot be blank.',
        SUCCESS_MSG_1: 'Your setting has been saved successfully.',
    },
    WHITE_LABEL: {
        LINK_SAVE: 'The Link has been saved successfully.',
        NOT_VALID_URL: 'is not a valid url'
    },
    ROLL_UP: {
        SELECT_A_CHILD_OBJECT: 'Please select a Child Object',
        SELECT_A_PARENT_OBJECT: 'Please select a Parent Object',
        NO_CHILD_FIELD_SELECTED: 'No child field selcted',
        NO_PARENT_FIELD_SELECTED: 'No parent field selcted',
        SAME_TYPE_FIELD: 'Please select same type of fields for Child and Parent objects',
        ROLL_UP_EXIST: 'Same roll up field combination already exists',
        PARENT_FIELD_EXIST: 'Already one Roll up has been configured the selected Parent Object field.',

    },
    ROYALTY_REPORT: {
        BLANK_ROYALTY_PERCENT: 'Please enter royalty percent.',
        REPORT_SAVE_SUCCESS: 'Report saved successfully.',
        SETUP_SAVE_SUCCESS: 'Royalty setup saved successfully.'
    },
    MENU: {
        ATLEAST_ONE_MODULE_ACTIVE: 'Sorry, at least one module should be active.',
        ATLEAST_ONE_MODULE_ITEM_ACTIVE: 'Sorry, at least one module items should be active.',
        MODULE_LABEl_CHAR_LIMIT_MSG: 'Name cannot exceed more than 32 characters.',
        DUPLICATE_NAME: 'module name already exists.'
    },
    SALES_PURCHASE_ORDER: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_PURCHSE_RECORD: 'You have an open purchase order tab. Please close that tab before adding a new purchase order.',
        RESTRICT_TO_OPEN_TWO_OR_MORE_SALES_RECORD: 'You have an open sales order tab. Please close that tab before adding a new sales order.',
        SAVE_PURCHASE_SUCCESS: 'Purchase order saved successfully.',
        SAVE_SALES_SUCCESS: 'Sales order saved successfully.',
        SALES_REP_NAME_CANT_BLANK: 'Sales rep name cannot be blank.',
        SELECT_ONE_ITEM: 'Please select one item information.',
        DISCOUNT_AMOUNT_CANT_GREATER: "The discount amount can't be greater than the subtotal.",
        SPECIFY_TRANSC_AMOUNT: 'You must specify a transaction amount that is 0 or greater.',
        SETUP_COMPANY_PROFILE: 'Please set up your company profile before adding quotes. Please contact your CRM administrator to set up the profile.'
    },
    JOBS: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_JOBS_RECORD: 'You have an open job tab. Please close that tab before adding a new job.',
        FIELDS_CANNOT_BLANK: ' field cannot be blank.',
        PRODUCT_NAME_NOT_CONTAIN: 'Product name cannot contain colon (:).',
        JOB_SAVE_SUCCESS: 'Job saved successfully.',
        PRODUCT : 'Product',
        ALREADY_EXIST: 'is already exist.',

    },
    POWER_CALLING: {
        ALERT: {
            WARNING: 'Are you sure',
        },
        YOU_DONT_HAVE_CALL_SCRIPT: "You don't have any call scripts.",
        ALERT_MSG_1: 'Out of the ',
        ALERT_MSG_2: ' you have selected, only ',
        ALERT_MSG_3: ' have valid local numbers. You will be able to call those ',
        ALERT_MSG_4: 'Spoke with ',
        ALERT_MSG_5: "There are no more",
        ALERT_MSG_6: "in the queue to place a call.",
        ALERT_MSG_7: 'You do not have any active connection.',
        ALERT_MSG_8: 'you have selected, none of them has mobile or phone number.',
        ALERT_MSG_9: 'You already have one open Power Dialer, please close to open a new Power Dialer. ',
        ALERT_MSG_10 :'To close tab, click on close icon given top of tab.'
    },
    LEAD_SCORE_SETUP: {
        ALREDY_DEFINED_SCORE: 'You have already defined a score for this action.',
        SAVE_SUCCESSFULLY: 'The score has been saved successfully.',
        CAN_NOT_UPDATE: 'Score settings can not be updated. Please modify the score settings correctly.',
        SELECT_ONE_ACTION: 'Please select at least one action',
        SELECT_ACTION: 'Please select an Action',
        SELECT_OBJECT: 'Please select an Object',
        SELECT_WORKFLOW: 'Please select a workflow',
        CONVERT_FROM_CANT_BLANK: 'Converted From can not be blank',
        CONVERT_TO_CANT_BLANK: 'Converted To can not be blank',
        CHOOSE_DIFF_STATUS: 'You cannot convert the same status. Please choose a different status.',
        SELECT_GROUP: 'Please select a group',
        SELECT_FIELD: 'Please select a field',
        PROVIDE_VALUE_TO_UPDATE: 'Please provide a value you want to update',
        ENTER_SCORE: 'Please enter a score between 1-99',
        EXIST_IN_LIST: 'Value already exists in List',
        SCORE_DELETED: 'This score will be deleted. Are you sure?',
        REMOVE_MSG: 'Remove successfully',
        UPDATE_MSG: 'Update successfully',
        CHAR_ERROR: 'Character is not allowed'
    },
    DUPLICATE_RULE: {
        ERROR_MSG_DEFAULT: 'You cannot turn off default rule.',
        ERROR_MSG_Active: 'You cannot deactive a default rule.',
        ERROR_MSG_FOR_FIELDS: 'Select all the field(s) from a particular block to save the rule.',
        ERROR_MSG_1: 'Something went wrong. Please try again later.',
        SUCCESS_MSG_1: 'Your duplicate rule(s) has been saved successfully.',
        ERROR_MSG_SAME_FIELD: ' is already selected. Please Select another field.',
    },
    REQUIRED_FORM: {
        FIELD_FILL_MESG:'These fields need to be filled in before this ',
        CAN_BE_SUBMITTED:' can be submitted.'
    },
    GREENLIGHT: {
        COMPANY_NAME_BLANK: 'Please enter a company name.',
        COMPANY_NAME_REQUIRED: 'Company Name cannot be more than 30 characters.',
        ADDRESS1_REQUIRED: 'Address 1 is required.',
        ADDRESS1_ADDRESS2_LIMIT: 'Address1 + Address2 combined cannot be more than 30 characters.',
        PHONE_NUMBER_REQUIRED: 'Please enter a valid phone number.',
        PHONE_NUMBER_LIMIT: 'Phone numbers cannot be more than 10 digits.',
        NET_ANNUAL_LIMIT: 'Net Anual cannot be blank and cannot be zero.',
        NET_ANNUAL_INVALID: 'Please enter a valid amount in Net Anual.'
    },
    AUDIT_QUESTIONS: {
        QUESTION_REQUIRED: 'Question is required',
        ENTER_VALID_SCORE: 'Please enter a valid Score.',
        ENTER_VALID_CHOICES: 'Please enter valid choices',
        SUCCESS: 'Success',
        ERROR: 'Error',
        NO_QUESTIONS: "No questions found.",
        THANK_YOU: "Thank You!",
        ALERT:'Alert',
        SOMETHING_WRONG: "Something went wrong while submitting your feedback, please try again!",
        FEATURE_NOT_AVAILABLE: 'This feature is not available.',
        RECEIVED_FEEDBACK_1: "Well done! You have successfully saved the answers for the ",
        RECEIVED_FEEDBACK_2:"category.",
        RECEIVED_FEEDBACK_3:"Remember, you can always update your answers for this category before final submission.",
        MAIL_SENT_MESSAGE:'Mail Sent.',
        SUBMIT_AUDIT_1:'There are unanswered required questions in the following categories: ',
        SUBMIT_AUDIT_2:'Please answer those required questions and then Submit the Audit.',
        SUBMIT_AUDIT_3:'You have successfully submitted your audit!',
        SUBMIT_AUDIT_4:'You can view your submitted audit answers and your scores in the CRM under HUB > Audits.',
        SUBMIT_AUDIT_5:'You will receive email notification, if Franchisor provides any feedback on your audit.',
        SUBMIT_AUDIT_6:'You can also see the feedback given by Franchisor and take corrective actions from HUB > Audits.',
        SAVE_ALERT_1:'Attention! You are about to leave this ',
        SAVE_ALERT_2:'category without saving the changes.',
        SAVE_ALERT_3:'Please Click OK to continue after saving, or close this alert to remain here and continue with this category.',
        REQUEST_CORRECTIVE_SUCCESS:'Thanks! Your feedback has been emailed successfully.',
        SUBMIT_CORRECTIVE_ACTION:'Thanks! Your corrective action information has been emailed successfully.',
        EMPTY_REQUEST_CORRECTIVE_MESSAGE:'You have not added your feedback for any of the questions.'+'\n'+'Please add the feedback first and then click on “Request Corrective Actions”',
        EMPTY_SUBMIT_CORRECTIVE_MESSAGE:'You have not added your corrective actions against any of the feedback.' + '\n' + 'Please add the corrective action first and then click on “Submit Corrective Actions”.',
        EMPTY_AUDIT_BY_NAME_AND_EMAIL:'Your Name and Your email address field is blank. Please enter values in those fields.',
        EMPTY_AUDIT_BY_NAME:'Your Name field is blank. Please enter values in those fields.',
        EMPTY_AUDIT_BY_EMAIL:'Your email address field is blank. Please enter values in those fields.',
        AUDIT_TYPE_SELECT_VALIDATION:'Please select one Audit type to generate the link.',
        INVALID_AUDIT_EMAIL:'Please Enter a valid email.',
        INVALID_AUDIT_NAME:'Please Enter a valid Name.',
        DUE_BY_EMPTY:'',
        PASSING_SCORE_EMPTY:'',
        EMPTY_NAME:"Name Field can't be Blank. Please Enter a valid Name . ",
        SUBMIT_AUDIT_FORM_VALIDATION:'Category already submitted.Click OK to close Audit Form',
    },
    AUDIT_ANSWERS:{
        NO_RECORD_FOUND:'No Answers with Corrective Actions found',
    },
    AUDIT_QUESTION_TYPES:{
        MAX_EXCEED:'Max Exceed',
    },
    AUDIT_CATEGORY:{
        CATEGORY_BALNK_MESSAGE:"Category can't be blank.",
        CATEGORY_CHANGES_UPDATED:'Category Changes Updated.',
        CATEGORY_ALREADY_EXIST:'This Category already exists. Duplicate Category creation is not allowed.',
        SAVED:'Category Saved',
        MAX_EXCEED:'You have reached the maximum category adding limit. We allow up to 50 categories.',
        NO_AUDIT_CATEGORY_QUESTION_FOUND:'There are no questions available to select from this category.',
    },
    AUDIT_CHECKLIST:{
        ALREADY_SELECTED_CTEAGORY_VALIDATION:'Selected Category already added.Please select another one.',
        TYPE:'Type',
        STATUS:'Status',
        SAVED_SUCCESSFULLY:'Saved Successfully',
        UPDATED_SUCCESSFULLY:'Updated Successfully.',
        INACTIVE_AUDIT_ALERT_MESSAGE:'Category Not found.',
        EMPTY_AUDIT_TYPE:"Audit type can't be blank",
        EMPTY_AUDIT_STATUS:"Audit status can't be blank",
    },
    COMPLIANCE: {
        SINGLE_RECORD_EDIT: 'Please select 1 record to edit.',
    },
    REVIEW_WEBSITE: {
        WEBSITE_NAME: 'Please enter website name',
        WEBSITE_URL: 'Please enter website url',
        SAVE:'Saved Successfully',
        VALID_URL:'Please enter a valid url'
        
    },
    WEBFORM :{
        GROUP_BLANK :'Please select a Group',
        NAME_BLANK :'Name can not be blank',
        URL_VALIDATION:'Please provide a valid website url!',
        ASSIGNTO_BLANK :'Please select at-least one Sales Rep from the Assign to field.',
        WORKFLOW_BLANK:'Please select a workflow.',
        SOURCE_BLANK:'Please select a source',
        LINK_COPIED_SUCCESS :'code Copied.',
        SAVE_SUCCESS:'Web Forms has been saved successfully!',
        
    },
    ROYALTY_PLANS: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_ROYALTY_PLAN_RECORD: 'You have an open royalty plan tab. Please close that tab before adding a new royalty plan.',
        FIELDS_CANNOT_BLANK: ' field cannot be blank.',
        ROYALTY_PLAN_SAVE_SUCCESS: 'Royalty plan has been saved successfully.',
        ROYALTY_PLAN_DUPLICATE_PLAN_NAME: 'You already have royalty plan with same plan name. Please enter a different plan name.',
        FIXED_PERCENTAGE_VALIDATION:'field should have value between 1-100%.'
    },
    ROYALTY_RUNS: {
        REPORT_SALES_SAVED_SUCESS: 'Reported Sales record(s) has been saved successfully.',
        ROYALTIES_PROCESSED: 'Reported Sales record(s) has been calculated successfully.',
        ROYALTIES_NOT_PROCESSED: 'Unable to process royalty! One or more Reported Sales records are not ready for processing. Verify Reported Sales for all franchisees and close them. Then process the Royalty.',
        ROYALTIES_SUBMITTED: 'You have successfully submitted Royalty for this period.',
        ROYALTIES_NOT_SUBMITTED: 'No records to submit!',
        INVOICE_ALL_CREATED: 'Invoice records have been created for all units.',
        INVOICE_NOT_CREATED: 'There is no Royalty record(s) available for this Royalty Run record. You can perform Invoice All action only when Royalty record(s) are available.',
        EMAIL_ALL_SUCCESS:'Email has been sent to all the units where Invoice is available.',
        POST_ALL_SUCCESS: 'Royalty information for this period has been posted successfully to all the units.',
        NO_UNITS_ENROLLED: 'Unable to create Reported Sales! There is no unit who has enrolled to this plan. First enroll units to this plan and then process Reported Sales.',
        TO_QUICKBOOKS_MSG:'All the invoices of this royalty cycle will be pushed to your connected Quickbooks account. Click Ok to proceed',
        QUICKBOOKS_SUCCESS:'Royalty information for this period has been posted successfully to quickbooks.'
    },
    REPORTED_SALES: {
        REPORTED_SALES_UPDATED: 'Report Sales updated successfully.',
        REPORTED_SALES_CLOSED: 'Reported Sales record has been closed successfully.',
        //REPORTED_SALES_SOURCE_NOT_ADDED: 'Source field is mandatory in order to update the Reported Sales record. Please edit the record and provide correct information in the Source field and then perform Update operation.',
        REPORTED_SALES_SOURCE_NOT_ADDED: 'Unable to update Reported Sales! Source needs to be specified in the FE Login > Settings > Franchise > Reported Sales. Then perform the Update.',
        REPORTED_SALES_CREATED :'Reported sales records have been added successfully for this period for all the enrolled units.'
    },
    ROYALTIES: {
        INVOICE_ALREADY_EXIST: 'Invoice already exits for this royalty record.',
        INVOICE_CREATED: 'Invoice record has been created for the selected unit.',
        EMAIL_SUCCESS:'Email is sent to the unit.',
        POST_SUCCESS: 'Invoice posted to the unit as attachments under reported sales.',
        NO_INVOICE_EXIST: 'There is no Invoice record available for this Royalty record. Create an invoice and then try again.'
    },
    ROYALTY_REPORT_V2: {
        NO_LAST_RUN_RECORD_FOUND: 'There is no previous royalty run record available, please select a different option for period.',
        ALL_OPTIONS_REQUIRED: 'Please select all the available filters and try again',
        NO_ROYALTY_FIELD_SETUP_DONE: 'Please setup the fields first for the Royalty report from Settings > Franchise > Royalty Report Setup and then run the Royalty report.',
        NO_FE_ROYALTY_FIELD_SETUP_DONE: 'Your royalty report is not set up yet. Please contact your corporate administrator to set it up.',
        NO_RECORDS: 'No records found!',
    },
    SALES_AND_REFUND: {
        ALL_OPTIONS_REQUIRED: 'Please select all the available filters and try again',
    },
    INTAKE_FORM : {
        RESTRICT_TO_OPEN_TWO_OR_MORE_INTAKE_FORM:'You have an open Intake form tab. Please close that tab before opening or adding a new Intake form.',
        INTAKE_FORM_SUCCESSFULLY_SAVE: 'Saved Successfully.',
        THANK_YOU: "Thank You!",
    },
    EXCEPTION: {
        EXCEPTION_SAVE_SUCESS: 'Saved Successfully.',
    },
    PHONE_NUMBERS: {
        USE_FOR_NOTIFICATION_SAVE: 'From Number Updated for Notifications.',
        USE_NOTIFICATION: 'This number will be used for notification. Please click OK to confirm.',
        REMOVE_NOTIFICATION: 'This number will be removed for notification. Please click OK to confirm.',
    },
    PHONE_AND_TEXT_INTEGRATION: {
        SAVE_SUCCESS: 'Your setting saved successfully.',
        SAVE_FAIL: 'Your setting is not saved.',
        NO_PROVIDER: 'No provider has been selected for Phone and Text feature yet.\n Please select a provider first in order to use Phone and Text features.',
        NO_TWILIO_NUMBERS_FOUND: 'There is no Phone number available in your connected Twilio Account.\n Please add the number first in your Twilio Account then perform import operation.',
        NONE_SELECT_CONFIRM: 'This will disable Phone and Text option in CRM from all the places.\n If you have any Workflow or Automation Campaign where Text is already configured, please change those accordingly as it will not work anymore.',
        WRONG_AUTHENTICATION_SID_TOKEN: 'We could not authenticate your Twilio account with the Account SID and Token you have provided during Phone and Text Integration.\n Please double check them once.'
    },
    TWILIO_SMS_VERIFICATION:{
        FORM_SUCCESSFULLY_SAVE: 'Saved Successfully.',
        FORM_SUCCESSFULLY_SAVE_SUMBIT:'Saved and Sumbitted Successfully',
        MSG_FOR_BUSINESS_REGISTRATION_NUMBER:'Business registration number should be 9 characters.',
        MSG_FOR_DESCRIPTION: 'Description sholud be between 40 and 4096 characters in length.',
        MSG_FOR_OPT_IN_MESSAGE:'OPT in Message sholud be between 40 and 2049 characters in length.',
        MSG_FOR_SAMPLE_MESSAGES_1:'Sample message 1 must be between 20 and 1024 characters in length.',
        MSG_FOR_SAMPLE_MESSAGES_2:'Sample message 2 must be between 20 and 1024 characters in length.'
   },
   DASHBOARD:{
        DELETE_SUCCESS:'Widget removed successfully.',
        PROVIDE_WIDGETNAME:'Please provide widget name.',
        SELECT_REPORT:'Please select a report from list.',
        WIDGET_ADDED_SUCCESS:'Widget Added Successfully.',
        WIDGET_NAME_DUPLICATE: 'Widget name already exists.',
        WIDGET_SAVE_SUCCESS: 'Widget Saved successfully.',
        SELECT_AVAILABLEWIDGET:'Please select a widget from the widget list.',
        NO_DATA_IN_AVAILABLEWIDGET:'There is no data available in widgetlist.',
        SELECT_WIDTH:'Please select width.',
        TIMELINE_DETAILS_NOT_AVAILABLE: 'Details are not available for the selected timeline.',
        DASHBOARD_WIDGET_REMOVE_LIMIT: 'You can not remove the widget. A Dashboard must have at list one widget.'
   },
   MENU_DESIGN:{
    UNABLE_TO_DELETE_MENU:(deletedMenuName,names)=>`Unable to delete Menu <b>${deletedMenuName}</b>. <br/> This menu is used by  <b>${names}</b> security level(s). <br/> Remove this Menu from all the security level(s) and try again.`,
    MENU_NAME_LESS_THAN_20_CHARACTERS:'Menu Name should be less than 20 characters',
    MENU_NAME_CANNOT_BE_BLANK:'Menu Name cannot be blank',
    SPECIAL_CHARACTERS_OR_NUMBERS_NOT_ALLOWED:'Special characters or numbers are not allowed',
    MENU_NAME_ALREADY_EXISTS:'Menu Name already exists',
    MENU_DELETED:'Menu Deleted',
    MENU_SAVED_SUCCESSFULLY:'Menu saved successfully.',
   },
   WEBHOOK_RULES: {
    NO_WEBHOOK_RULES: 'No rules available.',
    NO_NAME: 'Please provide a webhook name.',
    NAME_MAX_LENGTH: 'Webhook name cannot be more than 64 characters.',
    NO_CONDITION: 'Please provide at least one condition.',
    RULE_SAVE_SUCCESS: 'Webhook saved successfully.',
    RULE_NAME_EXISTS: 'Name already exists!',
    ACTIVE_RULE_EXCEEDS: 'You already have 60 active assignment rules.',
    NO_ENDPOINT: 'Please provide an endpoint url.',
    NO_DATA: 'Please provide at least one data.',
    INVALID_EMAIL:'Please provide a valid email in Failure Notification Recipients.'
 }

}
export default message
