import React, { useState, useEffect, useCallback, useRef } from "react";
import $ from 'jquery';
import { Button, FormControl, Menu, MenuItem, IconButton, TextField, Typography, Icon } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { getCookie, setCookie } from '../../../services/helper/sfCookies';
import FroalaEditor from 'react-froala-wysiwyg';
import Froalaeditor from 'froala-editor'
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { verifyEailStatus, sendMail, getUserList, getTemplateList, getTemplate, getVerifiedEmais, getEmailSignature, getEmails, scheduleMail, storeTemplateListInRedux, updateSendMailDataInRedux, resetSendMailDataInRedux, updateSendMailTemplateDataInRedux } from '../../../services/actions/sendMailActions';
import { isValidParam, getStringParam, isEmail, getIntParam, getArrayParam, getObjectParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { sortArrayObjectByProperty, StripHtmlToText, dateFormat, timeFormat, sortArrayObjectByDatePropertyDescending } from '../../../services/helper/utils';
import * as sfDialogs from '../components/sfDialogs';
import { getAppDialog, getAppDrawer, getAppCustomDrawer, minimizeAppCustomDrawer } from '../../../services/actions/appContainerActions';
import {
    refreshDetailViewData, refreshDetailViewListViewData, refreshPurchaseOrder, refreshQuotation, detailViewIsMounted,
    isDetailViewSet,
} from '../../../services/actions/detailViewActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { hasAccessPermission, getCoupons, getDefaultBeeEditorData } from '../../../services/helper/common';
import { sortArrayObjectByPropertyOrderType } from '../../../services/helper/utils'
import { getTasksRefresh } from '../../../services/actions/taskActions';
import { getAttachmentAgreementData } from '../../../services/actions/listViewAcions';
import Cookies from "universal-cookie";
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import ShowCircularProgress from '../components/circularProgress';
import _ from 'lodash';
import PopOver from './PopOver';
import { getBeeEditorFontList } from "../../../services/helper/utils";
import Autocomplete from '@mui/material/Autocomplete'
import { getCouponsAction } from '../../../services/actions/couponActions';
import SFLoader from '../components/sfLoader';
import Bee from "@mailupinc/bee-plugin";
import { useDispatch, useSelector } from "react-redux";
import Switch from "@mui/material/Switch";
import { FormControlLabel } from "@mui/material";
import { styled } from '@mui/material/styles';
import store from "../../../services/store";
import TagAutocomplete from '../../sfPlugins/tagAutocomplete';
import Box from "@mui/material/Box";
import copy from 'copy-to-clipboard'

require("froala-editor/js/froala_editor.pkgd.min.js");
require("froala-editor/css/froala_editor.pkgd.min.css");
require("froala-editor/js/plugins/font_family.min.js");

const moment = require('moment');
const taskActionsIdValue = [{ id: 'nextday', value: getLocalizedStrings().label.APPOINTMENTS_TASKS.NEXT_DAY },
{ id: 'nextweek', value: getLocalizedStrings().label.APPOINTMENTS_TASKS.NEXT_WEEK }];
const ToggleSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#424242",
        '&:hover': {
            backgroundColor: '#2c2c2c1f',
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#424242",
    },
}));
const SendMail = ({ data, callFrom }) => {
    const dispatch = useDispatch();
    const cookies = new Cookies();
    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const sendEMail = useSelector((state) => state.sendMail);
    const listView = useSelector((state) => state.listView);
    const customDrawer = useSelector((state) => state.appContainer.customDrawerProps);
    const couponsFromRedux = useSelector((state) => state.coupons);
    const detailViewFromRedux = useSelector((state) => state.detailView);
    const reactTagsCc = useRef(null)
    const reactTagsBcc = useRef(null)
    const [fromEmail, setFromEmail] = useState(app.me.email)
    const [toEmail, setToEmail] = useState('')
    const [selectedTemplate, setSelectedTemplate] = useState(0)
    const [emailSignature, setEmailSignature] = useState('')
    const [emailSignatureTag, setEmailSignatureTag] = useState('')
    const [agreementType, setAgreementType] = useState('')
    const [signerCount, setSignerCount] = useState(0);
    const [selectedSigner, setSelectedSigner] = useState({});
    const [signerDetails, setSignerDetails] = useState([]);
    const [signerTags, setSignerTags] = useState([]);
    const [template, setTemplate] = useState({
        id: 0,
        name: '',
        subject: '',
        email_message: '',
    })
    const [isTemplateUpdated, setIsTemplateUpdated] = useState(false)
    const [actionMode, setActionMode] = useState(null)
    const [ccTab, setCCTab] = useState(false)
    const [bccTab, setBCCTab] = useState(false)
    const [attachFiles, setAttachFiles] = useState([])
    const [isOpenAttachmentPopover, setIsOpenAttachmentPopover] = useState(false)
    const [isOpenTaskAction, setIsOpenTaskAction] = useState(false)
    const [taskActionValue, setTaskActionValue] = useState('')
    const [isDisabledToEmail, setIsDisabledToEmail] = useState(true)
    const [ccBccFocus, setCCBCCFocus] = useState(null)
    const [tags, setTags] = useState({
        cc: "",
        bcc: "",
        to: ""
    })
    const [suggestions, setSuggestions] = useState([])
    const [toSuggestion, settoSuggestion] = useState([])
    const [ccSuggestions, setccSuggestions] = useState([])
    const [optedInRecipients, setOptedInRecipients] = useState([])
    const [coupons, setCoupons] = useState({})
    const [editorLoader, setEditorLoader] = useState(true)
    const [sending, setSending] = useState(false)
    const [scheduleDate, setScheduleDate] = useState(null)
    const [moreActionOpen, setMoreActionOpen] = useState(false)
    const [anchorMoreActionEl, setAnchorMoreActionEl] = useState(null)
    const [actionType, setActionType] = useState('')
    const [isAgreementSelected, setIsAgreementSelected] = useState(false)
    const [btnIndex, setBtnIndex] = useState(0)
    const [agreementTemplateMsgbody, setAgreementTemplateMsgbody] = useState('')
    const [attachLoader, setAttachLoader] = useState(false)
    const [isFromAttachment, setIsFromAttachment] = useState(false)
    const [emailIdList, setEmailIdList] = useState([])
    const [isBackHideen, setIsBackHideen] = useState(false)
    const [loadAgreementEditor, setLoadAgreementEditor] = useState(true)
    const [anchorEl, setAnchorEl] = useState(null)
    const [editorType, setEditorType] = useState(null)
    const [emailContent, setEmailContent] = useState('')
    const [catagoryList, setCatagoryList] = useState([
        { name: 'sales_categories', key: 'Sales Template', value: 'Sales Template', id: 'Sales Template', label: "Sales Templates" },
        { name: 'marketing_categories', key: 'Marketing Template', value: 'Marketing Template', id: 'Marketing Template', label: "Marketing Templates" },
        { key: 'Agreement Template', value: 'Agreement Template', id: 'Agreement Template', label: "Agreement Templates" }
    ]);
    const [selectedCatagory, setSelectedCatagory] = useState('Sales Template')
    const [selectedSubCatagory, setSelectedSubCatagory] = useState('')
    const [selectedDataFetchType, setSelectedDataFetchType] = useState('Recent')
    const dataFetchTypeList = [
        { key: 'Recent', value: 'Recent', id: 'Recent', label: "Recent" },
        { key: 'All', value: 'All', id: 'All', label: "All" },
    ]
    const [templateOptions, setTemplateOptions] = useState([])
    const [isAutoCompleteDisabled, setIsAutoCompleteDisabled] = useState(false)
    const [isSendDisabled, setIsSendDisabled] = useState(false)
    const [changeLog, setChangeLog] = useState({ mergeFields: [], categories: [], fddInfo: {}, fddAttachedPdfInfo: {}, fdd_template_id: 0, fddPDFName: '' })
    const [taskAnchor, setTaskAnchor] = useState(null)
    const [token, setToken] = useState(null);
    const [bee, setBee] = useState({})
    const [starredMail, setStarredMail] = useState(true);
    const [editorInstance, setEditorInstance] = useState('');
    const beeEditor = new Bee(token);
    const [htmlBody, setHtmlBody] = useState(null);
    const [isMailBoxConfigured, setIsMailBoxConfigured] = useState(false);
    const [trackingFunctionCallCount, setTrackingFunctionCallCount] = useState(0);

    let froalaRef;
    useEffect(() => {
        let minimizeList = customDrawer.minimizeList;
        if (minimizeList.hasOwnProperty('email') && minimizeList.email) {
            if (sendEMail.maildata.editorType && sendEMail.maildata.editorType === constants.EDITOR_BEE) {
                let type = 'Expand';
                registerBeeEditor(type, undefined, sendEMail.maildata, sendEMail.maildata.editorType);
            }
            if (data.attachment_type === "Agreement") {
                setLoadAgreementEditor(false);
            }
            setEditorLoader(false);
            setTemplate({ ...sendEMail.maildata.template });
            setToEmail(sendEMail.maildata.toEmail);
            setStarredMail(sendEMail.maildata.starredMail);
            setCatagoryList([...sendEMail.maildata.catagoryList]);
            setSelectedCatagory(sendEMail.maildata.selectedCatagory);
            setSelectedSubCatagory(sendEMail.maildata.selectedSubCatagory);
            setSelectedDataFetchType(sendEMail.maildata.selectedDataFetchType);
            setTemplateOptions(sendEMail.maildata.templateOptions);
            setSelectedTemplate(sendEMail.maildata.selectedTemplate);
            setEditorType(sendEMail.maildata.editorType);
            setTags(sendEMail.maildata.tags);
            setSignerCount(sendEMail.maildata.signerCount);
            setSignerDetails(sendEMail.maildata.signerDetails);
            setIsDisabledToEmail(sendEMail.maildata.isDisabledToEmail);
            setSignerTags(sendEMail.maildata.signerTags);
            setIsFromAttachment(sendEMail.maildata.isFromAttachment);
            setAttachFiles(sendEMail.maildata.attachFiles);
            setIsAgreementSelected(sendEMail.maildata.isAgreementSelected);
            if (sendEMail.maildata.isAgreementSelected) {
                setAgreementTemplateMsgbody(sendEMail.maildata.agreementTemplateMsgbody);
                setSelectedSigner(sendEMail.maildata.selectedSigner);
                setAgreementType(sendEMail.maildata.agreementType);
            }
            setBtnIndex(sendEMail.maildata.btnIndex);
            if (sendEMail.maildata.ccEmail !== '') {
                setCCTab(true);
            }
            if (sendEMail.maildata.bccEmail !== '') {
                setBCCTab(true);
            }
            setFromEmail(sendEMail.maildata.fromEmail);
            setIsMailBoxConfigured(sendEMail.maildata.isMailBoxConfigured);
            if (sendEMail.maildata.hasOwnProperty('optedInRecipients')) {
                setOptedInRecipients(sendEMail.maildata.optedInRecipients)
            }
        } else {
            getSendMailDetail();
        }
    }, []);

    useEffect(() => {
        if (data.object === constants.AUDIT_ANSWERS_OBJECT && data.type === 'corrective action') {
            setIsAutoCompleteDisabled(true);
        }
    }, [])

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const handleDocumentClick = (event) => {
        if (popUpClose(event.target)) {
            handlePopUpClose()

        }
    };

    const popUpClose = (element) => {
        let _popUp = false;
        const popUpElemrnt = document.querySelector('.fr-popup');
        const dialogElement = document.querySelector('.MuiDialog-paper');
        const insertElement = document.querySelector('.btn-insert');
        if (!insertElement && !dialogElement && popUpElemrnt && !popUpElemrnt.contains(element)) {
            _popUp = true;
        } else {
            _popUp = false;
        }
        if (dialogElement && !dialogElement.contains(element)) {
            if (popUpElemrnt && !popUpElemrnt.contains(element)) {
                if (insertElement && !insertElement.contains(element)) {
                    _popUp = false;
                }
            }
        }
        return _popUp;
    }

    const handlePopUpClose = () => {
        let _store = store.getState()
        const divElement = document.querySelector('.fr-popup');
        if (divElement && !_store.appContainer.dialogProps.isOpenDialog && !_store.topUpDialog.isOpenDialog) {
            divElement.classList.remove('fr-block');
            if (froalaRef && froalaRef?.current) {
                froalaRef.current.events.enableBlur();
            }
        }
    };

    const disableEditorBlurEffect = () => {
        const divElement = document.querySelector('.fr-popup');
        if (divElement) {
            divElement.classList.add('fr-block');
        }
    }

    const getSendMailDetail = () => {
        let _data = getObjectParam(data);
        let object = getStringParam(_data.object);
        let fromEmailCookie = app.me.id + '_' + app.me.projectId + '_from_email';
        let _maildata = {}
        let fromEmail = null;
        if ((object == constants.CASES_OBJECT || _data.parentObject == constants.CASES_OBJECT) && app.me?.aurinko_cases_mailbox &&
            app.me?.aurinko_cases_mailbox != null && app.me.aurinko_cases_mailbox != undefined) {
            fromEmail = app.me.aurinko_cases_mailbox;
            if(object == constants.CASES_OBJECT || object == ""){
                setIsMailBoxConfigured(true);
            }
            _maildata = { ..._maildata, isMailBoxConfigured: true };
        } else {
            fromEmail = cookies.get(fromEmailCookie) || app.me.email;
        }
        loadCoupons();
        if (sendEMail?.maildata?.merge_fields) {
            let temp = changeLog;
            temp.mergeFields = sendEMail.maildata.merge_fields;
            setChangeLog({ ...temp });
        } else {
            loadMergeFields();
        }
        try {
            if ((!sendEMail?.verifiedEmails || sendEMail.verifiedEmails.length === 0) && !sendEMail.isVerifyEmailCalled) {
                dispatch(getVerifiedEmais(false));
            }
            if (object === constants.UNITS_OBJECT) {
                object = constants.ACCOUNTS_OBJECT;
            }
            let type = getStringParam(_data.type);
            let isBatchMail = getBooleanParam(_data.batch_mail);
            let fieldName = getStringParam(_data.fieldName);
            let attachmentType = getStringParam(_data?.attachment_type);
            if (type === constants.TYPE_EMAIL.toString() && !isBatchMail && fieldName === 'email') {
                _maildata = { ..._maildata, isShowAgreementTemplate: true }
            }
            let _ctCookieName = "SelectedCatagory_" + app.me.id + "_" + app.me.projectId;
            let _subCtcookieName = "SelectedSubCatagory_" + app.me.id + "_" + app.me.projectId;
            let _selectedCatagory = getCookie(_ctCookieName);
            let _selectedSubCatagory = getCookie(_subCtcookieName);
            if (attachmentType != null && attachmentType == 'File') {
                _selectedCatagory = 'Sales Template';
            }
            if (catagoryList) {
                if (isBatchMail && attachmentType == 'Agreement' && _selectedCatagory == 'Agreement Template') {
                    _selectedCatagory = catagoryList[0].value;
                }
                if (_selectedCatagory) {
                    setSelectedCatagory(_selectedCatagory);
                    setSelectedSubCatagory(_selectedSubCatagory);
                    if (_selectedCatagory === "Agreement Template") {
                        const _tag = { ...tags, cc: "", bcc: "" }
                        setTags({ ..._tag })
                        setIsAgreementSelected(true);
                        _maildata = { ..._maildata, ccEmail: _tag.cc, tags: _tag, bccEmail: _tag.bcc, isAgreementSelected: true, editorType: 'froala', selectedCatagory: 'Agreement Template', selectedSubCatagory: '' }
                    } else {
                        setIsAgreementSelected(false);
                        if (attachmentType != null && attachmentType == 'File') {
                            _maildata = { ..._maildata, isAgreementSelected: false, selectedCatagory: _selectedCatagory, selectedSubCatagory: _selectedSubCatagory, selectedDataFetchType: 'Recent' }
                        } else {
                            _maildata = { ..._maildata, isAgreementSelected: false, selectedCatagory: _selectedCatagory, selectedSubCatagory: _selectedSubCatagory }
                        }
                    }
                } else {
                    setSelectedCatagory(selectedCatagory)
                    setSelectedSubCatagory(selectedSubCatagory)
                    _maildata = { ..._maildata, selectedCatagory: selectedCatagory, selectedSubCatagory: selectedSubCatagory }
                }
            }
            if (type === constants.EMAIL_OBJECT) {
                setIsDisabledToEmail(false)
                setFromEmail(fromEmail)
                let _tags = { ...tags }
                _maildata = { ..._maildata, isDisabledToEmail: false, fromEmail: fromEmail }
                let _actionType = getStringParam(_data.actionType);
                let record = getObjectParam(_data.record);
                if (_actionType === 'REPLY') {
                    let _template = { ...template, subject: 'Re: ' + remakeSubject(record.t_subject) }
                    setToEmail(getStringParam(record.t_email))
                    let temp = template;
                    temp.subject = 'Re: ' + remakeSubject(record.t_subject)
                    setTemplate({ ...temp });
                    _maildata = { ..._maildata, template: _template, fromEmail: fromEmail }
                } else if (_actionType === 'REPLY-ALL') {
                    let _template = { ...template, subject: 'Re: ' + remakeSubject(record.t_subject) }
                    setToEmail(getStringParam(record.t_email))
                    let temp = template;
                    temp.subject = 'Re: ' + remakeSubject(record.t_subject)
                    setTemplate({ ...temp });
                    _maildata = { ..._maildata, template: _template, toEmail: getStringParam(record.t_email) }
                    let ccEmail = getStringParam(record.t_cc);
                    if (ccEmail !== '') {
                        setCCTab(true)
                        _tags = { ..._tags, cc: ccEmail }
                        _maildata = { ..._maildata, tags: _tags, ccEmail: ccEmail, ccTab: true }
                    }
                    let bccEmail = getStringParam(record.t_bcc);
                    if (bccEmail !== '') {
                        setBCCTab(true)
                        _tags = { ..._tags, bcc: bccEmail }
                        _maildata = { ..._maildata, tags: _tags, bccEmail: bccEmail, bccTab: true }
                    }
                    setTags(_tags)
                } else if (_actionType === 'FORWARD') {
                    let _template = { ...template, subject: 'Fwd: ' + remakeSubject(record.t_subject) }
                    let temp = template;
                    temp.subject = 'Fwd: ' + remakeSubject(record.t_subject)
                    setTemplate({ ...temp });
                    _maildata = { ..._maildata, tamplate: _template }
                }
                let d = new Date(record.created_on);
                const momentDate = moment(d);
                let date = momentDate.format(dateFormat[app.me.date_format]);
                let time = momentDate.format(timeFormat[app.me.date_format]);
                let dateString = "On " + date + " at " + time + ", " + app.me.email + " wrote:";
                dateString = getStringParam(dateString);
                let msg = getStringParam(record.t_msg);
                if (!/<[a-z][\s\S]*>/i.test(msg)) {
                    if (msg.includes('\n')) {
                        msg = msg.split("\n").join("<br />");
                    }
                }
                msg = "<div></br></div>" + dateString + "<div></br></br></div>" + msg;
                let email_message = getStringParam(msg);
                let _templte = { ...template, email_message: email_message }
                setTemplate({ ..._templte })
                setActionType(_actionType)
                setEditorLoader(false)
                setEditorType(record.editor_type)
                _maildata = { ..._maildata, tamplate: _templte, actionType: _actionType, editorLoader: false, editorType: record.editor_type }
                if ((_actionType === 'REPLY' || _actionType === 'REPLY-ALL' || _actionType === 'FORWARD') && record.editor_type === constants.EDITOR_BEE) {
                    setEmailContent(record.mail_content)
                    _maildata = { ..._maildata, emailContent: record.mail_content }
                    let type = 'New';
                    registerBeeEditor(type, undefined, _maildata, record.editor_type);
                }
            } else if (_data.hasOwnProperty('batch_mail') && getBooleanParam(_data.batch_mail)) {
                let tempIds = [];
                let emailIdList = getArrayParam(_data.email_id_list);
                _data.selectedEmailLength = emailIdList.length;
                emailIdList.forEach(obj => {
                    tempIds.push(obj.id);
                });
                if (tempIds.length > 0) {
                    _data.email_id_list = getArrayParam(tempIds);
                    verifyEmails(_data);
                }
            } else if (_data.hasOwnProperty('batch_mail') && !getBooleanParam(_data.batch_mail)) {
                let emailIdList = getArrayParam(_data.email_id_list);
                let email = getStringParam(emailIdList[0].email);
                let recordId = parseInt(getStringParam(emailIdList[0].id));

                if (email === '' && _data.hasOwnProperty('isAttachment') && _data.isAttachment === false) {
                    let tempIds = [];
                    emailIdList.forEach(obj => {
                        tempIds.push(obj.id);
                    });
                    if (tempIds.length > 0) {
                        let params = { ids: tempIds };
                        let promise = Promise.resolve(getEmails(object, params));
                        if (isValidParam(promise)) {
                            promise.then((response) => {
                                emailIdList = getArrayParam(response);
                                let email = emailIdList.length > 0 ? getStringParam(emailIdList[0].email) : '';
                                if (email !== '') {
                                    setToEmail(email)
                                    setFromEmail(fromEmail)
                                    _maildata = { ..._maildata, toEmail: email, fromEmail: fromEmail }
                                } else {
                                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.MAIL.NO_RECIPIENT_FOUND, null);
                                    dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
                                }
                            });
                        }
                    }
                } else {
                    setToEmail(email)
                    setFromEmail(fromEmail)
                    _maildata = { ..._maildata, toEmail: email, fromEmail: fromEmail }
                    if (_data.hasOwnProperty('attachmentId') && getIntParam(_data.attachmentId) > 0 && _data.hasOwnProperty('isAttachment') && _data.isAttachment === true) {
                        setIsDisabledToEmail(false)
                        setAttachLoader(true)
                        setIsFromAttachment(true)
                        _maildata = { ..._maildata, isDisabledToEmail: false, attachLoader: true, isFromAttachment: true }
                        dispatch(updateSendMailDataInRedux({ ..._maildata }));
                        if (email !== '') {
                            let _tags = { ...tags, to: email }
                            setTags(_tags)
                            _maildata = { ..._maildata, tags: _tags }
                        }
                        if (_data.attachment_type === 'Agreement') {
                            let promise = getAttachmentAgreementData({ attchment_id: data.attachmentId });
                            promise.then((response) => {
                                if (isValidParam(response)) {
                                    let agreementMailSubject = getStringParam(response.document_name);
                                    let email_message = getStringParam(response.template_content)
                                    const _tag = { ...tags, cc: "", bcc: "" }
                                    let _template = { ...template, name: '', subject: agreementMailSubject, email_message: email_message, id: response.template_id, agreement_type: response.agreement_type };
                                    setSelectedTemplate(response.template_id);
                                    setTemplate({ ..._template })
                                    setEditorType('froala');
                                    setSelectedCatagory('Agreement Template');
                                    setSelectedDataFetchType('All');
                                    setIsAutoCompleteDisabled(true);
                                    setTags({ ..._tag })
                                    setIsAgreementSelected(true);
                                    setIsDisabledToEmail(true);
                                    setIsBackHideen(true);
                                    setAgreementType(response.agreement_type);
                                    let attachFile = {};
                                    let files = [...attachFiles];
                                    attachFile.name = response?.fdd_info?.fdd_doc_name;
                                    attachFile.src = response?.fdd_info?.fdd_file_url;
                                    if (attachFile.name && attachFile.src) {
                                        files = [...files, attachFile];
                                        setAttachFiles([...files]);
                                    }
                                    _maildata = {
                                        ..._maildata, ccEmail: _tag.cc, tags: _tag, bccEmail: _tag.bcc, template: _template, isTypeAgreement: true, isAutoCompleteDisabled: true, isBackHideen: true, isDisabledToEmail: true, isAgreementSelected: true, isTemplateListDisabled: false, agreementMailSubject: agreementMailSubject, editorType: 'froala',
                                        selectedCatagory: 'Agreement Template', selectedSubCatagory: null, selectedDataFetchType: 'All', selectedTemplate: response.template_id, agreementType: response.agreement_type,
                                        attachFiles: files
                                    }
                                    geTemplateListByCategory('Agreement Template', null, 'All', _maildata);
                                    dispatch(updateSendMailDataInRedux({ ..._maildata }));
                                    loadAgreementAttachment(_maildata, response);
                                }
                            })
                        } else {
                            bucketChange();
                        }
                    }
                }
            } else {
                if ((_data.callFrom === constants.QUOTATION_OBJECT || _data.callFrom === constants.PURCHASE_ORDER_OBJECT
                    || _data.callFrom === constants.SALES_ORDER_OBJECT) && _data.hasOwnProperty('attachFiles')) {
                    setAttachFiles([..._data.attachFiles]);
                    _maildata = { ..._maildata, attachFiles: _data.attachFiles }
                }
                let id = getIntParam(_data.id);
                let email = getStringParam(_data.email);
                _maildata = { ..._maildata, batchMail: false }
                if (isEmail(email)) {
                    setToEmail(email)
                    _maildata = { ..._maildata, toEmail: email }
                } else if (getIntParam(id) > 0) {
                    let salesRep = email;
                    getSalesRepMail(salesRep);
                }
                else {
                    setToEmail(app.me.email)
                    _maildata = { ..._maildata, toEmail: app.me.email }
                }
                setFromEmail(fromEmail)
                _maildata = { ..._maildata, fromEmail: fromEmail }
                if (data.hasOwnProperty("stageName") && constants.GREENLIGHT_STAGE_DENIAL_INQUIRY === getStringParam(data.stageName)) {
                    populateEmailContent();
                }
            }
            if (_data.attachment_type !== 'Agreement') {
                geTemplateListByCategory(_maildata.selectedCatagory, _maildata.selectedSubCatagory, _maildata.selectedDataFetchType ?? 'Recent', _maildata);
            }
            dispatch(updateSendMailDataInRedux({ ..._maildata }));
        } catch (error) {
            console.error("Error in 'sendMail.js -> componentDidMount()':" + error);
        }
    }

    const handleMoreActionOpen = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setMoreActionOpen(true);
        setAnchorMoreActionEl(event.currentTarget)
    }

    const handleMoreActionClose = () => {
        setMoreActionOpen(false);
        setAnchorMoreActionEl(null)
    }

    useEffect(() => {
        if (ccBccFocus == 'ccTab') {
            reactTagsCc.current?.input?.focus()
        }
        if (ccBccFocus == 'bccTab') {
            reactTagsBcc.current?.input?.focus()
        }
    }, [ccBccFocus])

    const addTO = (allTag, newTag) => {
        let _tag = { ...tags, to: allTag }
        setToEmail(allTag)
        setTags(_tag)
        let _tagArr = allTag?.indexOf(',') > -1 ? allTag.split(",") : [allTag]
        setEmailIdList(_tagArr)
        let _maildata = { ...sendEMail.maildata, toEmail: toEmail, tags: _tag, email_id_list: _tagArr }
        dispatch(updateSendMailDataInRedux({ ..._maildata }));
        setSuggestions([])
    }

    const removeTO = (allTag, removedTag) => {
        let tag = { ...tags, to: allTag }
        setToEmail(allTag)
        setTags(tag)
        let _tagArr = allTag?.indexOf(',') > -1 ? allTag.split(",") : [allTag]
        setEmailIdList(_tagArr)
        let _maildata = { toEmail: toEmail, tags: tag, email_id_list: _tagArr }
        dispatch(updateSendMailDataInRedux({ ..._maildata }));
    }

    const addCC = (allTag, newTag) => {
        let _tag = { ...tags, cc: allTag }
        setTags(_tag)
        let _maildata = { ccEmail: _tag.cc, bccEmail: _tag.bcc, tags: _tag }
        dispatch(updateSendMailDataInRedux({ ..._maildata }));
        setSuggestions([])
    }

    const removeCC = (allTag, removedTag) => {
        let tag = { ...tags, cc: allTag }
        setTags(tag)
        let _maildata = { ccEmail: tag.cc, tags: tag, bccEmail: tag.bcc }
        dispatch(updateSendMailDataInRedux({ ..._maildata }));
    }

    const addBCC = (allTag, newTag) => {
        let _tag = { ...tags, bcc: allTag }
        setTags(_tag)
        let _maildata = { bccEmail: _tag.bcc, ccEmail: _tag.cc, tags: _tag }
        dispatch(updateSendMailDataInRedux({ ..._maildata }));
        setSuggestions([])
    }

    const removeBCC = (allTag, removedTag) => {
        let _tag = { ...tags, bcc: allTag }
        setTags(_tag)
        let _maildata = { bccEmail: _tag.bcc, ccEmail: _tag.cc, tags: _tag }
        dispatch(updateSendMailDataInRedux({ ..._maildata }));
    }

    const handleCopyToClipboard = (type) => {
        if (type == 'CC') {
            if (tags.cc == '') {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.EMPTY_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                copy(tags.cc);
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.COPY_EMAIL, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
            }
        } else {
            if (tags.bcc == '') {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.EMPTY_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                copy(tags.bcc);
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.COPY_EMAIL, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
            }
        }
    }

    const removeCcBcc = (type) => {
        let _focusMode = null;
        let _maildata = {};
        if (type === 'CC') {
            setCCTab(false)
            if (reactTagsBcc?.current?.input) {
                _focusMode = "bccTab";
            }
            let _tag = { ...tags, cc: "" }
            setTags(_tag)
            _maildata = { ...sendEMail.maildata, ccEmail: "", tags: _tag }
        } else {
            setBCCTab(false)
            if (reactTagsCc?.current?.input) {
                _focusMode = "ccTab";
            }
            let _tag = { ...tags, bcc: "" }
            setTags(_tag)
            _maildata = { ...sendEMail.maildata, bccEmail: "", tags: _tag }
        }
        dispatch(updateSendMailDataInRedux({ ..._maildata }));
        setCCBCCFocus(_focusMode)
    }

    const wait = (delay = 500) => {
        return new Promise((resolve) => setTimeout(resolve, delay))
    }

    const debounce = (fn, delay = 500) => {
        let timeoutID
        return function (...args) {
            clearTimeout(timeoutID)
            timeoutID = setTimeout(() => fn(...args), delay)
        }
    }

    const handleInputChangeForBcc = useCallback(
        debounce(async (value) => {
            setCCBCCFocus('bccTab')
            if (value.length < 2) {
                setSuggestions([])
                return
            }
            try {
                await wait()
                let params = {
                    email_text: value
                }
                let promise = Promise.resolve(HTTPClient.get(endPoints.SEND_MAIL.AUTOCOMPLETE_GET, params));
                promise.then((response) => {
                    let emailList = getArrayParam(response.emaillist);
                    let bccsuggestions = [];
                    emailList.forEach(email => {
                        bccsuggestions.push(
                            { label: email, value: email }
                        );
                    });
                    setSuggestions(bccsuggestions)
                })
            } catch (error) {
                console.error(`The Send mail bcc tag API returned a ${error}`)
                setSuggestions([])
            }
        }),
        [suggestions]
    )

    const handleInputChangeForCC = useCallback(
        debounce(async (value) => {
            setCCBCCFocus('ccTab')
            if (value.length < 2) {
                setSuggestions([])
                return
            }
            try {
                await wait()
                let params = {
                    email_text: value
                }
                let promise = Promise.resolve(HTTPClient.get(endPoints.SEND_MAIL.AUTOCOMPLETE_GET_CC, params));
                promise.then((response) => {
                    let emailList = getArrayParam(response.emaillist);
                    if (emailList.length === 0) return;
                    let ccSuggestions = [];
                    emailList.forEach(email => {
                        ccSuggestions.push(
                            { value: email, label: email }
                        );
                    });
                    setccSuggestions(ccSuggestions)
                })
            } catch (error) {
                console.error(`The Send mail cc tag API returned a ${error}`)
                setccSuggestions([])
            }
        }),
        [ccSuggestions]
    )

    const handleInputChangeForTo = useCallback(
        debounce(async (value) => {
            setCCBCCFocus('toTab')
            if (value.length < 2) {
                setSuggestions([])
                return
            }
            try {
                await wait()
                let params = {
                    email_text: value
                }
                let promise = Promise.resolve(HTTPClient.get(endPoints.SEND_MAIL.AUTOCOMPLETE_GET_TO, params));
                promise.then((response) => {
                    let emailList = getArrayParam(response.emaillist);
                    if (emailList.length === 0) return;
                    let toSuggestion = [];
                    emailList.forEach(email => {
                        toSuggestion.push(
                            { value: email.email, label: email.email }
                        );
                    });
                    settoSuggestion(toSuggestion)
                })
            } catch (error) {
                console.error(`The Send mail cc tag API returned a ${error}`)
                settoSuggestion([])
            }
        }),
        [toSuggestion]
    )

    const inputRestriction = (event) => {
        if ((event.key === ',') || (event.key === ' ')) {
            event.preventDefault();
            return false;
        }
        else {
            return true;
        }
    }

    const loadMergeFields = () => {
        let response = Promise.resolve(HTTPClient.get(endPoints.SALES_TEMPLATE.NEW, null));
        response.then((response) => {
            let temp = changeLog;
            temp.mergeFields = response.contact_mail_merge_fields;

            let _categories = catagoryList.map((item) => {
                let _subCatagory = [];
                if (response[item.name]) {
                    response[item.name].map((__subCatagory) => {
                        let _subCatagoryObj = {};
                        _subCatagoryObj.catagory = item.key;
                        _subCatagoryObj.label = __subCatagory;
                        _subCatagoryObj.value = __subCatagory;
                        _subCatagoryObj.id = __subCatagory;
                        _subCatagoryObj.key = __subCatagory;
                        _subCatagory.push(_subCatagoryObj);
                    });
                    const _allObjects = { catagory: item.key, label: 'All', value: '', id: 'All', key: 'All' }
                    const _all = _subCatagory.find(f => f.id === _allObjects.id);
                    if (!_all) {
                        _subCatagory.unshift(_allObjects);
                    };
                    item.subCatagory = _subCatagory;
                }
                item.catagory = item.key;
                return item;
            });
            temp.categories = _categories
            setChangeLog({ ...temp });
            setCatagoryList(_categories);
            dispatch(updateSendMailDataInRedux({ catagoryList: _categories }));
        });
    }

    const bucketChange = () => {
        let _data = getObjectParam(data);
        try {
            let params = { documentId: _data.attachmentId, documentType: "attachment" };
            let promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.ATTACHMENT_CHANGE_BUCKET, params));
            promise.then((response) => {
                if (response.src !== '') {
                    let _attachFiles = [];
                    let attachObj = {};
                    attachObj.name = response.documentName;
                    attachObj.src = response.src;
                    _attachFiles.push(attachObj);
                    setAttachFiles([..._attachFiles]);
                    setAttachLoader(false)
                    dispatch(updateSendMailDataInRedux({ attachFiles: _attachFiles, attachLoader: false }));

                } else {
                    dispatch(showCustomSnackBar('There is some problem with the attached document. You cannot email this document.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    setAttachLoader(false)
                    dispatch(updateSendMailDataInRedux({ attachLoader: false }));

                }
            });
        } catch (error) {
            console.error("Error in 'sendMail.js -> bucketChange()':" + error);
        }
    }

    const loadAgreementAttachment = (_maildata) => {
        let _data = getObjectParam(data, data);
        let param = {};
        try {
            let emailIdList = getArrayParam(_data.email_id_list);
            let _selectedTemplate = _maildata.selectedTemplate;
            param.template_id = _selectedTemplate;
            param.email_id = getStringParam(emailIdList[0].email);
            param.parent_record_id = getStringParam(emailIdList[0].id).toString();
            param.parent_table_id = OBJECT_TABLEID_MAP[data.object];
            param.type = _data.type;
            param.from_email = fromEmail;
            if (data.attachment_type == "Agreement") {
                param.call_from = data.attachment_type;
                param.attachment_id = data.attachmentId;
            }
            let firstName = '';
            if (_data.hasOwnProperty('firstName') && _data.firstName !== null && _data.firstName !== undefined) {
                firstName = _data.firstName;
            }
            let msg = '';
            let subject = '';
            let promise = Promise.resolve(getTemplate(param));
            promise.then((response) => {
                if (isValidParam(response)) { //debugger
                    let _selectedTemplate = _maildata.selectedTemplate;
                    let documentName = getStringParam(_maildata.agreementMailSubject);
                    setEmailSignatureTag(response.email_signature_tag)
                    setEmailSignature(response.email_signature)
                    _maildata = { ..._maildata, emailSignatureTag: response.email_signature_tag, emailSignature: response.email_signature, encodedParams: response?.encodedParams }
                    msg = 'Hi ' + firstName + ',' + '<br/><br/> ' + 'Please use the link below to sign the ' + documentName + "."
                        + '<br/><br/> <a href="##agtemplateurl##"  target="_blank" id="esignurl">' + documentName + '</a> <br/><br/> Thank You.<br/><br/>';
                    subject = documentName;
                    if (subject.indexOf('Please sign') === 0) {
                    } else {
                        subject = 'Please sign ' + documentName;
                    }

                    msg += response.email_signature;
                    let _template = { ...template, subject: subject, name: documentName, id: _selectedTemplate, email_message: _maildata.template.email_message }
                    const _signerCount = response?.agreement_info?.signer_count || 0;
                    const _signerDetails = response?.agreement_info?.signer_details || [];
                    const _signerTags = response?.agreement_info?.sign_tags || [];
                    setAgreementTemplateMsgbody(msg);
                    setSignerCount(_signerCount);
                    setSignerDetails([..._signerDetails]);
                    setSignerTags([..._signerTags]);
                    const _index = 0;
                    const _signer = _signerDetails.find(f => f.is_primary);
                    const _selectedSigner = _signer ? { [_index]: _signer } : { ...selectedSigner };
                    const _tag = { ...tags, cc: "", bcc: "" }
                    setSelectedSigner({ ..._selectedSigner });
                    setSelectedTemplate(_selectedTemplate);
                    setBtnIndex(data.attachment_type === 'Agreement' ? 1 : 2)
                    setAttachLoader(false)
                    setEditorLoader(false)
                    setLoadAgreementEditor(false)
                    setTags({ ..._tag })
                    setIsAgreementSelected(true)
                    setIsDisabledToEmail(true)
                    setIsBackHideen(false);
                    setIsBackHideen(false);
                    setTemplate({ ..._template })
                    _maildata = {
                        ..._maildata, ccEmail: _tag.cc, tags: _tag, bccEmail: _tag.bcc, selectedTemplate: _selectedTemplate, template: _template, agreementTemplateMsgbody: msg, btnIndex: data.attachment_type === 'Agreement' ? 1 : 2,
                        isShowAgreementTemplate: false, attachLoader: false, editorLoader: false, loadAgreementEditor: false,
                        isAgreementSelected: true, isTemplateListDisabled: true, isDisabledToEmail: true, isBackHideen: false,
                        signerCount: _signerCount, signerDetails: _signerDetails, signerTags: _signerTags, selectedSigner: _selectedSigner
                    }
                    if (response.alert) {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response.alert, null);
                    }

                    dispatch(updateSendMailDataInRedux({ ..._maildata }));

                }
            });

        } catch (error) {
            console.error("Error in 'sendMail.js -> loadAgreementAttachment()':" + error);
        }
    }

    const loadCoupons = () => {
        try {
            let coupons = {};
            let _maildata = { ...sendEMail.maildata };
            if (!couponsFromRedux?.couponMounted) {
                let promise = getCoupons()
                promise.then((response) => {
                    if (isValidParam(response.records)) {
                        dispatch(getCouponsAction(response.records));
                        let records = getArrayParam(response.records);
                        records.forEach(record => {
                            let discount = record.discount_type.toLowerCase() === 'percentage' ? record.discount + '%' : '$' + record.discount;
                            coupons[' ' + record.name] = `${record.name} (${discount})`;
                        });
                        setCoupons(coupons)
                        _maildata = { ..._maildata, coupons: coupons }
                        dispatch(updateSendMailDataInRedux({ ..._maildata }));

                    }
                })
            } else {
                if (isValidParam(couponsFromRedux.coupons)) {
                    let records = getArrayParam(couponsFromRedux.coupons);
                    records.forEach(record => {
                        let discount = record.discount_type.toLowerCase() === 'percentage' ? record.discount + '%' : '$' + record.discount;
                        coupons[' ' + record.name] = `${record.name} (${discount})`;
                    });
                }
                setCoupons(coupons)
                _maildata = { ..._maildata, coupons: coupons }
                dispatch(updateSendMailDataInRedux({ ..._maildata }));

            }
        } catch (error) {
            console.error("Error in 'sendMail.js -> loadCoupons()':" + error);
        }
    }

    const remakeSubject = (subject) => {
        subject = getStringParam(subject).trim();
        try {
            if (subject.indexOf('Fwd:') === 0) {
                subject = subject.replace('Fwd:', '');
            }
            if (subject.indexOf('Re:') === 0) {
                subject = subject.replace('Re:', '');
            }
        } catch (error) {
            console.error("Error in 'sendMail.js -> remakeSubject()':" + error);
        }
        return subject;
    }

    const loadRecentTemplate = (recentTempCat, _maildata) => {
        let getfromCookie = true;
        let _data = getObjectParam(data);
        let object = getStringParam(_data.object);
        let fromEmailCookie = app.me.id + '_' + app.me.projectId + '_from_email';
        let fromEmail = null;
        if (object == constants.CASES_OBJECT && app.me?.aurinko_cases_mailbox &&
            app.me?.aurinko_cases_mailbox != null && app.me.aurinko_cases_mailbox != undefined) {
            fromEmail = app.me.aurinko_cases_mailbox;
            setIsMailBoxConfigured(true);
            _maildata = { ..._maildata, isMailBoxConfigured: true }
        } else {
            fromEmail = cookies.get(fromEmailCookie) || app.me.email;
        }
        setFromEmail(fromEmail);
        _maildata = { ..._maildata, fromEmail: fromEmail }
        if (recentTempCat !== undefined && recentTempCat !== "" && recentTempCat !== null && recentTempCat.length > 0) {
            let option = recentTempCat[0];
            setSelectedTemplate(option.value)
            _maildata = { ..._maildata, selectedTemplate: option.value }
            let _data = getObjectParam(data);
            let param = {};
            param.from_email = fromEmail;
            if (_data.hasOwnProperty('batch_mail') && _data.batch_mail === true) {
                param.template_id = option.value;
                param.batch_mail = _data.batch_mail;
            } else if (_data.hasOwnProperty('batch_mail') && _data.batch_mail === false) {
                let emailIdList = getArrayParam(_data.email_id_list);
                param.template_id = option.value;
                param.email_id = getStringParam(emailIdList[0].email);
                param.parent_record_id = getStringParam(emailIdList[0].id).toString();
                param.parent_table_id = OBJECT_TABLEID_MAP[data.object];
                param.type = _data.type;
            } else {
                param.template_id = option.value;
                param.email_id = _data.email;
                param.parent_record_id = _data.id;
                param.parent_table_id = OBJECT_TABLEID_MAP[data.object];
                param.type = _data.type;
            }
            if (_data.callFrom === constants.QUOTATION_OBJECT) {
                param.table_id = _data.table_id;
                param.record_id = _data.record_id;
                param.request_status = getStringParam(_data.request_status);
                Object.assign(param, {
                    ...(app.me.is_moneris_connected ? { callForMoneris: trackingFunctionCallCount > 0 ? false : true } : {})
                });
            }
            if (_data.hasOwnProperty("opportunity_info") && _data.opportunity_info != undefined) {
                param.opportunity_info = _data.opportunity_info;
            }
            if (data.object === constants.AUDIT_ANSWERS_OBJECT && data.type === "corrective action") {
                param.template_id = 0;
                param.record_id = param.parent_record_id;
                param.call_from = "Audits";
                delete param['email_id'];
                delete param['parent_record_id'];
                delete param['parent_table_id'];
                delete param['type'];
                delete param['template_id'];
            }
            if( _data?.is_share_partner){
                param.is_shared_partners = true;
                }

            if (getfromCookie) {
                if (_data.callFrom === constants.INTAKE_FORM) {
                    let param = {};
                    param.table_id = _data.table_id;
                    param.record_id = _data.record_id;
                    param.form_type = 'INTAKE FORM';
                    param.form_id = data.form_id;
                    let promise = Promise.resolve(getTemplate(param));
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            if (response !== null) {
                                let mailMsg = getStringParam(response.mail_body);
                                let mailContent = response.content;
                                let temp = changeLog;
                                temp.fddInfo = response.fdd_info;
                                setChangeLog({ ...temp });
                                let attachedFDDPdf = response?.attachment_info;
                                let _temp = changeLog;
                                _temp.fddAttachedPdfInfo = response?.attachment_info;
                                setChangeLog({ ..._temp });
                                if (isValidParam(attachedFDDPdf) && attachedFDDPdf != undefined) {
                                    let _attachFiles = [];
                                    let attachObj = {};
                                    attachObj.name = attachedFDDPdf.name;
                                    attachObj.src = attachedFDDPdf.url;
                                    _attachFiles.push(attachObj);
                                    setAttachFiles([..._attachFiles]);

                                }
                                if (mailMsg.indexOf(response.email_signature_tag) > 0) {
                                    mailMsg = mailMsg.split(response.email_signature_tag).join(response.email_signature);
                                }
                                if (response.template_name === 'Blank Template') {
                                    mailMsg = mailMsg + '<br><br>' + response.email_signature;
                                }
                                let templateCatagory = getStringParam(response.catagory);
                                let subject = getStringParam(response.subject);
                                let templateName = getStringParam(response.template_name);
                                let selectedTemplateArr = recentTempCat.filter((f) => {
                                    return f.value === option.value;
                                });
                                if (
                                    templateCatagory !== null &&
                                    templateCatagory !== undefined &&
                                    templateCatagory !== '' &&
                                    templateCatagory === 'Agreement Template'
                                ) {
                                    const _tag = { ...tags, cc: "", bcc: "" };
                                    setTags({ ..._tag });
                                    setIsAgreementSelected(true);
                                    setAgreementType(response.agreement_type);
                                    setBtnIndex(1);
                                    dispatch(updateSendMailDataInRedux({ ccEmail: _tag.cc, tags: _tag, bccEmail: _tag.bcc, isAgreementSelected: true, btnIndex: 1, agreementType: response.agreement_type }));
                                    subject = 'Please sign ' + option.label;

                                } else {
                                    setIsAgreementSelected(false);
                                    dispatch(updateSendMailDataInRedux({
                                        isAgreementSelected: false
                                    }));
                                }

                                if (selectedTemplateArr.length > 0) {
                                    let _editorType = selectedTemplateArr[0].editor_type;
                                    if (response.template_name === 'Blank Template') {
                                        _editorType = 'froala';
                                    }
                                    let lastSelectedEditorType = editorType;
                                    let emailSignatureTag = getStringParam(response.email_signature_tag);
                                    let emailSignature = getStringParam(response.email_signature);
                                    let encodedParams = getStringParam(response?.encodedParams);
                                    let _template = { ...template, subject: subject, email_message: mailMsg, id: option.value, name: templateName };
                                    setTemplate({ ..._template });
                                    setEditorType(_editorType);
                                    setEmailContent(mailContent);
                                    setSelectedTemplate(option.value);
                                    setEditorLoader(false);
                                    setEmailSignatureTag(emailSignatureTag);
                                    setEmailSignature(emailSignature);
                                    _maildata = {
                                        ..._maildata,
                                        template: _template,
                                        editorType: _editorType,
                                        emailContent: mailContent,
                                        selectedTemplate: option.value,
                                        editorLoader: false,
                                        emailSignature: emailSignature,
                                        emailSignatureTag: emailSignatureTag,
                                        encodedParams: encodedParams,
                                    };
                                    dispatch(
                                        updateSendMailDataInRedux({
                                            template: _template,
                                            editorType: _editorType,
                                            emailContent: mailContent,
                                            selectedTemplate: option.value,
                                            editorLoader: false,
                                            emailSignature: emailSignature,
                                            emailSignatureTag: emailSignatureTag,
                                            encodedParams: encodedParams,
                                        }),
                                    );
                                    if (_editorType && _editorType === constants.EDITOR_BEE) {
                                        let _templateObj = getStringParam(mailContent) !== '' ? JSON.parse(mailContent) : getDefaultBeeEditorData();
                                        if (lastSelectedEditorType === constants.EDITOR_BEE) {
                                            if (token != null) {
                                                // Bee.load(templateObj)
                                                let type = 'All';
                                                registerBeeEditor(type, _templateObj, _maildata, _editorType);
                                            }
                                        } else {
                                            // setToken(null);
                                            let type = 'New';
                                            registerBeeEditor(type, _templateObj, _maildata, _editorType);
                                        }
                                    }
                                } else {
                                    addEmailSignatureBlankTemplate();
                                }
                            } else {
                                addEmailSignatureBlankTemplate();
                            }
                            if (response.alert) {
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response.alert, null);
                            }
                        }
                    });
                } else {
                    let promise = Promise.resolve(getTemplate(param));
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            if (response !== null) { //debugger
                                let mailMsg = getStringParam(response.mail_body);
                                let mailContent = response.content;
                                let temp = changeLog;
                                temp.fddInfo = response.fdd_info;
                                setChangeLog({ ...temp });
                                let attachedFDDPdf = response?.attachment_info
                                let _temp = changeLog;
                                _temp.fddAttachedPdfInfo = response?.attachment_info;
                                setChangeLog({ ..._temp });
                                let _attachFiles = [];
                                if (isValidParam(attachedFDDPdf) && attachedFDDPdf != undefined) {
                                    let attachObj = {};
                                    attachObj.name = attachedFDDPdf.name;
                                    attachObj.src = attachedFDDPdf.url;
                                    _attachFiles.push(attachObj);
                                    setAttachFiles([..._attachFiles]);
                                } else {
                                    let _data = getObjectParam(data);
                                    let attachmentType = getStringParam(_data?.attachment_type);
                                    if (attachmentType != null && attachmentType == 'File') {
                                        // bypass
                                    } else if (_data.callFrom !== "quotations") {
                                        let _attachFiles = [];
                                        setAttachFiles([..._attachFiles]);
                                    }
                                }
                                if (mailMsg.indexOf(response.email_signature_tag) > 0) {
                                    mailMsg = mailMsg.split(response.email_signature_tag).join(response.email_signature);
                                }
                                if (response.template_name === 'Blank Template') {
                                    mailMsg = mailMsg + "<br><br>" + response.email_signature;
                                }
                                let templateCatagory = getStringParam(response.catagory);
                                let subject = getStringParam(response.subject);
                                if (templateCatagory !== null && templateCatagory !== undefined && templateCatagory !== "" && templateCatagory === 'Agreement Template') {
                                    const _tag = { ...tags, cc: "", bcc: "" }
                                    setTags({ ..._tag })
                                    setIsAgreementSelected(true)
                                    setAgreementType(response.agreement_type)
                                    setBtnIndex(1)
                                    dispatch(updateSendMailDataInRedux({
                                        ccEmail: _tag.cc, tags: _tag, bccEmail: _tag.bcc, isAgreementSelected: true, btnIndex: 1, agreementType: response.agreement_type,
                                        attachFiles: _attachFiles
                                    }));
                                    subject = 'Please sign ' + option.label;
                                } else {
                                    setIsAgreementSelected(false);
                                    dispatch(updateSendMailDataInRedux({
                                        isAgreementSelected: false
                                    }));
                                }
                                let templateName = getStringParam(response.template_name);
                                let selectedTemplateArr = recentTempCat.filter(f => { return f.value === option.value });
                                if (selectedTemplateArr.length > 0) {
                                    let _editorType = selectedTemplateArr[0].editor_type;
                                    if (response.template_name === 'Blank Template') {
                                        _editorType = "froala";
                                    }
                                    let lastSelectedEditorType = editorType;
                                    let emailSignatureTag = getStringParam(response.email_signature_tag)
                                    let emailSignature = getStringParam(response.email_signature)
                                    let encodedParams = getStringParam(response?.encodedParams)
                                    let _template = { ...template, subject: subject, email_message: mailMsg, id: option.value, name: templateName }
                                    const _signerCount = response?.agreement_info?.signer_count || 0;
                                    const _signerDetails = response?.agreement_info?.signer_details || [];
                                    const _signerTags = response?.agreement_info?.sign_tags || [];
                                    const _index = 0;
                                    const _signer = _signerDetails.find(f => f.is_primary);
                                    const _selectedSigner = _signer ? { [_index]: _signer } : { ...selectedSigner };
                                    const caseCCEmail = response?.case_cc_value || "";
                                    if (caseCCEmail != null && caseCCEmail != undefined && caseCCEmail != "") {
                                        setCCTab(true);
                                        let _tags = { ...tags };
                                        _tags = { ..._tags, cc: caseCCEmail };
                                        setTags({ ..._tags });
                                        _maildata = { ..._maildata, tags: _tags, ccEmail: caseCCEmail, ccTab: true };
                                        dispatch(updateSendMailDataInRedux({
                                            tags: _tags, ccEmail: caseCCEmail, ccTab: true
                                        }));
                                    }
                                    if (data.object === constants.AUDIT_ANSWERS_OBJECT && data.type === "corrective action") {
                                        let mailMsg = response.mail_body;
                                        if (mailMsg.indexOf(response.email_signature_tag) > 0) {
                                            mailMsg = mailMsg.split(response.email_signature_tag).join(response.email_signature);
                                        }
                                        else {
                                            mailMsg = mailMsg + "<br><br>" + response.email_signature;
                                        }
                                        _template = { ..._template, email_message: mailMsg, emailSignature: response.email_signature, emailSignatureTag: response.email_signature_tag }
                                        setToEmail(response.to_mail);
                                        setEmailSignature(response.email_signature);
                                        setEmailSignatureTag(response.email_signature_tag);
                                    } else {
                                        setEmailSignatureTag(emailSignatureTag)
                                        setEmailSignature(emailSignature)
                                    }
                                    setSignerCount(_signerCount);
                                    setSignerDetails([..._signerDetails]);
                                    setSignerTags([..._signerTags]);
                                    setSelectedSigner({ ..._selectedSigner });
                                    setTemplate({ ..._template });
                                    setEditorType(_editorType)
                                    setEmailContent(mailContent)
                                    setSelectedTemplate(option.value)
                                    setEditorLoader(false)
                                    _maildata = {
                                        ..._maildata, template: _template, editorType: _editorType, emailContent: mailContent,
                                        selectedTemplate: option.value, editorLoader: false, emailSignature: emailSignature,
                                        emailSignatureTag: emailSignatureTag, encodedParams: encodedParams,
                                        signerCount: _signerCount, signerDetails: _signerDetails, signerTags: _signerTags,
                                        selectedSigner: _selectedSigner
                                    }
                                    dispatch(updateSendMailDataInRedux({
                                        template: _template, editorType: _editorType, emailContent: mailContent,
                                        selectedTemplate: option.value, editorLoader: false, emailSignature: emailSignature,
                                        emailSignatureTag: emailSignatureTag, encodedParams: encodedParams,
                                        signerCount: _signerCount, signerDetails: _signerDetails, signerTags: _signerTags,
                                        selectedSigner: _selectedSigner
                                    }));
                                    if (_editorType && _editorType === constants.EDITOR_BEE) {
                                        let _templateObj = getStringParam(mailContent) !== "" ? JSON.parse(mailContent) : getDefaultBeeEditorData();
                                        if (lastSelectedEditorType === constants.EDITOR_BEE) {
                                            if (token != null) {
                                                // Bee.load(templateObj)
                                                let type = 'All';
                                                registerBeeEditor(type, _templateObj, _maildata, _editorType);
                                            }
                                        } else {
                                            // setToken(null);
                                            let type = 'New';
                                            registerBeeEditor(type, _templateObj, _maildata, _editorType);

                                        }
                                    }
                                } else {
                                    addEmailSignatureBlankTemplate();
                                }
                            } else {
                                addEmailSignatureBlankTemplate();
                            }
                            if (response.alert) {
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response.alert, null);
                            }
                        }
                    });
                }
            } else {
                addEmailSignatureBlankTemplate();
            }
        } else {
            addEmailSignatureBlankTemplate();
        }
        setTrackingFunctionCallCount((trackingFunctionCallCount) => trackingFunctionCallCount + 1);
    }

    const verifyEmails = (data) => {
        let msg = "";
        let senderList = "";
        let statusVerifyData = [];
        if (data.object === constants.UNITS_OBJECT) {
            data.object = constants.ACCOUNTS_OBJECT;
        }
        if (data.object == constants.CONTACTS_OBJECT && app.me.is_franchisee && listView.data.hasOwnProperty("doNotShowAction") && listView.data?.doNotShowAction) {
            data.call_from = "shared_with"
        }
        if (data.object == constants.PARTNERS_OBJECT && app.me.is_franchisee) {
            data.call_from = "shared_with"
        }
        let promise = verifyEailStatus(data);
        promise.then((response) => {
            if (isValidParam(response)) {
                if (response.status === 0) {
                    statusVerifyData = response.data;
                    setOptedInRecipients([...getArrayParam(statusVerifyData)])
                    let _data = getObjectParam(data);
                    let oldlen = getIntParam(data.selectedEmailLength);
                    let newlen = statusVerifyData.length;
                    let mailObject = _data.object;
                    if (oldlen > newlen) {
                        if (newlen > 0) {
                            msg = getLocalizedStrings().message.MAIL.BATCH_SEND_MAIL_MSG_1 + " " + oldlen + " " + mailObject + " " + getLocalizedStrings().message.MAIL.BATCH_SEND_MAIL_MSG_2 + " " + newlen + " " + getLocalizedStrings().message.MAIL.BATCH_SEND_MAIL_MSG_3 + " " + newlen + " " + mailObject + getLocalizedStrings().label.COMMON.DOT;
                            statusVerifyData.forEach(iemailId => {
                                senderList = senderList === "" ? iemailId.email : senderList + "," + iemailId.email;
                            });
                        } else {
                            msg = getLocalizedStrings().message.MAIL.BATCH_SEND_MAIL_MSG_4 + " " + mailObject + " " + getLocalizedStrings().message.MAIL.BATCH_SEND_MAIL_MSG_5 + getLocalizedStrings().label.COMMON.DOT;
                        }
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                    } else {
                        statusVerifyData.forEach(iemailId => {
                            senderList = senderList === "" ? iemailId.email : senderList + "," + iemailId.email;
                        });
                    }
                    if (getBooleanParam(appContainer.drawerProps.isOpenDrawer)) {
                        appContainer.drawerProps.data.email_id_list = statusVerifyData;
                    } else if (getBooleanParam(appContainer.customDrawerProps.isOpen)) {
                        appContainer.customDrawerProps.data.email_id_list = statusVerifyData;
                    }
                    processBatchEmailDetails(_data.batch_mail, senderList, statusVerifyData);
                }
            }
        });
    }

    const processBatchEmailDetails = (isBatchMail, mailIds, _optedInRecipients) => {
        try {
            let fromEmailCookie = app.me.id + '_' + app.me.projectId + '_from_email';
            let _maildata = { ...sendEMail.maildata };
            let _data = getObjectParam(data);
            let object = getStringParam(_data.object);
            let fromEmail = null;
            if (object == constants.CASES_OBJECT && app.me?.aurinko_cases_mailbox &&
                app.me?.aurinko_cases_mailbox != null && app.me.aurinko_cases_mailbox != undefined) {
                fromEmail = app.me.aurinko_cases_mailbox;
                setIsMailBoxConfigured(true);
                _maildata = { ..._maildata, isMailBoxConfigured: true }
            } else {
                fromEmail = cookies.get(fromEmailCookie) || app.me.email;
            }
            setToEmail(app.me.email)
            setFromEmail(fromEmail)
            setBCCTab(true)
            let bccList = [];
            mailIds.split(',').forEach(function (el) {
                bccList.push({ id: el, name: el, disabled: true })
            });
            let _tags = { ...tags, bcc: bccList }
            setTags(_tags)
            _maildata = { ..._maildata, tags: _tags, toEmail: app.me.email, fromEmail: fromEmail, bccTab: true, batchMail: isBatchMail, bccEmail: mailIds, optedInRecipients: _optedInRecipients }
            dispatch(updateSendMailDataInRedux({ ..._maildata }));
        } catch (error) {
            console.error("Error in 'sendMail.js -> processBatchEmailDetails()':" + error);
        }
    }

    const getSalesRepMail = (salesRep) => {
        let promise = Promise.resolve(getUserList());
        let _maildata = { ...sendEMail.maildata };
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response) && response.hasOwnProperty('users')) {
                    let users = getArrayParam(response.users);
                    let tempUsers = users.filter(u => { return u.name === salesRep });
                    let toEmail = tempUsers.length > 0 ? getStringParam(tempUsers[0].email) : '';
                    toEmail = toEmail === '' ? app.me.email : toEmail;
                    setToEmail(toEmail)
                    _maildata = { ..._maildata, toEmail: toEmail }
                }
            });
            dispatch(updateSendMailDataInRedux({ ..._maildata }));
        }
    }

    const handleTaskActionOpen = (event) => {
        let _maildata = { ...sendEMail.maildata };
        event.preventDefault();
        setIsOpenTaskAction(true)
        setTaskAnchor(event.currentTarget)
        _maildata = { ..._maildata, isOpenTaskAction: true, taskAnchor: event.currentTarget }
        dispatch(updateSendMailDataInRedux({ ..._maildata }));
    }

    const handleTaskActionClose = () => {
        setIsOpenTaskAction(false);
        dispatch(updateSendMailDataInRedux({ isOpenTaskAction: false }));
    }

    const handleModelChange = (event) => {
        if (isAgreementSelected && btnIndex === 2) {
            setAgreementTemplateMsgbody(event);
            setCCBCCFocus(null);
            sendEMail.maildata.agreementTemplateMsgbody = event;
        }
        else {
            let _template = { ...template, email_message: event }
            setTemplate({ ..._template });
            setCCBCCFocus(null);
            sendEMail.maildata.template = _template;
        }
        handlePopUpClose();
    };

    const setFieldValue = (event, fieldName) => {
        let val;
        val = event.target.value;
        let _maildata = {};
        if (fieldName === 'subject') {
            let _template = { ...template, subject: val }
            setTemplate({ ..._template });
            _maildata = { template: _template }
        } else if (fieldName === 'toEmail') {
            setToEmail(val);
            _maildata = { toEmail: val }
        } else if (fieldName === 'ccEmail') {
            _maildata = { ccEmail: val }
        } else if (fieldName === 'bccEmail') {
            _maildata = { bccEmail: val }
        }
        dispatch(updateSendMailDataInRedux({ ..._maildata }));
    }

    const getSignaturesArr = () => {
        let _signatures = [];
        Object.keys(selectedSigner).map((key) => {
            _signatures.push({ ...selectedSigner[key], idx: Number(key) + 1 })
        });
        sortArrayObjectByProperty(_signatures, 'idx');
        return _signatures
    }

    const _sendMail = (_scheduleDate, from) => {
        let selectedText = '';
        if (selectedCatagory !== 'Marketing Template' && editorInstance) {
            selectedText = editorInstance.selection.text();
        }
        let email_id_list = emailIdList;
        let objectId = 0;
        let _data = getObjectParam(data);
        let object = getStringParam(_data.object);
        if (object === constants.UNITS_OBJECT) {
            object = constants.ACCOUNTS_OBJECT;
        }
        if (_data.parentObject == 'cases' && (_data.actionType === 'REPLY' || _data.actionType === 'REPLY-ALL' || _data.actionType === 'FORWARD')) {
            object = constants.CASES_OBJECT;
        }
        if (_data.hasOwnProperty('isAttachment') && _data.isAttachment === true) {
            if (object !== constants.ACCOUNTS_OBJECT && object !== constants.CONTACTS_OBJECT && object !== constants.JOBS_OBJECT) {
                objectId = getIntParam(OBJECT_TABLEID_MAP['contacts']).toString();
            } else {
                objectId = getIntParam(OBJECT_TABLEID_MAP[object]).toString();
            }
        } else {
            objectId = getIntParam(OBJECT_TABLEID_MAP[object]).toString();
        }
        let params = {};
        let _ctCookieName = "SelectedCatagory_" + app.me.id + "_" + app.me.projectId;
        let _subCtcookieName = "SelectedSubCatagory_" + app.me.id + "_" + app.me.projectId;
        setCookie(_ctCookieName, selectedCatagory);
        setCookie(_subCtcookieName, selectedCatagory === 'Agreement Template' ? '' : selectedSubCatagory);
        if (selectedCatagory === 'Agreement Template') {
            params.signers = getSignaturesArr();
        }
        if ((_data.hasOwnProperty('batch_mail') && _data.batch_mail === true) || (email_id_list.length > 1 && _data.hasOwnProperty('attachmentId') && getIntParam(_data.attachmentId) > 0 && _data.hasOwnProperty('isAttachment') && _data.isAttachment === true)) {
            let parentObject = getStringParam(_data.parentObject);
            let parentRecordId = getStringParam(_data.parentRecordId);
            params.recipients = toEmail;
            params.frommail = fromEmail
            params.fromname = app.me.name;
            if (_data.hasOwnProperty('isAttachment') && _data.isAttachment === true && emailIdList.length > 1) {
                let _ccEmail = tags.cc != null && tags.cc != undefined ? tags.cc : "";
                let _bccEmail = tags.bcc != null && tags.bcc != undefined ? tags.bcc : "";
                if (_ccEmail != null && _ccEmail != undefined && _ccEmail != "") {
                    params.cc = _ccEmail;
                }
                if (_bccEmail != null && _bccEmail != undefined && _bccEmail != "") {
                    params.bcc = _bccEmail;
                }
                params.isFromAttachment = isFromAttachment;
            } else {
                params.cc = "";
                params.bcc = "";
            }
            params.subject = template.subject !== undefined ? template.subject : "";
            params.templateBody = template.email_message
            params.template_id = template.id
            params.object_id = objectId;
            params.record_ids = "";
            params.is_email_note = true;
            params.starred_mail = starredMail;
            if (_data.hasOwnProperty('isAttachment') && _data.isAttachment === true && emailIdList.length > 1) {
                params.email_id_list = JSON.stringify(emailIdList);
            } else {
                params.email_id_list = JSON.stringify(_data.email_id_list);
            }
            let t_subject = template.subject;
            selectedText = selectedText.concat("\n");
            selectedText = selectedText + t_subject;
            params.note_subject = selectedText;
            params.note_body = template.email_message.replace(/[\u200B-\u200D\uFEFF]/g, '');
            if (parentRecordId !== "" && parentObject !== "") {
                params.parent_table_id = OBJECT_TABLEID_MAP[parentObject];
                params.parent_record_id = parentRecordId;
            }
        } else {
            let recordIds = getStringParam(_data.id);
            let emailIdList = getArrayParam(_data.email_id_list);
            if (_data.hasOwnProperty('batch_mail') && !getBooleanParam(_data.batch_mail) && emailIdList.length === 1 && email_id_list.length === 0) {
                recordIds = getStringParam(emailIdList[0].id);
            } else if (email_id_list.length === 1) {
                recordIds = email_id_list[0].id;
                if (recordIds === undefined) {
                    recordIds = 0;
                }
            }
            let parentObject = getStringParam(_data.parentObject);
            let parentRecordId = getStringParam(_data.parentRecordId);
            params.recipients = toEmail;
            params.frommail = fromEmail;
            params.fromname = app.me.name;
            let _ccEmail = tags.cc != null && tags.cc != undefined ? tags.cc : "";
            let _bccEmail = tags.bcc != null && tags.bcc != undefined ? tags.bcc : "";
            if (_ccEmail != null && _ccEmail != undefined && _ccEmail != "") {
                params.cc = _ccEmail;
            }
            if (_bccEmail != null && _bccEmail != undefined && _bccEmail != "") {
                params.bcc = _bccEmail;
            }
            params.subject = template.subject !== undefined ? template.subject : "";
            if (_data.hasOwnProperty('isAttachment') && _data.isAttachment === true) {
                params.isFromAttachment = isFromAttachment;
            }
            params.template_id = template.id;
            params.object_id = objectId;
            params.record_ids = recordIds.toString();
            let t_subject = template.subject;
            let formatSelectedText = selectedText.concat("\n") + t_subject;
            let getEmailBodyText = StripHtmlToText(template.email_message);
            let noteBodyText = getEmailBodyText.substring(0, 200) + "...";
            let formatNoteBodyText = t_subject.concat("\n") + noteBodyText.replace(/\n+/g, ' ');
            if (selectedCatagory === 'Sales Template') {
                params.note_subject = selectedText.length > 0 ? formatSelectedText : formatNoteBodyText;
            } else {
                params.note_subject = formatSelectedText
            }
            params.isAgreementTemplate = isAgreementSelected;
            params.template_name = template.name;
            params.starred_mail = starredMail;
            //Add Opportunity Info for Linking Opportunity With Note while sending Mail to Contact/Accounts
            if (_data.hasOwnProperty('opportunity_info') && _data.opportunity_info !== null &&
                _data.opportunity_info != undefined && _data.opportunity_info !== '') {
                params.opportunity_info = _data.opportunity_info;
            }
            if (params.isAgreementTemplate) {
                let msgBody = agreementTemplateMsgbody;
                let $html = $("<div/>").html(msgBody);
                let hrf = $html.find("a#esignurl").attr("href");
                if (hrf !== '##agtemplateurl##') {
                    $html.find("a#esignurl").attr("href", "##agtemplateurl##");
                    setAgreementTemplateMsgbody($html[0].innerHTML)
                }
                params.note_body = agreementTemplateMsgbody.replace(/[\u200B-\u200D\uFEFF]/g, ''); //StripHtmlToText(this.state.template['email_message']);
                params.email_body = agreementTemplateMsgbody;
                params.templateBody = template.email_message.replace(/(<img("[^"]*"|[^\/">])*)>/gi, "$1/>");
                params.agreement_type = agreementType;
            } else {
                params.note_body = template.email_message.replace(/[\u200B-\u200D\uFEFF]/g, ''); //StripHtmlToText(this.state.template['email_message']);
                params.email_body = StripHtmlToText(template.email_message);
                if (editorType === constants.EDITOR_BEE && selectedCatagory == 'Marketing Template') {
                    params.templateBody = htmlBody;
                } else {
                    params.templateBody = template.email_message;
                }
            }
            params.is_email_note = true;
            if (parentRecordId !== "" && parentObject !== "") {
                params.parent_table_id = OBJECT_TABLEID_MAP[parentObject];//parentObject;
                params.parent_record_id = parentRecordId;
            }
        }
        let strAtachmentInfo = '';
        let arrAttachment = getArrayParam(attachFiles);
        arrAttachment.forEach(f => {
            if (strAtachmentInfo === '') {
                strAtachmentInfo = '##' + f.name + '##' + f.src;
            } else {
                strAtachmentInfo += ',@##' + f.name + '##' + f.src;
            }
        });
        params.attachment_info = strAtachmentInfo;
        params.editorType = editorType;
        params.templateCategory = selectedCatagory;
        params.is_send_mail_using_aurinko = app.me.is_send_mail_using_aurinko;
        if (editorType === constants.EDITOR_BEE) {
            params.emailContent = emailContent;
        }
        if (getStringParam(_data.callFrom) === constants.QUOTATION_OBJECT) {
            if (_data.hasOwnProperty('record_id') && getIntParam(_data.record_id) > 0) {
                params.quote_id = getIntParam(_data.record_id);
            }
        }
        if(_data?.is_share_partner && _data?.object == 'partners'){
            params.fe_account_recordid = data?.accountRecordId;
            params.is_shared_partners = true ;
        }
        if (_data.object == constants.CONTACTS_OBJECT && app.me.is_franchisee && listView.data.hasOwnProperty("doNotShowAction") && listView.data?.doNotShowAction) {
            params.call_from = "shared_with"
        }
        if (isValid(params)) {
            if (_scheduleDate !== undefined && _scheduleDate !== null && from == 'schedule') {
                _scheduleMail(params, _scheduleDate);
            } else {
                setSending(true);
                let fromEmailCookie = app.me.id + '_' + app.me.projectId + '_from_email';
                if (object == constants.CASES_OBJECT ||object == '' && app.me?.aurinko_cases_mailbox) {
                    //bypass
                } else {
                    cookies.set(fromEmailCookie, fromEmail, { path: "/" });
                }
                let fddId = changeLog?.fdd_template_id;
                params.fdd_template_id = fddId;

                let promise = sendMail(params);
                if (isValidParam(promise)) {

                    if ((_data.hasOwnProperty('batch_mail') && _data.batch_mail === true)) {
                        if (data.callFrom === constants.QUOTATION_OBJECT && getStringParam(data.request_status) === 'Request Approval') {
                            updateQuotationStatus();
                        }
                        if (data.callFrom === constants.PURCHASE_ORDER_OBJECT) {
                            updatePurchaseOrderStatus();
                        }
                        closeDrawer();
                        dispatch(resetSendMailDataInRedux());
                    }
                    promise.then((response) => {
                        if (isValidParam(response) && response.hasOwnProperty('status')) {
                            if (response.status === 0) {
                                dispatch(refreshDetailViewData(true));
                                dispatch(refreshDetailViewListViewData(true));
                                geTemplateListByCategory(selectedCatagory, selectedSubCatagory, selectedDataFetchType, sendEMail.maildata);
                                if (!(_data.hasOwnProperty('batch_mail') && _data.batch_mail === true)) {
                                    if (data.callFrom === constants.QUOTATION_OBJECT && getStringParam(data.request_status) === 'Request Approval') {
                                        updateQuotationStatus();
                                    }
                                    if (data.callFrom === constants.PURCHASE_ORDER_OBJECT) {
                                        updatePurchaseOrderStatus();
                                    }
                                    closeDrawer();
                                }
                                //request/submit corrective action
                                if (data.object === constants.AUDIT_ANSWERS_OBJECT) {
                                    let params = {};
                                    params.id = data.parent_record_id;
                                    const promise = Promise.resolve(HTTPClient.post(endPoints.AUDIT_ANSWERS.SEND_AUDIT_MAIL, params));
                                    if (promise) {
                                        promise.then(res => {
                                            if (res.status === 0) {
                                                let message;
                                                if (app.me.is_franchisor) {
                                                    message = getLocalizedStrings().message.AUDIT_QUESTIONS.REQUEST_CORRECTIVE_SUCCESS;
                                                } else {
                                                    message = getLocalizedStrings().message.AUDIT_QUESTIONS.SUBMIT_CORRECTIVE_ACTION;
                                                }
                                                dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                                setSending(false)
                                                setIsSendDisabled(false)
                                                dispatch(detailViewIsMounted(false));
                                                dispatch(isDetailViewSet(false));
                                                dispatch(refreshDetailViewData(true));
                                                dispatch(refreshDetailViewListViewData(true));
                                            } else {
                                                setSending(false)
                                                setIsSendDisabled(false)
                                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response.error.message, null);
                                            }
                                        })
                                    }
                                }
                                setSending(false)
                                setIsSendDisabled(false)
                            } else if (response.status === 1) {
                                setSending(false)
                                setIsSendDisabled(false)
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response.error.message, null);
                            } else if (response.status > 0) {
                                setSending(false)
                                setIsSendDisabled(false)
                                dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            } else {
                                setSending(false)
                                setIsSendDisabled(false)
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            }
                        }
                    });
                } else {
                    setIsSendDisabled(false)
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
            }
        } else {
            setIsSendDisabled(false)
        }
    }

    const _scheduleMail = (params, _scheduleDate) => {
        let fddId = changeLog?.fdd_template_id;
        let _subject = params.subject;
        let selectedText = params.note_subject;
        if (selectedCatagory !== 'Marketing Template' && editorInstance) {
            selectedText = editorInstance.selection.text();
            selectedText = selectedText.concat("\n");
            selectedText = selectedText + _subject;
        }
        params.subject = selectedText
        params.starred_mail = starredMail;
        params.scheduled_on = scheduleDate == null ? _scheduleDate : scheduleDate;
        params.fdd_template_id = fddId;
        if (params.hasOwnProperty('parent_table_id')) params.object_id = params.parent_table_id.toString();
        if (params.hasOwnProperty('parent_record_id')) params.record_ids = params.parent_record_id.toString();
        params.editor_type = editorType;
        if (editorType === constants.EDITOR_BEE) {
            params.emailContent = emailContent;
        }
        let promise = scheduleMail(params);
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response) && response.hasOwnProperty('status')) {
                    dispatch(detailViewIsMounted(false));
                    dispatch(isDetailViewSet(false));
                    dispatch(refreshDetailViewData(true));
                    dispatch(refreshDetailViewListViewData(true));
                    if (response.status === 0) {
                        if (data.object === constants.AUDIT_ANSWERS_OBJECT) {
                            let params = {};
                            params.id = data.parent_record_id;
                            const promise = Promise.resolve(HTTPClient.post(endPoints.AUDIT_ANSWERS.SEND_AUDIT_MAIL, params));
                            if (promise) {
                                promise.then(res => {
                                    if (res.status === 0) {
                                        let message;
                                        if (app.me.is_franchisor) {
                                            message = getLocalizedStrings().message.AUDIT_QUESTIONS.REQUEST_CORRECTIVE_SUCCESS;
                                        } else {
                                            message = getLocalizedStrings().message.AUDIT_QUESTIONS.SUBMIT_CORRECTIVE_ACTION;
                                        }
                                        dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                        setSending(false)
                                        setIsSendDisabled(false)
                                        dispatch(detailViewIsMounted(false));
                                        dispatch(isDetailViewSet(false));
                                        dispatch(refreshDetailViewData(true));
                                        dispatch(refreshDetailViewListViewData(true));
                                    } else {
                                        setSending(false)
                                        setIsSendDisabled(false)
                                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response.error.message, null);
                                    }
                                })
                            }
                        }
                        setSending(false)
                        setIsSendDisabled(false)
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.MAIL.SCHEDULE_SUCCESS, null);
                        dispatch(getTasksRefresh(true));
                        closeDrawer();
                        dispatch(resetSendMailDataInRedux());
                    } else if (response.status === 1) {
                        setSending(false)
                        setIsSendDisabled(false)
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response.error.message, null);
                    } else if (response.status > 0) {
                        setSending(false)
                        setIsSendDisabled(false)
                        dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    } else {
                        setSending(false)
                        setIsSendDisabled(false)
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                }
            });
        } else {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
    }

    const isValid = (params) => {
        let _data = getObjectParam(data);
        if (isValidParam(params.frommail) && params.frommail === '') {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.BLANK_FROM_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else if (isValidParam(params.frommail) && !isEmail(params.frommail)) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.INVALID_FROM_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else if (!(_data.hasOwnProperty('batch_mail') && _data.batch_mail === true) && isValidParam(params.recipients) && params.recipients === '' && !isAgreementSelected && !getBooleanParam(data.isAttachment)) {
            if (!((isValidParam(params.cc) && params.cc !== '') || (isValidParam(params.bcc) && params.bcc !== ''))) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.BLANK_TO_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            } else if (isValidParam(params.cc) && !isEmail(params.cc)) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.INVALID_CC_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            } else if (isValidParam(params.bcc) && !isEmail(params.bcc)) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.INVALID_BCC_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            }
        } else if (isValidParam(params.recipients) && params.recipients === '' && emailIdList.length === 0 && getBooleanParam(_data.isAttachment)) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.BLANK_TO_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
        else if ((isValidParam(params.recipients) && !isEmail(params.recipients))) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.INVALID_TO_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else if (isValidParam(params.cc) && !isEmail(params.cc)) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.INVALID_CC_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else if (isValidParam(params.bcc) && !isEmail(params.bcc)) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.INVALID_BCC_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else if (isValidParam(params.subject) && params.subject === '') {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.BLANK_SUBJECT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else if (isValidParam(params.templateBody) && params.templateBody === '') {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.BLANK_EMAIL_BODY, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else if (_data.batch_mail && isValidParam(params.email_id_list) && JSON.parse(params.email_id_list).length === 0) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.NO_RECIPIENT + getLocalizedStrings().label.COMMON.DOT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else if (getBooleanParam(params.isAgreementTemplate) && params.template_id === 0) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.TEMPLATE_NOT_SELECTED + getLocalizedStrings().label.COMMON.DOT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
        return true;
    }

    const geTemplateListByCategory = (category, subcategory, selectedDataFetchType, _maildata) => {
        let arrPromise = [];
        let templateListObj = null;
        let tableId = data?.opportunity_info?.tableId ? data?.opportunity_info?.tableId : OBJECT_TABLEID_MAP[data.object];
        let _recordId = data?.opportunity_info?.recordId ? data?.opportunity_info?.recordId : data.id;
        let fddInfo = changeLog.fddInfo;
        if (category !== null && selectedDataFetchType !== null) {
            if (sendEMail.templateList.length === 0) {
                arrPromise.push(Promise.resolve(getTemplateList(category, subcategory, selectedDataFetchType, 'sendMail', tableId, _recordId, fddInfo)));
                Promise.all(arrPromise).then((arrResponse) => {
                    let templateObj = getArrayParam(arrResponse);
                    if (templateObj.length > 0) {
                        if (selectedDataFetchType !== 'Recent') {
                            dispatch(storeTemplateListInRedux(templateObj));
                        }
                    }
                    setTemplateOptions(templateObj);
                    dispatch(updateSendMailDataInRedux({ templateOptions: templateObj }));
                    processTemplateList(arrResponse, selectedDataFetchType, _maildata);
                });
            } else if (sendEMail.templateList.length > 0) {
                let finalArr = [];
                templateListObj = sendEMail.templateList[0];
                let list = templateListObj[category];
                if (selectedDataFetchType === 'Recent') {
                    let fddInfo = changeLog.fddInfo;
                    arrPromise.push(Promise.resolve(getTemplateList(category, subcategory, selectedDataFetchType, 'sendMail', tableId, _recordId, fddInfo)));
                    Promise.all(arrPromise).then((arrResponse) => {
                        let templateObj = getArrayParam(arrResponse);
                        if (templateObj.length > 0) {
                            if (selectedDataFetchType !== 'Recent') {
                                dispatch(storeTemplateListInRedux(templateObj));
                            }
                        }
                        setTemplateOptions(templateObj);
                        dispatch(updateSendMailDataInRedux({ templateOptions: templateObj }));
                        processTemplateList(arrResponse, selectedDataFetchType, _maildata);
                    });
                } else if (selectedDataFetchType !== 'Recent') {
                    let fddInfo = changeLog.fddInfo;
                    arrPromise.push(Promise.resolve(getTemplateList(category, subcategory, selectedDataFetchType, 'sendMail', tableId, _recordId, fddInfo)));
                    Promise.all(arrPromise).then((arrResponse) => {
                        let templateObj = getArrayParam(arrResponse);
                        if (templateObj.length > 0) {
                            Object.assign(templateListObj, templateObj[0]);
                            finalArr.push(templateListObj);
                            if (selectedDataFetchType !== 'Recent') {
                                dispatch(storeTemplateListInRedux(finalArr));
                            }
                        }
                        setTemplateOptions(templateObj);
                        dispatch(updateSendMailDataInRedux({ templateOptions: templateObj }));
                        processTemplateList(arrResponse, selectedDataFetchType, _maildata);
                    });
                } else {
                    setTemplateOptions(sendEMail.templateList);
                    dispatch(updateSendMailDataInRedux({ templateOptions: sendEMail.templateList }));
                    processTemplateList(sendEMail.templateList, selectedDataFetchType, _maildata);
                }
            }
        }
    }

    const getMergeTags = () => {
        let mergeTags = [];
        let tempMergeFields = changeLog.mergeFields;
        try {
            mergeTags = [
                { name: 'Company Logo', value: "${company.logo}" },
                { name: 'Company Name', value: "${company.business_name}" },
                { name: 'Company Website', value: "${company.website}" },
                { name: 'Company Address', value: "${company.business_address}" },
                { name: 'Company Phone', value: "${company.business_phone}" },
                { name: 'Company Email', value: "${company.business_email}" },
                // { name: 'Company Country', value:"${company.country}"},
                { name: 'Opt-in Link', value: '[Start Opt-in Link] Enter your display text [End Opt-in Link]' },
                { name: 'Opt-out Link', value: '[Start Opt-out Link] Enter your display text [End Opt-out Link]' },
                { name: 'Signature', value: '${Email_Signature}' },
            ];
            let tempTags = tempMergeFields.map(mergeField => {
                let key = Object.keys(mergeField)[0];
                return { name: mergeField[key], value: '$' + `{${key}}` };
            });
            mergeTags.push(...tempTags);
        } catch (error) {
            console.error("Error in 'MosaicoTemplate.js -> getMergeTags()':" + error);
        }
        return mergeTags;
    }

    const getSpecialLinks = () => {
        let specialLinks = [];
        try {
            let slug = app.appointment_scheduler_slug;
            if (isValidParam(slug) && slug !== '') {
                let link = { type: 'Appointment', label: 'Book an appointment', link: constants.APPOINTMENT_SCHEDULER_URL + slug }
                specialLinks.push(link);
            }
        } catch (error) {
            console.error("Error in 'MosaicoTemplate.js -> getSchedulerLink()':" + error);
        }
        return specialLinks
    }

    const registerBeeEditor = async (type, _templateObj, _maildata, _editorType) => {
        let content;
        if (actionType === 'REPLY' || actionType === 'REPLY-ALL' || actionType === 'FORWARD') {
            content = data.record.mail_content;
        } else {
            content = _maildata.emailContent ? _maildata.emailContent : emailContent;
        }
        try {
            if (_maildata.editorType === constants.EDITOR_BEE || data.record?.editor_type === constants.EDITOR_BEE || _editorType === constants.EDITOR_BEE) {
                let tenantId = getStringParam(app.me.projectId);
                let mergeTags = getMergeTags();
                let specialLinks = getSpecialLinks();
                let beeConfig = {
                    uid: tenantId, //needed for identify resources of the that user and billing stuff
                    // container: 'bee-container',
                    container: "bee-plugin-container", //Identifies the id of div element that contains BEE Plugin
                    language: 'en-US',
                    mergeTags: mergeTags,
                    specialLinks: specialLinks,
                    editorFonts: {
                        showDefaultFonts: false,
                        customFonts: getBeeEditorFontList()
                    },
                    titleDefaultConfig: {
                        bold: true
                    },
                    titleDefaultStyles: {
                        h1: {
                            color: '#555555',
                            'font-size': '34px',
                            'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            'link-color': '#E01253',
                            'line-height': '120%',
                            'text-align': 'center',
                            'direction': 'ltr',
                            'letter-spacing': 0,
                        },
                        h2: {
                            color: '#555555',
                            'font-size': '23px',
                            'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            'link-color': '#E01253',
                            'line-height': '120%',
                            'text-align': 'center',
                            'direction': 'ltr',
                            'letter-spacing': 0,
                        },
                        h3: {
                            color: '#555555',
                            'font-size': '18px',
                            'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            'link-color': '#E01253',
                            'line-height': '120%',
                            'text-align': 'center',
                            'direction': 'ltr',
                            'letter-spacing': 0,
                        },
                    },
                    contentDefaults: {
                        text: {
                            html: "I'm a new Text block ready for your content.",
                            styles: {
                                color: "#555555",
                                linkColor: "#0068A5",
                                fontSize: '14px',
                                lineHeight: "120%",
                                fontFamily: "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            },
                        },
                    },
                    onSaveAsTemplate: (jsonFile) => {
                        /*Call content service api to get Responsible HTML for email */
                        let response = Promise.resolve(HTTPClient.post(endPoints.MARKETING_TEMPLATE.GET_BEE_MESSAGE_HTML, { page: JSON.parse(jsonFile) }));
                        response.then((response) => {
                            if (isValidParam(response) && isValidParam(response.data) && response.data !== '') {
                                let html = response.data;
                                setHtmlBody(html);
                                setEmailContent(jsonFile);
                                dispatch(updateSendMailTemplateDataInRedux({ email_message: html, content: jsonFile }))
                                dispatch(updateSendMailDataInRedux({ emailContent: jsonFile }));
                                setIsTemplateUpdated(true);
                            }
                        });
                    }
                }
                let templateObjData = getStringParam(content) !== "" ? JSON.parse(content) : getDefaultBeeEditorData();
                let templateObj = type == 'All' ? _templateObj : templateObjData;
                await beeEditor.getToken(
                    constants.BEE_EDITOR_CLIENT_ID,
                    constants.BEE_EDITOR_CLIENT_SECRET_ID
                )
                    .then((response) => {
                        setToken(response.access_token)
                    })
                    .then(() => {
                        beeEditor.start(beeConfig, templateObj)
                            .then(instance => {
                                setBee(instance)
                            })
                    }).catch((error) => console.error('error during iniziatialization --> ', error))
            }
        } catch (error) {
            console.error("Error in 'sendMail.js -> registerBeeEditor()':" + error);
        }
    }

    const minimizeCustomDrawer = (event) => {
        if (editorType === constants.EDITOR_BEE) {
            if (isValidParam(bee)) {
                bee.saveAsTemplate();
            }
        } else {
            handelMinimizeCustomDrawer();
        }

    }

    const handelMinimizeCustomDrawer = () => {
        if (sendEMail.maildata.fromEmail == "") {
            sendEMail.maildata.fromEmail = fromEmail;
        }
        if (sendEMail.maildata.toEmail == "") {
            sendEMail.maildata.toEmail = toEmail;
        }
        if (sendEMail.maildata.templateOptions.length == 0) {
            sendEMail.maildata.templateOptions = templateOptions;
        }
        let data = appContainer.customDrawerProps;
        let minimizeList = data.minimizeList;
        let type = data.actionType;
        minimizeList[type] = true
        let style = {};
        let labelName = appContainer.customDrawerProps.dialogName;
        let headerProps = {};
        let prevScrollPosition = detailViewFromRedux.scrollPosition;
        detailViewFromRedux.prevScrollPosition = prevScrollPosition;
        if (attachFiles.length == 0) {
            sendEMail.maildata.attachFiles = [];
        }
        dispatch(minimizeAppCustomDrawer(true, labelName, style, { isMinimize: true }, headerProps, minimizeList));
        dispatch(updateSendMailDataInRedux({ ...sendEMail.maildata, attachFiles }));
    }

    const processTemplateList = (arrResponse, selectedDataFetchType, _maildata) => {
        let callFrom = data.hasOwnProperty("stageName") ? data.stageName : "";
        let _data = getObjectParam(data);
        let type = getStringParam(_data.type);
        let templateObj = getArrayParam(arrResponse)[0];
        let agreementTemplateListOptions = [];
        if (_data.attachment_type !== 'Agreement') {
            restOptionsAfterSavingLibraryTemplates(templateObj, callFrom, type, selectedDataFetchType, _maildata);
        } else {
            let agreementTemplateList = getArrayParam(templateObj['Agreement Template']);
            if (agreementTemplateList !== null && agreementTemplateList.length > 0) {
                agreementTemplateList.forEach((template) => {
                    let id = getIntParam(template.id);
                    let name = getStringParam(template.name);
                    let emailStatus = getStringParam(template.email_status);
                    if (template.id > 0 && name !== '' && emailStatus === 'Active') {
                        agreementTemplateListOptions.push({ label: template.name, value: template.id });
                    }
                });
            }
            if (agreementTemplateListOptions !== null && agreementTemplateListOptions.length > 0) {
                const _tag = { ...tags, cc: "", bcc: "" }
                setTags({ ..._tag })
                setIsAgreementSelected(true)
                _maildata = { ..._maildata, ccEmail: _tag.cc, tags: _tag, bccEmail: _tag.bcc, isAgreementTemplateExists: true, isAgreementSelected: true, isTypeAgreement: true }
                dispatch(updateSendMailDataInRedux({ ..._maildata }));
            }

            if (_maildata.hasOwnProperty('isAgreementSelected') && _maildata.isAgreementSelected && _maildata.hasOwnProperty('isShowAgreementTemplate') && _maildata.isShowAgreementTemplate) {
                setTemplateOptions(agreementTemplateListOptions)
                _maildata = { ..._maildata, templateOptions: agreementTemplateListOptions, isLoading: false, agreementTemplateList: agreementTemplateListOptions }
                if (!_maildata.isAgreementSelected) {
                    setBtnIndex(1)
                    _maildata = { ..._maildata, btnIndex: 1 }
                }
                dispatch(updateSendMailDataInRedux({ ..._maildata }));
            }
        }
    }

    const restOptionsAfterSavingLibraryTemplates = (templateObj, callFrom, type, selectedDataFetchType, _maildata) => {
        let agreementTemplateListOptions = [];
        let tempCat = [];
        let MarketingTempCat = [];
        let recentTempCat = [];
        let _templateOptions = [];
        if (selectedDataFetchType === "Recent") {
            if (templateObj["Recent Template"] !== null && templateObj["Recent Template"] !== undefined && templateObj["Recent Template"].length > 0) {
                let recentTemplateList = getArrayParam(templateObj['Recent Template']);
                recentTemplateList.forEach((template) => {
                    let name = getStringParam(template.name);
                    let emailStatus = getStringParam(template.email_status);
                    if (template.id > 0 && name !== '' && emailStatus === 'Active') {
                        let dateStr = null;
                        if (isValidParam(template.execution_time)) {
                            let date = new Date(template.execution_time);
                            dateStr = date.toString();
                        }
                        recentTempCat.push({ label: template.name, title: template.name, execution_time: dateStr, value: template.id, editor_type: template.editor_type });
                    }
                });
                if (recentTempCat.length > 0) {
                    let tempArrTime = recentTempCat.filter((template) => { return template.execution_time !== null });
                    sortArrayObjectByDatePropertyDescending(tempArrTime, 'execution_time');
                    let tempArr = recentTempCat.filter((template) => { return template.execution_time === null });
                    recentTempCat = tempArrTime.concat(tempArr);
                }
            }
            _templateOptions = recentTempCat;
        } else if (selectedCatagory === "Sales Template" && selectedDataFetchType === 'All') {
            if (templateObj["My Sales Template"] !== undefined && templateObj["My Sales Template"] !== null && templateObj["My Sales Template"].length > 0) {
                let mySalesTemplateList = getArrayParam(templateObj['My Sales Template']);
                mySalesTemplateList.forEach((template) => {
                    let name = getStringParam(template.name);
                    let emailStatus = getStringParam(template.email_status);
                    if (template.id > 0 && name !== '' && emailStatus === 'Active') {
                        tempCat.push({ label: template.name, title: template.name, value: template.id, editor_type: template.editor_type });
                    }
                });
            }
            if (templateObj["Sales Template"] !== undefined && templateObj["Sales Template"] !== null && templateObj["Sales Template"].length > 0) {
                let salesTemplateList = getArrayParam(templateObj['Sales Template']);
                salesTemplateList.forEach((template) => {
                    let name = getStringParam(template.name);
                    let emailStatus = getStringParam(template.email_status);
                    if (template.id > 0 && name !== '' && emailStatus === 'Active') {
                        let isShowTemplate = true;
                        let exist = tempCat.filter((e) => { return e.value === template.id })
                        if (exist !== undefined && exist.length > 0) {
                            isShowTemplate = false;
                        }
                        if (isShowTemplate) {
                            tempCat.push({ label: template.name, title: template.name, value: template.id, editor_type: template.editor_type });
                        }
                    }
                });
            }
            _templateOptions = tempCat;
            let _template = { ...template, id: 0, name: '', subject: '', email_message: '' }
            setSelectedTemplate(0)
            setEditorType('froala')
            setTemplate({ ..._template })
            _maildata = { ..._maildata, selectedTemplate: 0, editorType: 'froala', template: _template }
            dispatch(updateSendMailDataInRedux({ ..._maildata }));
        } else if (selectedCatagory === "Marketing Template" && selectedDataFetchType === 'All') {
            if (templateObj["Marketing Template"] !== undefined && templateObj["Marketing Template"] !== null && templateObj["Marketing Template"].length > 0) {
                let marketingTemplateList = getArrayParam(templateObj['Marketing Template']);
                marketingTemplateList.forEach((template) => {
                    let id = getIntParam(template.id);
                    let name = getStringParam(template.name);
                    let emailStatus = getStringParam(template.email_status);
                    if (template.id > 0 && name !== '' && emailStatus === 'Active') {
                        MarketingTempCat.push({ label: template.name, title: template.name, value: template.id, editor_type: template.editor_type });
                    }
                });
                MarketingTempCat = MarketingTempCat.filter((template) => { return template.editor_type !== constants.EDITOR_NEW });
            }
            _templateOptions = MarketingTempCat;
            let _template = { ...template, id: 0, name: '', subject: '', email_message: '' }
            setSelectedTemplate(0)
            setEditorType('froala')
            setTemplate({ ..._template })
            _maildata = { ..._maildata, selectedTemplate: 0, editorType: 'froala', template: _template }
            dispatch(updateSendMailDataInRedux({ ..._maildata }));
        } else if (selectedCatagory === "Agreement Template" && selectedDataFetchType === 'All') {
            let agreementTemplateList = getArrayParam(templateObj['Agreement Template']);
            if (agreementTemplateList !== undefined && agreementTemplateList !== null && agreementTemplateList.length > 0) {
                agreementTemplateList.forEach((template) => {
                    let id = getIntParam(template.id);
                    let name = getStringParam(template.name);
                    let emailStatus = getStringParam(template.email_status);
                    if (template.id > 0 && name !== '' && emailStatus === 'Active') {
                        agreementTemplateListOptions.push({ label: template.name, value: template.id, editor_type: template.editor_type });
                    }
                });
            }

            if (agreementTemplateListOptions.length > 0) {
                let _template = { ...template, id: 0, name: '', subject: '', email_message: '' }
                setIsDisabledToEmail(true)
                setSelectedTemplate(0)
                setEditorType('froala')
                setTemplate({ ..._template })
                _maildata = { ..._maildata, isDisabledToEmail: true, selectedTemplate: 0, editorType: 'froala', template: _template }
                dispatch(updateSendMailDataInRedux({ ..._maildata }));
            } else {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.NO_ACTIVE_AGREEMENT_TEMPLATE_MSG, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            _templateOptions = agreementTemplateListOptions;
        }
        if (_templateOptions.length > 0 && selectedDataFetchType !== "Recent") {
            sortArrayObjectByProperty(_templateOptions, 'label');
        }
        if (selectedCatagory === "Sales Template" && selectedDataFetchType === 'All') {
            _templateOptions.unshift({ label: 'Blank Template', value: 6395 });
        }
        if (data.object === constants.AUDIT_ANSWERS_OBJECT && data.type === 'corrective action') {
            setIsAutoCompleteDisabled(true);
        }
        if (app.me.is_franchisor && data.object !== constants.AUDIT_ANSWERS_OBJECT && data.type !== 'corrective action') {
            _templateOptions = _templateOptions.filter((ele) => ele.title !== "Request Corrective Action");
        } else {
            _templateOptions = _templateOptions.filter((ele) => ele.title !== "Submit Corrective Action");
        }
        setTemplateOptions([..._templateOptions])
        _maildata = {
            isLoading: false, tempSalesTemplate: tempCat, agreementTemplateList: agreementTemplateListOptions,
            tempMarketingTemplate: MarketingTempCat, templateOptions: _templateOptions, recentTemplateList: recentTempCat
        }
        dispatch(updateSendMailDataInRedux({ ..._maildata }));

        if (callFrom === "" && type !== constants.EMAIL_OBJECT) {
            loadRecentTemplate(_templateOptions, _maildata);
        }
    }

    const actionOnHandleTemplateChange = (option) => {
        try {
            let _maildata = { ...sendEMail.maildata };
            if (isValidParam(option)) {
                setSelectedTemplate(option.value)
                let _data = getObjectParam(data);
                let param = {};
                param.from_email = fromEmail;
                if (_data.hasOwnProperty('batch_mail') && _data.batch_mail === true) {
                    param.template_id = option.value;
                    param.batch_mail = _data.batch_mail;
                } else if (_data.hasOwnProperty('batch_mail') && _data.batch_mail === false) {
                    let emailIdList = getArrayParam(_data.email_id_list);
                    param.template_id = option.value;
                    param.email_id = getStringParam(emailIdList[0].email);
                    param.parent_record_id = getStringParam(emailIdList[0].id).toString();
                    param.parent_table_id = OBJECT_TABLEID_MAP[_data.object];
                    param.type = _data.type;
                } else {
                    param.template_id = option.value;
                    param.email_id = _data.email;
                    param.parent_record_id = _data.id;
                    param.parent_table_id = OBJECT_TABLEID_MAP[_data.object];
                    param.type = _data.type;
                    param.request_status = getStringParam(_data.request_status);
                }
                if (_data.hasOwnProperty("opportunity_info") && _data.opportunity_info != undefined) {
                    param.opportunity_info = _data.opportunity_info;
                }
                if( _data?.is_share_partner ){
                    param.is_shared_partners = true;
                    }
                let promise = Promise.resolve(getTemplate(param));
                promise.then((response) => {
                    if (isValidParam(response)) { //debugger
                        setEmailSignatureTag(response.email_signature_tag)
                        setEmailSignature(response.email_signature)
                        let attachedFDDPdf = response?.attachment_info;
                        let temp = changeLog;
                        temp.fddAttachedPdfInfo = response?.attachment_info;
                        setChangeLog({ ...temp });
                        let _attachFiles = [];
                        if (isValidParam(attachedFDDPdf) && attachedFDDPdf != undefined) {
                            let attachObj = {};
                            attachObj.name = attachedFDDPdf.name;
                            attachObj.src = attachedFDDPdf.url;
                            _attachFiles.push(attachObj);
                            setAttachFiles([..._attachFiles]);
                            temp.fddPDFName = attachedFDDPdf.name;
                            setChangeLog({ ...temp })
                        } else {
                            let _attachFiles = attachFiles.filter((f) => {
                                return f.name != changeLog.fddPDFName;
                            })
                            setAttachFiles([..._attachFiles]);
                            let _data = getObjectParam(data);
                            let attachmentType = getStringParam(_data?.attachment_type);
                            if (attachmentType != null && attachmentType == 'File') {
                                // bypass
                            } else {
                                if (response.catagory == "Agreement Template") {
                                    let __attachFiles = [];
                                    setAttachFiles([...__attachFiles]);
                                }
                            }
                        }
                        _maildata = { ..._maildata, emailSignature: response.email_signature, emailSignatureTag: response.email_signature_tag, encodedParams: response?.encodedParams }
                        let mailMsg = response.mail_body;
                        let mailContent = response.content;
                        if (mailMsg?.indexOf(emailSignatureTag) > 0) {
                            mailMsg = mailMsg.split(emailSignatureTag).join(emailSignature);
                        }
                        if (option.label === 'Blank Template') {
                            mailMsg = mailMsg + "<br><br>" + emailSignature;
                        }
                        let subject = '';
                        if (isAgreementSelected) {
                            subject = 'Please sign ' + option.label;
                            let _template = { ...template, name: option.label }
                            setTemplate({ ..._template })
                            setBtnIndex(1)
                            const _signerCount = response?.agreement_info?.signer_count || 0;
                            const _signerDetails = response?.agreement_info?.signer_details || [];
                            const _signerTags = response?.agreement_info?.sign_tags || [];
                            setSignerCount(_signerCount);
                            setSignerDetails([..._signerDetails]);
                            setSignerTags([..._signerTags]);
                            const _index = 0;
                            const _signer = _signerDetails.find(f => f.is_primary);
                            const _selectedSigner = _signer ? { [_index]: _signer } : { ...selectedSigner };
                            setSelectedSigner({ ..._selectedSigner });
                            setAgreementType(response.agreement_type)
                            _maildata = {
                                ..._maildata, template: _template, btnIndex: 1,
                                signerCount: _signerCount, signerDetails: _signerDetails, signerTags: _signerTags, selectedSigner: _selectedSigner,
                                agreementType: response.agreement_type, attachFiles: _attachFiles
                            }
                            dispatch(updateSendMailDataInRedux({ ..._maildata }));
                        } else {
                            subject = response.subject;
                        }
                        let lastSelectedEditorType = editorType;
                        let selectedTemplateArr = templateOptions.filter(f => { return f.value === option.value });
                        let _editorType = selectedTemplateArr[0]?.editor_type;
                        if (option.label === 'Blank Template') {
                            _editorType = "froala";
                        }
                        if (editorType === undefined && data.attachment_type === 'Agreement') {
                            _editorType = "froala";
                        }
                        let _template = { ...template, subject: subject, email_message: mailMsg, id: option.value, name: option.label }
                        setTemplate({ ..._template })
                        setEditorType(_editorType)
                        setEmailContent(mailContent)
                        setSelectedTemplate(option.value)
                        _maildata = { ..._maildata, template: _template, editorType: _editorType, emailContent: mailContent, selectedTemplate: option.value }
                        dispatch(updateSendMailDataInRedux({ ..._maildata }));
                        if (_editorType && _editorType == constants.EDITOR_BEE) {
                            let templateObj = getStringParam(mailContent) !== "" ? JSON.parse(mailContent) : getDefaultBeeEditorData();
                            if (lastSelectedEditorType == constants.EDITOR_BEE) {
                                if (token) {
                                    bee.load(templateObj)
                                }
                            } else {
                                let type = 'New';
                                registerBeeEditor(type, templateObj, _maildata, _editorType);
                            }
                        }
                        if (response.alert) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response.alert, null);
                        }
                    }
                });
            } else {
                setSelectedTemplate(0)
                dispatch(updateSendMailDataInRedux({ selectedTemplate: 0 }));
            }
        } catch (error) {
            console.error("Error in 'sendMail.js -> actionOnHandleTemplateChange()':" + error);
        }
    }

    const addEmailSignatureBlankTemplate = () => {
        let param = {};
        if (data.hasOwnProperty('callFrom') && data.callFrom === constants.QUOTATION_OBJECT) {
            Object.assign(param, {
                ...(app.me.is_moneris_connected ? { callForMoneris: trackingFunctionCallCount > 0 ? false : true } : {})
            });
        }
        param.template_id = 0;
        let _data = getObjectParam(data);
        let object = getStringParam(_data.object);
        let _maildata = { ...sendEMail.maildata };
        let fromEmailCookie = app.me.id + '_' + app.me.projectId + '_from_email';
        let fromEmail = null;
        if (object == constants.CASES_OBJECT && app.me?.aurinko_cases_mailbox &&
            app.me?.aurinko_cases_mailbox != null && app.me.aurinko_cases_mailbox != undefined) {
            fromEmail = app.me.aurinko_cases_mailbox;
            setIsMailBoxConfigured(true);
            _maildata = { ..._maildata, isMailBoxConfigured: true }
        } else {
            fromEmail = cookies.get(fromEmailCookie) || app.me.email;
        }
        param.from_email = fromEmail;
        param.parent_table_id = OBJECT_TABLEID_MAP[_data.object];
        if (_data.hasOwnProperty('batch_mail') && _data.batch_mail === false && _data.attachment_type !== 'Agreement') {
            let emailIdList = getArrayParam(_data.email_id_list);
            if (emailIdList.length === 1) {
                param.email_id = getStringParam(emailIdList[0].email);
                param.parent_record_id = getStringParam(emailIdList[0].id).toString();
                if (data.object === constants.AUDIT_ANSWERS_OBJECT && data.type === "corrective action") {
                    param.template_id = 0;
                    param.record_id = param.parent_record_id;
                    param.call_from = "Audits";
                    delete param['email_id'];
                    delete param['parent_record_id'];
                    delete param['parent_table_id'];
                    delete param['type'];
                    delete param['template_id'];
                }
                let promise = Promise.resolve(getTemplate(param));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        let mailMsg = response.mail_body;
                        if (mailMsg.indexOf(response.email_signature_tag) > 0) {
                            mailMsg = mailMsg.split(response.email_signature_tag).join(response.email_signature);
                        }
                        else {
                            mailMsg = mailMsg + "<br><br>" + response.email_signature;
                        }
                        if (response.to_mail && !toEmail) {
                            setToEmail(response.to_mail);
                        }
                        let _template = { ...template, subject: response.subject, email_message: mailMsg, }
                        setTemplate({ ..._template })
                        setEditorType('froala')
                        setEditorLoader(false)
                        setEmailSignatureTag(response.email_signature_tag)
                        setEmailSignature(response.email_signature)
                        _maildata = {
                            ..._maildata, template: _template, editorType: 'froala', editorLoader: false,
                            emailSignatureTag: response.email_signature_tag, emailSignature: response.email_signature,
                            encodedParams: response?.encodedParams
                        }
                        dispatch(updateSendMailDataInRedux({ ..._maildata }));
                    }
                });
            }
        } else {
            if (!_data.hasOwnProperty('batch_mail')) {
                param.email_id = getStringParam(_data.email);
                param.parent_record_id = getStringParam(_data.id).toString();
                                if (_data.callFrom === constants.QUOTATION_OBJECT) {
                    param.table_id = _data.table_id;
                    param.record_id = _data.record_id;
                    param.request_status = getStringParam(_data.request_status);
                }
                if (data.object === constants.AUDIT_ANSWERS_OBJECT && data.type === "corrective action") {
                    param.template_id = 0;
                    param.record_id = param.parent_record_id;
                    param.call_from = "Audits";
                    delete param['email_id'];
                    delete param['parent_record_id'];
                    delete param['parent_table_id'];
                    delete param['type'];
                    delete param['template_id'];
                }
                let promise = Promise.resolve(getTemplate(param));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        let mailMsg = response.mail_body;
                        if (mailMsg.indexOf(response.email_signature_tag) > 0) {
                            mailMsg = mailMsg.split(response.email_signature_tag).join(response.email_signature);
                        }
                        else {
                            mailMsg = mailMsg + "<br><br>" + response.email_signature;
                        }
                        let _template = { ...template, subject: response.subject, email_message: mailMsg }
                        setTemplate({ ..._template })
                        setEditorType('froala')
                        setEditorLoader(false)
                        setEmailSignature(response.email_signature)
                        setEmailSignatureTag(response.email_signature_tag)
                        _maildata = {
                            ..._maildata, template: _template, editorType: 'froala', editorLoader: false,
                            emailSignatureTag: response.email_signature_tag, emailSignature: response.email_signature,
                            encodedParams: response?.encodedParams
                        }
                        dispatch(updateSendMailDataInRedux({ ..._maildata }));
                    }
                });
            } else {
                setEditorType('froala')
                setEditorLoader(false)
                _maildata = { ..._maildata, editorType: 'froala', editorLoader: false }
                dispatch(updateSendMailDataInRedux({ ..._maildata }));
            }
        }

        setTrackingFunctionCallCount((prevCount) => prevCount + 1);
     
    }

    const setHandleAttachFiles = (files) => {
        let _files = getArrayParam(files);
        if (_files.length > 0) {
            let attachfiles = [...attachFiles, ..._files]
            setAttachFiles([...attachfiles])
            let _maildata = { ...sendEMail.maildata, attachFiles: attachfiles }
            dispatch(updateSendMailDataInRedux({ ..._maildata }));
        }
    }

    const removeFile = (index) => {
        attachFiles.splice(index, 1);
        setAttachFiles([...attachFiles]);
        let _maildata = { ...sendEMail.maildata, attachFiles: attachFiles }
        dispatch(updateSendMailDataInRedux({ ..._maildata }));

    }

    const setHandleFromEmail = (value) => {
        setFromEmail(value)
        setFromEmailSignature(value)
    }

    const setFromEmailSignature = (value) => {
        let emailMsg = template.email_message;
        let oldEmailSignature = emailSignature;
        let signature = "";
        let params = null;
        value = getStringParam(value);
        if (value.length > 0) {
            params = {};
            params.signature_email_id = value;
            let promise = Promise.resolve(HTTPClient.get(endPoints.SEND_MAIL.DIRECT_EMAIL_SIGNATURE, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    signature = getStringParam(response.t_signature);
                    if (emailMsg !== '' && emailMsg.indexOf(oldEmailSignature) > 0) {
                        emailMsg = emailMsg.split(oldEmailSignature).join(signature);
                    }
                    let _template = { ...template, email_message: emailMsg }
                    setTemplate({ ..._template })
                    setEmailSignature(signature)
                    let _maildata = { template: _template, emailSignature: signature, fromEmail: value }
                    dispatch(updateSendMailDataInRedux({ ..._maildata }));

                }
            });
        }


    }

    const setTaskAction = (value, label, event) => {
        if (value === '-999') {
            value = label;
        }
        setTaskActionValue(value)
        setIsOpenTaskAction(false)
        let _maildata = { ...sendEMail.maildata, taskActionValue: value, isOpenTaskAction: false }
        dispatch(updateSendMailDataInRedux({ ..._maildata }));

    }

    const getVerifiedEmailList = () => {
        if (sendEMail.mounted) {
            let verifiedEmails = getArrayParam(sendEMail.verifiedEmails);
            let menuItems = [];
            let _data = {};
            _data.label = app.me.email;
            _data.value = app.me.email;
            menuItems.push(_data);
            verifiedEmails.map(email => {
                let data = {};
                data.label = email;
                data.value = email;
                let duplicateIndex = menuItems.findIndex((i) => i.value == email);
                if (duplicateIndex == -1) {
                    menuItems.push(data);
                }
            });
            return menuItems;
        }
    }

    const getLibrary = (editor, type) => {
        if (type != "image") {
            editor.events.disableBlur();
            disableEditorBlurEffect();
        }
        let data = {
            editor: editor,
            type: type,
            fullWidth: true,
            is_library: true,
            is_attachment: false,
        }
        data.maxWidth = 'md';
        data.callFrom = constants.SEND_EMAIL;
        dispatch(getAppDialog(true, constants.LIBRARY_DIALOG, getLocalizedStrings().message.COMMON.LIBRARY_DIALOG_TITLE, handleLibraryResponse, data, constants.SALES_EMAIL));
    }

    const handleLibraryResponse = (obj) => {
        obj.editor.events.enableBlur();
        if (obj.type === 'link' && obj.src !== '') {
            document.getElementsByName('href')[0].value = obj.src;
        } else if (obj.type === 'image' && obj.src !== '') {
            obj.editor.image.insert(obj.src)
        }
        dispatch(getAppDialog(false, constants.LIBRARY_DIALOG, null, null, null, null));
    }

    const getVideoInsertDialog = (editor, videoType) => {
        editor.events.disableBlur();
        let data = {
            editor: editor,
            contentStyle: { width: '80%', maxWidth: 'none' },
            videoType: videoType
        }
        let title = null;
        if (videoType === constants.VIDEO_TYPE_YOUTUBE) {
            title = getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE;
        } else if (videoType === constants.VIDEO_TYPE_WISTIA) {
            title = getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE;
        }
        dispatch(getAppDialog(true, constants.VIDEO_INSERT_DIALOG, title, (event) => handleVideoInsertResponse(event), data, null));
    }

    const handleVideoInsertResponse = (obj) => {
        obj.editor.events.focus();
        dispatch(getAppDialog(false, constants.VIDEO_INSERT_DIALOG, null, null, null, null));
        obj.editor.html.insert('<a href="' + obj.video_link + '" target="_blank" className="editor_video-selector"><img height="220" width="380" src="' + obj.thumbnail.url + '" title="' + obj.title + '" /></a>');
        obj.editor.events.focus();
        obj.editor.events.enableBlur();
    }

    const getSignature = (inst) => {
        let param = {};
        param.signature_email_id = fromEmail;
        let promise = Promise.resolve(getEmailSignature(param));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let signature = getStringParam(response.t_signature).trim();
                    inst.html.insert(signature);
                    setEmailSignature(signature)
                    let _maildata = { ...sendEMail.maildata, emailSignature: signature }
                    dispatch(updateSendMailDataInRedux({ ..._maildata }));

                }
            });
        }
    }

    const setCcBccEmailAreaFlag = useCallback((event) => {
        let val = event.currentTarget.id
        event.preventDefault();
        if (val === 'ccTab') {
            let parentRecordId = data?.id;
            if (!parentRecordId) {
                parentRecordId = data?.email_id_list && data?.email_id_list.length && data?.email_id_list[0]?.id ? data?.email_id_list[0]?.id : 0;
            }
            let params = {};
            params.parent_table_id = data?.opportunity_info?.tableId ? data?.opportunity_info?.tableId : OBJECT_TABLEID_MAP[data.object];
            params.child_table_id = OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT];
            params.parent_record_id = data?.opportunity_info?.tableId ? data?.opportunity_info?.recordId : parentRecordId;
            let promise = Promise.resolve(HTTPClient.get(endPoints.COMMON.CONTACT_EMAIL_ID_GET, params))
            promise.then((response) => {
                let emailList = response;
                emailList = emailList.filter((fil) => {
                    if (data.email == undefined) {
                        return fil != data?.email_id_list[0].email;
                    } else {
                        return fil != data?.email;
                    }
                })
                emailList = getArrayParam(emailList).join(",")
                let _tag = { ...tags, cc: emailList }
                setTags(_tag)
                let _maildata = { ccEmail: _tag.cc, tags: _tag, bccEmail: _tag.bcc };
                //dispatch(updateSendMailDataInRedux({ ..._maildata }));
            })
            setCCTab(true)

        } else {
            setBCCTab(true)
        }
        setCCBCCFocus(val)
        setAnchorEl(event.currentTarget)
    }, [reactTagsCc]
    )

    const openAttachmentPopover = (event) => {
        event.preventDefault();
        setIsOpenAttachmentPopover(true)
        setAnchorEl(event.currentTarget)
    }

    const closeAttachmentPopover = () => {
        setIsOpenAttachmentPopover(false)
    };

    const closeDrawer = () => {
        let _data = getObjectParam(data);
        let type = getStringParam(_data.type);
        let callFrom1 = getStringParam(callFrom);
        let minimizeList = customDrawer.minimizeList;
        let _actionType = customDrawer.actionType;
        if (minimizeList.hasOwnProperty(_actionType)) {
            minimizeList[_actionType] = false;
        }
        let prevScrollPosition = detailViewFromRedux.scrollPosition;
        detailViewFromRedux.prevScrollPosition = prevScrollPosition;
        if (callFrom1 === constants.NOTES && type === constants.EMAIL_OBJECT && isValidParam(_data.record)) {
            let subject = getStringParam(_data.record.t_subject);
            let dialogTitle = subject.length > 55 ? subject.substring(0, 55) + '...' : subject;
            if (getBooleanParam(appContainer.drawerProps.isOpenDrawer)) {
                dispatch(getAppDrawer(true, dialogTitle, constants.EMAIL_DETAILS, _data, callFrom1));
            } else if (getBooleanParam(appContainer.customDrawerProps.isOpen)) {
                _data.callFrom = callFrom1;
                dispatch(getAppCustomDrawer(true, dialogTitle, constants.EMAIL_DETAILS, null, _data, null, false));
            }
        } else {
            if (getBooleanParam(appContainer.drawerProps.isOpenDrawer) && !getBooleanParam(appContainer.customDrawerProps.isOpen)) {
                dispatch(getAppDrawer(false, null, null, null, null));
                dispatch(minimizeAppCustomDrawer(false, null, null, { isMinimize: false }, null, minimizeList));
                dispatch(resetSendMailDataInRedux());
            } else if (getBooleanParam(appContainer.customDrawerProps.isOpen)) {
                if (isAgreementSelected && btnIndex === 1) {
                    confirmCancelForAgreementTemplate();
                } else {
                    dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
                    dispatch(minimizeAppCustomDrawer(false, null, null, { isMinimize: false }, null, minimizeList));
                    dispatch(resetSendMailDataInRedux());
                }
            }
        }
    }

    const confirmCancelForAgreementTemplate = () => {
        let msg_1 = '<div>Your changes to the agreement, if any, will be lost.<br /></div>';
        let msg_2 = '<div>Press Ok to lose changes and start over.  Press Cancel to stay.</div>';
        let fnl = '<div>' + msg_1 + msg_2 + '</div>';
        sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, fnl, () => dispatch(getAppCustomDrawer(false, null, null, null, null, null)), null);
    }

    const openScheduleMailDialog = () => {
        let _mailUpdateData = { ...sendEMail.maildata };
        dispatch(updateSendMailDataInRedux({ ..._mailUpdateData }));
        setMoreActionOpen(false)
        setAnchorMoreActionEl(null)
        let data = { object: constants.SEND_EMAIL };
        dispatch(getAppDialog(true, constants.SF_SCHEDULE_DIALOG, getLocalizedStrings().label.MAIL.SCHEDULE_DIALOG_TITLE, (data) => handleScheduleEmail(data), data, null));
    }

    const handleScheduleEmail = (obj) => {
        setActionMode(null);
        let _scheduleDate = obj.date.format('YYYY-MM-DD HH:mm:ss')
        setScheduleDate(_scheduleDate)
        handelSendAction(_scheduleDate, 'schedule');
        dispatch(getAppDialog(false, constants.SF_SCHEDULE_DIALOG, null, null, null, null));
    }

    const handelSendAction = (_scheduleDate, from) => {
        if (!isSendDisabled) {
            setActionMode(from);
            setIsSendDisabled(true)
            if (editorType === constants.EDITOR_BEE) {
                if (isValidParam(bee)) {
                    bee.saveAsTemplate();
                }
            } else {
                _sendMail(_scheduleDate, from);
            }
        }
    }

    useEffect(() => {
        if (isTemplateUpdated) {
            setIsTemplateUpdated(false);
            if (editorType === constants.EDITOR_BEE) {
                if (actionMode != null) {
                    if (actionMode == 'send') {
                        _sendMail(null, actionMode);
                    } if (actionMode == 'schedule') {
                        _sendMail(scheduleDate, actionMode);
                    }
                } else {
                    handelMinimizeCustomDrawer();
                }
                setActionMode(null)
            }
        }
    }, [editorType, actionMode, isTemplateUpdated]);

    const handleNext = () => {
        let signers = getSignaturesArr();
        if (selectedCatagory === 'Agreement Template' && signers.length != signerTags.length) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.SELECT_ALL_SIGNERS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else {
            getMailBodyTemplate();
        }
    }

    const getMailBodyTemplate = () => {
        try {
            let _data = getObjectParam(data);
            let emailIdList = getArrayParam(_data.email_id_list);
            let email = null;
            let parentRecordId = null;
            if (emailIdList !== null && emailIdList !== "" && emailIdList !== undefined && emailIdList.length > 0) {
                email = getStringParam(emailIdList[0].email);
                parentRecordId = getStringParam(emailIdList[0].id).toString();
            } else {
                email = getStringParam(_data.email);
                parentRecordId = getStringParam(_data.id).toString();
            }
            let param = {};
            param.template_id = selectedTemplate;
            param.email_id = email;
            param.parent_record_id = parentRecordId;
            param.parent_table_id = OBJECT_TABLEID_MAP[data.object];
            param.type = data.type;
            param.from_email = fromEmail;
            param.isAgreementTemplate = true;
            param.agreement_type = agreementType;
            param.signer1_id = selectedSigner?.[0].id || 0;
            if (data.attachment_type == "Agreement") {
                param.call_from = data.attachment_type;
                param.attachment_id = data.attachmentId;
                param.copy_subject = true;
            }
            let promise = Promise.resolve(getTemplate(param));

            if (isValidParam(promise)) {
                promise.then(response => { //debugger
                    if (isValidParam(response)) {
                        let status = response.status;
                        if (status == 0) {
                            let signature = getStringParam(response.email_signature).trim();
                            let _template = { ...template, subject: response.subject }
                            setAgreementTemplateMsgbody(response.mail_body)
                            setTemplate({ ..._template })
                            setEmailSignature(signature)
                            setBtnIndex(2)
                            if (isValidParam(response?.fdd_template_id) && response?.fdd_template_id > 0) {
                                let temp = changeLog;
                                temp.fdd_template_id = response?.fdd_template_id;
                                setChangeLog({ ...temp });
                            }
                            let _maildata = {
                                ...sendEMail.maildata, agreementTemplateMsgbody: response.mail_body, agreementMailSubject: response.subject,
                                template: _template, emailSignature: signature, btnIndex: 2,
                            }

                            dispatch(updateSendMailDataInRedux({ ..._maildata }));

                        } else if (status == -1 && response.hasOwnProperty('validationMsg')) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response.validationMsg, null);
                        }
                    }
                });
            }

        } catch (error) {
            console.error("Error in 'sendMail.js -> getMailBodyTemplate()':" + error);
        }
    }

    const handleBack = () => {
        setBtnIndex(1)
        let _maildata = { ...sendEMail.maildata, btnIndex: 1, isTemplateListDisabled: false }
        dispatch(updateSendMailDataInRedux({ ..._maildata }));
    }

    const getActionButton = (props) => {
        try {
            return <div  {...getObjectParam(props)}>
                {isAgreementSelected && btnIndex === 1 ?
                    <div>
                        <Button
                            onClick={handleNext}
                            style={{ ...styles.primaryButton, height: 32 }}
                        >{getLocalizedStrings().label.COMMON.NEXT}</Button>
                    </div>
                    :
                    <div>
                        <div style={{ ...styles.emailSendBtn, backgroundColor: app.me.background }}>
                            <div
                                aria-disabled={sending}
                                style={{ float: 'left', margin: 5, textAlign: 'center', padding: '0px 15px', pointerEvents: sending && 'none' }} onClick={() => handelSendAction(null, 'send')}>
                                {!sending ? getLocalizedStrings().label.MAIL.SEND : getLocalizedStrings().label.MAIL.SENDING}
                            </div>
                          {!_data?.is_share_partner &&
                          <div style={{ float: 'right', borderLeft: '1px solid rgba(159, 46, 10, 0.6)' }} onClick={(event) => handleMoreActionOpen(event)}>
                          <i className="fa fa-angle-down" aria-hidden="true" style={{ margin: 5, fontSize: 20 }}></i>
                      </div>
                          }
                            

                        </div>
                        <Menu
                            open={moreActionOpen}
                            anchorEl={anchorMoreActionEl}
                            onClose={handleMoreActionClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                        >
                            <MenuItem value={'SEND LATER'} style={styles.popoverMenuItem} onClick={() => openScheduleMailDialog()}>{getLocalizedStrings().label.MAIL.SEND_LATER}</MenuItem>
                        </Menu>
                    </div>
                }
            </div>
        } catch (error) {
            console.error("Error in 'sendMail.js -> getActionButton()':" + error);
        }
    }

    const getTopActionButtons = () => {
        return <span style={{ float: "right", display: 'flex' }}>
            {getActionButton({ key: 'top' })}
            <div>
                {isAgreementSelected && sendEMail.maildata.btnIndex === 2 && !isBackHideen ?
                    <Button
                        onClick={handleBack}
                        style={{ ...styles.secondaryButton, height: 32 }}
                    > {getLocalizedStrings().label.COMMON.BACK}</Button>
                    :
                    <Button
                        onClick={closeDrawer}
                        style={{ ...styles.secondaryButton, height: 32, paddingLeft: '17px', paddingRight: '17px' }}
                    >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                }
            </div>
        </span>
    }

    const getSchedulerLink = (event) => {
        try {
            let slug = app.appointment_scheduler_slug;
            if (slug === null) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SCHEDULER.NO_SCHEDULER, null);
            } else {
                let link = constants.APPOINTMENT_SCHEDULER_URL + slug;
                event.html.insert(' <a target="_blank" href="' + link + '">Book an appointment</a> ');
            }
        } catch (error) {
            console.error("Error in 'sendMail.js -> getSchedulerLink()':" + error);
        }
    }

    const getAuditSurveyLink = (event) => {
        event.html.insert(' ${audit_survey_link} ');
    }

    const updateQuotationStatus = () => {
        let url = '';
        let param = null;
        try {
            param = {
                quoteId: data.record_id,
                status: data.request_status === 'Request Approval' ? 'Approval Requested' : 'Sent',
            };
            url = endPoints.QUOTATIONS.UPDATE_FIELDS;
            let promise = Promise.resolve(HTTPClient.get(url, param));
            promise.then((response) => {
                if (isValidParam(response.status !== -1)) {
                    dispatch(refreshQuotation(true));
                }
            });
        } catch (error) {
            console.error("Error in 'sendMail.js -> updateQuotationStatus()':" + error);
        }
    }

    const updatePurchaseOrderStatus = () => {
        let url = '';
        let param = null;
        try {
            param = {
                orderid: data.orderid,
                status: 'Sent',
                tableId: OBJECT_TABLEID_MAP[constants.PURCHASE_ORDER_OBJECT],
            };
            url = endPoints.PURCHASE_ORDER.UPDATE_FIELDS;
            let promise = Promise.resolve(HTTPClient.get(url, param));
            promise.then((response) => {
                if (isValidParam(response.status !== -1)) {
                    dispatch(refreshPurchaseOrder(true));
                }
            });
        } catch (error) {
            console.error("Error in 'sendMail.js -> updatePurchaseOrderStatus()':" + error);
        }
    }

    const populateEmailContent = () => {
        try {
            let params = {};
            params.id = data.hasOwnProperty("id") ? data.id : 0;
            let promise = Promise.resolve(HTTPClient.get(endPoints.WORKFLOWS.GET_DENIAL_INQUIRY_MAIL_CONTENT, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let templateSubject = getStringParam(response.subject);
                    let templateEmailMessage = getStringParam(response.content);
                    setEditorType('froala')
                    setTemplate({ ...template, subject: templateSubject, email_message: templateEmailMessage })
                    let _maildata = { ...template, subject: templateSubject, email_message: templateEmailMessage, editorType: 'froala' }
                    setEditorLoader(false)
                    dispatch(updateSendMailDataInRedux({ ..._maildata }));
                }
            });
        } catch (error) {
            console.error("Error in 'sendMail.js -> populateEmailContent()':" + error);
        }
    }

    const onClickUploadAttachment = (value, event) => {
        if (value === "Computer") {
            setIsOpenAttachmentPopover(false)
            let data = {};
            data.fullWidth = true;
            data.maxWidth = 'md';
            data.callFrom = constants.SEND_EMAIL;
            dispatch(getAppDialog(true, constants.SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG, getLocalizedStrings().message.ATTACHMENT.UPLOAD_FILE, (files) => setHandleAttachFiles(files), data, null));
        } else if (value === "Library") {
            let hasManageAssetsPermission = hasAccessPermission(constants.ASSETS_OBJECT, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_MANAGE_ASSETS);
            setIsOpenAttachmentPopover(false)
            if (hasManageAssetsPermission) {
                let data = {
                    type: "link",
                    is_library: false,
                    is_attachment: true,
                    show_external: false,
                }
                data.callFrom = constants.SEND_EMAIL;
                data.fullWidth = true;
                data.maxWidth = 'md';
                dispatch(getAppDialog(true, constants.LIBRARY_DIALOG, getLocalizedStrings().message.COMMON.LIBRARY_DIALOG_TITLE, (sendData) => {
                    let attachFile = {};
                    attachFile.name = sendData.name;
                    attachFile.src = sendData.src;
                    let files = [...attachFiles, attachFile];
                    setAttachFiles([...files]);

                    let _maildata = { ...sendEMail.maildata, attachFiles: files }
                    dispatch(updateSendMailDataInRedux({ ..._maildata }));

                    dispatch(getAppDialog(false, null, null, null, null, null));
                }, data, constants.SALES_EMAIL));
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
            }
        }
    }

    const handleManualController = (initControls) => {
        initControls.initialize();
        froalaRef = initControls.getEditor();
        setEditorInstance(initControls.getEditor());
    }

    const getEditor = (templateBodyMsg, FroalaConfig) => {
        let editor = null;
        if (editorType !== null) {
            if (editorType === constants.EDITOR_BEE) {
                {
                    token ? editor = <div id='bee-plugin-container' style={{ padding: '0px 10px', height: (window.innerHeight - 120) + 'px' }}></div> : editor = <div style={styles.loaderContainer}>
                        <div style={{ width: '100%', height: contentHeight }}>
                            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            } else {
                editor = <FroalaEditor onManualControllerReady={handleManualController} ref={(ref) => froalaRef = ref} tag='textarea' model={templateBodyMsg} config={FroalaConfig} style={{ marginLeft: '10px' }} onModelChange={(data) => handleModelChange(data)} />
            }
        }
        return editor
    }

    const handleCatagoryChange = (value) => {
        let _maildata = { ...sendEMail.maildata }
        let _value = getStringParam(value['catagory']);
        let _subValue = _value === "Agreement Template" ? "" : getStringParam(value['value']);
        setSelectedCatagory(_value);
        setSelectedSubCatagory(_subValue);
        if (_value === "Agreement Template") {
            const _tag = { ...tags, cc: "", bcc: "" }
            setTags({ ..._tag })
            setIsAgreementSelected(true)
            _maildata = { ..._maildata, ccEmail: _tag.cc, tags: _tag, bccEmail: _tag.bcc, selectedDataFetchType: 'Recent', selectedCatagory: _value, selectedSubCatagory: _subValue, isAgreementSelected: true }
            dispatch(updateSendMailDataInRedux({ ..._maildata }));
        } else {
            setAgreementType('');
            setIsAgreementSelected(false)
            _maildata = { ..._maildata, selectedDataFetchType: 'Recent', selectedCatagory: _value, selectedSubCatagory: _subValue, isAgreementSelected: false }
            dispatch(updateSendMailDataInRedux({ ..._maildata }));
        }
        setSelectedDataFetchType('Recent');
        geTemplateListByCategory(_value, _subValue, 'Recent', _maildata);
    }

    const handleDataFetchTypeChange = (value) => {
        let val = getStringParam(value);
        let _maildata = { ...sendEMail.maildata }
        _maildata = { ..._maildata, selectedDataFetchType: val }
        setSelectedDataFetchType(val)
        if (value === 'All') {
            setBtnIndex(0)
            _maildata = { ..._maildata, btnIndex: 0 }
        }
        dispatch(updateSendMailDataInRedux({ ..._maildata }));
        geTemplateListByCategory(selectedCatagory, selectedSubCatagory, value, _maildata);
    }

    const focusHandle = () => {
        setCCBCCFocus(null)
    }

    const onCheckStarEmail = (starredMailCheck) => {
        setStarredMail(starredMailCheck);
        let _maildata = { ...sendEMail.maildata, starredMail: starredMailCheck }
        dispatch(updateSendMailDataInRedux({ ..._maildata }));

    };

    const handleSignerChange = (index, signer) => {
        let hasValue = false;
        Object.keys(selectedSigner).map((key) => {
            if (!hasValue) {
                hasValue = selectedSigner[key].id == signer.id && key != index
            }
        });
        if (!hasValue) {
            let _selectedSigner = { ...selectedSigner, [index]: signer };
            setSelectedSigner({ ..._selectedSigner });
            dispatch(updateSendMailDataInRedux({ selectedSigner: _selectedSigner }));
        } else {
            let _selectedSigner = { ...selectedSigner };
            delete _selectedSigner[index];
            setSelectedSigner({ ..._selectedSigner });
            dispatch(updateSendMailDataInRedux({ selectedSigner: _selectedSigner }));
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.SELECT_DIFFERENT_SIGNER, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
    }

    const generateSigner = () => {
        let elements = [];
        const perRowCount = 5;
        if (signerTags) {
            const totalElements = signerTags.length;
            const numRows = Math.ceil(totalElements / perRowCount);
            let _signerDetails = signerDetails;
            sortArrayObjectByPropertyOrderType(_signerDetails, 'full_name', 'asc');
            for (let row = 0; row < numRows; row++) {
                const startIdx = row * perRowCount;
                const endIdx = Math.min((row + 1) * perRowCount, totalElements);
                const rowElements = [];
                const numElements = endIdx - startIdx;

                for (let i = startIdx; i < endIdx; i++) {
                    rowElements.push(
                        <div key={i} style={{ width: `${100 / numElements}%` }}>
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <Autocomplete
                                    key={"template" + "_" + uKey}
                                    id={"template"}
                                    name="template"
                                    options={_signerDetails}
                                    onChange={(_, newValue) => handleSignerChange(i, newValue)}
                                    getOptionLabel={(option) => option.full_name ? option.full_name + " (" + option.email + ") " : ''}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            title={`${option.full_name} (${option.email})`}
                                            {...props}
                                        >
                                            {option.full_name} ({option.email})
                                        </Box>
                                    )}
                                    className="sendMail"
                                    disabled={isAgreementSelected && sendEMail.maildata.btnIndex === 2}
                                    defaultValue={selectedSigner?.[i] || {}}
                                    disableClearable={true}
                                    renderInput={(params) => <TextField {...params} label={`Signer ${i + 1}`} placeholder={'Search to select Signer'} title={template.name} variant="outlined" margin="dense" size='small' className={"sf-fields-bg"} />}
                                />
                            </FormControl>
                        </div>
                    );
                }

                elements.push(
                    <div key={row} style={{ display: 'flex', gap: '15px', width: '100%' }}>
                        {rowElements}
                    </div>
                );
            }
        }
        return elements;
    }

    let isMinimize = getBooleanParam(data.isMinimize);
    if (isMinimize) {
        return <div />;
    }
    let personalize_fields = {
        "j_contacts.company": "Company",
        "j_contacts.first_name": "First name",
        "j_contacts.last_name": "Last name"
    }

    let type = getStringParam(data.type);
    let batchMailFlag = (data.hasOwnProperty("batch_mail") && data.batch_mail === true) ? true : false;
    let record = getStringParam(data.id);
    let taskId = getIntParam(data.taskId);
    record = record.toString();
    let recordArray = record.split(",");
    let recSize = recordArray.length;
    if (recSize > 1) {
        Froalaeditor.DefineIcon("personalize_dropdown", { NAME: "fa-duotone fa-gear", template: "font_awesome" });
        Froalaeditor.RegisterCommand('personalize_dropdown', {
            title: getLocalizedStrings().label.MAIL.PERSONALIZE,
            type: 'dropdown',
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            options: personalize_fields,
            callback: function (cmd, val) {
                this.html.insert(' ${' + val + '} ');
            },
            // Callback on refresh.
            refresh: function ($btn) {
            },
            // Callback on dropdown show.
            refreshOnShow: function ($btn, $dropdown) {
            }
        });
    }
    Froalaeditor.DefineIcon('signature', { NAME: "fa-regular fa-file-signature", template: "font_awesome" });
    Froalaeditor.RegisterCommand('signature', {
        title: getLocalizedStrings().label.MAIL.ADD_SIGNATURE,
        focus: false,
        undo: true,
        icon: 'signature',
        refreshAfterCallback: true,
        callback: function () {
            getSignature(this);
        }
    });
    Froalaeditor.DefineIcon('library', { NAME: 'image', template: "font_awesome" });
    Froalaeditor.RegisterCommand('library', {
        title: getLocalizedStrings().label.MAIL.INSERT_IMAGE,
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            getLibrary(this, 'image')
        }
    });
    Froalaeditor.DefineIcon("insertYouTubeVideo", {
        NAME: "fa-brands fa-youtube", template: "font_awesome"
    });
    Froalaeditor.RegisterCommand('insertYouTubeVideo', {
        title: getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            getVideoInsertDialog(this, constants.VIDEO_TYPE_YOUTUBE)
        }
    });
    Froalaeditor.DefineIcon("insertWistiaVideo", {
        SRC: "/asset/images/wistia.png",
        ALT: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
        template: "image",
    });
    Froalaeditor.RegisterCommand('insertWistiaVideo', {
        title: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            getVideoInsertDialog(this, constants.VIDEO_TYPE_WISTIA)
        }
    });
    Froalaeditor.DefineIcon('link_library', { NAME: 'file-archive-o', template: "font_awesome" });
    Froalaeditor.RegisterCommand('link_library', {
        title: getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_FROM_LIBRARY,
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            getLibrary(this, 'link')
        }
    });
    Froalaeditor.DefineIcon('coupon', { NAME: 'ticket', template: "font_awesome" });
    Froalaeditor.RegisterCommand('coupon', {
        title: getLocalizedStrings().label.COMMON.COUPONS,
        type: 'dropdown',
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        options: coupons,
        callback: function (cmd, val) {
            val = val.trim();
            this.html.insert(' ${coupon_code.' + val + '} ');
        },
        // Callback on refresh.
        refresh: function ($btn) {
        },
        // Callback on dropdown show.
        refreshOnShow: function ($btn, $dropdown) {
        }
    });
    Froalaeditor.DefineIcon('scheduler-editor', { NAME: 'fas fa-calendar-minus', template: "font_awesome" });
    Froalaeditor.RegisterCommand('scheduler-editor', {
        title: getLocalizedStrings().label.COMMON.SCHEDULER_LINK,
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            getSchedulerLink(this);
        }
    });
    let autoFocus = (actionType === 'REPLY' || actionType === 'REPLY-ALL' || actionType === 'FORWARD') ? true : false;
    let FroalaConfig = {
        key: constants.FROALA_EDITOR_KEY,
        placeholderText: getLocalizedStrings().label.MAIL.TYPE_HERE,
        height: document.body.clientHeight - 150,
        toolbarButtons: constants.SEND_MAIL_EDITOR_TOOLBAR_CONFIGURATION,
        toolbarButtonsMD: constants.SEND_MAIL_EDITOR_TOOLBAR_CONFIGURATION,
        toolbarButtonsSM: constants.SEND_MAIL_EDITOR_TOOLBAR_CONFIGURATION,
        toolbarButtonsXS: constants.SEND_MAIL_EDITOR_TOOLBAR_CONFIGURATION,
        colorsBackground: constants.EDITOR_TOOLBAR_BACKGROUND_COLOR_PALETTE,
        colorsText: constants.EDITOR_TOOLBAR_TEXT_COLOR_PALETTE,
        toolbarSticky: false,
        enter: FroalaEditor.ENTER_BR,
        iframe: true,
        htmlUntouched: true,
        attribution: false,
        inlineMode: false,
        linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
        linkInsertButtons: ['linkEdit', 'linkRemove', 'link_library'],
        imageEditButtons: ['imageAlign', 'imageRemove', 'imageLink', 'imageDisplay', 'imageAlt', 'imageSize', 'linkOpen', 'linkEdit', 'linkRemove'],
        tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign'],
        quickInsertButtons: [],
        imageUpload: false,
        imagePaste: false,
        fileUpload: false,
        autofocus: autoFocus,
        fontSize: constants.EDITOR_FONT_SIZE,
        fontFamily: {
            'Adobe Garamond Pro': 'Adobe Garamond Pro',
            'Arial,Helvetica,sans-serif': 'Arial',
            'Futura Std': 'Futura Std',
            'Georgia,serif': 'Georgia',
            'Impact,Charcoal,sans-serif': 'Impact',
            "RobotoSegoe": 'Roboto',
            'Tahoma,Geneva,sans-serif': 'Tahoma',
            "'Times New Roman',Times,serif": 'Times New Roman',
            'Verdana,Geneva,sans-serif': 'Verdana',
        },
        fontFamilySelection: true,
        events: {
            'froalaEditor.initialized': function (e, editor) {
                $('.fr-command').on('click', function () {
                    if ($(this).data('cmd') === 'insertLink') {
                        $('input[name="target"]').prop('checked', true);
                    }
                });
            }
        },
        events: {
            'initialized': function () {
                window.WEBSPELLCHECKER.init({
                    container: this.$iframe ? this.$iframe[0] : this.el
                });
            }
        },
        zIndex: 25000
    }
    let showBCCTab = true;
    let isBatchMail = false;
    let totalRecipient = 0;
    let object = getStringParam(data.object);
    let _catagoryList = catagoryList;
    if (data.hasOwnProperty('selectedEmailLength') && getIntParam(data.selectedEmailLength) > 1) {
        showBCCTab = false;
        isBatchMail = true;
        totalRecipient = getIntParam(optedInRecipients.length);
    }
    if (isBatchMail || object == constants.CASES_OBJECT || data?.is_share_partner) {
        _catagoryList = _catagoryList.filter(v => v.value !== "Agreement Template");
    }
    let templateBodyMsg = template.email_message;
    if (isAgreementSelected && btnIndex === 2) {
        templateBodyMsg = agreementTemplateMsgbody;
    }
    let popOverEmailOptions = [];
    if (sendEMail.mounted) {
        popOverEmailOptions = getVerifiedEmailList();
    }
    const centerAlign = {
        padding: 10,
        alignItems: 'center',
        display: 'flex',
    }
    let contentHeight = 250;
    let top = (contentHeight - 10) / 2;
    let mailDrawer = window.innerHeight - 100;
    let toEmailValue = isBatchMail ? totalRecipient + ' ' + getLocalizedStrings().label.MAIL.RECIPIENTS : toEmail;
    let uKey = Math.random();
    let _selectedTemplateObj = {};
    if (templateOptions) {
        let _selectedTemplate = sendEMail.maildata.selectedTemplate
        const _filteredSelectedTemplate = templateOptions.filter(v => v.value == _selectedTemplate);
        if (_filteredSelectedTemplate.length > 0) {
            _selectedTemplateObj = _filteredSelectedTemplate[0]
        }
        if (isBatchMail) {
            if (selectedCatagory === "Agreement Template") {
                handleCatagoryChange('Sales Template');
            }
        }
    }
    let _isAttachAFileLink = true;
    if (agreementType == "FDD") {
        _isAttachAFileLink = false;
    }
    if (selectedCatagory == "Agreement Template") {
        if (ccTab || bccTab) {
            setCCTab(false);
            setBCCTab(false);
        }
        if (tags.cc.trim() || tags.bcc.trim()) {
            const _tag = { ...tags, cc: "", bcc: "" }
            setTags({ ..._tag })
        }
    }
    let _data = getObjectParam(data);
    let _actionType = getStringParam(_data.actionType);
    return (
        <div>
            {data.actionType === undefined &&
                <div className='sf-call-drawer-toolbar'>
                    {<div className='sf-drawer-title'>
                        <Typography variant="h6" title={appContainer.customDrawerProps.dialogName} style={{ color: '#fff', paddingLeft: "24px", fontWeight: 400, fontSize: '24px', textAlign: 'left', height: '48px', lineHeight: '48px', width: '90%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            {appContainer.customDrawerProps.dialogName}
                        </Typography>
                        <span className='sf-call-drawer-button-right'>
                            <IconButton onClick={(event) => minimizeCustomDrawer(event)}
                                title={'Minimize'}>
                                <i className="fas fa-window-minimize" title="Minimize"
                                    style={{ fontWeight: 'normal', fontSize: '1.0em', cursor: 'pointer', color: '#fff', marginLeft: 5 }}
                                ></i>
                            </IconButton>
                            <IconButton
                                onClick={() => closeDrawer()}
                                title={getLocalizedStrings().label.COMMON.CLOSE}>
                                <Icon style={{ color: '#fff', marginTop: 2 }}> close</Icon>
                            </IconButton>
                        </span>
                    </div>}
                </div>}
            <div style={{ height: mailDrawer, overflowY: 'scroll' }}>
                <div style={{ width: '100%', padding: '10px' }} className='mail-container'>
                    <div style={{ ...styles.row }}>
                        <div style={type === constants.EMAIL_OBJECT ? data.actionType === undefined ? { ...styles.sf_6, } : { ...styles.sf_12, } : { ...styles.sf_12 }}>
                            <div style={{ ...styles.row, marginTop: '0px', marginBottom: '7px', height: '28px' }}>
                                <div style={{ width: 100, float: 'left' }}>
                                    <FormControlLabel
                                        control={
                                            <ToggleSwitch
                                                color="default"
                                                key="star_email"
                                                name="star_email"
                                                checked={starredMail}
                                                value={starredMail}
                                                onChange={(e) => onCheckStarEmail(e.target.checked)}
                                                defaultChecked={false}
                                            />
                                        }
                                        label="Star"
                                        labelposition="right"
                                        labelstyle={{
                                            color: "#717171",
                                            padding: "0px",
                                            width: "auto",
                                            fontWeight: "normal",
                                        }}
                                    />
                                </div>
                                <div style={{ width: '50%', marginTop: '5px', float: 'right' }}>
                                    {sending && <span style={{ color: '#717171', fontWeight: 700, float: 'right' }}>Sending Email(s)... </span>}
                                </div>
                                {data.actionType !== undefined &&
                                    <div style={{ width: '100%', float: 'right' }}>
                                        {getTopActionButtons()}
                                    </div>}
                            </div>
                            <div style={{ width: '100%', padding: '10px 15px 1px 0px' }}>
                                <PopOver
                                    id={'fromEmail'}
                                    key={'fromEmail'}
                                    name={'fromEmail'}
                                    btnType={'div'}
                                    buttonEndIcon={"keyboard_arrow_down"}
                                    buttonStyle={{ fontSize: '16px' }}
                                    containerStyle={{ lineHeight: 1 }}
                                    buttonLineOrigin={[46, 15]}
                                    buttonLabel={"From"}
                                    isShowIcon={isMailBoxConfigured ? false : true}
                                    options={popOverEmailOptions}
                                    onclickAction={(value) => setHandleFromEmail(value)}
                                    value={fromEmail}
                                    innerIcon={'left'}
                                    innerLabelStyle={{ padding: "5 5 2 5", fontSize: '15px' }}
                                    height={38}
                                    downArrowStyle={{ marginTop: '-2px', padding: ' 0 5 0 5px' }}
                                    disable={isMailBoxConfigured}
                                />
                            </div>
                        </div>
                        {type !== constants.EMAIL_OBJECT && <div style={{ ...styles.sf_7, zIndex: 0 }}>
                            <div style={{ ...styles.row, padding: 0, marginTop: '0px', marginBottom: '7px', height: '28px' }}>
                                <div style={{ float: 'left' }}>
                                    <span style={{ display: 'flex' }}>
                                        <div >
                                            <PopOver
                                                id={'Use'}
                                                key={'Use'}
                                                name={'Use'}
                                                btnType={'div'}
                                                optionType={'accordion'}
                                                nestKey={'subCatagory'}
                                                valueKey={'catagory'}
                                                buttonLabel={"Catagory"}
                                                buttonOuterLabelStyle={{ display: 'none' }}
                                                buttonEndIcon={"keyboard_arrow_down"}
                                                buttonStyle={{ fontSize: '16px' }}
                                                containerStyle={{ lineHeight: 1, marginTop: 9, marginLeft: '20px' }}
                                                buttonLineOrigin={[46, 15]}
                                                isShowIcon={true}
                                                options={_catagoryList}
                                                disable={isAutoCompleteDisabled}
                                                onclickAction={(value) => handleCatagoryChange(value)}
                                                value={selectedCatagory}
                                                nestValue={selectedSubCatagory}
                                                innerIcon={'left'}
                                                innerLabelStyle={{ padding: "-8 5 2 5", fontSize: '15px', height: '30px' }}
                                                height={30}
                                                divStyle={{ color: 'rgb(97, 97, 97)', border: '0px solid #c4c4c4', background: 'transparent' }}
                                                downArrowStyle={{ marginTop: '-2px', padding: ' 0 5 0 5px' }}
                                            />
                                        </div>
                                        <div >
                                            <PopOver
                                                id={'dataFetchType'}
                                                key={'dataFetchType'}
                                                name={'dataFetchType'}
                                                btnType={'div'}
                                                buttonOuterLabelStyle={{ lineHeight: '0.1', marginTop: '-2px', padding: '-1px', paddingLeft: 5, paddingRight: 4, fontSize: 11, fontWeight: 500, color: '#717171' }}
                                                buttonEndIcon={"keyboard_arrow_down"}
                                                buttonStyle={{ fontSize: '16px' }}
                                                containerStyle={{ lineHeight: 1, marginTop: 9, marginLeft: '20px' }}
                                                buttonLineOrigin={[46, 15]}
                                                disable={isAutoCompleteDisabled}
                                                isShowIcon={true}
                                                options={dataFetchTypeList}
                                                onclickAction={handleDataFetchTypeChange}
                                                value={selectedDataFetchType}
                                                innerIcon={'left'}
                                                innerLabelStyle={{ padding: "0 5 2 5", fontSize: '15px', height: '30px' }}
                                                height={30}
                                                divStyle={{ color: 'rgb(97, 97, 97)', border: '0px solid #c4c4c4', background: 'transparent' }}
                                                downArrowStyle={{ marginTop: '-2px', padding: ' 0 5 0 5px' }}
                                            />
                                        </div>
                                    </span>
                                </div>
                                <div style={{ width: 250, float: 'right' }}>
                                    {getTopActionButtons()}
                                </div>
                            </div>
                            {/* template */}
                            <div style={{ width: '100%' }}>
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <Autocomplete
                                        key={"template" + "_" + uKey}
                                        id={"template"}
                                        name="template"
                                        value={_selectedTemplateObj}
                                        options={templateOptions}
                                        onChange={(_, newValue) => actionOnHandleTemplateChange(newValue)}
                                        getOptionLabel={(option) => option.label ? option.label : ''}
                                        style={{ ...styles.sfFormFieldContainer, paddingLeft: 0 }}
                                        className="sendMail"
                                        disabled={isAutoCompleteDisabled}
                                        disableClearable={true}
                                        renderInput={(params) => <TextField {...params} placeholder={getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_TEMPLATE} title={template.name} variant="outlined" margin="dense" size='small' className={"sf-fields-bg"}
                                            onFocus={() => focusHandle('template')} />}
                                    />
                                </FormControl>
                            </div>
                        </div>}
                    </div>
                    {isAgreementSelected && <div style={{ padding: '0 10', gap: '15px' }}>
                        {generateSigner()}
                    </div>
                    }
                    {(!isAgreementSelected || _actionType === 'REPLY' || _actionType === 'REPLY-ALL' || _actionType === 'FORWARD') &&
                        <div style={{ ...styles.row }}>
                            {!isFromAttachment || isBatchMail ?
                                <div style={{ ...styles.sf_12 }}>
                                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                        <TextField
                                            variant="outlined"
                                            onChange={(event) => setFieldValue(event, 'toEmail')}
                                            value={toEmailValue}
                                            fullWidth={true}
                                            label={getLocalizedStrings().label.MAIL.TO}//'To'
                                            disabled={isDisabledToEmail}
                                            autoComplete="new-password"
                                            margin='dense'
                                            className={"sf-fields-bg"}
                                            size='small'
                                            onFocus={() => focusHandle('toEmail')} /></FormControl>
                                </div> :
                                <div style={{ ...styles.sf_12, paddingTop: '10px' }}>
                                    <div onKeyDown={(event) => inputRestriction(event)}>
                                        <TagAutocomplete
                                            selected={tags.to}
                                            label={'To'}
                                            rule='email'
                                            suggestions={toSuggestion}
                                            onDelete={removeTO}
                                            onAdd={addTO}
                                            allowNew={true}
                                            onInput={handleInputChangeForTo}
                                            minSearchLength={2}
                                            placeholderText=''
                                            labelText=""
                                            tagListLabelText=""
                                            ariaAddedText=""
                                        />
                                    </div>
                                </div>
                            }
                            {(!ccTab) && (!bccTab) && (!isBatchMail) &&
                                <div style={{ ...centerAlign, width: 80 }}>
                                    {(!ccTab) &&
                                        <span className="mail" style={{ cursor: 'pointer', fontSize: '14px', marginRight: '8px' }} onClick={setCcBccEmailAreaFlag}
                                            id="ccTab">Cc</span>
                                    }
                                    {(!bccTab) &&
                                        <span className="mail" style={{ cursor: 'pointer', fontSize: '14px' }} onClick={setCcBccEmailAreaFlag} id="bccTab">Bcc</span>
                                    }
                                </div>
                            }
                        </div>}
                    {(ccTab) &&
                        <div style={{ ...styles.row, paddingTop: '10px' }}>
                            <div style={{ width: '100%' }} onKeyDown={(event) => inputRestriction(event)}>
                                <div style={{ display: 'flex' }}>
                                    <TagAutocomplete
                                        id="cc_field"
                                        name='CC'
                                        ref={reactTagsCc}
                                        label={'Cc'}
                                        rule='email'
                                        selected={tags.cc}
                                        suggestions={ccSuggestions}
                                        onDelete={removeCC}
                                        onAdd={addCC}
                                        clearAllTitle={'Clear All'}
                                        isClearable={true}
                                        allowNew={true}
                                        onInput={handleInputChangeForCC}
                                        minSearchLength={2}
                                        placeholderText=''
                                        labelText=""
                                        tagListLabelText=""
                                        ariaAddedText=""
                                        inputFieldPosition="bottom"
                                        autocomplete
                                    />
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-24px' }}>
                                        <IconButton style={{ ...centerAlign, cursor: "pointer" }} onClick={() => removeCcBcc('CC')}> <i className="fa fa-times" title={getLocalizedStrings().label.COMMON.DELETE} ></i></IconButton>
                                        <i
                                            className="far fa-copy"
                                            aria-hidden="true"
                                            title={'Copy to clipboard'}
                                            onClick={() => handleCopyToClipboard('CC')}
                                            style={{ paddingLeft: '10px', cursor: 'pointer', fontSize: '17px' }}
                                        >
                                        </i>
                                    </div>
                                </div>
                            </div>
                            {(!bccTab && ccTab) &&
                                <div style={{ ...centerAlign, width: 40, height: 45, marginTop: '2px' }}>
                                    <span className="mail" style={{ cursor: 'pointer', fontSize: '14px' }} onClick={setCcBccEmailAreaFlag} id="bccTab">Bcc</span>
                                </div>
                            }
                        </div>
                    }
                    {showBCCTab && (bccTab) &&
                        <div style={{ ...styles.row, paddingTop: '5px' }}>
                            <div style={{ width: '100%' }} onkeyDown={(event) => inputRestriction(event)}>
                                <div style={{ display: 'flex' }}>
                                    <TagAutocomplete
                                        ref={reactTagsBcc}
                                        label={'Bcc'}
                                        rule='email'
                                        name='BCC'
                                        selected={tags.bcc}
                                        suggestions={suggestions}
                                        onDelete={removeBCC}
                                        onAdd={addBCC}
                                        clearAllTitle={'Clear All'}
                                        isClearable={true}
                                        allowNew={true}
                                        onInput={handleInputChangeForBcc}
                                        minSearchLength={2}
                                        isDisabled={batchMailFlag}
                                        placeholderText=''
                                        labelText=""
                                        tagListLabelText=""
                                        ariaAddedText=""
                                    />
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-24px' }}>
                                        <IconButton style={{ ...centerAlign, cursor: "pointer" }} onClick={() => removeCcBcc('BCC')}> <i className="fa fa-times" title={getLocalizedStrings().label.COMMON.DELETE} ></i></IconButton>
                                        <i
                                            className="far fa-copy"
                                            aria-hidden="true"
                                            title={'Copy to clipboard'}
                                            onClick={() => handleCopyToClipboard('BCC')}
                                            style={{ paddingLeft: '10px', cursor: 'pointer', fontSize: '17px' }}
                                        >
                                        </i>
                                    </div>
                                </div>
                            </div>
                            {(!ccTab && bccTab) && (!batchMailFlag) &&
                                <div style={{ ...centerAlign, width: 40, height: 45, marginTop: '2px' }}>
                                    <span className="mail" style={{ cursor: 'pointer', fontSize: '14px' }} onClick={setCcBccEmailAreaFlag} id="ccTab">Cc</span>
                                </div>
                            }
                        </div>
                    }
                    <div style={{ ...styles.row }}>
                        <div style={{ width: '100%' }}>
                            <FormControl style={{ width: '100%', marginBottom: '8px' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    onChange={(event) => setFieldValue(event, 'subject')}
                                    value={template['subject']}
                                    label={getLocalizedStrings().label.MAIL.SUBJECT}
                                    fullWidth={true}
                                    style={styles.sfFormFieldContainer}
                                    className={"sf-fields-bg"}
                                    autoComplete="new-password"
                                    margin='dense'
                                    size='small'
                                    onFocus={() => focusHandle('subject')}
                                />
                            </FormControl>
                        </div>
                        {taskId > 0 && <div style={{ width: '25px', display: 'none' }}>
                            <div key='taskActionDiv' onClick={(event) => handleTaskActionOpen(event)}
                                style={{ ...styles.sfFormFieldContainer, 'width': '100%', cursor: "pointer", fontSize: '14px', color: '#717171' }}>
                                <div>{getLocalizedStrings().label.MAIL.RESCHEDULE} </div>
                                <span className="ellip">{
                                    taskActionsIdValue.map(f => {
                                        if (f.id === taskActionValue) {
                                            return f.value;
                                        }
                                    })
                                }
                                    <i className="fa fa-angle-down" aria-hidden="true" style={{ paddingLeft: '7px' }}></i>
                                </span>
                                <div>
                                    <PopOver
                                        key='taskAction'
                                        name='taskAction'
                                        open={isOpenTaskAction}
                                        anchorEl={taskAnchor}
                                        onClose={handleTaskActionClose}
                                        value={taskActionValue}
                                        buttonLineOrigin={[22, 20]}
                                    >
                                        {
                                            taskActionsIdValue.map(obj => {
                                                return <MenuItem
                                                    style={styles.popoverMenuItem}
                                                    fullWidth={true}
                                                    labelstyle={{ color: '#479ccf', fontSize: '12px' }}
                                                    underlinestyle={{ display: 'none' }}
                                                    autowidth={"true"}
                                                    className='pomenuitem'
                                                    value={obj.id}
                                                    onClick={(event) => setTaskAction(event, obj.id, obj.value)}
                                                >
                                                    {obj.value}
                                                </MenuItem>
                                            })
                                        }
                                    </PopOver>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div style={{ ...styles.row, marginTop: '0px' }}>
                        <div style={{ width: '100%' }}>
                            {(data.attachment_type === "Agreement" && loadAgreementEditor) || editorLoader ? <div style={styles.loaderContainer}>
                                <div style={{ width: '100%', height: contentHeight }}>
                                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                                :
                                <div>
                                    {getEditor(templateBodyMsg, FroalaConfig)}
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{ ...styles.row, marginTop: 15, height: '28px' }}>
                        <div style={{ width: '100%', fontSize: '14px' }}>
                            {_isAttachAFileLink && <div>
                                <a herf="" onClick={openAttachmentPopover} style={{ cursor: 'pointer' }}><i className="fa fa-paperclip" aria-hidden="true"></i><span> {getLocalizedStrings().label.MAIL.ATTACH}</span></a>
                                <i className="fa fa-angle-down" aria-hidden="true" onClick={openAttachmentPopover} style={{ paddingLeft: '7px', cursor: 'pointer' }}></i>
                                <Menu
                                    open={isOpenAttachmentPopover}
                                    anchorEl={anchorEl}
                                    onClose={closeAttachmentPopover}
                                    style={{ width: '100%' }}
                                >
                                    <MenuItem onClick={() => onClickUploadAttachment('Computer')} value='Computer' style={styles.popoverMenuItem}>{getLocalizedStrings().label.MAIL.COMPUTER}</MenuItem>
                                    <MenuItem onClick={() => onClickUploadAttachment('Library')} value='Library' style={styles.popoverMenuItem}>{getLocalizedStrings().label.MAIL.LIBRARY}</MenuItem>
                                </Menu>
                            </div>
                            }
                        </div>
                        <div style={{ ...styles.sf_3, float: "right", marginRight: "-5px", width: 'auto' }}>
                            <span style={{ float: "right", display: 'flex' }}>
                                {getActionButton({ key: 'bottom' })}
                                <div >
                                    {isAgreementSelected && sendEMail.maildata.btnIndex === 2 && !isBackHideen ?
                                        <Button
                                            onClick={handleBack}
                                            style={{ ...styles.secondaryButton, height: 32 }}
                                        > {getLocalizedStrings().label.COMMON.BACK}</Button>
                                        :
                                        <Button
                                            onClick={closeDrawer}
                                            style={{ ...styles.secondaryButton, height: 32, paddingLeft: '17px', paddingRight: '17px' }}
                                        >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                                    }
                                </div>
                            </span>
                        </div>
                    </div>
                    <div style={{ ...styles.row, marginTop: '10px' }}>
                        {attachFiles.length > 0 && !attachLoader &&
                            <AttachmentFiles files={attachFiles} removeFile={(id) => removeFile(id)} isShowRemoveIcon={_isAttachAFileLink} />}
                        {attachLoader &&
                            <div style={{ width: '150px', height: '67px', padding: '0 60px' }}>
                                <SFLoader style={{ fontSize: '20px' }} />
                            </div>
                        }
                    </div>
                </div >
            </div>
        </div>
    );
}
const AttachmentFiles = ({ files, removeFile, isShowRemoveIcon }) => {
    const attachmentList = files.map((f, index) => (
        <div style={{ display: 'flex' }}>
            <i
                className="fa fa-paperclip"
                aria-hidden="true"
                style={{ paddingRight: "5px", cursor: "pointer" }}
            />
            <li key={f.name + index}>
                <span
                    style={{
                        float: 'left',
                        textAlign: 'left',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'block',
                        whiteSpace: 'nowrap',

                    }}
                    title={f.name}
                >
                    {f.name.length > 50 ? f.name.substring(0, 50) + "..." : f.name}
                </span>
                &nbsp;
                {f.name !== undefined && <i
                    className="fa fa-times"
                    aria-hidden="true"
                    style={{ paddingRight: '5px', cursor: 'pointer', display: isShowRemoveIcon ? 'inline' : 'none' }}
                    onClick={() => removeFile(index)}
                ></i>}
            </li>
        </div>
    ));
    if (attachmentList.length > 0) {
        return <ul>{attachmentList}</ul>;
    } else {
        return null;
    }
};
export default SendMail;