import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styles } from '../../../services/constants/styles.js';
import { getListViewSetupData, getSetupViewFormFields, refreshListView, saveListViewSetupData, setListViewMounted } from '../../../services/actions/listViewAcions.js';
import * as ParameterVerifier from '../../../services/helper/parameterVerifier.js';
import { getArrayParam, getBooleanParam, getObjectParam, getStringParam, isValidParam } from '../../../services/helper/parameterVerifier.js';
import concat from 'lodash/concat';
import Button from '@mui/material/Button';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction.js';
import { getMultilingualLabelName, makeTiteleCase } from '../../../services/helper/utils.js';
import { getActiveTab, getActiveTabInfo, TYPE_COLUMN_VIEW, TYPE_DETAIL_VIEW, TYPE_LIST_VIEW, updateActiveTab, updateTabInfo } from '../../../services/helper/sfTabManager.js';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual.js';
import DraggableSelect from '../../sfPlugins/draggableSelect.tsx';
import ShowCircularProgress from './circularProgress.js';
import { endPoints } from '../../../services/constants/endPoints.js';
import * as HTTPClient from '../../../services/helper/httpClient.js';
import moment from 'moment';
import { dateTimeFormat} from "../../../services/helper/utils";

import { getAppDrawer } from "../../../services/actions/appContainerActions.js";
import {
  constants,
} from '../../../services/constants/constants.js';
import { Data } from '@react-google-maps/api';


const maxLimit = 20;

const Compare = ({ object, closeDrawer, isDetailView, selectedRows }) => {
  //selectFieldCompare
  const [isSetupSelectorLoading, setIsSetupSelectorLoading] = useState(true);
  const [setupSelectedFieldOptions, setSetupSelectedFieldOptions] = useState([]);
  const [setupAvailableFieldOptions, setSetupAvailableFieldOptions] = useState([]);
  const [sortFieldName, setSortFieldName] = useState('');
  const [sortFieldLabel, setSortFieldLabel] = useState('');
  const [isNextBtnClick, setIsNextBtnClick] = useState(false);
  const appContainer = useSelector((state) => state.appContainer);
  const [mounted, setMounted] = useState(false);
  const [records, setRecords] = useState([]);
  const [queryId, setQueryId] = useState('');

  const [childObjectSelectedField, setChildObjectSelectedField] = useState(null);
  const app = useSelector((state) => state.app);


  const getListViewInstanceFromRedux = (state, isDetailView) => {
    let tabInfo = getActiveTabInfo();
    let isReport = getBooleanParam(tabInfo.isReport);
    if (getBooleanParam(isDetailView)) {
      return state.childListView;
    } else if (getBooleanParam(isReport)) {
      return state.otherListView;
    } else {
      return state.listView;
    }
  }

  const listView = useSelector((state) => getListViewInstanceFromRedux(state, isDetailView));

  const dispatch = useDispatch();

  useEffect(() => {
    getListViewSetupDataParam();
  }, []);

  const onCallCloseEvent = () => {
    //closeEvent();

  }
  function getListViewSetupDataParam() {
    try {
      let _callFrom = appContainer.drawerProps.data.callFrom
      let params = {};

      params.query_name = _callFrom == 'Compare' ? 'Compare Partners' : 'Two Min Drill';
      params.query_type = 'all';
      params.parent_object = 'Partners';
      let tempObject = 'partners';

      const promise = Promise.resolve(getListViewSetupData(tempObject, params));
      if (ParameterVerifier.isValidParam(promise)) {
        promise.then((response) => processSetupData(response));
      }
    } catch (e) {
      console.error("Error in 'listViewSetup.js -> getListViewSetupData()':" + e);
    }
  }

  function processSetupData(data) {
    try {
      let availableFields = data.available_fields;
      let selectedFields = data.selected_fields;
      let activeTab = getObjectParam(getActiveTab());

      let tempSelectedFields = [];
      let isSortFieldSelected = false;
      selectedFields.forEach((field, index) => {
        field.value = field.name;
        field.label = getMultilingualLabelName(object, field.label);
        field.title = getMultilingualLabelName(object, field.label); //To show the title of selected fields //Sourav 26-02-2018

        if (data.sort_field_name === field.name) {
          isSortFieldSelected = true;
        }
        tempSelectedFields.push(field);

      });

      selectedFields = tempSelectedFields;

      availableFields.forEach((field) => {
        field.value = field.name;
        field.label = getMultilingualLabelName(object, field.label);
      });



      availableFields = concat(availableFields, selectedFields);

      let sortFieldName = data.sort_field_name;
      if (!sortFieldName || sortFieldName === '') {
        sortFieldName = 't_status';
      }
      let sortField = availableFields.find(function (f, index) {
        return f.name === sortFieldName
      });
      let sortFieldLabel = isValidParam(sortField) ? sortField.label : '';
      let tempObject = object;

      sortFieldLabel = getMultilingualLabelName(tempObject, sortFieldLabel);

      let childObjectSelectedField = {};
      let childObjects = getArrayParam(data.child_objects);

      setChildObjectSelectedField(childObjectSelectedField);
      setSetupAvailableFieldOptions(availableFields);
      setSetupSelectedFieldOptions(selectedFields);
      setIsSetupSelectorLoading(false);
      setQueryId(data.query_id)
    } catch (e) {
      console.error("Error in 'listViewSetup.js -> processSetupData()':" + e);
    }
  }

  function updateProperties(arr1, arr2) {
    return arr2.map(obj => {
      const updatedObj = { ...obj };
        arr1.forEach(key => {
        // If the key exists in the object, update its value
        if (updatedObj.hasOwnProperty(key)) {
          updatedObj[key] = moment(getStringParam(updatedObj[key])).format(dateTimeFormat[app.me.date_format]);
        }
      });
  
      return updatedObj;
    });
  }

  function getRecordsDetails() {
    let _selectedRows = getArrayParam(appContainer.drawerProps.data.selectedRows);
    let _selectedRowsIds = [];
    if (_selectedRows.length > 0) {
      _selectedRows.forEach((row) => {
        _selectedRowsIds.push(row.id.toString())
      })
    }
    let fieldName = [];
    if (setupSelectedFieldOptions.length > 0) {
      setupSelectedFieldOptions.forEach((field) => {
        fieldName.push(field.name)
      })
    }

    let _params = {
      ids: _selectedRowsIds,
      field_name: fieldName
    }

    const response = Promise.resolve(HTTPClient.get(endPoints.PARTNERS.COMPARE, _params));
    response.then((response) => {
      let _records = getArrayParam(response.records);
      let valArr = []
       setupSelectedFieldOptions.forEach((m)=>{
        if(m.type == "TIMESTAMP"){
         valArr.push(m.value)
            
        }
      })
    
      const updatedArr2 = updateProperties(valArr, _records);
       if(updatedArr2.length > 0){
        setRecords([...updatedArr2]);  
       }else{
      setRecords([..._records]);  

       }
       setMounted(true);
      
    })
  }

  function handleNext() {
    try {
      let label = 'Partners'
      let objectName = 'partners';
      let queryName;
      let queryType;
      let tempPageSize = 0;
      if (setupSelectedFieldOptions && setupSelectedFieldOptions.length === 0) {
        dispatch(showCustomSnackBar(getLocalizedStrings().message.LIST_VIEW.NOT_ADDED + ' ' + label + ' ' + getLocalizedStrings().message.LIST_VIEW.FIELDS_TO_VIEW, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
      } else if (setupSelectedFieldOptions && setupSelectedFieldOptions.length === 1) {
        let snackbarStyle = { ...styles.snackBarStyleLongMsg, left: styles.snackBarStyleLongMsg.left + 70 };
        dispatch(showCustomSnackBar(getLocalizedStrings().message.LIST_VIEW.SETUP_MINIMUM_FIELD_ALERT, styles.snackbarBodyStyleError, snackbarStyle));
      } else if (setupSelectedFieldOptions && setupSelectedFieldOptions.length > 1) {
        queryName = listView.data.query_name;
        queryType = 'all';
        tempPageSize = listView.data.page_size;
        let _callFrom = appContainer.drawerProps.data.callFrom

        let params = {};
        params.query_name = _callFrom == 'Compare' ? 'Compare Partners' : 'Two Min Drill';
        params.query_type = 'all';
        params.query_id = queryId;
        params.sort_field_name = sortFieldName;
        params.field_width_info = [...setupSelectedFieldOptions];
  
        let tempObject = 'partners';
  
        let promise = Promise.resolve(saveListViewSetupData(tempObject, params));
        if (isValidParam(promise)) {
  
          promise.then((response) => {
            if (isValidParam(response)) {
              if (response.status === 0) {
                setIsNextBtnClick(true)
                getRecordsDetails()
              } else {
                dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
              }
            } else {
              dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
          });
  
        }
      }
    } catch (e) {
      console.error("Error in 'listViewSetup.js -> setupSave()':" + e);
    }
  }

  const getTotalSelectedFieldCount = () => {
    let totalCount = 0;
    try {
      totalCount = getArrayParam(setupSelectedFieldOptions).length;
      let tempChildObjectSelectedField = getObjectParam(childObjectSelectedField);
      Object.values(tempChildObjectSelectedField).forEach(element => {
        totalCount += getArrayParam(element).length;
      });

    } catch (error) {
      console.error("Error in 'listViewSetup.js -> getTotalSelectedFieldCount()':" + error);
    }
    return totalCount;
  }

  const handleSelectChange = (options) => {
    let totalSelectedFieldCount = getTotalSelectedFieldCount();
    let isValid = false;
    let tempSortFieldName = sortFieldName;
    let tempSortFieldLabel = sortFieldLabel;
    try {
      let selectedLength = getArrayParam(setupSelectedFieldOptions).length;
      let isColumnViewValid = true;
      if (isValidParam(options) && selectedLength > options.length) {
        isValid = true;
      } else if (isValidParam(options) && totalSelectedFieldCount < maxLimit) {
        isValid = true;
      } else if (totalSelectedFieldCount > maxLimit) {
        isValid = false;
        isColumnViewValid = false;
      }

      if (isValid) {
        let tempOptions = options.filter(f => {
          return f.name === tempSortFieldName
        });
        if (tempOptions.length === 0 && options.length > 0) {
          tempSortFieldName = options[0].name;
          tempSortFieldLabel = options[0].label;
        }
        let arrSlectedObj = options.map((obj, index) => {
          let tempObj = {
            name: obj.name,
            label: getMultilingualLabelName(object, obj.label),
            value: obj.value,
            width: obj.width,
            title: obj.label, //To show the title of selected fields //Sourav 26-02-2018
          };

          return tempObj;
        });

        setSetupSelectedFieldOptions(arrSlectedObj);
        setSortFieldLabel(tempSortFieldLabel);
        setSortFieldName(tempSortFieldName);
      } else {
        if (!isColumnViewValid) {
          dispatch(showCustomSnackBar(getLocalizedStrings().message.LIST_VIEW.SELECT_MAXIMUM_FIELD_FOR_COLUMN_SETUP, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else {
          dispatch(showCustomSnackBar(getLocalizedStrings().message.LIST_VIEW.SELECT_MAXIMUM_FIELD_FOR_PARTNERS_COMPARE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
      }
    } catch (e) {
      console.error("Error in 'listViewSetup.js -> handleSelectChange()':" + e);
    }
  }

  const exportToExcel = () => {
    // Create an HTML table
    let tableHTML = `
          <table border="1">
            <thead>
              <tr>
                <th>Field Name</th>
                ${records.map((record) => `<th>${record.company}</th>`).join("")}
              </tr>
            </thead>
            <tbody>
              ${setupSelectedFieldOptions
        .map(
          (field) =>
            `<tr>
                      <td>${field.label}</td>
                      ${records
              .map((record) => `<td>${record[field.name] || "N/A"}</td>`)
              .join("")}
                    </tr>`
        )
        .join("")}
            </tbody>
          </table>
        `;

    // Encode the table in a data URI
    const blob = new Blob([tableHTML], { type: "application/vnd.ms-excel" });
    const url = URL.createObjectURL(blob);

    // Create a download link and trigger download
    let _callFrom = appContainer.drawerProps.data.callFrom

    const link = document.createElement("a");
    link.href = url;
    link.download = _callFrom == "Compare" ? 'compare_partners.xls' : 'partner_2_min_drill.xls';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onSortEnd = (selectedOptions) => {
    setSetupSelectedFieldOptions(selectedOptions);
  }
  let contentHeight = 280;
  let top = (contentHeight - 40) / 2;
  let _callFrom = appContainer.drawerProps.data.callFrom

  return (
    <div key="listview-setup-container" style={{ ...styles.customizedFieldsContainerDiv, border: '0px solid green', display: 'flex', flexDirection: 'column' }}>
      {isNextBtnClick == false ?
        <>
            <div style={styles.customizedFieldsHeaderDiv}>
            <span style={styles.customizedFieldsHeader}>
              {_callFrom== 'Compare' ? "Select Partners fields to compare" : "Select Partners fields for 2 Min Drill"}
            </span>
          </div>
        
          <div style={{ ...styles.customizedFieldsNotify, fontWeight: 'bold', paddingTop: '15px' }}>
            {getLocalizedStrings().label.LIST_VIEW.ADD_AND_REORDER} {'Partners'} {getLocalizedStrings().label.LIST_VIEW.FIELDS}: {getLocalizedStrings().label.LIST_VIEW.CLICK_TO_ADD_MORE_FIELDS}
          </div>
          <div style={{ position: "relative", zIndex: 2 }}>
            {setupAvailableFieldOptions.length > 0 && <DraggableSelect
              key={'select-draggable-' + object}
              id={'select-draggable-' + object}
              isClearable
              onSortEnd={(selectedOptions) => onSortEnd(selectedOptions)}
              closeMenuOnSelect={false}
              disabled={false}
              value={getArrayParam(setupSelectedFieldOptions)}
              selectLimit={maxLimit}
              limitMessage={getLocalizedStrings().message.LIST_VIEW.SELECT_MAXIMUM_FIELD_FOR_PARTNERS_COMPARE}
              //placeholder={getLocalizedStrings().label.LIST_VIEW.SELECT_FIELDS}
              options={getArrayParam(setupAvailableFieldOptions)}
              onChange={(options) => handleSelectChange(options)}
              clearAllText={getLocalizedStrings().label.COMMON.CLEAR_ALL}
              className='my-cool-select-top'
            />}
            {setupAvailableFieldOptions.length === 0 &&
              <div style={{ border: '1px solid #aeaeae', borderRadius: ' 4px', padding: '10px', paddingLeft: '45%', }}>
                <ShowCircularProgress size={25} style={{ marginTop: '0px', width: '100%' }} />
              </div>
            }
          </div>

          <div style={{ textAlign: 'right', marginLeft: '-14px', marginTop: '20px' }}>
            <Button
              onClick={handleNext}
              style={{ ...styles.primaryButton, marginRight: '0px' }}
            >{getLocalizedStrings().label.COMMON.NEXT}</Button>
            <Button
              key='close'
              onClick={() => closeDrawer()}
              style={{ ...styles.secondaryButton, marginLeft: '6px', marginRight: '0px', verticalAlign: 'top' }}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
          </div>
          <div style={{marginLeft: '20px' , width : '60%'}}> 
            <ul style={{ listStyleType: 'none', padding: 0 }}>
               <li>
                   <span style={{color : 'red' , fontSize: 14 }}> CONFIDENTIAL: DO NOT SHARE WITH CLIENTELE </span>
                    </li>
                <li>
                    <span style={{color : 'red', fontSize: 14 }}>   All information in the report has been provided by the Franchisor.  Although every effort has been made to provide complete and accurate information, FranNet makes no warranties, express or implied, or representations as to the accuracy of the content. 
              </span>
              </li>
            </ul>
            
            </div>
        </>
        : isNextBtnClick == true && mounted ?
          <>
            <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", marginTop: '-20px', marginBottom: '-20px' }}>
              <table
                style={{ width: "100%", borderCollapse: "collapse" }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        //backgroundColor: app.me.background,
                        textAlign: "left",
                        width: "200px",
                      }}
                    ></th>
                    {records.map((record) => (
                      <th
                        key={record.id}
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          backgroundColor: app.me.background,
                          textAlign: "center",
                          fontWeight: 550,
                          color: '#ffffff',
                          minWidth : '170px'
                        }}
                      >
                        {record.company}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {setupSelectedFieldOptions.map((field) => (
                    <tr key={field.id}>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "15px",
                          textAlign: "left",
                          backgroundColor: '#e0e0e021',
                          fontSize: 14,
                          fontWeight: 550,
                          color: '#000000c2'
                        }}
                      >
                        {field.label}
                      </td>
                      {records.map((record) => (
                        <td
                          key={record.id}
                          style={{
                            border: "1px solid #ddd",
                            padding: "15px",
                            textAlign: "left",
                            fontSize: 14,
                            color: '#000000c2'

                          }}
                        >
                          {record[field.name] || "N/A"}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div style={{ float: 'left', marginTop: '10px' , marginBottom :'10px', marginLeft: '20px'}}>
              <Button
                onClick={() => { exportToExcel(); }}
                  style={{ ...styles.primaryButton, marginRight: '0px' }}
                  title= 'Download'
                  >Download</Button>
        
            </div>
            <div style={{marginLeft: '20px', width : '60%'}}> 
            <ul style={{ listStyleType: 'none', padding: 0 }}>
               <li>
                   <span style={{color : 'red' , fontSize: 14 }}> CONFIDENTIAL: DO NOT SHARE WITH CLIENTELE </span>
                    </li>
                <li>
                    <span style={{color : 'red', fontSize: 14 }}>   All information in the report has been provided by the Franchisor.  Although every effort has been made to provide complete and accurate information, FranNet makes no warranties, express or implied, or representations as to the accuracy of the content. 
              </span>
              </li>
            </ul>
            
            </div>
          </>
          :
          <div style={{ width: '100%', height: contentHeight }}>
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
              <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
              </div>
            </div>
          </div>
      }

    </div>
  )

}
export default Compare;