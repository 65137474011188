import { Calculate } from "@mui/icons-material"

 const label = {
    MODULE: {
        Sales: 'Sales',
        Marketing: 'Marketing',
        Service: 'Service',
        Operations: 'Operations',
        HUB: 'HUB',
        Management:'Management',
    },
    MODULE_ITEMS: {
        Reports: 'Reports',
        Dashboard: 'Dashboard',
        'Marketing Templates': 'Marketing Email Templates',
        'Broadcast Campaigns': 'Broadcast Campaigns',
        'Automation Designer': 'Automation Designer',
        'Email Marketing': 'Email Marketing',
        'Lead Capture': 'Lead Capture',
        'Landing Pages': 'Landing Pages',
        'Web Forms': 'Web Forms',
        'Automated Campaigns': 'Automated Campaigns',
        'Social Marketing': 'Social Marketing',
        'Website Tracking': 'Website Tracking',
        'Website Analytics Setup': 'Website Analytics Setup',
        'Ab Campaigns': 'Ab Campaigns',
        'Opportunities': 'Opportunities',
        'Marketing Reports': 'Reports',
        'Touch Campaigns': 'Touch Campaigns',
        'A/B Campaigns': 'A/B Campaigns',
        'Email Blast': 'Email Blast',
        'Recent Campaigns': 'Recent Campaigns',
        Activities: 'Activities',
        Conversion: 'Conversion',
        Campaigns: 'Campaigns',
        Custom: 'Custom',
        Sales: 'Sales',
        Marketing: 'Marketing',
        Offers: 'Offers',
        Coupons: 'Coupons',
        'Coupons Claim Report': 'Report', // Report
        ASSETS_DISTRIBUTION: 'Asset Distribution',
        USEFUL_LINKS: 'Useful Links',
        Royalties: 'Royalties - Legacy',
        COMPLIANCE_REPORT: "Compliance",
        AUDIT_SUMMARY_REPORT: 'Audit Summary Report',
        ROYALTIES_V2: 'Royalties',
    },
    COMMON: {
        SEND_THE_REST: 'Send the Rest using the Winner',
        AUDIT_FILTER:'Answers with Corrective Actions',
        SEND: 'Send',
        FILTER:'Filter',
        STAGES: 'Stages',
        FRENCHISEE_COMPANY_NAME:'Select a franchisee company name',
        BRANCH_COMPANY_NAME:'Select a branch name',
        SELECT_AN_ACCOUNT:'Select a Unit',
        ADD_TO_PLAN:'Add to Plan',
        LOGIN_TO_BASE:'Login to Base',
        PLUS_BRANCH: 'Branch',
        PLUS_UNIT: 'Unit',
        ACTIONS:'Actions',
        WINNER_BY : 'Select Winner By',
        PUSH_NO_CONFIRM: 'Push without confirmation',
        ADD: 'Add',
        GO:'Go',
        ADD_NEW_APPOINTMENT: 'Add a New',
        SHOW: 'Show',
        HIDE: 'Hide',
        REFRESH: 'Refresh',
        EDIT: 'Edit',
        SAVE: 'Save',
        SAVE_SUBMIT: 'Save and Submit',
        TEST: 'Test',
        CANCEL: 'Cancel',
        ENTER_TEXT: 'Enter Text',
        APPLY: 'Apply',
        AND: 'and',
        EMPTY: 'Empty',
        EXPAND: 'Expand',
        DELETE: 'Delete',
        REQUIRED: ' (required)',
        AUTO_SEARCH_TEXT: 'Same text, different values',
        NOTE: 'Note',
        SEARCH: 'Search',
        OK: 'Ok',
        SEND_MAIL: 'Send email',
        ASSIGN: 'Assign',
        SELECT_A: 'Select a ',
        SELECT:'Select',
        SELECT_MORE: 'Select one or more ',
        CLOSE: 'Close',
        CREATE: 'Create',
        WITH: 'with',
        CONVERT: 'Convert',
        GREENLIGHT: 'Greenlight',
        SEARCH_PLACEHOLDER: 'Type and press enter to search',
        SHARE_WITH_UNITS:'Share with',
        COPY: 'Copy',
        MORE: 'More',
        CLICK_TO_SEARCH: 'Click here to search',
        PREVIEW: 'Preview',
        BACK: 'Back',
        CONFIRM_REQUEST: 'Confirm Request',
        SELECT: 'Select',
        ADVANCED: 'Advanced',
        ADVANCED_SEARCH: 'Advanced Search',
        RESULTS_FOR: 'Results for',
        SETTINGS: 'Settings',
        SIGN_OUT: 'Sign out',
        BACK_TO_YOUR_ACCOUNT: 'Back to your Account',
        BACK_TO_LIST: 'Go back to the list.',
        BACK_TO_COLUMN_VIEW: 'Go back to the column view.',
        MERGE: 'Merge',
        DISPLAY_DIFFERENT_FIELDS: '*Only displaying fields that are different.',
        WORKFLOW: 'Workflow',
        STATUS: 'Status',
        RECORD: 'Record',
        MERGED_RECORD: 'Merged Record',
        EXPORT: 'Export',
        CTR_DETAILS_FOR: 'CTR details for',
        URL: 'URL',
        COUNT: 'Count',
        CLICK_FOR_KANBAN_VIEW: 'Column',
        CLICK_FOR_LIST_VIEW: 'List',
        INSERT: 'Insert',
        CATEGORIES: 'Categories',
        REMOVE: 'Remove',
        REPLY: 'Reply',
        REPLY_ALL: 'Reply All',
        FORWARD: 'Forward',
        WORKFLOW_NAME: 'Workflow Name',
        CONVERTING: 'Converting',
        TO: 'to',
        CURRENT_WORKFLOW: 'Current Workflow',
        CHANGE_WORKFLOW: 'Change Workflow',
        UPDATE: 'Update',
        STATUS_TO_OPTIONAL: 'status to (optional)',
        MANAGE: 'Manage',
        ADD_PERSONAL_NOTE_OPTIONAL: 'Add a personal note to your request (optional)',
        CONVERTED: 'Converted',
        SELECT_USER: 'Select a user',
        COMING_SOON: 'Coming Soon',
        IMPORT: 'Import',
        NO: 'No',
        FOUND: 'found',
        NOTHING_FOUND: 'Nothing found for',
        DOT: '.',
        UNDER_PROCESSING: 'Under Processing,  Coming Soon ',
        FIND: 'Find',
        LOADING: 'Loading',
        NAME: 'Name',
        CREATE:'Create',
        CLEAR_ALL: 'Clear all',
        CLEAR: 'Clear',
        SHARE: 'Share',
        ADD_TO_GROUP: 'Add to Group',
        MAP: 'Map',
        AUDIT: 'See Field History',
        INVOICE_RECIPIENTS : "Invoice Recipients",
        OPEN_AUDIT_FORM: 'Audit',
        TRAIL: 'Trail',
        ADD_NOTES: 'Add Notes',
        TO_EXCEL: 'To Excel',
        APPROVED:'Approve',
        SENDING_FOR_APPROVAL:'Sending for Approval',
        REJECTED:'Reject',
        PUSH: 'Push',
        RESCHEDULE: 'Reschedule',
        PRINT: 'Print',
        COMPLETE: 'Complete',
        COMPLETE_New: 'Complete & New',
        SEND_EMAIL: 'Send Email',
        SEND_EMAIL_ATTACHMENT: 'Email an Attachment',
        SEND_SMS: 'Send SMS',
        CONVERT_TO_ACCOUNTS: 'Convert to Accounts',
        CONNECT: 'Connect',
        DISCONNECT: 'Disconnect',
        DOWNLOAD: 'Download',
        BATCH_EDIT: 'Batch Edit',
        BATCH_CONVERT: 'Batch Convert',
        INACTIVE: 'Inactive',
        ACTIVE: 'Active',
        RESTORE: 'Restore',
        PROFILE: 'Profile',
        TYPE_TO_SEARCH: 'Type here to search',
        CONVERT_AND_ADD_OPP: 'Convert & Add Opportunity',
        SEND_VERIFICATION_EMAIL: 'Send Verification Email',
        THESE: 'these',
        THIS: 'this',
        ALL_APPOINTMENTS: 'All Appointments',
        ALL_TASKS: 'Tasks',
        CONTINUE: 'Continue',
        ALL_OPPORTUNITIES: 'All Opportunities',
        EDIT_NOTES: 'Edit Notes',
        RUN: 'Run',
        INTEGRATION_TITLE: 'Integration',
        UNSUBSCRIBE: 'Unsubscribe',
        MY_INTEGRATIONS: 'Integrations',
        NOTIFY: 'Notify ',
        HELP: 'Help',
        PUBLISH: 'Publish',
        UNPUBLISH: 'Unpublish',
        CALL_SCRIPT: 'Call Scripts',
        DOCUSIGN: 'Send DocuSign',
        DOCUSIGN_INTEGRATION: 'Send DocuSign',
        ALL: 'All',
        USER: 'User',
        SYSTEM: 'System',
        MY: 'My',
        TODAYS: "Today's",
        YES: 'Yes',
        FUTURE: 'Future',
        OVERDUE: 'Overdue',
        TOMORROWS: "Tomorrow's",
        TOMORROW: "Tomorrow",
        TODAY: 'Today',
        CONNECTED_AS: 'Connected as',
        SALESREP: 'Sales Rep',
        EVERYONE: 'Everyone',
        NOTE_TYPE: 'Note type',
        TEXT: 'Text',
        FOR: 'for',
        MODULE_SETTING: 'Settings',
        TEMPLATE: {
            LIBRARY_TEMPLATE: 'Library template',
            USER_TEMPLATE: 'User template',
        },
        OF: 'of',
        'System Fields': 'System Fields',
        'Custom Fields': 'Custom Fields',
        'Additional Information': 'Additional Information',
        'Contact Details': 'Contact Details',
        'Phone and Email Information': 'Phone and Email Information',
        'Sales rep': 'Sales Rep',
        DONE: 'Done',
        OPEN: 'Open',
        IMPORT_CSV_SOURCE_FIELD_HEADER: 'I would map my sources from the CSV file',
        ADD_NEW_SOURCE: 'Add a new source',
        ADD_TO_THIS_LIST: 'Add to this List',
        TYPE_SOMETHING: 'Type something',
        'Opportunity Details': 'Opportunity Details',
        'Company Details': 'Company Details',
        'Contact Information': 'Contact Information',
        'Case Information': 'Case Information',
        'Solution Details': 'Solution Details',
        'Project Details': 'Project Details',
        'Issue Details': 'Issue Details',
        'Assigned to': 'Assigned to',
        'Sales person': 'Sales person',
        EMAIL_TO: 'Email to',
        RECENT_SEARCHES: 'Recent Searches',
        RECENT_RECORDS: 'Recent Records',
        ENABLE_MAILING: 'Enable Email Sending',
        ASSETS: 'Library',
        DISABLE_MAILING: 'Disable Email Sending',
        CLICK_TO_REMOVE: 'Click here to remove',
        PIN: 'Pin',
        UNPIN: 'Unpin',
        RENAME: 'Rename',
        DEACTIVATE: 'Deactivate',
        ACTIVATE: 'Activate',
        COUPONS: 'Coupons',
        ADD_FOLDER: 'Add Folder',
        ADD_FILE: 'Add File',
        SELECT_CONTACTS: 'Select Contacts',
        NOTIFICATION_REMINDERS: 'Notifications & Reminders',
        PREFERENCES: 'Preferences',
        LOCK_STAGES: 'Lock Stages',
        LOCK_TASKS: 'Lock Tasks',
        SEND_BATCH_SMS: 'Send Batch Text Message',
        SEND_TEXT_MESSAGE: 'Send Text Message',
        ADD_TASK:'Add Task',
        VERIFY_EMAIL: 'Verify Email',
        SELECT_UNIT_NAME: 'Select Unit Name',
        NEWS: 'News',
        CLOSE_CASE: 'Close Case',
        CUSTOMIZE: 'Customize',
        VIEW_MODE: 'VIEW MODE',
        VIEW_MODE1: 'View Mode',
        LIST: 'List',
        COLUMN: 'Column',
        SETUP_SCHEDULING_PAGES: 'Setup Scheduling Pages',
        SCHEDULER_LINK: 'Scheduler Link',
        SAVE_RUN: 'SAVE & RUN',
        BATCH_CONVERT_TO_ACCOUNTS: 'Batch Convert to Accounts',
        NEXT: 'Next',
        ADD_QUICKBOOKS: 'Add to Quickbooks',
        ITEM_FROM_QB: 'FROM Quickbooks',
        PUSH_TO_QB: 'Add to Quickbooks',
        PUSH_TO_XERO: 'Add to Xero',
        ADD_NOTE: 'Add Note',
        ADD_NOTE_CONVERT: 'Add Note & Convert',
        OBJECT_NAME: 'Object Name',
        GREENLIGHT_EXPORT_DOWNLOAD: 'Process & Download',
        PREVIOUS: 'Previous',
        VIEW: 'View',
        MENU: 'Menu',
        NO_DUPLICATE: 'No duplicate',
        ONGOING: 'Ongoing',
        ONGOING_ONBOARDING: 'Ongoing Onboarding',
        SYNC_NOW: 'Sync Now',
        'Generate Invoice': 'Generate Invoice',
        'Add Payment': 'Add Payment',
        'Print': 'Print',
        MOBILE_MENU: 'Mobile Menu',
        PORTAL_MENU: 'Portal Menu',
        TO_QUICKBOOKS: 'To Quickbooks',
        TO_XERO: 'To Xero',
        CONVERT_INVOICE: 'Convert to Invoice',
        RECEIVE: 'Receive',
        ACCEPT: 'Accept',
        CONFIRM: 'Confirm',
        FULFILL: 'Fulfill',
        SHIP: 'Ship',
        ADD_JOBS: 'Add Jobs',
        ROYALTY_SETUP: 'Royalty Setup',
        ROYALTY_REPORT_SETUP: 'Royalty Report Setup',
        WEBSITE_REVIEW: 'Review Website',
        UNIT_CRM: 'Unit CRM',
        SAVE_AND_SCHEDULE: 'Save & Schedule',
        UNLINK: 'Unlink',
        TO_XMS: 'To XMS',
        REFUND: 'Refund',
        ADD_TASK: 'Add Task',
        SEQUENTIAL:"Sequential",
        AMPERSAND: '&',
        INVOICE: 'Invoice',
        COLLECT: 'Collect',
        MARK_PAID:'Mark Paid',
        EMAIL_TO_UNIT:'Email to Unit',
        EMAIL: 'Email',
        POST: 'Post',
        PLAN_NAME: 'Plan Name',
        CALCULATE: 'Calculate',
        COMPARE: 'Compare',
        STAR: 'Star',
        PIN: 'Pin',
        SHARE_WITH : 'Share with',
        FRANCHISOR: 'Franchisor',
        FRANCHISE: 'Franchise',
        ALL_PHONE_NO: 'All Phone Numbers',
        BRING_YOUR_OWN:'BYO (Bring Your Own) Number',
        LEASE_NUMBER:'Lease Number',
        ADD_NUMBERS:'ADD NUMBERS',
        ADD_CREDITS:'ADD CREDITS',
        DLC_Validation:'10DLC Validation',
        CALL_MINUTES:'call minutes and',
        TEXT_CREDIT:'text credit remaining',
        LEASE_A_NO:'Lease a Number',
        TOLL_FREE:'Toll-free',
        LOCAL:'Local',
        COUNTRY:'Country',
        SURVEY_SETUP : 'Survey Setup',
        THANKS_SURVEY:'Thumbs Up Survey-Thank you Form',
        REVIEW_WEBSITE_REQUIRED:'Review Website\'s URL(required)',
        THUMBS_UP_TITLE:'Thumbs Up Title',
        THUMBS_UP_DESC:'Thumbs Up Description',
        YES_BUTTON:'Yes Button',
        NO_BUTTON:'No Button',
        DAYS: 'Day(s)',
        LOCATION_ALERT:'Location already exists!',
        TASK_NAME:'Task Name',
        DESC:'Description',
        UNIT_CRM: 'Unit CRM Setup',
        UNIT_CRM_LABEL: 'Unit CRM administrators can:',
        UNIT_CRM_CUSTOMIZE_FORMS: 'Customize Forms',
        UNIT_CRM_UPDATE_STAGES:'Update Stages',
        UNIT_CRM_UPDATE_TASKS:'Update Tasks',
        UNIT_CRM_CUSTOMIZE_WORKFLOW: 'Customize Workflows',
        ACTION: 'Actions:',
        INVOICE_ALL : 'Invoice All',
        EMAIL_ALL:'Email All',
        POST_ALL: 'Post All',
        TO_UNITS: 'To Units',
        TO_QUICKBOOKS:'To Quickbooks',
        REPORT_FIELDS: 'Report Fields:',
        COMPLIANCE_NOTIFICATION:'Compliance Notification',
        COMPLIANCE_LABEL:'Enter the email address to notify FR when a franchisee is out of compliance',
        ONBOARDING_NOTIFICATION:'Onboarding Notification',
        EMAIL:'Email',
        HELPDESK_NOTIFICATION:'Helpdesk Notification',
        LMS_SETUP:'LMS Setup',
        LMS_LABEL:'LMS Account (Required)',
        PLUS_BASE: 'Base',
        UNITBASE:'Unit Base',
        EXCEPTION: 'Royalty Exceptions',
        ARCHIVE:'Archive',
        UNARCHIVE:'Unarchive',
        IMPORT_NUMBER:'Import Numbers',
        DISABLE:'Disable',
        DISABLED:'Disabled',
        NEW:'New'
    },
    WORKFLOW_SETUP:{
        SAVE_WORKFLOW:'Save Workflow',
        INSERT_IMAGE: 'Insert Image',
        CHARS:'Chars',
        SAVE_TASK:'Save Task',
        ADD_NEW:'Add New',
        ACCOUNT_WORKFLOW:'Account Workflow',
        NO_TASK_FOUND:'No actions found. Click the Add dropdown to choose an action.',
        AFTER: 'After',
        UPDATE_TASK:'Update Task',
        REQUIRED_FIELDS: 'Required Fields',
        ADD_A_REQUIRED_FIELD:'Add a required field',
        MORE_REQUIRED_FIELD:'Click here to select one or more required fields(s)',
        MANUALLY:'Manually',
        AUTOMATICALLY:'Automatically when all the required tasks are done',
        CONVERT_TO_NEXT_STAGE:'Convert to the next stage',
        STATUS:'Status',
        TABLE:'Table',
        DESCRIPTION:'Description',
        NO_FILE_CHOOSEN:'No file chosen',
        ATTACH_DOCUMENT:'Attach Documents',
        ADD_ANOTHER_REMINDER:'Add Another Reminder',
        REMINDER:'Reminder',
        OWNER:'Owner',
        TASK_NAME:'Task Name',
        FROM:'from',
        AFTER:'After',
        INSERT_FIELD:'Insert Field',
        NOTIFY_UNIT_OWNER:'Notify Unit Owner',
        SCHEDULE_ON_WEEKEND:"Don't Schedule On Weekends",
    },
    NOTE:{
        ALL_NOTES:'All Notes',
        ALL_TYPES:'All Types',        
    },
    HOME: {
        MY_FILTERS: 'My Filters',
        GROUPS: 'Groups',
        ADD_NEW: 'Add New',
        RECENT: 'Recent',
        HOME: 'Home',
        CLICK_TO_REFRESH: 'Refresh',
        RECENTLY_VIEWED: 'Recently Viewed',
        FILTERS: 'Filters',
        ADD_GROUP: 'Add Group',
        NOTIFICATIONS: 'Notifications',
        WHATS_NEW: "What's New",
        COLLAPSE: 'Collapse',
        EXPAND: 'Expand',
        ADD_FILTER: 'Add Filter',
        CLICK_TO_FIND_GROUP: 'Click here to find a group',
        CLICK_TO_FILTER_GROUP: 'Filter your groups',
        CLICK_TO_FIND_FILTER: 'Click here to find a filter',
        FORECASTS: 'Forecasts',
        SALES_REPORT: 'Sales Reports',

        //not found in doc
        ADD_FIELD: 'Click here to add a field to the filter',
        FITER_SETUP: 'Filter Setup',
        ENTER_BY_HAND: 'Enter by Hand',
        IMPORT: 'Import',
        VIEW: 'View',
        DELETE: 'Delete',
        CLICK_TO_FIND_REPORT: 'Click here to find a report',

    },
    CAMPAIGNS: {
        NAME_REQUIRED: 'Campaign Name (required)',
        GROUP: 'Group',
        CONTACT_FILTER: 'Contact Filter',
        ACCOUNT_FILTER: 'Account Filter',
        FROM_NAME_REQUIRED: 'From Name (required)',
        VIEW_AS_WEB_PAGE: 'View as a web page',
        SUBJECT_REQUIRED: 'Subject (required)',
        DAYS: 'Day(s)',
        EXECUTE_NOW: 'Execute Now',
        TEST: 'Test',
        SCHEDULED_FOR: 'Scheduled for',
        EXECUTED_ON: 'Executed on',
        EXECUTING: 'Executing',
        ABORTED: 'Aborted',
        SCHEDULE: 'Schedule',
        CLICK_URL: 'Click URL',
        SELECT_GROUP: 'Select up to three groups',
        SELECT_CONTACT_FILTER: 'Select a filter from contact',
        SELECT_ACCOUNT_FILTER: 'Select a filter from account',
        CHANGE_TEMPLATE: 'Change Template',
        WITHOUT_AB_TEST: 'Without a/b test',
        WITH_AB_TEST: 'With a/b test',
        WITH_AUTOMATION: 'Automated Campaigns',
        WITHOUT_AUTOMATION: 'Touch Campaigns (Legacy)',
        // SEND_ON_WEEK_DAY: 'Send on weekdays only (Mon-Fri)',
        SEND_ON_WEEK_DAY:  "Don't send on weekends (Sat, Sun)",
        RESEND_AFTER: 'Resend After',
        RESEND_ALL: 'Resend All',
        FROM_EMAIL_REQUIRED: 'From Email (required)',
        CAMPAIGN_TYPE_OPTIONAL: 'Campaign type (optional)',
        CAMPAIGN_TYPE: 'Campaign type',
        START_DATE_REQUIRED: 'Start Date (required)',
        SENT: 'Sent',
        OPENED: 'Opened',
        UNOPENED: 'Unopened',
        CLICKED: 'Clicked',
        BOUNCED: 'Bounced',
        UNSUBSCRIBED: 'Unsubscribed',
        SPAM: 'Spam',
        DROPPED: 'Dropped',
        TYPE_HERE: 'Type here.',
        ADD_SIGNATURE: 'Add Signature',
        INSERT_IMAGE: 'Insert Image',
        PERSONALIZE: 'Personalize',
        TEMPLATE_DIALOG_TITLE: 'Select a Template',

        // Campaign Status
        'All': 'All',
        'Active': 'Active',
        'Running': 'Running',
        'Stopped': 'Stopped',

        'New': 'New',
        'Queued': 'Queued',
        'Executed': 'Executed',
        OPEN: 'Open',
        BOUNCE: 'Bounce',
        SPAMREPORT: 'Spamreport',
        'Halted': 'Halted',
        'Auto Response': 'Auto Response',
        'Marketing Template': 'Marketing Template',
        'Newsletter': 'Newsletter',
        'Greetings': 'Greetings',
        'Inactive': 'Inactive',
        'Executed - No Winner': 'Executed - No Winner',
        'Sent to Winner': 'Sent to Winner',
        ADD_CAMPAIGN_TYPE: 'Add a new campaign type',
        CAMPAIGN_NAME: 'Campaign Name',
        SUBJECT: 'Subject',
        MANAGE: 'Manage',
        FROM_EMAIL: 'From Email',
        SELECT_A_FILTER: 'Select a filter from ',
        BETWEEN: 'Between',
        WEEKDAYS_ONLY: 'Weekdays Only'
    },
    BROADCAST_CAMPAIGNS: {
        PERSONALIZE_SUBJECT: 'Personalize Subject',
        FROM_EMAIL_REQUIRED: 'From Email (required)',
        EMAIL_CAMPAIGN_REPORT: 'Email Campaign Report',
        SCHEDULE_BROADCAST: 'Schedule Broadcast',
        RESCHEDULE_BROADCAST: 'Reschedule Broadcast',
        EXECUTE_BROADCAST: 'Execute Broadcast',
        ADD_GROUP: 'Add Group',
        CATEGORY: 'Category',
        TEMPLATE: 'Template',
        PERFORM_AB_TESTING: 'Perform A/B Testing',
        VERIFY_EMAIL: 'Verify Your Email Address',
        VERIFY_EMAIL_ADDRESS: 'Enter your email address here',
        VERIFY_EMAIL_ADDRESS_OPTION: 'Verify another email address',
        BROADCAST_CAMPAIGN_EDIT_TAB_LABEL: 'Edit Broadcast Campaign',
        BATCH_EDIT: 'Batch Edit',
        BROADCAST_CAMPAIGN_COPY_TAB_LABEL: 'Copy Broadcast Campaign',
        BROADCAST_CAMPAIGN_CREATE_TAB_LABEL: 'Create Broadcast Campaign',
        All_BROADCASTS: 'All Broadcasts',
        BROADCASTS: 'Broadcasts',
        ADD_SIGNATURE: 'Add Signature',
        BROADCAST_INFO: 'Broadcast Info',
        DESIGN: 'Design',
        DRAG_AND_DROP: 'Drag-and-drop',
        LEGACY: 'Legacy',
        EMAIL_BUILDER: 'Email Builder',
        QUALIFIED_CONTACTS: 'View the recipient list',
    },
    AB_CAMPAIGNS: {
        TARGET_BY: 'Target by',
        AB_TEST_DISTRIBUTION: 'A/B Test Distribution',
        MIN_MARGIN_PERCENT: 'Min Winning Margin %',
        A_PERCENT: 'A%',
        B_PERCENT: 'B%',
        AB_CAMPAIGN_REPORT: 'A/B Campaign Report',
        //not found in doc
        AB_SEND_REST: 'Send the Rest Using the Winner',
        AB_SELECT_WINNER_BY: 'Select Winner By',
        VERIFY_EMAIL_ADDRESS_OPTION: 'Verify another email address',
        EMAIL_VIEWS: 'Email Views',
        EMAIL_CLICKS: 'Email Clicks',
        AB_CAMPAIGN_EDIT_TAB_LABEL: 'Edit A/B Campaign',
        BATCH_EDIT: 'Batch Edit',
        AB_CAMPAIGN_COPY_TAB_LABEL: 'Copy A/B Campaign',
        AB_CAMPAIGN_CREATE_TAB_LABEL: 'Create A/B Campaign',
        ALL_AB_CAMPAIGNS: 'All AB Campaigns',
        AB_TESTS: 'A/B Tests',
        MESSAGE_A: 'Message A',
        MESSAGE_B: 'Message B',
        AFTER: 'after',
        SCHEDULE_AB_CAMPAIGN: 'Schedule A/B Campaign',
        WINNER_BASEDON_A: 'Winner (based on Message A)',
        WINNER_BASEDON_B: 'Winner (based on Message B)'
    },
    CALENDAR: {
        CALENDAR_TASKS: 'Calendar/Tasks',
        CALENDAR_ONLY: 'Calendar',
        CALENDARS: 'Calendar for',
        SHOW_OPTION_1: 'Cancelled',
        SHOW_OPTION_2: 'Completed',
        SHOW_OPTION_3: 'Scheduled',
        TODAY: 'Today',
        BACK: 'Back',
        NEXT: 'Next',
        DAY: 'Day',
        WEEK: 'Week',
        MONTH: 'Month',
        AGENDA: 'Agenda',
        DATE: 'Date',
        TIME: 'Time',
        EVENT: 'Event',
        ALL_DAY: 'all day',
        WORK_WEEK: 'work week',
        YESTERDAY: 'yesterday',
        TOMMOROW: 'tomorrow',
        SHOW_MORE: '',
        TYPE: 'Type',
        WORKING_HOUERS: 'Working Hours',
        DEFAULT_lOCATION: 'Default location',
        DEFAULT_DESCRIPTION: 'Default description',
        MY_CALENDAR_SETTING: 'My Calendar Settings',

        MY_TASK_SETTING: 'My Tasks Settings',
        DISPLAY: 'Display',
        ACCOUNT_NAME: 'Account Name',
        JOB_NAME: 'Job Name',
        COLOR_CODE: 'Color Code',
    },
    GROUP: {
        GROUP_NAME: 'Group Name',
        DELETE_RECORD: 'Delete Record',
        USED_IN: 'Used In',
        REMOVE_FROM: 'Remove from',
        SELECT_RECORDS: 'Selected records',
        ALL_RECORDS: 'All records',
        SELECT_OPTIONS: 'Select options',
        GROUP_FILTER: 'Select group filter',
        ALL: 'All',
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        DETAILS: 'Details',
        'Active': 'Active',
        'Inactive': 'Inactive',
        'New': 'New',
        'scheduled': 'scheduled',
        'Queued': 'Queued',
        'Executed': 'Executed',
        'Running': 'Running',
        'Halted': 'Halted'
    },
    APPOINTMENTS_TASKS: {
        MY_TASKS: 'Tasks',
        PERSONALIZE_OPTION_1: 'Overdue',
        PERSONALIZE_OPTION_2: 'Today',
        PERSONALIZE_OPTION_3: 'Future',
        PERSONALIZE_OPTION_4: 'Tomorrow',
        PERSONALIZE_SORT_1: 'Date',
        PERSONALIZE_SORT_2: 'Priority',
        TASKS_FOR: 'Tasks For',
        SORT_BY: 'Sort By',
        COMPLETE_THIS_APPOINTMENT: 'Complete this Appointment',
        COMPLETE_THIS_TASK: 'Complete this Task',
        NEXT_DAY: 'Next Day',
        NEXT_WEEK: 'Next Week',
        MONTH_WEEK: 'Next Month',
        MINI_EDIT: 'Edit',
        COMPLETE: 'Complete',
        DELETE: 'Delete',
        TASKS: 'Tasks',
        APPOINTMENTS: 'Appointments',
        ADD_NOTE: 'Add a Note (optional):',
        NEXT_MONTH: 'Next Month',
        DAYS: 'Days',
        SPECIFIC_DATE: 'Specific Date',
        RESCHEDULE: 'Reschedule',
        RESCHEDULE_FOR: 'Reschedule for:',
        REPEAT: 'Repeat',
        DAY_OF_THE_MONTH: 'day of the month',
        DAY_OF_THE_WEEK: 'day of the week',
        REPEAT_TYPE: 'Repeat type',
        REPEAT_ON: 'Repeat on',
        REPEAT_BY: 'Repeat by',
        START_DATE: 'Start date',
        END_DATE: 'End date',
        REPEAT_EVERY: 'Repeat every',
        REMOVE_REMINDER: 'Remove reminder',
        ONLY_THIS: 'Only this',
        THIS: 'This',
        THIS_AND_FOLLOWING: 'This and following',
        FOLLOWING: 'Following',
        ALL: 'All',
        ONLY_THIS_RECORD: 'Only this Record',
        FOLLOWING_RECORDS: 'Following Records',
        ALL_RECORDS: 'All Records',
        ADD_A_NOTE: 'Add a Note',
        COMPLETE_TASK_OPTIONAL: 'Complete pending tasks',
        COMPLETE_TASK_OPTIONAL_OBJ: 'Complete pending tasks/emails/text messages',
        CREATE_NEXT_TASKS: 'Create next tasks',
        CREATE_NEXT_TASKS_OBJ: 'Create next tasks/emails/text messages',
        PENDING_TASKS: 'Pending Tasks',
        SEARCH_BY_NAME: 'Search by name',
        INVITE_GUEST: 'Invite Guests',
        ATTENDEES: 'Attendees',
        SALES_REP: 'Sales Rep',

        //not found in doc
        ADD_REMINDER: 'Add a reminder',
        'Email': 'Email',
        'Pop-up': 'Pop-up',
        'minutes': 'minutes',
        'hours': 'hours',
        'days': 'days',
        'weeks': 'weeks',
        DAILY: 'Daily',
        WEEKLY: 'Weekly',
        MONTHLY: 'Monthly',
        YEARLY: 'Yearly',
        DAYS_S: 'days',
        WEEKS: 'weeks',
        MONTHS: 'months',
        YEARS: 'years',
        APPOINTMENT: 'Appointment',
        TASK: 'Task',
        SUBJECT: 'Subject',
        ASSIGN_TO: 'Assign to',
        COMPANY: 'Company',
        CONTACT_NAME: 'Contact Name',
        OUTLOOK_SYNC: 'Outlook Sync',
        GOOGLE_SYNC: 'Google Sync',
        'Scheduled': 'Scheduled',
        'Completed': 'Completed',
        'Cancelled': 'Cancelled',
        'Pending': 'Pending',
        TASK_NAME: 'Task Name',
        DUE_BY: 'Due By',
        DESCRIPTION: 'Description',
        OWNER: 'Owner',
        FOLLOWUP: 'Followup',
        'All': 'All',
        'Empty': 'Empty',
        'No': 'No',
        'Yes': 'Yes',
        'Everyone': 'Everyone',
        'Just me': 'Just me',
        ALL_TASK_COMPLETED: 'This task has been already completed.',
        'duration': 'Duration',
        'days_after': 'Days after',
        DEP_TASK_NAME: 'Task Name',
        TASK_COMPLETED: 'Task Completed(%)',
        APPOINTMENTS_AND_TASKS: 'Appointments & Tasks',
        APPOINTMENTS_ONLY: 'Appointments Only',
        TASKS_ONLY: 'Tasks Only',
        EDIT: 'Edit',
        TASKS_CANCEL: 'These tasks will be canceled',
        SENDNOW: 'Send Now',
        OPPORTUNITY_NAME: 'Opportunity Name',
        SELECT_USER: 'Select User',
        SELECT_STATUS: 'Select Status',
        VISITS: 'Visits',
        UNIT_OWNER_TASKS: 'Unit\'s Tasks',
        COMPLETE_UNIT_TASK_OPTIONAL: 'Complete unit owner pending tasks',
        UNIT_TASKS: 'Tasks for the Unit',
        DOCUMENTS: 'Documents',
        LINKS: 'Links',
        TASK_SETTINGS: 'Task Settings',
        APPOINTMENT_SETTINGS: 'Appointments Settings',
    },
    ATTACHMENT: {
        UPLOAD: 'Upload',
        MY_COMPUTER: 'My Computer',
        BROWSE: 'Browse',
        DOWNLOAD: 'File Download',
        ATTACH_FILE: 'Attach a file',
        UPLOADING: 'Uploading',
        NO_IMAGE_FOUND: 'No Image Found',
        DOWNLOAD_RECORDING: 'Download the recording',
        ATTACHMENT: 'Attachment',
        ADD_AN_ATTACHMENT: 'Add an Attachment',
        ATTACHMENTS: 'Attachments',

    },
    LIST_VIEW: {
        CLICK_TO_COLLAPSE: 'Click here to collapse',
        CLICK_TO_EXPAND: 'Click here to expand',
        SHOW_TOTAL: 'Show Total',
        SHOW_PICTURE: 'Show Picture',
        DESCENDING: 'Descending',
        SORT_BY: 'Sort by',
        SELECT_COLUMN_FOR_VIEW: 'Select the fields for this view',
        SELECT_COLUMN_FOR_FILTER_VIEW: 'Select columns for the filter results',
        ADD_AND_REORDER: 'Add and reorder',
        FIELDS: 'fields',
        CLICK_TO_ADD_MORE_FIELDS: '(Click this box to add more fields. Drag and reorder the fields to change their position.)',
        SELECT_FIELDS: 'Select fields',
        MODIFY_FIELD: 'Modify field',
        EXPORT_SELECTED_RECORDS: 'Export selected records',
        EXPORT_ALL_RECORDS: 'Export all records',
        LINK_EXISTING: 'Link Existing',
        SET_UP: 'Setup',
        GROUP_BY: 'Group by',
        FIND_BY_FIELD: 'Find by',
        FILTER_TYPE: {
            DEFAULT_FILTERS: 'Default Filters',
            SHARED_FILTERS: 'Shared Filters'
        },
        COLUMN_VIEW_SETUP_INFO: 'This column view will only show the first five fields. The list view shows all the fields.',
        COUNT: "Count",
        ALPHABETICAL: "Alphabetical",
    },
    DETAIL_VIEW: {
        SHOW_LABELS: 'Show labels',
        SELECT_OPTIONS: 'Select options',
        CLICK_TO_ADD_MORE_FIELDS: 'Click this box to add more fields. Drag and reorder the fields to change their order.You can select maximum 50 fields.',
        SETUP_FIELDS: 'Setup Fields',
        ADD_OR_REMOVE_FROM_LIST: 'Add/Remove fields from this list',
        CLICK_TO_KNOW_MORE: 'Click to know more',
        CLICK_TO_ADD_CHILD_OBJECTS: 'Click this box to add more child objects. Drag and reorder the objects to change their position.',
        SETUP_CHILD_TABS: 'Setup Child Tabs',
        SHOW_LABELS_AGAINST_FIELD: 'Select this to show labels against the fields.',
        CUSTOMIZE_CHILD: 'Customize Child',
        REFUND_CHILD_LABEL: 'Refund',
        //not found in doc 
        GROUPS: 'Groups',
        MANAGE: 'Manage',
        ADD_TO_GROUP: 'Add to Group',
        DATA_TIP_1: 'This would create the tasks for the ',
        DATA_TIP_2: 'This would create the tasks/emails/text messages for the ',
        DATA_TIP_3: ' stage',

        //opportunity info 
        HIGHLIGHT_LABEL_OPPORTUNITY: 'Opportunity $',
        HIGHLIGHT_LABEL_FORECAST: 'Forecast $',
        HIGHLIGHT_LABEL_CLOSING_ON: 'Closing On',
        HIGHLIGHT_LABEL_OPPORTUNITY_AGE: 'Opportunity Age',
        HIGHLIGHT_LABEL_DAYS: 'Days',

        CHILD_SETUP_SELECT_CHILD_LABEL: 'Click here to add a child object to the set up.',
        NO_SETUP_OPTION: 'None'
    },
    RELATIONSHIP: {
        DEFINE_RELATIONSHIP: 'Define Relationship',
        RELATE: 'Relate',
        COMPANY: 'Company',
        FULL_NAME: 'Full Name',
        EMAIL: 'Email'
    },
    AUTOMATION_DESIGNER: {
        TOUCH_CAMPAIGN_REPORT: 'Touch Campaign Report',
        INTERVAL: 'After',
        NOTE_TYPE: 'Note type',
        TEXT_REQUIRED: 'Note Text (required)',
        TASK_NAME_REQUIRED: 'Task name (required)',
        OWNER_REQUIRED: 'Owner (required)',
        DUE_BY_REQUIRED: 'Due by (required)',
        DAY_AFTER_REQUIRED: 'Days after (required)',
        EMAIL: 'Email',
        POP_UP: 'Pop-up',
        MINUTES: 'minutes',
        HOURS: 'hours',
        DAYS: 'days',
        WEEKS: 'weeks',
        REMINDER: 'Reminder',
        GROUP_REQUIRED: 'Select Group (required)',
        ADD_TO_GROUP: 'Add to Group',
        MOVE_TO_GROUP: 'Move to Group',
        REMOVE_FROM_GROUP: 'Remove from Group',
        STATUS_REQUIRED: 'Status (required)',
        WORKFLOW_REQUIRED: 'Workflow (required)',
        CHOOSE_ANOTHER_FIELD: 'Choose another field',
        CHOOSE: 'Choose',
        FIELD_FOR: 'a field for',
        CAMPAIGN_NAME: 'Campaign Name (required)',
        START: 'Start',
        STOP: 'Stop',
        REPORT: 'Report',
        RESUME: 'Resume',
        SELECT_PAGE: 'Select a Page',
        ENTER_WEB_PAGE_LINK_OPTIONAL: 'Enter the URL of the link (optional).',
        ENTER_WEB_PAGE_LINK: 'Enter the web page link.',
        CLICK_TO_VIEW: 'Click here to view',
        CLICK_TO_EDIT: 'Click here to edit',
        DRAG_TO_MOVE: 'Drag to move',
        DRAG_TO_CONNECT: 'Drag to connect',
        CLICK_TO_REMOVE: 'Click here to remove',
        CLICK_TO_SEE_REPORT: 'Click here to see report',
        CLICK_TO_ADD_MSG: 'Click to add new message',
        NO: 'No',
        YES: 'Yes',
        CLICK_TO_SELECT_USERS: 'Click here to select users',
        SALES_REP: 'Sales Rep',
        USERS: 'Users',
        CAMPAIGN_SENDER: 'Campaign Sender',
        SCORE: 'Score',
        START_WITH: 'Add',//'Start with'
        DECISIONS: 'Decisions',
        ACTIONS: 'Actions',
        LINK_CLICKED: 'Link Clicked?',
        SCORE_REACHED: 'Score Reached?',
        TOUCH_CAMPAIGN: 'Touch Campaign',
        WEB_FORM: 'Web Form',
        LANDING_PAGE: 'Landing Page',
        WEBSITE_VISITED: 'Website Visited',
        CHANGE_GROUP: 'Change Group',
        ADD_NOTE: 'Add Note',
        NOTIFY: 'Notify',
        ADD_TASK: 'Add Task',
        FIELD_UPDATED: 'Update Fields',
        CHOOSE_TEMPLATE: 'Choose a Template',
        USE_THIS_TEMPLATE: 'Use this template',
        WAIT: 'Wait',
        NAME: "Name",
        TARGET_GROUPS: 'Target Group(s)',
        TARGET_FILTER: 'Target Filter',
        RESEND_AFTER: 'Resend After',
        START_DATE: 'Start Date',
        SUBJECT: 'Subject',
        TEXT: 'Text',
        TASK_NAME: 'Task Name',
        ASSIGN_TO: 'Assign to',
        FIELD_LABEL: 'Field Label',
        FIELD_VALUE: 'Field Value',
        LINK: 'Link',
        SEND_ON_ANY_TIME: 'Send on: Any time',
        SEND_ON_BUSINESS_DAY: 'Send on: Only on business days',
        CONVERT_TO: 'Convert to',
        QUALIFIER: 'Qualifier',
        OBJECT: 'Object',
        NOTIFY_SENDER: 'Notify Sender',
        NOTIFY_SALES_REP: 'Notify Sales Representative',
        NOTIFY_SENDER_SALESREP: 'Notify Sender, Notify Sales Representative',
        NOTIFY_USERS: 'Notify Users',
        TEST: 'Test',
        VIEW_AS_WEBPAGE_INFO: 'This option will add a link at the top of the email to open the email as a web page',
        LINK_CLICK_INFORMATION: 'If you leave this blank, all links in the email will be tracked.',
        GROUP_NAME: 'New Group Name',
        SELECT_USER: 'Assign to',
        REMIND_BEFORE: 'Remind before',
        INTERVAL_REQUIRED: 'Interval (required)',
        //not found in doc
        VERIFY_EMAIL_ADDRESS_OPTION: 'Verify another email address',
        'Touch Campaign': 'Touch Campaign',
        'Link Clicked': 'Link Clicked',
        'Link Clicked?': 'Link Clicked?',
        'Email Opened': 'Email Opened',
        'Email Opened?': 'Email Opened',
        'Unsubscribed': 'Unsubscribed',
        'Score Reached': 'Score Reached',
        //'Send One-off Email': 'Send One-off Email',
        'Send Email': 'Send Email',
        'Change Group': 'Change Group',
        'Add Note': 'Add Note',
        'Notify': 'Notify',
        'Add Task': 'Add Task',
        'Update Fields': 'Update Fields',
        'Assign': 'Assign',
        'Convert': 'Convert',
        'Start': 'Start',
        'Add Touch Message': 'Add Touch Message',
        'Web Form': 'Web Form',
        'Website Visit': 'Website Visit',
        'Landing Page': 'Landing Page',
        'Wait': 'Wait',
        WAIT_FOR_DAYS: 'day(s)',
        'Simple email nurturing': 'Simple email nurturing',
        'Capture leads using a web form and nurture': 'Capture leads using a web form and nurture',
        'Track web page visit and nurture': 'Track web page visit and nurture',
        'Capture leads from a landing page and nurture': 'Capture leads from a landing page and nurture',
        'Start with tracking website visit': 'Start with tracking website visit',

        COPY_OF: 'Copy of ',
        AUTOMATION_CAMPAIGN_CREATE_TAB_LABEL: 'New Automated Campaign',
        TARGET_BY: 'Target by',
        'Hours': 'Hours',
        'Hour(s)': 'Hour(s)',
        'day(s)': 'day(s)',
        'hour(s)': 'hour(s)',
        ALL_AUTOMATED_CAMPAIGNS: 'All Automated Campaigns',
        MESSAGE: 'Message',
        SELECT_A_CAMPAIGN: 'Please select a campaign',
        ADD_SIGNATURE: 'Add Signature',
        BIRTHDAY_CAMPAIGN: 'Birthday Campaign',
        'Add Birthday Message': 'Add Birthday Message',
        'Start with Birthday Campaign': 'Start with Birthday Campaign',
        'SMS Replied': 'SMS Replied',
        'Send SMS': 'Send SMS',
        PET_BIRTHDAY_CAMPAIGN: 'Pet Birthday Campaign',

        'Email Drip Campaign': 'Email Drip Campaign',
        'Birthday Campaign': 'Birthday Campaign',
        'Web Form Campaign': 'Web Form Campaign',
        'Landing Page Campaign': 'Landing Page Campaign',
        'Pet Birthday Greetings': 'Pet Birthday Greetings',
        'SMS Drip Campaign': 'SMS Drip Campaign',

        SEND_SMS: 'Send SMS',
        SMS_REPLY: 'SMS Replied?',
        BIRTHDAY_CAMPAIGN_REPORT: 'Birthday Campaign Report',
        START_IMMEDIATELY: 'Immediately',
        START_SCHEDULE: 'Schedule',
        SCHEDULE_ON_TEXT: 'Scheduled to start on',
        SCHEDULE_DIALOG_TITLE: 'Select schedule date and time',
        FROM_NUMBER: 'From Number',
        REPLY: 'Reply',
        NOT_CONVERTED: 'Not converted',
        SMS_CAMPAIGN: 'SMS Campaign',
        ADD_SMS: 'Add SMS'
        
    },
    TOUCH_CAMPAIGNS: {
        ALL_TOUCH_CAMPAIGNS: 'All Touch Campaigns',
    },
    MARKETING_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personalize Subject',
        NAME_REQUIRED: 'Name (required)',
        STATUS: 'Status',
        SUBJECT_REQUIRED: 'Subject (required)',
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        TEST: 'Test',
        SELECT_TEMPLATE: 'Start typing to select a template',
        TYPE_HERE: 'Type here.',
        ADD_SIGNATURE: 'Add Signature',
        INSERT_IMAGE: 'Insert Image',
        PERSONALIZE: 'Personalize',
        SELECT_FROM_LIBRARY: 'Select from library',
        USE_THIS_DESIGN: 'Use This Design',
        DEAR: 'Dear',
        EXPAND_EDITOR: 'Fullscreen',
        SHARE: 'Use for both marketing and sales emails',
        MARKETING_TEMPLATE_CREATE_TAB_LABEL: 'Create Marketing Email Template',
        COPY_OF: 'Copy_of',
        BATCH_EDIT: 'Batch Edit',
        MARKETING_TEMPLATE_EDIT_TAB_LABEL: 'Edit Marketing Email Template',
        MARKETING_TEMPLATE_COPY_TAB_LABEL: 'Copy Marketing Email Template',
        CATEGORY: 'Category',
        LAYOUT: 'Layout',
        'Auto Response': 'Auto Response',
        'Marketing Template': 'Marketing Template',
        'Newsletter': 'Newsletter',
        'Greetings': 'Greetings',
        CHOOSE_EMAIL_BUILDER: 'Choose an Email Builder',
        DRAG_AND_DROP_BUILDER: 'Use the new drag-and-drop builder',
        LEGACY_BUILDER: 'Use the legacy builder',
        TEMPLATE_INFO: 'Template Info',
        DESIGN: 'Design',
    },
    SALES_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personalize Subject',
        NAME_REQUIRED: 'Name (required)',
        STATUS: 'Status',
        SUBJECT_REQUIRED: 'Subject (required)',
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        TEST: 'Test',
        TYPE_HERE: 'Type here.',
        ADD_SIGNATURE: 'Add Signature',
        INSERT_IMAGE: 'Insert Image',
        PERSONALIZE: 'Personalize',
        DEAR: 'Dear',
        SALES_TEMPLATE_CREATE_TAB_LABEL: 'Create Sales Email Template',
        SALES_TEMPLATE_EDIT_TAB_LABEL: 'Edit Sales Email Template',
        SALES_TEMPLATE_COPY_TAB_LABEL: 'Copy Sales Email Template',
        COPY_OF: 'Copy_of',
        SALES_TEMPLATES: 'Sales Email Templates',
    },
    AGREEMENT_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personalize Subject',
        NAME_REQUIRED: 'Name (required)',
        STATUS: 'Status',
        // SUBJECT_REQUIRED: 'Subject (required)',
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        TEST: 'Test',
        TYPE_HERE: 'Type here.',
        ADD_SIGNATURE: 'Add Signature',
        INSERT_IMAGE: 'Insert Image',
        PERSONALIZE: 'Personalize',
        DEAR: 'Dear',
        AGREEMENT_TEMPLATE_CREATE_TAB_LABEL: 'Create Agreement Template',
        AGREEMENT_TEMPLATE_EDIT_TAB_LABEL: 'Edit Agreement Template',
        AGREEMENT_TEMPLATE_COPY_TAB_LABEL: 'Copy Agreement Template',
        AGREEMENT_TEMPLATE_FDD_CREATE_TAB_LABEL:'Create FDD Template',
        AGREEMENT_TEMPLATE_FRANCHISE_AGREEMENT_CREATE_TAB_LABEL:'Create Franchise Agreement Template',
        COPY_OF: 'Copy_of',
        AGREEMENT_TEMPLATES: 'Agreement Templates',
        ADD_SIGNATURE_1: 'Signature 1',
        ADD_SIGNATURE_2: 'Signature 2',
        ADD_SIGNATURE_3: 'Signature 3',
        ADD_SIGNATURE_4: 'Signature 4',
        ADD_SIGNATURE_5: 'Signature 5',
        COVER_LETTER: 'Cover Letter',
    },
    SMS_TEMPLATE: {
        SMS_TEMPLATE_EDIT_TAB_LABEL: 'Edit SMS Template',
        SMS_TEMPLATE_COPY_TAB_LABEL: 'Copy SMS Templates',
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME: 'Enter your domain name (exclude http or www)',
        GENERATE_TRACKING_CODE: 'Generate Tracking Code',
        TRACKING_CODE: 'Your tracking code',
        COPY_TO_CLIPBOARD: 'Copy to clipboard',
        DONE: 'Done'

    },
    WEB_FORM: {
        SELECT_FORM: 'Select a form',
        WEB_FORM_CREATE_TAB_LABEL: 'Create Web Forms',
    },
    MAIL: {
        EMAIL_FOR_TESTING: 'Enter email for testing',
        SEND_A_TEST_EMAIL: 'Send a test mail',
        TYPE_HERE: 'Type here.',
        INSERT_IMAGE: 'Insert Image',
        ADD_SIGNATURE: 'Add Signature',
        PERSONALIZE: 'Personalize',
        FROM: 'From',
        SEND_MAIL: 'Send Email',
        PERSONALIZE_SUBJECT: 'Personalize Subject',
        SUBJECT: 'Subject',
        COMPUTER: 'From Computer',
        LIBRARY: 'From Library',
        RESCHEDULE: 'Reschedule',
        TO: 'To',
        ATTACH: 'Attach a file',
        CC: 'CC',
        WHEN: 'When',
        SENDING_TEST_MAIL: 'Sending Test Email...',
        SCHEDULE: 'Schedule',
        SCHEDULE_DIALOG_TITLE: 'Select schedule date and time',
        SEND_LATER: 'SEND LATER',
        SEND: 'Send',
        RECIPIENTS: 'Recipients',
        SENDING: 'Sending...'
    },
    SMS: {
        TO: 'To',
        WHEN: 'When',
        FROM: 'From',
        FROM_NUMBER: 'From Number',
        TO_NUMBER: 'To Number',
        SEND_A_TEST_SMS: 'Send a test sms',
        STATUS: 'Status',
        ENTER_NUMBER :'Enter Number For Testing',
        FAILURE_REASON: 'Failure Reason',
    },
    AUDIT_LEADSCORE: {
        LEAD_SCORE_HISTORY: 'Lead Score History',
        SCORE: 'Score',
        DATE: 'Date',
        ACTION: 'Action',
        USER: 'User',
        DETAILS: 'Details',
        ACTION_NAME: 'Action Name',
        SCORE_DETAILS: 'Score Details',
        BY: 'by',
        FROM: 'from',
        LINK: 'Link',
        CLICKED: 'clicked',
        EMAIL: 'Email',
        OPENED: 'opened',
        UNSUBSCRIBED: 'Unsubscribed',
        CONVERTED_FROM: 'Converted from',
        TO: 'to',
        IN_OPPORTUNITIES: 'in Opportunities',
        ADDED_TO: 'Added to',
        REMOVED_FROM: 'Removed from',
        UPDATED_TO: 'updated to',
        WEBFORM: 'Webform',
        SUBMITTED: 'submitted',
        VISITED: 'Visited',
        EXPIRED: 'Expired',
        'Imported': 'Imported',
        'Created': 'Created',
        'Converted to Account': 'Converted to Account',
        'Created from Web form': 'Created from Web form',
        'Created from Landing page': 'Created from Landing page',
        'Created from Zapier': 'Created from Zapier',
        'Created from Outlook': 'Created from Outlook',
        'Created from Outlook (Client)': 'Created from Outlook (Client)',
        'Created from G Suite': 'Created from G Suite',
        'Created from Office 365': 'Created from Office 365',
        'Created from Facebook/twitter etc': 'Created from Facebook/twitter etc',
        'Pushed': 'Pushed',
        'Created from API': 'Created from API',
        'Inline/Quick updated': 'Inline/Quick updated',
        'Updated': 'Updated',
        'Batch Updated': 'Batch Updated',
        'Workflow Applied': 'Workflow Applied',
        'Converted': 'Converted',
        'Assigned': 'Assigned',
        'Deleted': 'Deleted',
        'Restored': 'Restored',
        'Merged': 'Merged',
        'Main Contact updated': 'Main Contact updated',
        'Shared': 'Shared',

        'Link Clicked': 'Link Clicked',
        'Email Opened': 'Email Opened',
        'Unsubscribed': 'Unsubscribed',
        'Web Form Submitted': 'Web Form Submitted',
        'Removed from Group': 'Removed from Group',
        'Field Updated': 'Field Updated',
        'Added to Group': 'Added to Group',
        'Website Visited': 'Website Visited',
        'Has Business Email': 'Has Business Email',
        'Clicked Link': 'Clicked Link',
        'Opened Email': 'Opened Email',
        ACCOUNT_SCORE: 'Your perfect score for Accounts is  ',
        CONTACT_SCORE: 'Your perfect score for Contacts is  '
    },
    DASHBOARD: {
        TODAY: 'Today',
        YESTERDAY: 'Yesterday',
        THIS_WEEK: 'This Week',
        THIS_MONTH: 'This Month',
        THIS_QUARTER: 'This Quarter',
        THIS_YEAR: 'This Year',
        LAST_WEEK: 'Last Week',
        LAST_MONTH: 'Last Month',
        LAST_QUARTER: 'Last Quarter',
        LAST_YEAR: 'Last Year',
        CUSTOM: 'Custom',

        //Kanban View
        NEXT_MONTH: 'Next Month',
        NEXT_QUARTER: 'Next Quarter',
        NEXT_THREE_MONTHS: 'Next three Months',
        NEXT_TWO_MONTHS: 'Next two Months',

        SALES_DASHBOARD: 'Sales Dashboard',
        MARKETING_DASHBOARD: 'Marketing Dashboard',
        OPERATIONS_DASHBOARD: 'Operations Dashboard',
        // SALES_MARKETING_DASHBOARD: 'Sales and Marketing Dashboard',
        REPOSITION_GADGET: 'Click and drag to reposition this gadget',
        'Top Sources By Lead Volume': 'Top Sources By Lead Volume',
        'Top Sources By Lead Conversion': 'Top Sources By Lead Conversion',
        'Marketing Email Summary': 'Marketing Email Summary',
        'Top Marketing Emails by CTR': 'Top Marketing Emails by CTR',
        'Top Web Pages': 'Top Web Pages',
        'Lead Pipeline': 'Lead Pipeline',
        'Opportunity Pipeline': 'Opportunity Pipeline',
        'Sales Leaderboard': 'Sales Leaderboard',

        'Status': 'Status',
        'Count': 'Count',
        'Subject': 'Subject',
        'Click-Through Rate(CTR)': 'Click-Through Rate(CTR)',
        'Website Name': 'Website Name',
        'Forecast': 'Forecast',
        'Sales person': 'Sales person',
        'Closed': 'Closed',

        'Activities': 'Activities',
        'Accounts': 'Accounts Matrix',
        'Opportunities': 'Opportunities Matrix',
        'Users': 'Users',
        'Appointment': 'Appointment',
        'Dialed': 'Dialed',
        'Email': 'Email',
        'LVM': 'LVM',
        'Note': 'Note',
        'Spoke': 'Spoke',
        'Task': 'Task',
        'Transferred': 'Transferred',

        'Qualified': 'Qualified',
        'Presentation': 'Presentation',
        'Proposal': 'Proposal',
        'Negotiation': 'Negotiation',
        'Close': 'Close',

        'Lead': 'Lead',
        'Prospect': 'Prospect',
        'Customer': 'Customer',
        WIDGET_NAME: 'Widget name',
        SELECT_REPORT: 'Select a Report',
        WIDTH: 'Width',
        NEW_DASHBOARD: 'New Dashboad',
        ADD_WIDGET: 'Add a Widget'
    },
    LOGIN: {
        LOG_IN: 'Login',
        EMAIL_ADDRESS: 'Email address',
        PASSWORD: 'Password',
        REMEMBER_ME: 'Remember me'
    },
    SOCIAL_CAMPAIGN: {
        SAVE_AS_DRAFT: 'Save as Draft',
        POST_NOW: 'Post Now',
        CONNECT: 'Connect'

    },
    DOCUMENT_LIBRARY: {
        COMPUTER: 'Computer',
        LIBRARY: 'Library',
        IMAGES: 'Images',
        STOCK_IMAGES: 'Stock Images',
        DOCUMENTS: 'Documents',
        SEARCH: 'Search...',
        UPLOAD_DOCUMENT: 'Upload Document',
        NAME: 'Name',
        TITLE: 'Title',
        TITLE_REQUIRED: 'Title (required)',
        LINK_REQUIRED: 'Link (required)',
        TYPE: 'Type',
        OWNER: 'Owner',
        ALL: 'All',
        NO_IMAGE_FOUND: 'No image found',
        UPLOAD_DIALOG_TITLE: 'Select a document to upload',
        SHARED_WITH: 'pushed with',
        UNITS: 'units',
        UNIT: 'unit',
        UNIT_PUSH: 'Unit:',
        RENAME_FOLDER: 'Rename Folder',
        RENAME_FILE: 'Rename File',
        LIBRARY_RENAME_FILE:"Edit File Link",
        CREATE_FOLDER: 'Create Folder',
        NEW_FOLDER: 'New Folder',
        ASSETS_NOT_FOUND: 'No folder & file found.',
        'Folder': 'Folder',
        'File': 'File',
        IMAGE: 'Image',
        DOCUMENT_AND_FILE: 'Documents & Files',
    },
    HEADER: {
        SALES_TEMPLATE: 'Sales Email Template',
        CALENDAR_TASKS: 'Calendar/Tasks',
        ACCOUNT: 'Account',
        SALES_REPORT: 'Sales Report',
        ACCOUNTS: 'Accounts',
        ACCOUNTS_WITHOUT_GROUP: 'Accounts without Groups',
        MY_ACCOUNT: 'My Accounts',
        TODAYS_ACCCOUNTS: 'Todays Accounts',
        CONTACTS: 'Contacts',
        CONTACTS_WITHOUT_ACCOUNT: 'Contacts without Account',
        CONTACTS_WITHOUT_ACCOUNTS: 'Contacts without Accounts',
        CONTACTS_WITHOUT_GROUPS: 'Contacts without Groups',
        MY_CONTACTS: 'My Contacts',
        ACCOUNTS_WITHOUT_CONTACT: 'Accounts without Contact',
        TODAYS_CONTACTS: 'Todays Contacts',

        ALL_APPOINMENTS: 'All Appointments',
        MY_APPOINMENTS: 'My Appointments',
        MY_COMPLETED_APPOINMENTS: 'My Completed Appointments',
        MY_MISSED_APPOINMENTS: 'My Missed Appointments',
        TODAYS_APPOINMENTS: 'Todays Appointments',

        ALL_TASKS: 'All Tasks',
        MY_COMPLETED_TASKS: 'My Completed Tasks',
        MY_FUTURE_TASKS: 'My Future Tasks',
        MY_PENDING_TASKS: 'My Pending Tasks',
        MY_TODAYS_TASKS: 'My Todays Tasks',
        ONBOARDING: 'Onboarding',
        SALES_PIPELINE:'Sales Pipeline Report',
        COUPONS_CLAIM_REPORTS: 'Coupon Claims Report',
        'Cases': 'Cases',
        'Projects': 'Projects',
        'Issues': 'Issues',
        'Solutions': 'Solutions',
        'Contacts': 'Contacts',
        'Accounts': 'Accounts',
        'Opportunities': 'Opportunities',
        'Appointments': 'Appointments',
        'Tasks': 'Tasks',
        'Onboarding': 'Onboarding',
        'Units': 'Units',
        'Assets': 'Library',
        'Activities': 'Activities',
        'Conversions': 'Conversions',
        'Campaigns': 'Campaigns',
        'Reports': 'Reports',
        'Sales_templates ': 'Sales Templates',
        'Dashboard': 'Dashboard',
        'Sales Leads': 'Sales Leads',
        'Recycle Bin': 'Accounts',
        'News': 'News',
        REPORTS: 'Reports',
        REPORT: 'Report',
        TASKS_PAGE: 'Tasks',
        'Products': 'Products',
        'Landing Pages': 'Landing Pages',

    },
    ADVANCE_SEARCH: {
        ADVANCE_SEARCH: 'Search',
        ADD_FIELD: 'Search Another Field'
    },
    IMPORT: {
        GROUP: 'Group ( Required )',
        WORKFLOW: 'Workflow ( Required )',
        STATUS: 'Status ( Required )',
        SOURCE: 'Source ( Required )',
        PRIMARY_SOURCE:'Primary Source ( Required )',
        SECONDARY_SOURCE:'Secondary Source',
        IMPORT: 'Import',
        TABLE_NAME:'Table',
        LINK_USING: 'Link Using',
        LINK_WITH: 'Link With',
        APPLY_WORKFLOW: 'Apply Workflow',
        AUTO_MAP: 'Auto Map',
        USE_SAVED_MAPPING: 'Use Saved Mapping',
        SAVE_MAPPING: 'Save Mapping',
        DELETE_MAPPING: 'Delete Mapping',
        DONOT_CHECK_DUPLICATE: 'Do not check duplicates',
        CHECK_DUPLICATE: 'Check duplicates',
        CHECK_DUPLICATE_BY: 'Check duplicates by',
        DONOT_IMPORT: 'Do not import',
        MARK_DUPLICATE: 'Import and mark as duplicate',
        UPDATE: 'Update',
        //not found in doc
        STEP_1: 'Select Source',
        STEP_2: 'Map Fields & Import',
        FINISH: 'Finish',
        NEXT: 'Next',
        MAP_CSV_COLUMNS: 'Map CSV Columns to CRM',
        MAP_XLS_COLUMNS: 'Map XLS Columns to CRM',
        MAP_XLSX_COLUMNS: 'Map XLSX Columns to CRM',
        MAP_TO_CRM_FIELD: 'Map to CRM Field',
        COLUMN: 'Column',
        ADD_NEW_GROUP: 'Add a new group',
        WORKFLOW_OPTIONAL: 'Workflow ( Optional )',
        FILE_MUST_CSV: 'The file must be in CSV format.',
        SKIP: 'Skip',
        MERGE: 'Merge',
        CHECK_DUPLICATE_USING: 'Check duplicates using',
        ROUTING:'Routing',
        NONE:'None',
        CUSTOM:'Custom',
        ASSIGN:'Assign'
    },
    REPORT: {
        START_DATE: 'Start Date',
        END_DATE: 'End Date',
        REPORT_ON: 'Report On',
        REFRESH: 'Refresh',
        VIEW: 'View',
        PRINT: 'Print',
        SALES_REP: 'Sales Rep',
        DATE_RANGE: 'Date Range',
        SALES_REPORT: 'Sales Reports',
        SERVICE_REPORT: 'Service Reports',
        'Activity Report': 'Activity Report',
        'Conversion Report': 'Conversion Report',
        'Call Report': 'Call Report',
        'Pipeline Report': 'Pipeline Report',
        'Customer Activation Report': 'Customer Activation Report',
        'Customer Service Type Report': 'Customer Service Type Report',
        'Customer Assessment Report': 'Customer Assessment Report',
        'Customer Leads Assessment Report': 'Customer Leads Assessment Report',
        'Customer Status Report': 'Customer Status Report',
        'Sales Pipeline Report': 'Sales Pipeline Report',
        'Days in Franchise Workflow Report': 'Franchise Workflow Report',
        'Franchise Workflow Report': 'Franchise Workflow Report',
        'Franchise Sales Pipeline Report': 'Franchise Sales Pipeline Report',
        'Franchise Employees Report': 'Franchise Employees Report',
        'Franchise Last Contact Report': 'Franchise Last Contact Report',
        'InXpress User Baseline Report': 'InXpress User Baseline Report',
        'Franchise User Report': 'Franchise User Report',
        'Sales Summary Report': 'Sales Summary Report',
        ACTIVITY_MATRIX: 'Activity Matrix',
        'ACTIVITY_TYPE': 'Activity Type',
        'Service Reports': 'Service Reports',
        'Project Reports': 'Project Reports',
        'CRM Activity by Account': 'CRM Activity by Account',
        'Activity by Franchise': 'Activity by Franchise',
        'Franchise Activity Report': 'Franchise Activity Report',
        'Issue Reports': 'Issue Reports',
        'Customer Service and Sales Rep Listing': 'Customer Service and Sales Rep Listing',
        GO: 'Go',
        'CRM Account Details': 'CRM Account Details',
        'Activity by Coach': 'Activity by Coach',
        'Franchise Conversion Report': 'Franchise Conversion Report',
        'Task Report': 'Task Report',
        'Email Report': 'Email Report',
        'SMS Report': 'SMS Report',
        'Orthotic Report': 'Orthotic Report',
        'Greenlight Export': 'Greenlight Export',
        REPORT_ON_OBJECTS: {
            ACCOUNTS: 'Accounts',
            OPPORTUNITIES: 'Opportunities'
        },
        'Count': 'Count',
        'Grand Total': 'Grand Total',
        ACTIVITIES_BY_TYPE: 'Activities By Type',
        ACTIVITIES_BY_SALES_REP: 'Activities By Sales Rep',
        ALL_CONVERSIONS_BY_STATUS: 'All Conversions By Status',
        CUSTOMERS_BY_SOURCE: 'Customers By Source',
        DEALS_BY_SALES_PER: 'Pipeline by Sales Rep',
        CONVERSION_BY_SALES_REP:'Conversion By Sales Rep',
        CONVERSION_MATRIX:'Conversion Matrix',
        DEALS_BY_STAGES: 'Deals by Stages',
        TOTAL_CALLS_BY_SALES_REP: 'Total Calls By Sales Rep',
        TOTAL_CALL_DURATION_BY_SALES_REP: 'Total Call Duration By Sales Rep',
        EXCEL: 'Excel',
        MATRIX_BY_STATUS: 'Matrix By Status',
        MATRIX_BY_SOURCE: 'Matrix By Source',
        'Status': 'Status',
        'Source': 'Source',
        '5 Ways Report': '5 Ways Report',
        'Forecast Report': 'Forecast Report',
        CASES_BY_STATUS: 'Cases by Status',
        CASES_BY_PRIORITY: 'Cases by Priority',
        PROJECTS_BY_STATUS: 'Projects by Status',
        PROJECTS_BY_TYPE: 'Projects by Type',
        ISSUES_BY_STATUS: 'Issues by Status',
        ISSUES_BY_PRIORITY: 'Issues by Priority',
        SUPPORT_MATRIX: 'Support Matrix',
        CUSTOMER_BY_CASES: 'Customers by Cases',
        PROJECT_MATRIX: 'Project Matrix',
        ISSUE_MATRIX: 'Issue Matrix',
        SUB_TOTAL: 'Sub total',
        SERVICE_MATRIX_HEADER: {
            'Assigned to': 'Assigned to',
            'Total count': 'Total count',
            'Company': 'Company',
            'Assigned To': 'Assigned To',
        },
        WORKFLOW_SELECT: 'Select Workflow',
        COUPONS_NAME: 'Select Coupons',
        PRODUCT_SELECT: 'Select Product',
        AVAILABLE_REPORTS: 'Available Reports',
        WORKFLOW_STAGES: 'Stages',
        CHART_TYPE: 'Chart Type',
        SHOW: 'Show',
        VALUE: 'Value',
        COUNT: 'Count',
        DEALS_BY_SALES_REP: 'Pipeline by Sales Rep',
        PIPELINE:'Pipeline',
    },
    FILTER: {
        NAME_REQUIRED: 'Filter Name (required)',
        REMOVE_CONDITION: 'Remove condition',
        CRITERIA_FOR_ASSOCIATED_OBJECTS:'Criteria for Associated Objects',

        //not found in doc
        NEXT_BUTTON_TEXT: 'Next',
        PREVIOUS_BUTTON_TEXT: 'Previous',
        AND: 'And',
        OR: 'Or',
        ADD_ANOTHER: 'Add another',
        ADD_CONDITION: 'Add condition',
        COPY_FILTER: 'Copy of ',
        FROM_DATE: 'From Date',
        TO_DATE: 'To Date',

        CONTAINS: 'contains',
        DOES_NOT_CONTAINS: 'does not contains',
        IS_EQUAL_TO: 'is equal to',
        IS_NOT_EQUAL_TO: 'is not equal to',
        IS_EMPTY: 'is empty',
        IS_NOT_EMPTY: 'is not empty',

        IS_GREATER_THAN: 'is greater than',
        IS_GREATER_THAN_OR_EQUAL_TO: 'is greater than or equal to',
        IS_LESS_THAN: 'is less than',
        IS_LESS_THAN_OR_EQUAL_TO: 'is less than or equal to',
        'Contacts': 'Contacts',
        'Contacts without Groups': 'Contacts without Groups',
        'My Contacts': 'My Contacts',
        'Contacts without Account': 'Contacts without Account',
        'Todays Contacts': 'Todays Contacts',
        'Contacts without Accounts': 'Contacts without Accounts',
        'All records': 'All records',
        'Added this Month': 'Added this Month',
        'Added this Week': 'Added this Week',
        'My Opportunities': 'My Opportunities',
        'Opportunities': 'Opportunities',
        'Accounts': 'Accounts',
        'Accounts without Contact': 'Accounts without Contact',
        'Accounts without Groups': 'Accounts without Groups',
        'My Accounts': 'My Accounts',
        'Todays Accounts': 'Todays Accounts',
        'Cases': 'Cases',
        'Projects': 'Projects',
        'Issues': 'Issues',
        'Accounts without Contacts': 'Accounts without Contacts',
    },
    SOURCE: {
        SOURCE_NAME: 'Source Name',
        PRIMARY_SOURCE:'Primary Source',
        SECONDARY_SOURCE:'Secondary source',
    },
    INXPRESS_REPORT: {
        COUNTRY_SELECT: 'Select Country',
        FRANCHISE_SELECT: 'Select Franchise',
        WORKFLOW_SELECT: 'Select Workflow',
        START_DATE: 'Start Date',
        NO_RECORDS_FOUND: 'No records found.',
        'Account': 'Account',
        // 'Company Name': 'Company Name',
        'Sales Rep': 'Sales Rep',
        'Workflow': 'Workflow',
        'Status': 'Status',
        REGION_SELECT: 'Select Region',
    },
    KANBAN_REPORT: {
        WORKFLOW: 'Workflow',
        USER: 'User',
        DURATION: 'Duration',
        SORT_BY: 'Sort By',
        REPORT_BY: 'Report By',
        'Forecast amount': 'Forecast amount',
        'Close date': 'Close date',
        'Sales rep': 'Sales rep',
        'Status': 'Status',
        'Convert': 'Convert',
        'Edit': 'Edit',
        'Delete': 'Delete',
        'opportunities': 'opportunities',
        'Probability': 'Probability',
    },
    APP: {
        CURRENT: 'I would use the new version',
        LEGACY: 'I would use the legacy version',
        HEADING: 'Please Choose a Soffront Version',
        SUB_TITLE: 'Your organization has access to our legacy version. Would you like to use it instead of the new version?'
    },
    OTHER_VIEW: {
        SUBSCRIBE_LABEL: 'Subscribe'
    },
    MY_INTEGRATION: {
        CONNECTING: 'Connecting',
        DISCONNECTING: 'Disconnecting',
        SYNC_EMAIL: 'Synchronize Emails',
        SYNC_CONTACT: 'Synchronize Contacts',
        SYNC_CALENDAR: 'Synchronize Calendar',
        APPEND_COMPANY: 'Append Company Name',
        APPEND_CONTACT: 'Append Contact Name',
        CALENDAR: 'Calendar',
        SERVICE: 'Service',
        INTEGRATION_HEADER: 'Email and Calendar Integration',
        PHONE_INTEGRATION: 'Phone Integration',
        MY_INTEGRATIONS: 'Integrations',
        CONTACT_FILTER: 'Contact Filter',
        RINGCENTRAL_HEADER: 'RingCentral',
        SMS_INTEGRATION: 'SMS Integration',
        QUICKBOOKS: 'QuickBooks',
        XERO: 'Xero',
        INTEGRATE: 'Integrate',
        SELECT_INTEGRATION_ITEMS: 'Select the item to integrate',
        INVOICES: 'Invoices',
        PAYMENTS: 'Payments',
        MANNUALLY: 'Manually (CRM > Xero)',
        AUTOMATICALLY: 'Automatically (Xero > CRM)',
        ACCOUNTING_INTEGRATION_LABEL: 'Integrate With',
        ACCOUNTING_INTEGRATION: 'Accounting Integration',
        QUICKBOOKS_INTEGRATION: 'QuickBooks Integration',
        PAYMENT_INTEGRATION: 'Payment Integration',
        EVENTBRITE_INTEGRATION: 'Eventbrite Integration',
        SELECT_A: 'Select a',
        CRM_CONTACT_FILTER_SELECT: 'Select a CRM Contact Filter',
        CONTACT_GROUP_NOT_FIND: 'Cannot find contact group.Please click',
        HERE: 'here',
        TO_TRY_AGAIN: 'to try again.',
        CONTACT_FILTER_NOT_FIND: 'Cannot find any contact filter.',
        SEND_SALES_EMAILS_USING: 'Send sales emails using',
        SYNCHRONIZE: 'Synchronize',
        QB_MANNUALLY: 'CRM > QuickBooks (manually)',
        QB_AUTOMATICALLY: 'QuickBooks > CRM  (automatically)',
        REFUNDS: 'Refunds',
        PHONE_AND_TEXT_INTEGRATION: 'Phone and Text Integration',
        PHONE_AND_TEXT_PROVIDER: 'Choose a provider',
    },
    PROFILE_DETAILS: {
        PROFILE_DETAILS_LABEL: 'Profile',
        PERSONAL_INFORMATION: 'Personal Information',
        USERNAME_LABEL: 'This will be used as the user name.',
        CHANGE_PASSWORD: 'Change Password',
        MANAGE_NOTIFICATIONS: 'Manage Notifications',
        EMAIL_SIGNATURE: 'Email Signature',
        TASKS: 'Tasks',
        APPOINTMENTS: 'Appointments',
        TASK_EMAIL: 'Task Email',
        TASK_POPUP: 'Task Pop up',
        APPOINTMENT_EMAIL: 'Appointments Email',
        APPOINTMENT_POPUP: 'Appointments Pop up',
        ON: 'On',
        OFF: 'Off',
        CHANGE_PHOTO: 'Change photo',
        NEW_PASSWORD: 'New password',
        CONFIRM_PASSWORD: 'Confirm new password',
        NAME_REQUIRED: 'Name (required)',
        EMAIL_REQUIRED: 'Email (required)',
        PHONE: 'Phone',
        TIME_ZONE_REQUIRED: 'Time zone (required)',
        DATE_FORMAT: 'Date Format',
        LANGUAGE: 'Language',
        USER_COLOR: 'User Color', 
    },
    DETAIL_VIEW_CHILD_NOTES: {
        ALL: 'All',
        USER: 'User',
        SYSTEM: 'System',
        MY: 'My',
        ATTACHMENT_INFO: 'Attachment Info',
    },
    DOCUSIGN: {
        CONNECT_BUTTON: 'Connect',
        SAVE_BUTTON: 'Save',
        CANCEL_BUTTON: 'Cancel',
        USERNAME: 'Username (required)',
        PASSWORD: 'Password (required)',
        INTREGATOR_KEY: 'Integrator Key (required)',
        SELECT_TEMPLATE: 'Select Template',
        SEND: 'Send',
        DISCONNECT_BUTTON: 'Disconnect',
        DOCUSIGN_SETUP_HEADER: 'DocuSign Integration',
        DOCUSIGN_CONNECT_TITLE: 'Connect to your DocuSign account',
        ADD_NOTE: 'Add a Comment (optional).',
    },
    TWILIO: {
        CALL: 'Call',
        VERIFY: 'Verify',
        DISCONNECT: 'Disconnect',
        SHOW_SCRIPTS: 'Show Scripts',
        HIDE_SCRIPTS: 'Hide Scripts',
        CALL_SETUP: 'Call Setup',
        CALL_SCRIPT: 'Call Scripts',
        CALL_SCRIPT_COPY_TAB_LABEL: 'Copy Call Scripts',
        COPY_OF: 'Copy of',
        ADD_NUMBERS: 'Add your outbound phone numbers',
        OUTBOUND_PHONE_NUMBERS: 'Outbound Phone Numbers',
        ADD_ANOTHER_NUMBER: 'Add another number',
        CALL_RECORDING: 'Call Recording',
        PHONE_CREDITS: 'Your Phone Credits',
        PURCHASE_CREDITS_YOU_HAVE: 'You have',
        PURCHASE_CREDITS_MINUTES_LEFT: 'minutes left.',
        PURCHASE_CREDITS_MORE: 'Purchase More Credits.',
        CALLING: 'Calling',
        RECORD_ALL_CALL: 'Record all calls',
        VERIFIED: 'Verified',
        CLICK_TO_CALL: 'I am ready to take the call',
        SCRIPTS: 'Scripts',
        VOICE_MAILS: 'VMs',
        END_CALL: 'End Call',
        ADDITIONAL_INFO: 'Show additional information',
        ADDITIONAL_INFO_TITLE: 'Additional information',
        SELECT_FIELD: 'Select fields (Maximum 3 fields)'
    },
    CALL_SCRIPT: {
        ADD_SCRIPT_NAME: 'Script Name (required)',
        ADD_DETAILS: 'Script Details (required)',
        WORKFLOW_NAME: 'Workflow Name',
        CALL_SCRIPTS_EDIT_TAB_LABEL: 'Edit Call Scripts',
    },
    TWILIO_SMS: {
        ZIP_CODE: 'Zip Code',
        AREA_CODE: 'Area Code',
        ADD_TO_ACCOUNT: 'Add',
        SMS_CREDITS: 'Your SMS Credits',
        SMS_CREDITS_LEFT: 'SMS credits left.',
        SEND: 'Send',
        NO_NUMBER_PURCHASED: 'You don\'t have any phone numbers yet. Use the find option to purchase phone numbers. After purchasing, you will be able to send messages using the numbers.',
        FIND_PHONE_NUMBER: 'Find phone numbers by zip code or phone area code',
        FIND_PHONE_NUMBER_BY_AREA_CODE: 'Find phone numbers by area code',
        FIND_TOLL_FREE_NUMBERS: 'Find toll-free numbers',
        SEND_SMS: 'Send Text Message',
        YOUR_MESSAGE: 'Your message',
        OR: 'OR',
        PHONE_NUMBER: 'Number',
        PHONE: 'Phone',
        AVAILABLE_TO: 'Available To',
        USER_NAME: 'User Name',
        USED_BY: 'Used By',
        UNIT_NAME: 'Unit Name',
    },
    QUICKBOOKS: {
        CONNECT_BUTTON: 'Connect',
        LABEL: 'Connect to your QuickBooks Online account'
    },
    PAYMENT: {
        CONNECT_BUTTON: 'Connect',
        DISCONNECT_BUTTON: 'Disconnect',
        LABEL: 'Setup payments using Stripe',
        STRIPE : 'Stripe',
        MONERIS : 'Moneris',
        STORE_ID: 'Store ID',
        API_TOKEN: 'API Token',
        CHECKOUT_ID: 'Checkout ID',
        DEFAULT_PAYMENT_METHOD: "Default Payment Method",
        CONNECT_BUTTON: 'Connect'
    },
    PREVIEW_EMAIL_TEMPLATE: {
        ALL_LAYOUT: 'All Layouts',
        LEFT_SIDEBAR_LAYOUT: 'Left Sidebar Layout',
        RIGHT_SIDEBAR_LAYOUT: 'Right Sidebar Layout',
        HORIZONTAL_LAYOUT: 'Horizontal Layout',
        MULTIPLE_COLUMN_LAYOUT: 'Multiple Column Layout'
    },
    EVENTBRITE: {
        CONNECT_BUTTON: 'Connect',
        SELECT_ORGANISATIONS: 'Synchronize events from this organization',
        EVENTBRITE_CONNECT_TITLE: 'Connect to your Eventbrite account',
        SELECT: 'Select',
        EVENTBRITE_TOGGLE_TITLE: 'During synchronization, add new contacts if not found in CRM',
        EVENTBRITE_MAP_FIELDS: 'Map Fields',
        CRM_FIELDS_TITLE: 'CRM Contact Fields',
        EVENTBRITE_FIELDS_TITLE: 'Eventbrite Fields',
    },
    SIDE_NAV: {
        APPOINTMENTS: 'Calendar/Tasks',
        DASHBOARD: 'Dashboard',
        REPORTS: 'Reports',
        SALES_TEMPLATES: 'Sales Email Templates',
        SOCIAL_MARKETING: 'Social Marketing',
        WEBSITE_TRACKING: 'Website Analytics',
        SOLUTIONS: 'Solutions',
        CONTACTS: 'Contacts',
        ACCOUNTS: 'Accounts',
        OPPORTUNITIES: 'Opportunities',
        PRODUCTS: 'Products',
        CASES: 'Cases',
        PROJECTS: 'Projects',
        ISSUES: 'Issues',
        ONBOARDING: 'Onboarding',
        UNITS: 'Units',
        ASSESTS: 'Library',
        OPERATIONS_ACTIVITIES: 'Activities',
        OPERATIONS_CONVERSIONS: 'Conversions',
        OPERATIONS_CAMPAIGNS: 'Campaigns',
        OPERATIONS_REPORTS: 'Reports',
        LEAD_CAPTURE: 'Lead Capture',
        EMAIL_MARKETING: 'Email Marketing',
        'CALENDAR/TASKS': 'Calendar/Tasks',
        'SALES REPORTS': 'Reports',
        'SALES TEMPLATES': 'Sales Templates',
        'SOCIAL MARKETING': 'Social Marketing',
        'ONLINE REPUTATION': 'Online Reputation',
        DASHBOARDS: 'Dashboards',
        'SALES LEADS': 'Sales Leads',
        'USEFUL LINKS': 'Useful Links',
        NEWS: 'News',
        LIBRARY: 'Library',
        COMMUNITY: 'Community',
        TRAINING: 'Training',
        SCROLL_TO_RIGHT: 'Scroll to right',
        SCROLL_TO_LEFT: 'Scroll to left',
        Royalties: 'Royalties',
        INBOX: 'Inbox',
    },
    NO_RECORD: {
        'Solutions': 'Solutions',
        'Appointments': 'Appointments',
        'Tasks': 'Tasks',
        'Groups': 'Groups',
        'Notes': 'Notes',
        'Attachments': 'Attachments',
        'Marketing-templates': 'Marketing-templates',
        'Sales-templates': 'Sales-templates',
        'Web-forms': 'Web-forms',
        'Products': 'Products',
        'Broadcast-campaigns': 'Broadcast-campaigns',
        'Ab-campaigns': 'Ab-campaigns',
        'Automation-designer': 'Automation-designer',
        'Projects': 'Projects',
        'Invoices': 'Invoices',
        'Iouch-campaigns': 'Touch-campaigns',
        'Events': 'Events',
        'Sms-templates': 'SMS-Templates',

        'Emails': 'Emails',
        'Relationships': 'Relationships',
        'Templates': 'Templates',

        'Cases': 'Cases',
        'Contacts': 'Contacts',
        'Accounts': 'Accounts',
        'Opportunities': 'Opportunities',
        'Issues': 'Issues',
        'Activities': 'Activities',
        'Conversions': 'Conversions',
        'Salesleads': 'Sales Leads',
        'Source': 'Source',
        'Users': 'Users',
        'Securitylevel': 'Security Levels',
        'Workflows': 'Workflows',
        'Relations': 'Relations',
        'Notetypes': 'Note Types',
        'Appointmenttypes': 'Appointment Types',
        'Campaigntypes': 'Campaign Types',
        'Emailintegration': 'Email Aliases',
        'Library': 'Library',
        'Recyclebin': 'Accounts',
        'Sms': 'Sms',
        'Assignmentrules': 'Assignment Rules',
        'Units': 'Units',
        'Orderitems': 'Order Items',
        'Landing-pages-froala': 'Landing Pages',
        'Claims': 'Claims',
        'Payments': 'Payments',
    },
    REPORT_SCHEDULE_DIALOG: {
        REPORT_SCHEDULE_SETUP: 'Report Schedule',
        DAY_OF_THE_MONTH: 'day of the month',
        DAY_OF_THE_WEEK: 'day of the week',
        REPEAT_TYPE: 'Repeat type (Required)',
        REPEAT_ON: 'Repeat on',
        REPEAT_BY: 'Repeat by',
        START_DATE: 'Start date (Required)',
        END_DATE: 'End date',
        REPEAT_EVERY: 'Repeat every (Required)',
        SEND_TO: 'Send To (Required)',
        SCHEDULE: 'Schedule',
    },
    LIST_VIEW_HEADER: {
        AUTOMATION_DESIGNER: {
            'Name': 'Name',
            'Status': 'Status',
            'Created By': 'Created By',
            'Created On': 'Created On',
            'Updated By': 'Updated By',
            'Updated On': 'Updated On',
        },
        APPOINTMENT: {
            'Subject': 'Subject',
            'Company': 'Company',
            'Status': 'Status',
            'Contact': 'Contact',
            'Contact Name': 'Contact Name',
            'Start date': 'Start date',
            'End date': 'End date',
            'Assign to': 'Assign to',
            'Visible to': 'Visible to',
            'Outlook Sync': 'Outlook Sync',
            'Google Sync': 'Google Sync',
            'Text': 'Text',
            'Creator': 'Creator',
            'Created on': 'Created on',
            'Updated by': 'Updated by',
            'Updated on': 'Updated on',
        },
        TASKS: {
            'Task Name': 'Task Name',
            'Company': 'Company',
            'Status': 'Status',
            'Previous Task': 'Previous Task',
            'Complete Date': 'Complete Date',
            'Owner': 'Owner',
            'Due By': 'Due By',
            'Contact Name': 'Contact Name',
            'Description': 'Description',
            'Updated on': 'Updated on',
            'Contact': 'Contact',
            'Start Time': 'Start Time',
            'End Time': 'End Time',
            'Item': 'Item',
            'Billable': 'Billable',
        },
        BROADCAST: {
            'Broadcast Name': 'Broadcast Name',
            'Status': 'Status',
            'Run Date': 'Run Date',
            'Owner': 'Owner',
            'Created On': 'Created On',
            'Updated On': 'Updated On',
            'Target': 'Target',
            'From email': 'From Email',
            'From name': 'From Name',
            'Updated By': 'Updated By',
            'Created By': 'Created By',
        },
        AB_CAMPAIGN: {
            'Campaign Name': 'Campaign Name',
            'Created By': 'Created By',
            'Created On': 'Created On',
            'Run Date': 'Run Date',
            'Status': 'Status',
            'Target Filter': 'Target Filter',
            'Updated By': 'Updated By',
            'Updated On': 'Updated On',
            
        },
        SALES_TEMPLATE: {
            'Name': 'Name',
            'Category': 'Category',
            'Subject': 'Subject',
            'Status': 'Status',
            'Owner': 'Owner',
            'Created On': 'Created On',
            'Updated By': 'Updated By',
            'Updated On': 'Updated On',
        },
        MARKETING_TEMPLATE: {
            'Name': 'Name',
            'Category': 'Category',
            'Subject': 'Subject',
            'Status': 'Status',
            'Owner': 'Owner',
            'Created On': 'Created On',
            'Updated By': 'Updated By',
            'Updated On': 'Updated On',
        },
        WEB_FORM: {
            'Name': 'Name',
            'Table Name': 'Table Name',
            'Group': 'Group',
            'Source': 'Source',
            'Status': 'Status',
            'Assign To': 'Assign To',
        },
        TOUCH_CAMPAIGNS: {
            'Campaign name': 'Campaign name',
            'Status': 'Status',
            'Owner': 'Owner',
            'Target': 'Target',
            'From email': 'From email',
            'Created On': 'Created On',
            'Created By': 'Created By',
            'From name': 'From name',
            'Updated On': 'Updated On',
            'Updated By': 'Updated By',
            'Resend All': 'Resend All',
            'Resend After': 'Resend After',
        },
        ACCOUNTS: {
            'Status': 'Status',
        },
        CONTACTS: {
            'Status': 'Status',
        },
        OPPORTUNITIES: {
            'Status': 'Status',
        },
        PROJECTS: {
            'Status': 'Status',
        },
        ISSUES: {
            'Status': 'Status',
        },
        CASES: {
            'Status': 'Status',
        },
        SOLUTIONS: {
            'Status': 'Status',
        },
        SALES_LEADS: {
            'Status': 'Status',
        },
    },
    DEFAULT_NOTE_TYPES: {
        'Appointment': 'Appointment',
        'Dialed': 'Dialed',
        'Email': 'Email',
        'LVM': 'LVM',
        'Note': 'Note',
        'Spoke': 'Spoke',
        'Task': 'Task',
        'Transferred': 'Transferred',
    },
    VIEW_TOGGLE: {
        LIST: 'List',
        COLUMN: 'Columns',
        LIST_TITLE: 'Switch to List View',
        COLUMN_TITLE: 'Switch to Column View',
        TIMELINE: 'Timeline',
        TIMELINE_TITLE: 'Switch to Timeline View',
        DETAILS: 'Details',
        DETAILS_TITLE: 'Switch to Details View',
    },
    EMAIL_MARKRTING_REPORT: {
        REPORT_HEADER: 'Email Marketing Report',
        MY_CAMPAIGNS: 'My Campaigns',
        MY_LOCATION_CAMPAIGNS: 'My Unit’s Campaigns',
        RECENT_CAMPAIGNS: 'Recent Campaigns',
        BROADCAST_REPORT: 'Broadcast Campaign Report',
        TOUCH_REPORT: 'Touch Campaign Report'
    },
    DOCUMENT_PUSH: {
        DIALOG_TITLE: 'Push to',
        DOCUMENT_NOT_FOUND: 'No Document found.'
    },
    OPERATIONS_REPORT: {
        REQUEST_NEW_REPORT: 'Request a New Report'
    },
    OPERATIONS_CAMPAIGNS: {
        REPORT_HEADER: 'Email Campaigns',
        BROADCAST_REPORT: 'Broadcast Campaigns',
        TOUCH_REPORT: 'Touch Campaigns'
    },
    OPERATIONS_RECENT_CAMPAIGNS: {
        REPORT_HEADER: 'Recent Email Campaigns',
        BROADCAST_REPORT: 'Broadcast Campaigns',
        TOUCH_REPORT: 'Touch Campaigns'
    },
    TASKS_REPORT: {
        TASKS_REPORT_LABEL: 'Task Report'
    },
    EMAIL_REPORT: {
        EMAIL_REPORT_LABEL: 'Email Report',
        ERROR_REASON: 'Reason'
    },
    SMS_REPORT: {
        SMS_REPORT_LABEL: 'SMS Report'
    },
    ORTHOTIC_REPORT: {
        ORTHOTIC_REPORT_LABEL: 'Orthotic Report'
    },
    BATCH_SMS: {
        MESSAGE: 'Your Message',
        SEND: 'Send',
        CHARS: ' chars',
        SCHEDULE: 'Schedule',
        SCHEDULE_SMS: 'Schedule Text message',
        SEND_LATER: 'Send Later'
    },
    SETTING_MENU: {
        MARKETING: 'Marketing',
        SECURITY: 'Users and Security',
        DATA: 'Data',
        CUSTOMIZATION: 'Customization',
        INTEGRATION: 'Integration',
        COMPANY: 'Company',
        WORKFLOW_RULES: 'Workflows & Rules',
        LISTS: 'Lists',
        FRANCHISE: 'Franchise',
        COMPANY_SCHEDULER: 'Company Scheduler'
    },
    MARKETING_SETTING: {
        'SOURCE': 'Sources',
        'LIBRARY': 'Library',
        'LEAD_SCORE': 'Lead Scores',
        'SCORES': 'Scores'
    },
    SECURITY_SETTING: {
        'USERS': 'Users',
        'SECURITY_LEVELS': 'Security Levels'
    },
    DATA_SETTING: {
        'EXPORT': 'Export',
        'RECYCLE_BIN': 'Recycle Bin',
        'FIND_DUPLICATES': 'Find Duplicates'
    },
    CUSTOMIZATION_SETTING: {
        'CHANGE_LOGO': 'Change Logo',
        'DATA_BASE': 'Database',
        'WORKFLOWS': 'Workflows',
        'RELATIONS': 'Relations',
        'AUTO_FILL_VALUES': 'Auto-Fill Values',
        'NOTE_TYPES': 'Task & Note Types',
        'CAMPAIGN_TYPES': 'Campaign Types',
        'CORPORATE_CURRENCY': 'Corporate Currency',
        'HUB_SETTING': 'HUB',
        'COMPANY_PROFILE': 'Company Profile',
        'REVIEW_WEBSITE' : 'Surveys',
        'WHITE_LABEL': 'White Label',
        'API_KEY': 'API Key',
        'RE_API_KEY': 'Re Generate API Key',
        'GENERATED_ON': 'Generated On',
        'UPDATED_ON': 'Updated On',
        'MENU': 'Menu',
        'DESKTOP_MENU': 'Desktop Menu',
        'MOBILE_MENU': 'Mobile Menu',
        'PHONE_NUMBERS': 'Phone Numbers',
        'DESIGN_MENUS': 'Design Menus',
        'MENU_NAME': 'Menu Name',
    },
    INTEGRATION_SETTING: {
        'MICROSOFT_OUTLOOK': 'Microsoft Outlook (Desktop)',
        'GENERATE_API': 'Generate API',
        'EMAIL_INTEGRATION': 'Email Integration'
    },
    SETTINGS: {
        ADDED: 'added',
        EDIT: 'updated',
        SUCCESSFULL: 'Successfully.',
        'SOURCE': 'Source',
        'NOTETYPES': 'Task & Note Types',
        'CAMPAIGNTYPES': 'Campaign Type',
        'CORPORATECURRENCY': 'Corporate Currency',
        'RELATIONS': 'Relation',
        'LIBRARY': 'Library',
        'USERS': 'Users',
        'SECURITYLEVEL': 'Security Levels',
        'WORKFLOWS': 'Workflows',
        'EMAILINTEGRATION': 'Email Aliases',
        'RECYCLEBIN': 'Recycle Bin',
        'ASSIGNMENTRULES': 'Assignment Rules',
        // 'AVAILABILITY':'Availability',
        'APPOINTMENT_TYPES': 'Appointment Types',
        'LOCATION':'Location',
        'TASKINFO':'Task',
        'INTAKE_FORM' : 'Intake Form',
        'SCHEDULER_LINK': 'Scheduler Link',
        // 'APPOINTMENTTYPES': 'Appointment Type',
        'ROLLUP': 'Roll Up Rules',
        'SUBSCRIPTION': 'Subscription',
        'UPGRADE': 'Upgrade Subscription',
        'BUYPRODUCT': 'Buy Products & Training',
        'BUYPROSERVICE': 'Buy Professional Services',
        'BILLING': 'Billing and Invoice Details',
        'CANCELSUB': 'Cancel Subscription',
        'SUBDETAIL': 'Subscription Details',
        'PURCHESEMAILCREDIT': 'Purchase Email Credits',
        'PURCHESPHONECREDIT': 'Purchase Phone Credits',
        'PURCHESSMSCREDIT': 'Purchase SMS Credits',
        'PURCHESCARDCREDIT': 'Purchase Card Scanning Credits',
        'PURCHESCONTACTCREDIT': 'Purchase More Contacts',
        'PURCHESTRANINGCREDIT': 'Purchase Training',
        'PURCHESMERKETINGSERVICE': 'Buy Marketing Services',
        'PURCHESPROFESSONALSERVICE': 'Buy Professional Services',
        'SETUP': 'My Settings',
        'APPOINTMENTTYPES': 'Appointment Types',
        SHOW_LOGO: 'Show logo',
        SHOW_SIGNOUT_LINK: 'Show Signout link',
        THEME_COLOR: 'Theme Color',
        LOGO_COLOR: 'Logo Background',
        REPORTED_SALES_SOURCE: 'Source',
        AUDIT_QUESTIONS: 'Audit Questions',
        LMS_USERS: 'LMS Users',
        AUDIT_CHECKLISTS:'Audit Checklists',
        WEBHOOKS: 'Webhooks',
        TEMPLATE_CATEGORIES: 'Template Categories',
    },
    CORPORATE_CURRENCY: {
        SELECT_CURRENCY_NAME: 'Select a corporate currency'
    },
    UNSUBSCRIBE: {
        EMAILS: 'Emails',
        TEXTS: 'Texts',
        BOTHS: 'Both'
    },
    HUB_SETUP: {
        SETUP_FIELD_NAME: 'Hub Name',
        USEFUL_LINK: 'Useful Links',
        CASES: 'Cases',
        SETUP_NAME: 'HUB Setup',
        NEWS: 'News',
        LIBRARY: 'Library',
        COMMUNITY: 'Community',
        TRAINING: 'Training',
        HYPER_LINK: 'hyperlink',
        SET: 'Set',
        MANNUAL: 'Mannual'
    },
    UNITS: {
        TASK_DETAILS: 'Tasks Details',
        'Unit': 'Unit',
        'Contact Name': 'Contact Name',
        'Done': 'Done',
        'Open': 'Open',
        'Tasks Name': 'Tasks Name',
        'Due By': 'Due By',
        'Status': 'Status',
        UNIT_DETAILS_LABEL: 'Unit Details',
        FIRST_NAME: 'First Name (required)',
        LAST_NAME: 'Last Name (required)',
        PASSWORD: 'Password (required)',
        RETYPE_PASSWORD: 'Re-type Password (required)',
        EMAIL: 'Email (required)',
        PHONE: 'Phone (required)',
        COMPANY: 'Company (required)',
        TIME_ZONE_SELECT: 'Timezone',
        COUNTRY_SELECT: 'Country',
        STATE_SELECT: 'State',
        FA_EXPIRATION_DATE: 'FA Expiration Date',
        PLAN_NAME: 'Plan Name',
        NAME:'Name',
    },
    ASSIGNMENT_RULES: {
        SETUP_FIRST_RULE: 'Setup your first rule',
        ASSIGNMENT_RULE: 'Assignment Rule',
        ASSIGNMENT_RULE_CREATE_TAB_LABEL: 'Create Assignment Rule',
        ASSIGNMENT_RULE_EDIT_TAB_LABEL: 'Edit Assignment Rule',
        NAME_REQUIRED: 'Name (required)',
        ASSIGNMENT_RULES: 'Assignment Rules',
        STATUS: 'Status',
        SETUP_RULE: 'Setup Assignment Rule',
        USE_RULE: 'Use Assignment Rule',
        TABLE_NAME: 'Table Name',
        SELECT_UNIT_WORKFLOW: 'select unit workflow',
        WORKFLOW_NAME: 'Workflow Name',
    },
    COUPONS: {
        PREFIX: 'Prefix',
        'Coupon Name': 'Coupon Name',
        'Discount Value': 'Discount Value',
        EXPIRATION_DATE: 'Expiration Date',
        PODUCT_SERVICE: 'Product/Service (optional)'

    },
    COUPONS_CLAIM_REPORTS: {
        COUPONS_CLAIM_REPORTS_LABEL: 'Coupon Claims Report',
        COUPONS_REPORT: 'Report'
    },

    NOTIFICATION_REMINDERS: {
        NOTIFICATION_REMINDERS: 'Alerts & Notifications',
        NOTIFY_ME: 'Notify Me When...',
        REMIND_ME: 'Remind Me Before...',
        NOTIFY_EMAIL_OPEN: 'My email is opened',
        NOTIFY_EMAIL_CLICK: 'My email is clicked',
        NOTIFY_TEXT_REPLY: 'My text is replied',
        REMIND_UPCOMING_APPOINTMENT: 'Upcoming appointments',
        REMIND_UPCOMING_TASK: 'Upcoming tasks',
        ADD_NEW_REMINDER: 'Add a new Reminder',
        REMIND: 'Remind',
        DAY_BEFORE: 'day(s) before',
        MY_WEB_FORM_SUBMITTTED: 'My web form is submitted',
        NO_DATE_FIELD: 'No available date fields',
    },

    PIPELINE_REPORT: {
        PIPELINE_REPORT_LABEL: 'Pipeline Report',
        SALES_SUMMARY_REPORT: 'Sales Summary Report'
    },
    EMAIL_INTEGRATION: {
        EMAIL: 'Email Aliases',
    },
    NEWS: {
        HEADER: 'News',
        STATUS: {
            DRAFT: 'Draft',
            PUBLISHED: 'Published'
        },
        EDIT_TAB_LABEL: 'Edit News',
        ADD_TAB_LABEL: 'Add News',
        CATEGORY_REQUIRED: 'Category (required)',
        STATUS_REQUIRED: 'Status (required)',
        TITLE_REQUIRED: 'Title (required)',
        SUMMARY_REQUIRED: 'Summary (required)',
        EXTERNAL_LINK_REQUIRED: 'External Link (required)',
        UPLOAD_IMAGE: 'Upload an Image',
        EXTERNAL_LINK: 'External Link',
        ENTER_DETAILS: 'Enter Details'
    },
    USEFUL_LINKS: {
        USEFUL_LINKS: 'Useful Links',
        CATEGORY: 'Category',
        LINK: 'Link',
        TITLE: 'Title',
        NO_RECORD_FOUND: 'No Useful Links Found.',
    },
    REPORTS: {
        NO_REPORTS_FOUND: 'No Report found.',
    },
    AUTO_FILL_VALUES: {
        SELECT_PARENT: 'Select a parent',
        SELECT_CHILD: 'Select a child',
        PARENT_FIELDS: 'Parent Object Fields',
        CHILD_FIELDS: 'Child Object Fields',
        SELECT_PARENT_FIELD: 'Select a parent field',
        SELECT_CHILD_FIELD: 'Select a child field',
    },
    WORKFLOW_AUTOMATION: {
        TOUCH_CAMPAIGN_REPORT: 'Touch Campaign Report',
        INTERVAL: 'After',
        NOTE_TYPE: 'Note type',
        TEXT_REQUIRED: 'Note Text (required)',
        TASK_NAME_REQUIRED: 'Task name (required)',
        OWNER_REQUIRED: 'Owner (required)',
        DUE_BY_REQUIRED: 'Due by (required)',
        DAY_AFTER_REQUIRED: 'Days after (required)',
        EMAIL: 'Email',
        POP_UP: 'Pop-up',
        MINUTES: 'minutes',
        HOURS: 'hours',
        DAYS: 'days',
        WEEKS: 'weeks',
        REMINDER: 'Reminder',
        GROUP_REQUIRED: 'Select Group (required)',
        ADD_TO_GROUP: 'Add to Group',
        MOVE_TO_GROUP: 'Move to Group',
        REMOVE_FROM_GROUP: 'Remove from Group',
        STATUS_REQUIRED: 'Status (required)',
        WORKFLOW_REQUIRED: 'Workflow (required)',
        CHOOSE_ANOTHER_FIELD: 'Choose another field',
        CHOOSE: 'Choose',
        FIELD_FOR: 'a field for',
        CAMPAIGN_NAME: 'Campaign Name (required)',
        START: 'Start',
        STOP: 'Stop',
        REPORT: 'Report',
        RESUME: 'Resume',
        SELECT_PAGE: 'Select a Page',
        ENTER_WEB_PAGE_LINK_OPTIONAL: 'Enter the URL of the link (optional).',
        ENTER_WEB_PAGE_LINK: 'Enter the web page link.',
        CLICK_TO_VIEW: 'Click here to view',
        CLICK_TO_EDIT: 'Click here to edit',
        DRAG_TO_MOVE: 'Drag to move',
        DRAG_TO_CONNECT: 'Drag to connect',
        CLICK_TO_REMOVE: 'Click here to remove',
        CLICK_TO_SEE_REPORT: 'Click here to see report',
        CLICK_TO_ADD_MSG: 'Click to add new message',
        NO: 'No',
        YES: 'Yes',
        CLICK_TO_SELECT_USERS: 'Click here to select users',
        SALES_REP: 'Sales Rep',
        USERS: 'Users',
        CAMPAIGN_SENDER: 'Campaign Sender',
        SCORE: 'Score',
        START_WITH: 'Add',//'Start with'
        DECISIONS: 'Decisions',
        ACTIONS: 'Actions',
        LINK_CLICKED: 'Link Clicked?',
        SCORE_REACHED: 'Score Reached?',
        TOUCH_CAMPAIGN: 'Touch Campaign',
        WEB_FORM: 'Web Form',
        LANDING_PAGE: 'Landing Page',
        WEBSITE_VISITED: 'Website Visited',
        CHANGE_GROUP: 'Change Group',
        ADD_NOTE: 'Add Note',
        NOTIFY: 'Notify',
        ADD_TASK: 'Add Task',
        FIELD_UPDATED: 'Update Fields',
        CHOOSE_TEMPLATE: 'Choose a Template',
        USE_THIS_TEMPLATE: 'Use this template',
        WAIT: 'Wait',
        NAME: "Name",
        TARGET_GROUPS: 'Target Group(s)',
        TARGET_FILTER: 'Target Filter',
        RESEND_AFTER: 'Resend After',
        START_DATE: 'Start Date',
        SUBJECT: 'Subject',
        TEXT: 'Text',
        TASK_NAME: 'Task Name',
        ASSIGN_TO: 'Assign to',
        FIELD_LABEL: 'Field Label',
        FIELD_VALUE: 'Field Value',
        LINK: 'Link',
        SEND_ON_ANY_TIME: 'Send on: Any time',
        SEND_ON_BUSINESS_DAY: 'Send on: Only on business days',
        CONVERT_TO: 'Convert to',
        QUALIFIER: 'Qualifier',
        OBJECT: 'Object',
        NOTIFY_SENDER: 'Notify Sender',
        NOTIFY_SALES_REP: 'Notify Sales Representative',
        NOTIFY_SENDER_SALESREP: 'Notify Sender, Notify Sales Representative',
        NOTIFY_USERS: 'Notify Users',
        TEST: 'Test',
        VIEW_AS_WEBPAGE_INFO: 'This option will add a link at the top of the email to open the email as a web page',
        LINK_CLICK_INFORMATION: 'If you leave this blank, all links in the email will be tracked.',
        GROUP_NAME: 'New Group Name',
        SELECT_USER: 'Assign to',
        REMIND_BEFORE: 'Remind before',
        INTERVAL_REQUIRED: 'Interval (required)',
        //not found in doc
        VERIFY_EMAIL_ADDRESS_OPTION: 'Verify another email address',
        'Touch Campaign': 'Touch Campaign',
        'Required Tasks Done': 'Required Tasks Done',
        'Not Converted': 'Not Converted',
        'Link Clicked': 'Link Clicked',
        'Link Clicked?': 'Link Clicked?',
        'Email Opened': 'Email Opened',
        'Email Opened?': 'Email Opened',
        'Unsubscribed': 'Unsubscribed',
        'Score Reached': 'Score Reached',
        //'Send One-off Email': 'Send One-off Email',
        'Send Email': 'Send Email',
        'Change Group': 'Change Group',
        'Add Note': 'Add Note',
        'Notify': 'Notify',
        'Add Task': 'Add Task',
        'Update Fields': 'Update Fields',
        'Assign': 'Assign',
        'Convert': 'Convert',
        'Start': 'Start',
        'Add Touch Message': 'Add Touch Message',
        'Web Form': 'Web Form',
        'Website Visit': 'Website Visit',
        'Landing Page': 'Landing Page',
        'Wait': 'Wait',
        WAIT_FOR_DAYS: 'day(s)',
        'Simple email nurturing': 'Simple email nurturing',
        'Capture leads using a web form and nurture': 'Capture leads using a web form and nurture',
        'Track web page visit and nurture': 'Track web page visit and nurture',
        'Capture leads from a landing page and nurture': 'Capture leads from a landing page and nurture',
        'Start with tracking website visit': 'Start with tracking website visit',

        COPY_OF: 'Copy of ',
        AUTOMATION_CAMPAIGN_CREATE_TAB_LABEL: 'New Automated Campaign',
        TARGET_BY: 'Target by',
        'Hours': 'Hours',
        'Hour(s)': 'Hour(s)',
        'day(s)': 'day(s)',
        'hour(s)': 'hour(s)',
        ALL_AUTOMATED_CAMPAIGNS: 'All Automated Campaigns',
        MESSAGE: 'Message',
        SELECT_A_CAMPAIGN: 'Please select a campaign',
        ADD_SIGNATURE: 'Add Signature',
        BIRTHDAY_CAMPAIGN: 'Birthday Campaign',
        'Add Birthday Message': 'Add Birthday Message',
        'Start with Birthday Campaign': 'Start with Birthday Campaign',
        'SMS Replied': 'SMS Replied',
        'Send SMS': 'Send SMS',
        PET_BIRTHDAY_CAMPAIGN: 'Pet Birthday Campaign',

        'Touch Campaign Automation': 'Touch Campaign Automation',
        'Birthday Campaign Automation': 'Birthday Campaign Automation',
        'Web Form Automation': 'Web Form Automation',
        'Landing Page Automation': 'Landing Page Automation',
        'Pet Birthday Greetings': 'Pet Birthday Greetings',


        SEND_SMS: 'Send SMS',
        SMS_REPLY: 'SMS Replied?',
        BIRTHDAY_CAMPAIGN_REPORT: 'Birthday Campaign Report',
        START_IMMEDIATELY: 'Immediately',
        START_SCHEDULE: 'Schedule',
        SCHEDULE_ON_TEXT: 'Scheduled to start on',
        SCHEDULE_DIALOG_TITLE: 'Select schedule date and time',
        FROM_NUMBER: 'From Number',
        REPLY: 'Reply',
        NOT_CONVERTED: 'If not conveted with in',
        DAYS_AFTER: 'Days After'
    },
    LANDING_PAGE: {
        LANDING_PAGE: 'Landing Page',
        LANDING_PAGES: 'Landing Pages',
        NAME_REQUIRED: 'Name (required)',
        URL_REQUIRED: 'URL (required)',
        LANDING_PAGE_FORMS: 'Form Builder',
        EDIT_LANDING_PAGE: 'Edit Landing Page',
        COPY_LANDING_PAGE: 'Copy Landing Page'
    },
    FIND_DUPLICATES: {
        FIND_DUPLICATES: 'Find Duplicates',
        SELECT_OBJECT: 'Select object',
        SELECT_FIELD: 'Select field',

    },
    SCHEDULER: {
        APPOINTMENT_SCHEDULER: 'My Scheduler',
        JOB_SCHEDULER: 'Job Scheduler',
        APPOINTMENT_INFO: 'Appointment Info',
        MY_AVAILABILITY: 'My Availability',
        AVAILABILITY:'Availability',
        APPOINTMENT_TYPES:'Appointment Types',
        SCHEDULER_LINK: 'Scheduler Link',
        INTAKE_FORM: 'Intake Form',
        APPOINTMENT_NAME: 'Appointment Name (required)',
        LOCATION: 'Location',
        TASKS:'Tasks',
        UNIT_CRM:'Unit Crm',
        NOTIFICATION_SETUP:'Notification Setup',
        DURATION: 'Duration',
        MIN_ADVANCE_NOTICE: 'Minimum Advance Notice ',
        DESCRIPTION: 'Description',
        COPY_TO_CLIPBOARD: 'Copy to my clipboard',
        SCHEDULER_LINK_TITLE: 'My appointment scheduler link',
        SCHEDULING_PAGE: 'Appointment Scheduler',
        ADD_BUTTON: 'Add a new schedule',
        MY_SCHEDULAR_LINK: 'My Scheduler Link',
        MY_APPOINTMENT_TYPE: 'My Appointment Type',
        APPOINTMENT_TYPE: 'Appointment Type',
        DEFINE_YOUR_INTAKE_FORM: 'Define Your Intake Form',
        REQUIRED: 'Required',
        ADD_ANOTHER_FIELD: 'Add Another Field',
        SUBMIT_BUTTON_LABEL: 'Submit Button Label',
        CHOOSE_A_STATUS_FOR_LEAD: 'Choose a status for the leads added from the scheduler',
        WORKFLOW_STUP_REULES:'Lead conversion Rules',
        DEFINE_YOUR_THANK_YOU_MESSAGE: 'Define Your Thank You Message',
        THANK_YOU_MESSAGE: 'Thank you message',
    },
    RELATION_REPORT: {
        RELATION_REPORT_LABEL: 'Relation Matrix',
        REPORT_SAVE_LABEL: 'Save Customization',
        REPORT_NAME_LABEL: 'Custom report name',
        REPORT_NAME_TEXTFIELD_LABEL: 'Report name',
        ROW_FIELD_LABEL: 'Row Field',
        COL_FIELD_LABEL: 'Column Field',
        DATE_FIELD_LABEL: 'Date Field',
        PDF_LABEL_DURATION: 'Duration',
        RELATION_FIELD_LABEL: 'Relation',
        SAVE_CUSTOMIZATION_LABEL: 'Save customization',
        RUN_BUTTON_LABEL: 'Run'
    },
    COMPANY_PROFILE: {
        UPLOAD_LOGO: 'Upload your logo',
        WEBSITE: 'Website',
        BUSINESS_NAME: 'Business Name',
        BUSINESS_ADDRESS: 'Business Address',
        BUSINESS_PHONE: 'Business Phone',
        BUSINESS_EMAIL: 'Business Email',
        CORPORATE_CURRENCY: 'Corporate Currency'
    },
    EXPORT_SETTINGS: {
        EXPORT_HEADING: 'Export',
        FILE_NAME_REQUIRED: 'File Name (required)',
        REVIEW_WEBSITE_REQUIRED: 'Review Website (required)',
        PARENT_OBJECT_REQUIRED: 'Parent Object Name (required)',
        FILE_TYPE_REQUIRED: 'File Type (required)',
        QUERY_NAME_REQUIRED: 'Query Name (required)',
        SELECTED_FIELDS_FOR_PARENT: 'Selected fields for parent',
        AVAILABLE_FIELDS_FOR_PARENT: 'Available fields for parent',
        CHILD_OBJECT_NAME: 'Child Object Name',
        AVAILABLE_FIELDS_FOR_CHILD: 'Available fields for child',
        SELECTED_FIELDS_FOR_CHILD: 'Selected fields for child',
        EXPORT_BUTTON: 'Export',
        DOWNLOAD_BUTTON: 'Download File',
        ADD: 'Add',
        ADD_ALL: 'Add all',
        REMOVE: 'Remove',
        REMOVE_ALL: 'Remove all'
    },
    QUEUE: {
        QUEUE: 'Queue',
        PREVIOUS: 'Previous in the queue',
        NEXT: 'Next in the queue',
        REMOVE: 'Remove from the queue',
        CLOSE: 'Exit the queue',
    },
    SECURITY_LEVEL: {
        ACCESS: 'Access',
        DESCRIPTION: 'Description',
        VIEW: 'View',
        PERMISSIONS: 'Permissions',
        OPERATIONS: 'Operations',
        ADMINISTRATION: 'Administration',
        ASSIGNED_MENU: 'Assigned Menu',
    },
    ROLL_UP: {
        CHILD_OBJECT_NAME: 'Child Object',
        PARENT_OBJECT_NAME: 'Parent Object',
        CHILD_FIELD_NAME: 'Child Field Name',
        PARENT_FIELD_NAME: 'Parent Field Name',
    },
    ROYALTY_REPORT: {
        ROYALTY_REPORT_LABEL: 'Royalty Report',
        ROYALTY_PERCENT: 'Royalty %',
        SHOW: 'Show',
        ALL_INVOICES: 'All Invoices',
        PAID_INVOICE_ONLY: 'Paid Invoices Only',
        SETUP_TITLE: 'Royalties Setup',
        PAID_INVOICES: 'Paid Invoices',
        USE: 'Use',
        FIXED: 'Fixed %',
        VARIABLE: 'Variable %',
        TOTAL_INVOICES: 'Total Invoices',
        TOTAL_PAYMENTS: 'Total Payments',
        CALCULATE: 'Calculate',
        WEEKLY: 'Weekly',
        MONTHLY: 'Monthly',
        WEEK_TYPE: 'Week Type',
        MON_TO_FRI: 'Mon - Fri',
        MON_TO_SUN: 'Mon - Sun',
        ADD_INVOICES: ' Add Invoice',
        TOTAL_Reported_Sales: 'Total Reported Sales',
        YEAR: 'Year',
        MARKETING_FEE: 'Marketing Fee',
        TECH_FEE: 'Tech Fee',
        PERCENT: 'Percentage',
        FIXED_VALUE: 'Fixed',
        WITH: 'With Fees',
        WITH_OUT: 'Without Fees',
        Reported_Sales_Franchisee: 'Reported Sales - Franchisee',
        Reported_Sales_Franchisor: 'Reported Sales - Franchisor',
        Reported_Sales_ThirdParty: 'Reported Sales - Third Party',
        AVAILABLE_FIELDS: 'Available Fields',
        SELECTED_FIELDS: 'Selected Fields',
    },
    POWER_CALLING: {
        NOTE_AND_CALL_SCRIPTS: 'Note and Call Scripts',
        SEND: 'SEND',
        LOG_CALL: 'Log Call',
        CALLER_ID: 'Caller ID',
        END_CALL: 'End Call',
        MOBILE: 'Mobile',
        PHONE: 'Phone',
        HOME_PHONE:'Home Phone',
        NEXT: 'next',
        COMPLETE: 'complete',
        OF: 'of',
        CHANGE_GROUP: 'Change',
        POWER_DIALER: 'Power Dialer'
    },
    LEAD_SCORE_SETUP: {
        SCORES: 'Scores',
        NO_SCORES_AVAILABLE: 'There are no actions available.',
        OBJECT: 'Object',
        GROUPS: 'Groups',
        WORKFLOW_NAME: 'Workflow Name',
        CONVERTED_FROM: 'Converted From',
        CONVERTED_TO: 'Converted To',
        FIELDS_NAME: 'Fields Name',
        POINTS: 'Points',
        EXPIRES_BY: 'Expires by',
        APPLY_TO_EXISTING_SCORE: '*The expiration date will retroactively apply to your existing scores.',
        GROUP_ALREADY_REMOVE: 'Group already removed. Please modify before saving.',
        FIELD_ALREADY_REMOVE: 'Field already removed. Please modify before saving.',
        FIELD_VALUE_ALREADY_REMOVE: 'Field value already removed. Please modify before saving.',
        AND_ABOVE: 'and above',
        LESS_THAN: 'Less than',
    },
    DUPLICATE_RULE: {
        DUPLICATE_RULE: 'Duplicate Rules',
        DUPLICATE_RULE_HEAD_LABEL: 'Check Duplicates Using',
        SELECT_OBJECT_LABEL: 'Select Object',
        ACTIVE: 'Active',
        DEFAULT: 'Default',
        ONE_FIELD: 'One Field',
        TWO_FIELD: 'Two Fields',
        THREE_FIELD: 'Three Fields',
        FIELD_ONE: 'Field 1',
        FIELD_TWO: 'Field 2',
        FIELD_THREE: 'Field 3',
        SELECT_FIELD_LABEL: 'Select Field',
        CHECK_DUPLICATES: 'Check Duplicates',
        SELECT_RULES: 'Select a Rule',
    },
    JOBS: {
        RESCHEDULE: 'Reschedule',
        OMW: 'OMW',
        INVOICE: 'Invoice',
        PAY: 'Pay'
    },
    TASK_REPORT:{
        TITLE:'Corrective Actions Report',
        CORRECTIVE_ACTION_REPORT_DETAILS:'Corrective Actions Report Details',
        UNITS:'Units',
        NO_TASK_FOUND:'No tasks found',
    },
    AUDIT_QUESTIONS: {
        MINIMUM_OPTIONS: 'There should at least 2 choices must be in this question',
        SINGLE_RECORD: 'Only 1 record is allowed to move at a time.',
        AUDIT_QUESTIONS: 'Audit Questions',
        GIVE_FEEDBACK:'Give Feedback',
        NEW_QUESTION: 'New Question',
        TEXT: 'Short Text',
        COMMENT: 'Long Text',
        LIST: 'Single Choice (dropdown)',
        CHECKBOX: 'Multiple Choice (checkboxes)',
        QUESTION: 'Question',
        ADD_NEW: 'Add New',
        ADD_NEW_CATEGORY:'Add a New Category',
        SINGLE_RECORD_EDIT: 'Please select 1 record to edit.',
        MESSAGE_DELETE: 'Are you sure want to delete',
        SELECTED_RECORDS: 'selected record(s)?',
        YOUR_ANSWER: "Your answer",
        SUBMITTING: 'Submitting...',
        SAVE_CATEGORY:'Save Category',
        SUBMIT_AUDIT:'Submit Audit',
        SAVING:'Saving...',
        SUBMIT: 'Submit',
        SCORE: 'Score',
        REQUIRED: 'Required',
        CHOICE: 'Choice',
        FIELD_REQUIRED: 'This field is required *',
        NEW_CHOICE: 'New Choice',
        FREE_FORM: 'Free Form',
        SCORE_HELP_TOOLTIP: "Assign points or scores to answers. The scores will add up to calculate the total score for the audit.",
        SURVEY_LINK: 'Audit/Survey Link',
        REQUIRES_PHOTO: 'Requires Photo',
        ENABLE_NOTE: 'Enable Note',
        ADD_PHOTO: 'Photo',
        UPDATE_PHOTO: 'Update',
        NOTE: 'Add a Note',
        PHOTO_REQUIRED: 'Photo is required *',
        NEXT_CATEGORY:'Next Category',
        GENERATE:'Generate',
        FIELD_AUDIT:'Field Audit (audited by the franchisor)',
        SELF_AUDIT:'Self Audit (audited by the franchisee)',
        GENERATE_AUDIT_LINK:'Choose a Checklist',
    },
    AUDIT_ANSWERS: {
        AUDIT_ANSWERS_LABEL: 'Audit Answer',
        QUESTION: 'Question',
        SCORE: 'Score',
        ANSWER: 'Answer',
        SUBMIT_CORRECTIVE_ACTIONS:'Submit Corrective Actions',
        REQUEST_CORRECTIVE_ACTIONS:'Request Corrective Actions',
        INSERT_PHOTO:'Insert Photo',
    },
    AUDIT_WEAKNESS:{
        CATEGORY:'Category',
        MIN_FAILURE_COUNT:'Min Failure Count',
    },
    AUDIT_CATEGORY:{
        ADD_NEW_CATEGORY:'Add a New Category',
        MANAGE_CATEGORY: 'Manage Categories',
        CATEGORIES:'Categories',
        CATEGORY_QUESTIONS_LABLE:' Category Questions',
    },
    COMPLIANCE_REPORT:{
        COMPLIANCE_REPORT_LABEL: 'Compliance Report',
    },
    COMPLIANCE:{
        EDIT_TAB_LABEL: 'Edit Compliance',
    },
    ROYALTY_PLAN:{
        EDIT_TAB_LABEL: 'Edit Royalty Plan',
        COPY_TAB_LABEL: 'Copy Royalty Plan',
        ADD_CALCULATED_FIELD:'Add Calculated Field',
        TAX_INCLUDED:'Include Tax'
    },
    ROYALTY_RUN: {
        REPORT_SALES :'Report Sales',
	    PROCESS :'Process',
	    SUBMIT:'Submit',
        INVOICE_ALL :'Invoice All',
	    EMAIL_ALL:'Email All',
        POST_ALL: 'Post All',
    },
    REPORTED_SALES: {
        UPDATE :'Update',
	    CLOSE :'Close',
        EDIT_TAB_LABEL: 'Edit Reported Sales'
    },
    ROYALTY_REPORT_V2: {
        PLAN_NAME: 'Plan Name',
        PERIOD: 'Period',
        START_DATE: 'Start Date',
        END_DATE: 'End Date',
        UNIT: 'Unit',
        GROUP_BY: 'Group By',
        SORT_BY: 'Sort By',
    },
    AUDIT_REPORT: {
        AUDIT_SCORE_DETAILS : 'Audit Score Details',
    },
    AUDIT_TASK_REPORT_DETAILS:{
        TASK_REPORT_DETAILS:'Task Report Details',
    },
    SALES_AND_REFUND: {
        PERIOD:'Period',
        START_DATE:'Start Date',
        END_DATE :'End Date',
        BASED_ON :'Based On',
        SALES_AND_REFUND_TITLE:'Sales and Refunds',
        SALES_AND_REFUND_REPORT:'Sales And Refund Report',
        SALES_AND_REFUND_DETAIL:'Sales And Refund Details',
    },
    AUDIT_WEAKNESS_REPORT:{
        AUDIT_WEAKNESS_REPORT_TITLRE:'Audit Weakness Report',
        AUDIT_WEAKNESS_FAILED_REPORT :'Audit Weakness Failed Report'
    },
    AUDIT_PERFORMANCE_REPORT:{
        AUDIT_PERFORMANCE_REPORT_TITLE:'Audit Performance Report',
        AUDIT_PERFORMANCE_REPORT_CHART_TITLE:'Audit Performance Report Chart',
        AUDIT_PERFORMANCE_REPORT_CHART_AVERAGE_SCORE_TOOLTIP:'Average Score',
    },
    SALES_AND_REFUND_DETAILS_REPORT:{
        SALES_AND_REFUND_DETAILS :'Sales And Refund Report Details',
        START_DATE:'Start Date',
        END_DATE:'End Date',
        BASED_ON:'Based On',
        NO_RECORD_EXPORT: 'No Record Found'
    },
    AUDIT_CHECKLISTS:{
        TITLE:'New Audit Checklist',
    },
    UNIT_CRM:{
        MY_SETTINGS:'My Settings',
        COMPANY:'Company',
        USER_AND_SECURITY:'Users and Security',
        SURVEYS: 'Surveys',
        WORKFLOW_RULES_SECTION:'Workflows & Rules',
        EMAIL_ALIASES:'Email Aliases',
        CUSTOMIZATION:'Customization',
        LISTS:'Lists',
        DATA:'Data',
        COMPANY_SCHEDULER:'Company Scheduler',
        FRANCHISE:'Franchise',
        INTEGRATION:'Integration',
        SUBSCRIPTION:'Subscription',
        CAMPAIGNS:'Campaigns',
        BOARDCAST_CAMPAIGNS :'Broadcast Campaigns',
        AUTOMATED_CAMPAIGNS:'Automated Campaigns',

    },
    INTAKE_FORM :{
        LAST_NAME: 'Last Name (required)',
        FIRST_NAME:'First Name (required)',
        MIDDLE_NAME :'Middle Name',
        ADMISSION_DATE:'Admission Date',
        ADMIT:'Admit',
        DOB:'DOB (required)',
        AGE:'Age',
        GENDER:'Gender (required)',
        PRONUONS:'Pronouns (required)',
        ALLERGIES:'Allergies (required)',
        SMOKING:'Smoking (required)',
        RELIGION:'Religion',
        MARITAL_STATUS:'Marital Status (required)',
        AGE_OF_CHILDREN:'Age of Children',
        ADDRESS:'Address (required)',
        CITY:'City (required)',
        STATE:'State (required)',
        ZIPCODE:'Zip Code (required)',
        COUNTRY:'Country',
        EMAIL_REQUIRED_LABEL:'Email (required)',
        PHONE:'Phone (required)',
        OCCUPATION:'Occupation',
        CURRENT_TRAETMENT:'Current Treatment (List any current PCP, Therapist, Psychiatrist, Life Coach etc)',
        CURRENT_TRAETMENT_PLACEHOLDER:'Include Name & Contact Information/Dates of Tx/What are you seeing them for?',
        DESCRIBE_EXPOSURE:'Describe any exposure to the 12 Step Community, recovery related exposure OR workshops',
        CURRENT_MEDICATIONS:'Current Medications (including prescriptions, vitamins, supplements and over the counter medications)',
        CURRENT_MEDICATIONS_PLACEHOLDER:'Include Dosage/Time on it/Reason',
        MEDICATION_CHANGES:'Please explain any recent medication changes (any changes within the past 30 days)',
        EFFECT_OF_MEDICATION:'Please describe any effects of your medication that you experience (i.e., fatigue, jitteriness, insomnia, etc.)',
        NAVIGATE_STAIRS:'Can you navigate stairs?',
        EXPLANTION:'Explanation',
        CARE:'Can you care for yourself?',
        MEDICAL_ALLERGIES:'Do you have any medical allergies?',
        FOOD_ALLERGIES:'Do you have any food allergies?',
        SPECIAL_DIET:'Do you have a special diet?',
        MEDICAL_CONDITION:'Do you have any medical conditions?',
        MEDICAL_NEEDS:'Are there medical needs you have (i.e., sharps containers, c-pop mach?)',
        SURGERIES_OR_MEDICAL_PROCEDURES :'Have you had any surgeries or medical procedures in the last 12 months?',
        MEDICAL_PROCEDURE:'Are you expecting to have any medical procedures or surgeries in the next 3 months?',
        PHYSICAL_HEALTH:'How would you describe your overall physical health?',
        FINANCIAL_PAYOR:'Financial Payer',
        CONTACT_NUMBER:'Contact Number',
        EMAIL:'Email',
        EMERGENCY_CONTACT:'Emergency Contact',
        RELATIONSHIP:'Relationship',
        THERAPIST_NAME:'Therapist Name',
        REFERRED_BY:'Referred By',
        MEDICAL_RESTRICTIONS:'Medical Needs/Restrictions',
        LAST_NAME_REQUIERD:'Last name is required',
        FIRST_NAME_REQUIRED:'First name is required',
        AGE_REQUIERD:'Age is required',
        DOB_REQUIRED:'DOB is Requierd.',
        PRONOUNS_REQUIERD:'Pronouns is required',
        ALLERGIES_REQUIERD:'Allergies is required',
        RELIGION_REQUIERD:'Religion is required' ,
        AGE_OF_CHILDREN_REQUIRED:'Age of Children is required',
        ADDRESS_REQUIRED: 'Address is required',
        CITY_REQUIRED: 'City is required' ,
        ZIPCODE_REQUIRED: 'ZipCode is required',
        EMAIL_REQUIRED:'Email is required',
        PHONE_REQUIRED:'Phone Number is required',
        GENDER_REQUIRED: 'Gender is required',
        SMOKING_REQUIRED:'Smoking is required',
        MARITAL_STATUS_REQUIRED:'Marital Status is required',
        STATE_REQUIRED: 'State is required',
    },
    TOTAL_REPORTED_SALES_DRILLDOWN:{
        TOTAL_REPORTED_SALES_DRILLDOWN:'Royalty Reported Sales Drilldown'
    },
    OTHER_FORM_DATA: {
        CARETAKING:'Caretaking',
        EXPLANATION:'Explanation',
        EXPLANATION_HISTORY_TIMELINE:'Explanation of history/timeline (dates/places/etc)',
        PEOPLE_PLEASING:'People Pleasing',
        RELATIONSHIPS:'Relationships',
        PERFECTIONISM:'Perfectionism',
        CONTROL:'Control',
        WORKAHOLISM:'Workaholism',
        SHOPPING_SPENDING:'Shopping/Spending',
        RELIGION_OR_ABUSE:'Religion/Abuse',
        RISK_TAKING:'Risk Taking',
        EXCESSIVE_SCREEN_TIME:'Excessive Screen time',
        DEPRESSION:'Depression',
        ANXIETY:'Anxiety',
        ANGER_OR_RAGE:'Anger/Rage',
        SELF_EXTEEM:'Self Esteem',
        PAINFUL_OR_TROMATIC :'Describe any painful/traumatic/memorable experiences or events that have been impactful in your life (trauma)',
        MOTHER:'Mother',
        FATHER:'Father',
        SIBLINGS:'Siblings',
        CHILDREN:'Children',
        SIG_OTHER:'Sig. Other',
        OTHER:'Other',
        PRESENTING_PROBLEM:'Describe the main presenting problem or current crisis/goal for coming to TBR',
        ALCOHAL:'Alcohol',
        HOW_MUCH:'How Much & What type?',
        HOW_OFTEN:'How often, and for how long?',
        DATE_LAST_USED:'Date Last Used',
        COCAINE_OR_CRACK :'Cocaine/Crack',
        HEROINE:'Heroin',
        MARIJUANA:'Marijuana',
        METHADONE_OR_SUBOXONE :'Methadone/Suboxone',
        AMPHETAMINES :'Amphetamines & Stimulants (Meth, Adderall, Speed)',
        NARCOTICS_OR_OPIATES:'Narcotics/Opiates',
        BENZODIAZEPINES:'Benzodiazepines',
        TOBACCO:'Tobacco',
        OTHER_ETC:'Other (Hallucinogenic, OTC, Synthesis, etc)',
        WITHDRAWEL_SYMPTOMS :'Withdrawal Symptoms (shaking, sweating, tremors, seizures etc)',
        MEDICAL_DETOX:'Medical Detox',
        PRIMARY_TRATMENT_OR_IOP:'Primary Treatment/IOP',
        SUCIDE_THOUGHT:'Had suicidal Thoughts?',
        ENGAGED_IN_SELF_HARD:'Engaged in Self Hard',
        ATTEMPED_SUCIDE:'Attempted Suicide While under the influence?',
        ENGAGED_IN_CUTTING:'Engaged in "cutting" behaviors',
        ATTEMPED_SUCIDE_WHITE_SOBER:'Attempted suicide while Sober?',
        ENGEGED_IN_SCORINCATION :'Engaged in "scorincation"' ,
        HOSPITALIZED_FOR_A_SUICIDE_ATTEMPT:'Been hospitalized for a suicide attempt?',
        ENGEGED_IN_BODY_MUTATION:'Engaged in any other body mutation bx',
        HISTORY_EXPLANATION:'If history, explanation (use this space to provide further details, including dates, current ideation, thoughts or plans, etc.)',
        PHYCHIATRIC_DIANOSIS:'Psychiatric diagnosis',
        DATES_DIAGNOSIS_TREATMENT:'Date, diagnosis, treatment',
        MENTAL_BREAKDOWNS_OR_HOSPITALIZATIONS:'Mental Breakdowns/Hospitalizations' ,
        ANOREXIA:'Anorexia',
        TREATMENT_HISTORY:'Treatment History',
        PERIODS_OF_ABSTINENCE:'Periods of Abstinence',
        LAST_EPISODE:'Last Episode',
        LOWEST_WEIGHT:'Lowest Weight',
        HIGHEST_WEIGHT:'Highest Weight',
        CURRENT_WEIGHT_OR_HEIGHT: 'Current Weight/Height',
        HOW_LONG_AT_CURRENT_WEIGHT:'How Long at Current Weight',
        BULIMIA:'Bulimia',
        OVEREATING:'Overeating',
        EXCESSING_EXCERCISING:'Excessive Exercising',
        DETAILS_SOBRIETY_LENGTH_PATTERNS:'If history, explanation (use this space to provide further details, sobriety length, patterns etc.)',
        BETTING:'Betting',
        ONSET:'Onset',
        TREATMENT:'Treatment',
        AMOUNT_OF_DEBT_ACCUMULAATED:'Amount of Debt accumulated',
        TIME_PER_DAY_OR_WEEK_SPENT_GAMBLING:'Time per day/week spent gambling',
        GAMING_OR_CASINOS :'Gaming/Casinos',
        ONLINE_GAMES_OR_APPS:'Online Games/Apps' ,
        PORNOGRAPHY:'Pornography',
        FIRED:'Have you ever been fired or had job threatened because of behavior?',
        EXCESSIVE_MASTURBATION:'Excessive Masturbation',
        VOYEURISM_OR_EXHIBITIONIST:'Voyeurism/Exhibitionist',
        ANONYMOUS_SEX:'Anonymous Sex (casual sex, one-night stands, hookup apps/websites)',
        PROSTITUTION:'Prostitution',
        AFFAIRS:'Affairs (Physical/Emotional)',
        SERIAL_RELATIONSHIP:'Serial Relationships',
        INCLUDE_DATE_TEASON_OR_CHARGE:'Include Date, Reason/Charge (What was the charge/reason for arrest), Result (was there a conviction, jail time served, probation/parole, sex offender registration etc), Current Pending/Legal Obligations',
        CODEPENDENCY_AND_PROCESS_ADDITIONS_BEHAVIOURS:'Codependency and process addictions behaviors ("C" for Current, "D" Denies or "H" for History)',
        RELATIONSHIP_AND_FAMILY_HISTORY:'Relationship/family history (List current relationship status and family history of addiction/psych issues)',
        SUBSTANCE_HISTORY:'Substance history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUBSTANCE_TREATMENT_HISTORY:'Substance treatment history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUICIDIAL_IDEATION:'Suicidal ideation / self harm behaviors ("C" for Current, "D" Denies or "H" for History)',
        MENTAL_HEALTH_AND_PSHYATRATIC_DIAGNOSIS_HISTORY :'Mental health/ psychiatric diagnosis history- ("C" for Current, "D" Denies or "H" for History)',
        EATING_DISORDER_BEHAVIER:'Eating disorder behaviors - ("C" for Current, "D" Denies or "H" for History)',
        GAMBLIMG_BEHAVIER:'Gambling behaviors - ("C" for Current, "D" Denies or "H" for History)',
        SEXUAL_RELATIONSHIP_LOVE_BEHAVIOUR:'Sexual/relationship/love behaviors- ("C" for Current, "D" Denies or "H" for History)',
        ARRESTED_HISTORY:'Arrest history (including prior/current and pending)',
    },
    PHONE_NUMBERS: {
        USE_NOTIFICATION_TOOLTIP: 'Use for Notification',
        REMOVE_NOTIFICATION_TOOLTIP: 'Remove for Notification',
    },
    TWILIO_SMS_VERIFIACTION:{
        LEGAL_ENTITY_NAME: 'Legal Entity Name (required)',
        WEBSITE_URL: 'Website URL (required)',
        FIRST_NAME: 'First Name (required)',
        LAST_NAME: 'Last Name (required)',
        EMAIL_ADDRESS: 'Email Address (required)',
        PHONE_NUMBER:'Phone Number (required)',
        COUNTRY: 'Country (required)',
        ADDRESS: 'Address (required)',
        CITY:'City (required)',
        STATE : 'State (required)',
        ZIP:'Zip (required)',
        USE_CASE:'Use Case(required)',
        USE_CASE_DESCRIPTION:'Use Case Description (required)',
        OPT_IN_MESSAGE:'OPT in Message (required)',
        CONSENT_PROOF: 'Consent Proof (Form of Screenshot URL) (required)',
        SAMPLE_MESSAGE_1: 'Sample Message #1 (required)',
        SAMPLE_MESSAGE_2: 'Sample Message #2 (required)',
        BUSINESS_LOCATION: 'Business Location',
        BUSINESS_REGISTRATION_IDENTIFIER:'Business Registration Identifier (required)',
        BUSINESS_REGISTRATION_NUMBER : 'Business Registration Number (required)',
        BUSINESS_TYPE: 'Business Type (required)',
        BUSINESS_INDUSTRY:'Business Industry (required)',
        BUSINESS_IDENTITY:'Business Identity (required)',
        BUSINESS_TITLE:'Business Title  (required)',
        JOB_POSITION:'Job Position (required)',
        BRAND_TYPE:'Brand Type (required)',
        COMPANY_TYPE:'Company Type (required)',
        BRAND_NAME:'Brand Name (required)',
        
        LEGAL_ENTITY_NAME_REQUIRED: 'Legal Entity Name is required',
        WEBSITE_URL_REQUIRED: 'Website URL is required',
        FIRST_NAME__REQUIRED: 'First Name is required',
        LAST_NAME_REQUIRED: 'Last Name is required',
        EMAIL_ADDRESS_REQUIRED: 'Email Address is required',
        PHONE_NUMBER_REQUIRED:'Phone Number is required',
        COUNTRY_REQUIRED: 'Country is required',
        ADDRESS_REQUIRED: 'Address is required',
        CITY_REQUIRED:'City is required',
        STATE_REQUIRED : 'State is required',
        ZIP_REQUIRED:'Zip is required',
        USE_CASE_REQUIRED:'Use Case is required',
        USE_CASE_DESCRIPTION_REQUIRED:'Use Case Description is required',
        OPT_IN_MESSAGE_REQUIRED:'OPT in Message is required',
        CONSENT_PROOF_REQUIRED: 'Consent Proof is required',
        SAMPLE_MESSAGE_1_REQUIRED: 'Sample Message #1 is required',
        SAMPLE_MESSAGE_2_REQUIRED: 'Sample Message #2 is required',
        BUSINESS_REGISTRATION_IDENTIFIER_REQUIRED:'Business Registration Identifier is required',
        BUSINESS_REGISTRATION_NUMBER_REQUIRED : 'Business Registration Number is required',
        BUSINESS_TYPE_REQUIRED: 'Business Type is required',
        BUSINESS_INDUSTRY_REQUIRED:'Business Industry is required',
        BUSINESS_IDENTITY_REQUIRED:'Business Identity is required',
        BUSINESS_TITLE_REQUIRED:'Business Title is required',
        JOB_POSITION_REQUIRED:'Job Position is required',
        BRAND_TYPE_REQUIRED:'Brand Type is required',
        COMPANY_TYPE_REQUIRED:'Company Type is required',
        BRAND_NAME_REQUIRED:'Brand Name is required'
    },
    LMS_USERS:{
        FIRST_NAME_REQUIRED: 'Please enter First Name.',
        LAST_NAME_REQUIRED: 'Please enter Last Name.',
        PASSWORD_REQUIRED: 'Please enter Password.',
        EMAIL_REQUIRED: 'Please enter Email.',
        USER_ID:'User ID',
        FIRST_NAME:'First Name (required)',
        LAST_NAME:'Last Name (required)',
        PASSWORD:'Password (required)',
        EMAIL:'Email (required)',
        ROLE:'Role',
        GROUP:'Group'
   },
     PHONE_AND_TEXT_INTEGRATION: {
         MANAGE_UNIT_PHONE: 'Manage unit\'s phone numbers, text credits and call minutes',
     },
     WEBHOOKS_RULES: {
        SETUP_FIRST_RULE: 'Setup your first rule',
        WEBHOOKS_RULE: 'Webhook',
        WEBHOOKS_RULE_CREATE_TAB_LABEL: 'Create Assignment Rule',
        WEBHOOKS_RULE_EDIT_TAB_LABEL: 'Edit Webhook',
        NAME_REQUIRED: 'Name (required)',
        STATUS: 'Status',
        OBJECT: 'Object',
        ENDPOINT_URL: 'Endpoint URL (required)',
        DATA: 'Data (required)',
        FAILURE_NOTIFICATION_RECIPIENTS: 'Failure Notification Recipients',
        CONDITION:'Condition (required)',
        HEADERS:'Headers'
    },
    TEMPLATE_CATEGORIES: {
        TEMPLATE_CATEGORY_REQUIRED : 'Please select a category',
        TEMPLATE_SUBCATEGORY_EXISTS : 'Sub Category name already exists',
        TEMPLATE_SUBCATEGORY_REQUIRED : 'Please enter a subcategory',
        TEMPLATE_SUBCATEGORY_LENGTH : 'Subcategory name should be less than 256 character'
    },
}
export default label